import React, { useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';


// import './OSINTFilter.css';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';

const defaultSearchValue = '';
const numPostsOptions = [10, 20, 50, 100];
const sources = ['twitter']; //,'reddit','news']
const activeSources = ['twitter'];

// eslint-disable-next-line no-empty-pattern
export default function OSINTFilter(props) {
  const {
    searchTerm,
    setSearchTerm,
    onChangeSearch,
    numPosts,
    setNumPosts,
    selectedSources,
    setSelectedSources,
    includeKeywords,
    setIncludeKeywords,
    excludeKeywords,
    setExcludeKeywords,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    submitClicked,
    subscriptionValidated,
  } = props;

  //const [searchTerm, setSearchTerm] = useState(defaultSearchValue );
  const [queryList, setQueryList] = useState([
    { label: defaultSearchValue, value: defaultSearchValue },
  ]);
  const [selectedQuery, setSelectedQuery] = useState(queryList[0]);

  const reduxOSINTAskResponses = useSelector((state) => {
    return state.app.OSINTAskResponses;
  });
  const isOSINTAskLoaded = useSelector((state) => {
    return state.app.isOSINTAskLoaded;
  });

  // functions
  /*
  const handleChangeSearch = (e) => {
    setSearchTerm(e.target.value);
  }

  const onSearchHandler = () => {
    onChangeSearch(searchTerm);
    if (searchTerm) {
      const _queryList = queryList;
      _queryList.push({
        label: searchTerm,
        value: searchTerm
      });
      setQueryList(_queryList);
    }
  }

  const handleChangeQueryList = (e) => {
    setSelectedQuery(e);
    setSearchTerm(e.value);
    onChangeSearch(e.value);
  }
  */

  function sourceClicked(s) {
    if (selectedSources.find((src) => src === s)) {
      setSelectedSources(selectedSources.filter((src) => src !== s));
    } else {
      setSelectedSources([...selectedSources, s]);
    }
  }

  return (
    <>
      <div className="bg-neutral-800 rounded p-3 p-md-4 mb-4">
        <h6 className="mb-2">Search</h6>

        <div className="form-search-fluid">
          <StylishNewSearchBar
            value={searchTerm}
            onChangeSearchTerm={(e) => setSearchTerm(e)}
            placeholder="Enter search term"
          />
        </div>
        <Accordion defaultActiveKey="0" className="dark mt-4 captalize-title">
          <Accordion.Item>
            <Accordion.Header>Advance Search Options</Accordion.Header>
            <Accordion.Body>
              <div className="mb-4">
                <label className="form-label">Number of Posts</label>
                <StylishNewSelect
                  value={{
                    value: numPosts,
                    label: numPosts.toString(),
                  }}
                  defaultValue={numPosts}
                  options={numPostsOptions.map((option) => ({
                    value: option,
                    label: option.toString(),
                  }))}
                  onChange={(e) => {
                    setNumPosts(e.value);
                  }}
                  placeholder={'Select number of posts'}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              </div>

              <div className="mb-4">
                {sources.map((s, idx) => {
                  return (
                    <div
                      key={`OSINTFilter-source-${s}`}
                      className={idx !== 0 ? 'mt-2' : ''}
                    >
                      <StylishNewCheckbox
                        checked={!!selectedSources.find((src) => src === s)}
                        onClick={() => sourceClicked(s)}
                        label={s}
                        disabled={true}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="mb-4">
                <label className="form-label">
                  Include Keywords (comma-separated)
                </label>
                <StylishNewTextArea
                  value={includeKeywords}
                  onChange={(e) => setIncludeKeywords(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="form-label">
                  Exclude Keywords (comma-separated)
                </label>
                <StylishNewTextArea
                  value={excludeKeywords}
                  onChange={(e) => setExcludeKeywords(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <StylishDateTimePicker
                  type="datetime-local"
                  value={startTime}
                  onChange={(date) => setStartTime(date)}
                  dateLabel={'Start Date'}
                  timeLabel={'Start Time'}
                />
              </div>
              <div className="mb-0">
                <StylishDateTimePicker
                  type="datetime-local"
                  value={endTime}
                  onChange={(date) => setEndTime(date)}
                  minDate={new Date(startTime)}
                  dateLabel={'End Date'}
                  timeLabel={'End Time'}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="button-group justify-content-end mt-4">
          {!subscriptionValidated && (
            <div>Pratus Premium required for OSINT</div>
          )}
          <StylishNewButton
            type="button"
            className="button--primary w-100 w-md-auto"
            onClick={() => {
              submitClicked();
            }}
            disabled={!subscriptionValidated}
          >
            Submit
          </StylishNewButton>
        </div>
      </div>

      {/*
        <div className="bg-gray-800 rounded p-3 p-md-4">
        <h6 className="mb-2 mt-3">Search history</h6>
        <StylishNewSelect
          options={queryList}
          value={selectedQuery}
          placeholder="Search Query List"
          onChange={(e) => handleChangeQueryList(e)}
          isClearable={false}
          isSearchable={false}
          isMulti={false}
        />
        </div>
        */}

      {/*      <div className="bg-gray-800 rounded p-3 p-md-4">
        <h4 className="m-0">AutoSITREP</h4>
        <hr className="dashed my-3" />

        {(!!reduxOSINTAskResponses.answer && !!isOSINTAskLoaded && (
          <>
            <p className="m-0">{reduxOSINTAskResponses.answer}</p>
            <hr className="dashed my-3" />
          </>
        )) ||
          (!isOSINTAskLoaded && (
            <>
              <h6 className="m-0 d-flex align-items-center">
                <i className="fa fa-2x fa-spinner fa-pulse me-3"></i>
                Loading AutoSITREP...
              </h6>
              <hr className="dashed my-3" />
            </>
          ))}

        <p className="m-0 fst-italic weight-500">
          Note: AutoSITREP is in a pre-Alpha development phase. Use discretion
          when applying AutoSITREP to your decisions.
        </p>
      </div>
      */}
    </>
  );
}
