import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  deleteCommunication,
  fetchCommunication205,
  getPlanningWorksheet205,
  upsertCommunication205,
} from 'actions/IAPForm204Actions';
import { fetchSignatures } from 'actions/signatureActions';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { Config205 } from './FormTemplates/FormConfig/205Config';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { AddCommunicationDialog } from './AddCommunicationDialog';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { startLoading } from 'reducers/loading/loading.action';
import { useNavigate } from 'react-router-dom';

export const IAPForm205 = ({ onFormRecordDataUpdate }) => {
  const [incidentName, setIncidentName] = useState();
  const [formState, setFormState] = useState(Config205);
  // const [specialInstructions, setSpecialInstructions] = useState('');
  const [openCommunicationModal, setOpenCommunicationModal] = useState(false);
  const [communicationModalMode, setCommunicationModalMode] = useState('Add');
  const [communicationEditData, setCommunicationEditData] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [preparedByDateTime, setPreparedByDateTime] = useState(moment());

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxUser = useSelector((state) => {
    return state.app.user;
  });
  const reduxSignatures = useSelector((state) => {
    return state.app.signatures;
  });
  const isFetchSignaturesLoaded = useSelector((state) => {
    return state.app.isFetchSignaturesLoaded;
  });
  const planningWorksheet = useSelector((state) => {
    return state.app.planningWorksheet;
  });
  const communication = useSelector((state) => state.app.communication);

  const reduxDispatch = useDispatch();

  const navigate = useNavigate();

  const onDelete = (row) => {
    setShowConfirmDialog(true);
    setDeleteConfirmationData(row);
  };
  // console.log('communication', communication);

  const basicRadioChannelUseColumns = [
    {
      dataField: 'function',
      text: 'Function',
      //   sort: true,
      //   headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Function' },
    },
    {
      dataField: 'channel_name',
      text: 'Channel Name/Trunked Radio System Talkgroup',
      //   sort: true,
      attrs: { title: 'Channel Name/Trunked Radio System Talkgroup' },
    },
    {
      dataField: 'assignment',
      text: 'Assignment',
      attrs: {
        title: 'Assignment',
      },
    },
    {
      dataField: 'rx_frequency',
      text: 'RX Freq',
      attrs: {
        title: 'RX Freq',
      },
    },
    {
      dataField: 'n_w',
      text: 'N or W',
      attrs: {
        title: 'N or W',
      },
    },
    {
      dataField: 'rx_tone',
      text: 'RX Tone/NAC',
      attrs: {
        title: 'RX Tone/NAC',
      },
    },
    {
      dataField: 'frequency',
      text: 'TX Freq N or W',
      attrs: {
        title: 'TX Freq N or W',
      },
    },
    {
      dataField: 'tx_tone',
      text: 'Tx Tone/NAC',
      attrs: {
        title: 'Tx Tone/NAC',
      },
    },
    {
      dataField: 'mode',
      text: 'Mode A, D or M',
      attrs: {
        title: 'Mode A, D or M',
      },
    },
    {
      dataField: 'remarks',
      text: 'Remarks',
      attrs: {
        title: 'Remarks',
      },
    },
    {
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Communication"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => onDelete(row)}
          onEdit={() => {
            setCommunicationModalMode('Edit');
            setOpenCommunicationModal(true);
            setCommunicationEditData(row);
          }}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const updateFormState = (key, value) => {
    const index = formState.field.findIndex((field) => field.key === key);
    if (index !== -1) {
      let obj = { ...formState.field[index] };
      obj = { ...obj, value: value };
      formState.field[index] = obj;
      setFormState(formState);
      onFormRecordDataUpdate(formState);
    }
  };

  useEffect(() => {
    if (
      (!reduxSignatures || !reduxSignatures.length) &&
      !!isFetchSignaturesLoaded
    ) {
      reduxDispatch(fetchSignatures());
    }
  }, []);

  // useEffect(() => {
  //   if (!!reduxSignatures && !!reduxSignatures.length > 0) {
  //     updateFormState('Signature_9', reduxSignatures[0].signature_image);
  //   }
  // }, [reduxSignatures]);

  useEffect(() => {
    if (reduxUser) {
      updateFormState(
        'PreparedBy',
        reduxUser?.user_name || reduxUser?.email_address
      );
      // updateFormState('DateTime_8', moment().format('DD/MM/YYYY HH:mm:ss'));
      updateFormState(
        'PreparedByDateTime',
        moment().format('DD/MM/YYYY HH:mm:ss')
      );
    }
  }, [reduxUser]);

  useEffect(() => {
    if (
      reduxCurrentIncident &&
      reduxCurrentIncident?.id &&
      reduxCurrentIncident?.current_period
    ) {
      setIncidentName(reduxCurrentIncident?.name);

      reduxDispatch(getPlanningWorksheet205(navigate));

      updateFormState('1 Incident Name', reduxCurrentIncident?.name);

      reduxCurrentIncident?.operational_period_start_date_time &&
        updateFormState(
          'OPeriod DateTimeForm',
          moment(
            reduxCurrentIncident?.operational_period_start_date_time
          ).format('DD/MM/YYYY HH:mm')
        );

      reduxCurrentIncident?.operational_period_start_date_time &&
        reduxCurrentIncident?.operational_period_interval &&
        updateFormState(
          'OPeriod DateTimeTo',
          moment(reduxCurrentIncident?.operational_period_start_date_time)
            .add(reduxCurrentIncident?.operational_period_interval, 'hours')
            .format('DD/MM/YYYY HH:mm')
        );
    }
  }, [reduxCurrentIncident]);

  // const updateCommunicationSpecialInstructions = () => {
  //   const updateCommunicationSpecialInstructionsData = {
  //     special_instructions: specialInstructions,
  //     prepared_by:
  //       communication?.prepared_by?.user_guid || reduxUser?.user_guid,
  //     prepared_by_date: communication?.prepared_by_date || moment(),
  //     id: communication?.id || generateUUID(),
  //   };
  //   reduxDispatch(
  //     upsertCommunication205(updateCommunicationSpecialInstructionsData)
  //   );
  // };

  const updateCommunicationPreparedBy = () => {
    const updateCommunicationPreparedByData = {
      special_instructions: communication?.specialInstructions,
      prepared_by: reduxUser?.user_guid,
      prepared_by_date: preparedByDateTime || moment(),
      id: communication?.id || generateUUID(),
      planning_worksheet_id: planningWorksheet.id,
    };
    reduxDispatch(startLoading());
    reduxDispatch(upsertCommunication205(updateCommunicationPreparedByData));
  };

  useEffect(() => {
    if (!!planningWorksheet?.id && !!communication?.id) {
      reduxDispatch(fetchCommunication205(planningWorksheet.id));
    }
    const communicationData = [];
    planningWorksheet?.communications?.map((data, index) => {
      communicationData.push({
        key: 'Function' + (index + 1),
        type: 'Text',
        value: data.function,
      });
      communicationData.push({
        key: 'Channel NameTrunked Radio System Talkgroup' + (index + 1),
        type: 'Text',
        value: data.channel_name,
      });
      communicationData.push({
        key: 'Assignment' + (index + 1),
        type: 'Text',
        value: data.assignment,
      });
      communicationData.push({
        key: 'RX Freq N or W' + (index + 1),
        type: 'Text',
        value: data.rx_frequency + ' ' + data.n_w,
      });
      communicationData.push({
        key: 'RX ToneNAC' + (index + 1),
        type: 'Text',
        value: data.rx_tone,
      });
      communicationData.push({
        key: 'TX Freq N or W' + (index + 1),
        type: 'Text',
        value: data.frequency,
      });
      communicationData.push({
        key: 'Tx ToneNAC' + (index + 1),
        type: 'Text',
        value: data.tx_tone,
      });
      communicationData.push({
        key: 'Mode A D or M' + (index + 1),
        type: 'Text',
        value: data.mode,
      });
      communicationData.push({
        key: 'Remarks' + (index + 1),
        type: 'Text',
        value: data.remarks,
      });
    });
    updateFormState('3 Basic Radio Channel Use', communicationData);
  }, [planningWorksheet]);

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteCommunication(deleteConfirmationData.id));
    setShowConfirmDialog(false);
    setDeleteConfirmationData(null);
  };

  return (
    <>
      <div className="mb-4">
        <h4>1. Incident Name</h4>
        <label className="form-label">Name</label>
        <p className="mb-3">{incidentName}</p>
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4>2. Operational Period</h4>
        {!!reduxCurrentIncident && reduxCurrentIncident?.current_period && (
          <>
            <div className="row">
              <div className="col-md-6 mb-3 mb-md-0">
                <label className="form-label d-block">From:</label>
                {moment(
                  reduxCurrentIncident?.operational_period_start_date_time
                ).format('MM/DD/YYYY HH:mm')}
              </div>
              <div className="col-md-6">
                <label className="form-label d-block">To:</label>
                {moment(
                  reduxCurrentIncident?.operational_period_start_date_time
                )
                  .add(
                    reduxCurrentIncident?.operational_period_interval,
                    'hours'
                  )
                  .format('MM/DD/YYYY HH:mm')}
              </div>
            </div>
          </>
        )}
      </div>
      <hr className="dashed my-4" />

      <div className="mb-4">
        <h4>3. Basic Radio Channel Use</h4>
        <StylishNewButton
          type="button"
          className="button--secondary mb-4"
          onClick={() => {
            setCommunicationModalMode('Add');
            setOpenCommunicationModal(true);
          }}
          //   disabled={!userPreparedBy?.length}
        >
          Add
        </StylishNewButton>
        <StylishNewTable
          keyField={'id'}
          rows={planningWorksheet?.communications || []}
          columns={basicRadioChannelUseColumns}
        />
      </div>
      <hr className="dashed my-4" />

      {/* <div className="mb-4">
        <h4>4. Special Instructions</h4>
        <StylishNewTextArea
          value={specialInstructions}
          onChange={(e) => setSpecialInstructions(e?.target?.value)}
          maxLength={1000}
        />
        <StylishNewButton
          className="button--primary mt-4"
          type="button"
          onClick={updateCommunicationSpecialInstructions}
          //   disabled={!userPreparedBy?.length}
        >
          Save
        </StylishNewButton>
      </div>
      <hr className="dashed my-4" /> */}

      <div className="mb-4">
        <h4 className="mb-1">4. Prepared by: (Communications Unit)</h4>
        {/* <p className="fst-italic weight-500 mb-4">
          Seats Can Be Managed at The{' '}
          <Link to={'/incident_configuration'}>Duty Roster</Link>
        </p> */}
        <div className="mb-3">
          <label className="form-label">Name</label>
          <StylishNewInput
            value={
              communication?.prepared_by
                ? communication?.prepared_by?.profile_settings?.userName ||
                  communication?.prepared_by?.email_address
                : reduxUser?.user_name
            }
            readOnly={true}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Date/Time</label>
          <StylishDateTimePicker
            value={
              communication?.prepared_by_date || preparedByDateTime || moment()
            }
            type="datetime-local"
            onChange={setPreparedByDateTime}
            // disabled={!userPreparedBy?.length}
          />
        </div>
        <div className="m-0">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          <p className="fst-italic weight-500 m-0">
            Signatures Are Used To Electronically Sign Forms
          </p>
        </div>
      </div>

      <StylishNewButton
        className="button--primary mt-4"
        type="button"
        onClick={updateCommunicationPreparedBy}
        // disabled={!userPreparedBy?.length}
      >
        Save
      </StylishNewButton>
      <hr className="dashed my-4" />

      {openCommunicationModal && (
        <AddCommunicationDialog
          show={openCommunicationModal}
          onClose={() => {
            setOpenCommunicationModal(false);
            setCommunicationEditData(null);
          }}
          mode={communicationModalMode}
          editData={communicationEditData}
          planning_worksheet_id={planningWorksheet?.id}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this Resource?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </>
  );
};
