import React, { useState } from 'react';
import WeatherAnalytics from './WeatherAnalytics';
import OutagePrediction from './OutagePrediction';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

type Keys = 'weather' | 'outage';

type Tab = {
  label: string;
  value: Keys;
  icon: JSX.Element;
};

const tabs: Tab[] = [
  {
    label: 'Weather Analytics',
    value: 'weather',
    icon: <CloudQueueIcon fontSize="small" />,
  },
  {
    label: 'Outage Prediction',
    value: 'outage',
    icon: <ElectricBoltIcon fontSize="small" />,
  },
];

const renderTabContent: Record<Keys, JSX.Element> = {
  weather: <WeatherAnalytics />,
  outage: <OutagePrediction />,
};

const UtilityImpactPrediction: React.FC = () => {
  const [key, setKey] = useState<Keys | undefined>('weather');

  return (
    <div className="ms-2 me-2 weather-outage">
      <br />
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k ? (k as Keys) : undefined)}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            eventKey={tab.value}
            title={
              <span>
                <span className="me-2">{tab.icon}</span>
                {tab.label}
              </span>
            }
          >
            {renderTabContent[tab.value]}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default UtilityImpactPrediction;
