import { Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import { CustomResourceType, useEditCustomResource } from './hooks/customResourceTypeHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import {
  selectGroupGuid,
  useAppSelector,
} from '../../slices/commonSelectors';

type FormFields = {
  id: string;
  name: string;
  type: string;
  description?: string;
  group_guid: string;
  archived?: boolean;
};

const CustomResourceEditDrawer: React.FC<EditEntityDrawerProps<CustomResourceType>> = ({
  toggle,
  selectedEntity: editedEntity,
}) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: { ...editedEntity },
  });
  const { handleSubmit } = form;

  const group_guid = useAppSelector(selectGroupGuid);

  const mutation = useEditCustomResource(editedEntity.id);

  const submitCustomResourceEdit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      group_guid: group_guid,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  const toggleActive = async () => {
    await mutation.mutateAsync({
      ...editedEntity,
      archived: !editedEntity.archived,
    });
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Custom Resource">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(submitCustomResourceEdit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: { value: 255, message: 'The maximum length is 255 characters' },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Type"
                fieldName="type"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: { value: 255, message: 'The maximum length is 255 characters' },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{ maxLength: { value: 300, message: 'The maximum length is 300 characters' } }}
                variant="full"
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={toggleActive}
              >
                {(!editedEntity.archived&&"Deactivate"||"Activate")}
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default CustomResourceEditDrawer;
