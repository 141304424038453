// ExternalMapEditDrawer.js
import React, { useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import FormTextField from 'components/IAP/formFields/FormTextField';

const ExternalMapEditDrawer = ({ show, onHide, onSave, externalMap }) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      id: '',
      name: '',
      url: '',
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    if (externalMap) {
      reset({
        id: externalMap.id || '',
        name: externalMap.name || '',
        url: externalMap.url || '',
      });
    } else {
      reset({
        id: '',
        name: '',
        url: '',
      });
    }
  }, [externalMap, reset]);

  const handleSaveExternalMap = (formData) => {
    onSave(formData);
  };

  return (
    <DrawerWrapper
      toggle={onHide}
      title={externalMap ? 'Edit External Map' : 'Add External Map'}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(handleSaveExternalMap)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            {/* Hidden ID Field */}
            {externalMap && <input type="hidden" {...register('id')} />}

            {/* Name Field with Required Validation */}
            <FormTextField
              title="Name"
              fieldName="name"
              register={register}
              rules={{ required: 'Name is required' }}
              error={errors.name}
            />

            {/* URL Field with Required Validation */}
            <FormTextField
              title="URL"
              fieldName="url"
              register={register}
              rules={{
                required: 'URL is required',
                pattern: {
                  value: /^https?:\/\/.+/,
                  message: 'Must be a valid URL starting with http:// or https://'
                }
              }}
              error={errors.url}
            />

          </Offcanvas.Body>

          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton className="button--secondary" onClick={onHide}>
                Cancel
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={!isValid}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default ExternalMapEditDrawer;