import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';


import { useLocation } from 'react-router-dom';

import { Stripe } from 'stripe';
const stripeCheckout = Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

import {
  createStripeSubscription,
  fetchAssignableUsersByGroup,
  assignStripeSeats,
  unassignStripeSeats,
} from 'actions/stripeActions';

import { fetchRostersForGroups } from 'actions/roleActions';

import './StripeCheckout.css';
import { noAutoClose } from 'assets/data/config';

export default function StripeCheckout() {
  const location = useLocation();
  const reduxDispatch = useDispatch();

  const priceKey = process.env.REACT_APP_STRIPE_PRATUS_PREMIUM_PRICE_KEY;
  const domainURL = window.location.origin + window.location.pathname;
  const searchParams = new URLSearchParams(location.search);

  const numSubscriptionsOptions = Array.from(Array(100).keys()).map((i) => {
    return {
      value: i,
      label: i,
    };
  });

  const [stripeSession, setStripeSession] = useState();
  const [paymentInProgress, setPaymentInProgress] = useState();
  const [numSubscriptions, setNumSubscriptions] = useState(0);
  const [assignedGroupMembers, setAssignedGroupMembers] = useState([]);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const user = useSelector((state) => {
    return state.app.user;
  });

  const reduxUserSubscriptionInfo = useSelector((state) => {
    return state.app.userSubscriptionInfo;
  });

  const reduxAssignableUsersByGroup = useSelector((state) => {
    return state.app.assignableUsersByGroup;
  });

  const reduxRostersForGroups = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });

  useEffect(() => {
    if (isUserSubscriptionOwner() && !!stripeSession) {
      toast.success('Pratus Premium purchase successful!', {
        autoClose: 2000,
      });
    }
    if (
      isUserSubscriptionOwner() &&
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid
    ) {
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
      reduxDispatch(fetchAssignableUsersByGroup());
    }
  }, [reduxUserSubscriptionInfo, reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (!stripeSession && !!searchParams.get('session_id')) {
      const sessionID = searchParams.get('session_id');
      stripeCheckout.checkout.sessions
        .retrieve(sessionID)
        .then((session) => {
          setStripeSession(session);
          reduxDispatch(
            createStripeSubscription({
              stripe_customer_id: session.customer,
              stripe_subscription_id: session.subscription,
              amount_total: session.amount_total,
            })
          );
        })
        .catch((error) => {
          console.error('ERROR stripe.checkout.sessions.retrieve', error);
          toast.error(
            'ERROR stripe.checkout.sessions.retrieve' + error.message,
            noAutoClose
          );
        });
    }
  }, [stripeSession]);

  useEffect(() => {
    if (isUserSubscriptionOwner() && !!reduxAssignableUsersByGroup) {
      setAssignedGroupMembers(
        reduxAssignableUsersByGroup.filter((u) => !!u.stripe_subscription_id)
      );
    }
  }, [reduxAssignableUsersByGroup, reduxUserSubscriptionInfo]);

  function purchaseClicked() {
    setPaymentInProgress(true);
    const sessionPayload = {
      mode: 'subscription',
      line_items: [
        {
          price: priceKey,
          quantity: numSubscriptions,
        },
      ],
      // ?session_id={CHECKOUT_SESSION_ID} means the redirect will have the session ID set as a query param
      success_url: `${domainURL}?stripeCreateSessionResult=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${domainURL}?stripeCreateSessionResult=canceled`,
      metadata: {
        pratus_customer_id: user.user_guid,
      },
      // automatic_tax: { enabled: true }
    };

    stripeCheckout.checkout.sessions
      .create(sessionPayload)
      .then((session) => {
        window.location.replace(session.url);
      })
      .catch((error) => {
        setPaymentInProgress(false);
        toast.error(
          'ERROR stripeCheckout.checkout.sessions.create' + error.message,
          noAutoClose
        );
      });
  }

  function validateCheckout() {
    if (numSubscriptions > 0) {
      return true;
    }
    return false;
  }

  function isUserSubscribed() {
    if (
      !reduxUserSubscriptionInfo ||
      Object.keys(reduxUserSubscriptionInfo).length === 0
    ) {
      return false;
    } else if (
      !!reduxUserSubscriptionInfo &&
      !!reduxUserSubscriptionInfo.userSubscriptions &&
      !!reduxUserSubscriptionInfo.userSubscriptions.length
    ) {
      return true;
    }
    return false;
  }

  function isUserSubscriptionOwner() {
    if (
      !reduxUserSubscriptionInfo ||
      Object.keys(reduxUserSubscriptionInfo).length === 0
    ) {
      return false;
    } else if (
      !!reduxUserSubscriptionInfo &&
      !!Object.keys(reduxUserSubscriptionInfo).length &&
      !!Object.keys(reduxUserSubscriptionInfo.userOwnedSubscriptions).length
    ) {
      return true;
    }
    return false;
  }

  let totalLicenses = 0;
  let assignedLicenses = 0;
  let licensesRemaining = 0;

  if (
    !!isUserSubscriptionOwner() &&
    !!reduxCurrentlySelectedGroup &&
    !!reduxAssignableUsersByGroup.length
  ) {
    totalLicenses =
      reduxUserSubscriptionInfo.userOwnedSubscriptions[0].subscription_seats;
    assignedLicenses =
      reduxUserSubscriptionInfo.userOwnedSubscriptions[0].subscription_users
        .length;
    licensesRemaining =
      reduxUserSubscriptionInfo.userOwnedSubscriptions[0].subscription_seats -
      reduxUserSubscriptionInfo.userOwnedSubscriptions[0].subscription_users
        .length;
  }

  const nonSelectableUsers = () => {
    let users = [];
    if (
      !licensesRemaining &&
      isUserSubscriptionOwner() &&
      !!reduxAssignableUsersByGroup
    ) {
      users = reduxAssignableUsersByGroup.map(
        (u) =>
          (!!u.stripe_subscription_id &&
            u.stripe_subscription_id !==
              reduxUserSubscriptionInfo.userOwnedSubscriptions[0]
                .stripe_subscription_id &&
            u.user_guid) ||
          (!u.stripe_subscription_id && u.user_guid)
      );
    } else {
      users =
        !!reduxAssignableUsersByGroup &&
        isUserSubscriptionOwner() &&
        reduxAssignableUsersByGroup.map(
          (u) =>
            !!u.stripe_subscription_id &&
            u.stripe_subscription_id !==
              reduxUserSubscriptionInfo.userOwnedSubscriptions[0]
                .stripe_subscription_id &&
            u.user_guid
        );
    }
    return users;
  };

  const assignableUserRows = reduxAssignableUsersByGroup.map((row) => {
    let note = '';
    const selectable = !nonSelectableUsers().find((u) => u === row.user_guid);
    if (!selectable) {
      if (
        !!row.stripe_subscription_id &&
        row.stripe_subscription_id !==
          reduxUserSubscriptionInfo.userOwnedSubscriptions[0]
            .stripe_subscription_id
      ) {
        note = 'User license owned by another subscription';
      } else if (licensesRemaining === 0) {
        note = 'Out of available licenses';
      }
    }
    return {
      ...row,
      note,
    };
  });

  const columns = [
    {
      dataField: 'user_name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: 'email_address',
      text: 'Member Email',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Member Email' },
    },
    {
      dataField: 'note',
      text: 'Note',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Note' },
      // formatter:  (cell, row, rowIndex) => {
      //     const selectable = !nonSelectableUsers().find(u=>u===row.user_guid)
      //     const message = !selectable && ((!!row.stripe_subscription_id && row.stripe_subscription_id !== reduxUserSubscriptionInfo.userOwnedSubscriptions[0].stripe_subscription_id && "User license owned by another subscription") || (!totalLicenses && "Out of available licenses")) || ""
      //     return (
      //       <div style={{ width: '200px' }} key={row?.current_status}>
      //         {message}
      //       </div>
      //     );
      //   },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: assignedGroupMembers.map((u) => u.user_guid),
    nonSelectable: nonSelectableUsers(),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (!assignedGroupMembers.find((m) => m.user_guid === row.user_guid)) {
        if (licensesRemaining) {
          setAssignedGroupMembers([...assignedGroupMembers, row]);
          reduxDispatch(
            assignStripeSeats({
              stripe_subscription_id:
                reduxUserSubscriptionInfo.userOwnedSubscriptions[0]
                  .stripe_subscription_id,
              users: [row],
            })
          );
        }
      } else {
        setAssignedGroupMembers(
          assignedGroupMembers.filter((m) => m.user_guid !== row.user_guid)
        );
        reduxDispatch(
          unassignStripeSeats({
            stripe_subscription_id:
              reduxUserSubscriptionInfo.userOwnedSubscriptions[0]
                .stripe_subscription_id,
            users: [row],
          })
        );
      }
    },
  };

  return (
    <>
      <div className="StripeCheckout form-block mb-5">
        <h4 className="mb-1">
          Pratus Premium:{' '}
          <strong>
            {(!!isUserSubscribed() && 'SUBSCRIBED') || 'NOT SUBSCRIBED'}
          </strong>{' '}
          <strong>
            {!!isUserSubscriptionOwner() && '- SUBSCRIPTION OWNER'}
          </strong>
        </h4>

        {!isUserSubscribed() && !isUserSubscriptionOwner() && (
          <>
            <div className="mb-3">
              Pratus Premium includes:
              <ul>
                <li>Automated Teams Workspaces</li>
                <li>Integrated Sharepoint file management</li>
                <li>OSINT search</li>
                <li>Incident Management forms and workflows</li>
                <li>
                  AutoSITREP of OSINT, Events, and Situation Intelligence
                </li>
              </ul>
            </div>

            <div className="mb-3">
              <label className="form-label">Number of Subscriptions</label>
              <StylishNewSelect
                options={numSubscriptionsOptions}
                onChange={(e) => setNumSubscriptions(e.value)}
                value={numSubscriptions.value}
                isClearable={false}
                isSearchable={false}
                isMulti={false}
                isDisabled={false}
              />
            </div>

            {!validateCheckout() && (
              <div>Must select at least 1 subscription</div>
            )}

            <StylishNewButton
              onClick={() => purchaseClicked()}
              disabled={!validateCheckout()}
              tooltipOnDisable={'Must select at least 1 subscription'}
            >
              Purchase Pratus Premium
            </StylishNewButton>
            {!!paymentInProgress && <div>Connecting to Stripe...</div>}
          </>
        )}

        {!!isUserSubscriptionOwner() &&
          !!reduxCurrentlySelectedGroup &&
          !!reduxAssignableUsersByGroup.length && (
            <>
              <div>
                <StylishNewButton
                  onClick={() =>
                    window.location.replace(
                      'https://billing.stripe.com/p/login/test_6oEaGL55B1TO9K8eUU'
                    )
                  }
                >
                  Manage Pratus Subscription
                </StylishNewButton>
              </div>

              <div>
                <label>{totalLicenses} Total Licenses</label>
              </div>

              <div>
                <label>{assignedLicenses} Used</label>
              </div>

              <div>
                <label>
                  <strong>{licensesRemaining} Licenses Remaining</strong>
                </label>
              </div>

              {licensesRemaining < 0 && (
                <div>
                  <label>
                    <strong>
                      OVERMAXED LICENSES: Assignments exceed licenses purchased.
                      Pratus Premium will be disabled for ALL users in this
                      subscription until licenses purchased match or exceed
                      licenses assigned.
                    </strong>
                  </label>
                </div>
              )}

              <div>
                <label>
                  Assign Licenses to Members of{' '}
                  <strong>{reduxCurrentlySelectedGroup.group_name}.</strong>
                </label>
              </div>

              {!licensesRemaining && (
                <div>
                  <strong>
                    {`You are out of available licenses. Click "Manage Pratus
                    Subscription" to add more.`}
                  </strong>
                </div>
              )}

              <StylishNewTable
                keyField={'user_guid'}
                columns={columns}
                rows={assignableUserRows}
                selectRow={selectRow}
              />
            </>
          )}
      </div>
    </>
  );
}
