import React, { useEffect, useState } from 'react';
import IAPForm204ResourceDialog from './IAPForm204ResourceDialog';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import BranchModal from './Branch/BranchModal';
import {
  deleteCommunication,
  getAllBranch,
  getAllDivision,
  getAllWorkAssignments,
  getPlanningWorksheet,
  markPlanningWorkAssignmentToWorkAssignment,
  unmarkPlanningWorkAssignmentToWorkAssignment,
  upsertPlanningWorksheet,
} from 'actions/IAPForm204Actions';
import DivisionModal from './Division/DivisionModal';
import { toast } from 'react-toastify';
import AddUpdateWorkAssignment from './WorkAssignment/AddUpdateWorkAssignment';
import {
  getAllObjectiveToIncident,
  getAllResourcesToIncident,
  getRosterForIncident,
} from 'actions/incidentActions';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import {
  deleteAction,
  deleteAssignmentResource,
} from 'actions/IAPForm201Actions';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import EditOperationalPeriodDialog from 'components/IncidentConfig/EditOperationalPeriodDialog';
import Communication from './Communication/communication';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { fetchRostersForGroups } from 'actions/roleActions';
import { updateIncident } from 'actions/diceActions';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { Config204 } from './FormTemplates/FormConfig/204Config';
import { toastConfig } from 'assets/data/config';

export default function IAPForm204({ onFormRecordDataUpdate, setDataLoaded }) {
  const reduxDispatch = useDispatch();
  const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
  const [resourceDialogType, setResourceDialogType] = useState('Edit');
  const [formState, setFormState] = useState(Config204);

  const [
    isWorkAssignmentsDialogOpen,
    setIsWorkAssignmentsDialogOpen,
  ] = useState(false);
  const [showBranchModal, setShowBranchModal] = useState(false);
  const [showDivisionModal, setShowDivisionModal] = useState(false);
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [updateAssignmentData, setUpdateAssignmentData] = useState();
  const [workAssignmentModalType, setWorkAssignmentModalType] = useState('New');
  const [communications, setCommunications] = useState([
    { assignment: 1, frequency: '', channel_name: '', phone: '' },
  ]);
  const [deleteCommunicationData, setDeleteCommunicationData] = useState();
  const [
    deleteConfirmationDialogText,
    setDeleteConfirmationDialogText,
  ] = useState('');
  const [deleteConfirmationType, setDeleteConfirmationType] = useState('');
  const [workAssignmentResource, setWorkAssignmentResource] = useState([]);
  const [
    deleteAssignmentResourceData,
    setDeleteAssignmentResourceData,
  ] = useState();
  const [
    updateAssignmentResourceData,
    setUpdateAssignmentResourceData,
  ] = useState();
  const [
    isEditOperationalPeriodOpen,
    setIsEditOperationalPeriodOpen,
  ] = useState(false);
  const [planningSectionChiefs, setPlanningSectionChiefs] = useState([]);
  const [operationsSectionChiefs, setOperationsSectionChiefs] = useState([]);
  const [isUserPSC, setIsUserPSC] = useState(true);
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const planningWorksheet = useSelector((state) => {
    return state.app.planningWorksheet;
  });
  const reduxIncidentResources = useSelector(
    (state) => state.app.incidentResources
  );
  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );

  const allWorkAssignments = useSelector(
    (state) => state.app.allWorkAssignments
  );
  const reduxRosterForIncident = useSelector((state) => {
    return state.app.rosterForIncident;
  });
  const reduxUser = useSelector((state) => {
    return state.app.user;
  });
  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });
  useEffect(() => {
    if (reduxCurrentIncident) {
      setValue('incident_name', reduxCurrentIncident.name);
      updateFormState('1 Incident Name', reduxCurrentIncident.name);
      updateFormState(
        'OPDateTimeFrom',
        reduxCurrentIncident.operational_period_start_date_time
          ? moment(
              reduxCurrentIncident.operational_period_start_date_time
            ).format('DD/MM/YYYY HH:mm')
          : ''
      );
      updateFormState(
        'OPDateTimeTo',
        reduxCurrentIncident.operational_period_start_date_time
          ? moment(reduxCurrentIncident.operational_period_end_date_time)
              .add(reduxCurrentIncident?.operational_period_interval, 'hours')
              .format('DD/MM/YYYY HH:mm')
          : ''
      );

      if (reduxCurrentIncident.current_period) {
        reduxDispatch(getPlanningWorksheet());
        reduxDispatch(getAllWorkAssignments());
      }
      reduxDispatch(getAllResourcesToIncident());
      reduxDispatch(getAllObjectiveToIncident());
      reduxDispatch(getRosterForIncident());
      reduxDispatch(endLoading());
    }
  }, [reduxCurrentIncident]);

  useEffect(() => {
    if (!!reduxRosterForIncident && reduxRosterForIncident.length > 0) {
      const pSectionChiefs = reduxRosterForIncident
        .filter((users) => users.incident_seat === 'Planning Section Chief')
        .map((users) => ({
          value: users,
          label:
            users.profile_settings.userName ||
            users.profile_settings.email_address,
        }));
      const oSectionChiefs = reduxRosterForIncident
        .filter((users) => users.incident_seat === 'Operations Section Chief')
        .map((users) => ({
          value: users,
          label: users.profile_settings?.userName || users.email_address,
        }));
      setPlanningSectionChiefs(pSectionChiefs);
      setOperationsSectionChiefs(oSectionChiefs);
    }
  }, [reduxRosterForIncident]);

  useEffect(() => {
    if (!!reduxRosterForIncident && !!reduxUser) {
      const isPSC =
        reduxRosterForIncident.filter(
          (users) =>
            users.user_guid === reduxUser.user_guid &&
            users.incident_seat === 'Planning Section Chief'
        ).length > 0;
      if (isUserPSC && !isPSC) {
        toast.info(
          'Only "Planning Section Chief" can edit ICS 204 form.',
          toastConfig
        );
        setIsUserPSC(false);
      }
    }
  }, [reduxRosterForIncident, reduxUser]);

  useEffect(() => {
    if (!!allWorkAssignments && allWorkAssignments.length > 0) {
      const newWorkAssignmentResource = [];
      allWorkAssignments.forEach((workAssignment) => {
        if (!!workAssignment.resources && workAssignment.resources.length > 0) {
          workAssignment.resources.forEach((resource) => {
            newWorkAssignmentResource.push({
              ...resource,
              action_id: workAssignment.action_id,
            });
          });
        }
      });
      setWorkAssignmentResource(newWorkAssignmentResource);
      updateWorkAssignmentTable(allWorkAssignments);
      const allWorkAssignmentsUpdate = [];
      allWorkAssignments.forEach((workAssignment) => {
        allWorkAssignmentsUpdate.push(
          `${workAssignment.description}(${workAssignment.status})`
        );
      });
      updateFormState('7 Work Assignments', allWorkAssignmentsUpdate);
    } else {
      setWorkAssignmentResource([]);
    }
  }, [allWorkAssignments]);

  useEffect(() => {
    if (
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid
    ) {
      reduxDispatch(getAllBranch());
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (planningWorksheet) {
      if (planningWorksheet.branch_id) {
        reduxDispatch(getAllDivision(planningWorksheet.branch_id));
      }
      setValue('branch', planningWorksheet.branch_name || '');
      updateFormState('3 Branch', planningWorksheet?.branch_name || '');
      updateFormState(
        '4 DivisionGroupStaging',
        planningWorksheet?.division_name || ''
      );
      updateFormState(
        'Branch Director',
        planningWorksheet?.branch_director_profile_settings?.userName ||
          planningWorksheet?.branch_director_email ||
          ''
      );
      updateFormState(
        'DivisionGroup SupervisorSTAM',
        planningWorksheet?.division_supervisor_profile_settings?.userName ||
          planningWorksheet?.division_supervisor_email ||
          ''
      );
      updateFormState(
        '8 Special Instructions',
        planningWorksheet?.form_special_instructions || ''
      );
      updateFormState(
        'Operations Section Chief',
        planningWorksheet?.osc_reviewed_by_profile_settings?.userName ||
          planningWorksheet?.osc_reviewed_by_email_address ||
          ''
      );
      updateCommunicationTable(planningWorksheet?.communications);
      updateFormState('Medical', planningWorksheet?.medical_comm || '');
      updateFormState('Evacuation', planningWorksheet?.evacuation_comm || '');
      updateFormState('Other', planningWorksheet?.other_comm || '');
      setValue(
        'division',
        planningWorksheet.division_name ? planningWorksheet.division_name : ''
      );
      setValue(
        'form_special_instructions',
        planningWorksheet.form_special_instructions
      );
      setValue('medical_comm', planningWorksheet.medical_comm);
      setValue('evacuation_comm', planningWorksheet.evacuation_comm);
      setValue('other_comm', planningWorksheet.other_comm);
      setValue(
        'reviewed_by_planning_section_chief',
        planningWorksheet.psc_reviewed_by_profile_settings
          ? {
              label:
                planningWorksheet.psc_reviewed_by_profile_settings?.userName ||
                planningWorksheet.psc_reviewed_by_email_address,
              value: {
                user_guid: planningWorksheet.psc_reviewed_by_guid,
                profile_settings:
                  planningWorksheet.psc_reviewed_by_profile_settings,
              },
            }
          : null
      );
      setValue(
        'reviewed_by_operations_section_chief',
        planningWorksheet.osc_reviewed_by_profile_settings
          ? {
              label:
                planningWorksheet.osc_reviewed_by_profile_settings?.userName ||
                planningWorksheet.osc_reviewed_by_email_address,
              value: {
                user_guid: planningWorksheet.osc_reviewed_by_guid,
                profile_settings:
                  planningWorksheet.osc_reviewed_by_profile_settings,
              },
            }
          : null
      );
      setValue('other_comm', planningWorksheet.other_comm);

      setSelectedAssignments(planningWorksheet.selectedworkassignments || []);
      reduxDispatch(
        getAllWorkAssignments()
        // planningWorksheet.branch_id,
        // planningWorksheet.division_id
      );
      if (!!planningWorksheet.prepared_by_guid && !!reduxRosterForIncident) {
        const preparedBy = reduxRosterForIncident.find(
          (user) => user.user_guid === planningWorksheet.prepared_by_guid
        );
        setValue(
          'prepared_by',
          preparedBy?.profile_settings?.userName || preparedBy?.email_address
        );
      }
      if (
        !!planningWorksheet.communications &&
        planningWorksheet.communications.length > 0
      ) {
        setCommunications(planningWorksheet.communications.reverse());
      } else {
        setCommunications([
          { assignment: 1, frequency: '', channel_name: '', phone: '' },
        ]);
      }
      reduxDispatch(endLoading());
    } else {
      setValue('branch', null);
      setValue('division', null);
    }
  }, [planningWorksheet, reduxRosterForIncident]);

  const onFocusDivision = () => {
    if (!!planningWorksheet && !!planningWorksheet.branch_id) {
      setShowDivisionModal(true);
    } else {
      toast.error('Please select a branch first', toastConfig);
    }
  };
  const onSaveSpecialInstructions = async () => {
    const valid = await trigger('form_special_instructions');
    if (!valid) {
      return;
    }
    reduxDispatch(
      upsertPlanningWorksheet(
        'Special Instructions',
        getValues('form_special_instructions')
      )
    );
  };
  const onSaveEmergencyCommunications = async () => {
    reduxDispatch(
      upsertPlanningWorksheet('Emergency Communications', {
        other_comm: getValues('other_comm'),
        evacuation_comm: getValues('evacuation_comm'),
        medical_comm: getValues('medical_comm'),
      })
    );
  };
  const updateFormState = (key, value) => {
    const index = formState.field.findIndex((field) => field.key === key);
    if (index !== -1) {
      let obj = { ...formState.field[index] };
      obj = { ...obj, value: value };
      formState.field[index] = obj;
      setFormState(formState);
      onFormRecordDataUpdate(formState);
    }
  };
  const updateCommunicationTable = (communications) => {
    let allComm = [];
    if (!communications || communications?.length === 0) {
      updateFormState('Comm', []);
      return;
    }
    communications.forEach((comm, index) => {
      const singleComm = [];
      singleComm.push({
        key: 'Assignment ' + (index + 1),
        type: 'Text',
        value: comm.assignment,
      });
      singleComm.push({
        key: 'Channel Name ' + (index + 1),
        type: 'Text',
        value: comm.channel_name,
      });
      singleComm.push({
        key: 'Frequency Tx ' + (index + 1),
        type: 'Text',
        value: comm.frequency,
      });
      singleComm.push({
        key: 'Phone ' + (index + 1),
        type: 'Text',
        value: comm.phone,
      });
      allComm = [...allComm, ...singleComm];
    });
    updateFormState('Comm', allComm);
  };
  const updateWorkAssignmentTable = (workAssignment) => {
    if (workAssignment && workAssignment.length > 0) {
      let allComm = [];
      workAssignment.forEach((wa, index) => {
        wa.resources.forEach((resource) => {
          const singleResource = [];
          singleResource.push({
            key: 'Strike TeamTask ForceResource IdentifierRow' + (index + 1),
            type: 'Text',
            value: resource.name,
          });
          singleResource.push({
            key: 'LeaderRow' + (index + 1),
            type: 'Text',
            value: resource.profile_settings?.userName || resource.user_name,
          });
          singleResource.push({
            key: 'Contact Info Row' + (index + 1),
            type: 'Text',
            value: resource.phone_number,
          });
          singleResource.push({
            key: 'Of PersonsRow' + (index + 1),
            type: 'Text',
            value: String(resource.required_amount),
          });
          singleResource.push({
            key: 'Reporting InfoNotesRemarksRow' + (index + 1),
            type: 'Text',
            value: resource.reporting_info ? resource.reporting_info : '',
          });
          singleResource.push({
            key: 'undefined_' + (index + 1),
            type: 'Check',
            value: selectedAssignments.includes(wa.action_id),
          });
          allComm = [...allComm, ...singleResource];
        });
      });
      updateFormState('WorkAssignment', allComm);
    }
  };
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' });

  const columnsResourceTable = [
    {
      dataField: 'name',
      text: 'Identifier',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Identifier' },
    },
    {
      dataField: 'user_name',
      text: 'Leader',
      sort: true,
      attrs: { title: 'Leader' },
    },
    {
      dataField: 'phone_number',
      text: 'Contact Info. #',
      sort: true,
      attrs: { title: 'Contact Info. #' },
    },
    {
      dataField: 'total',
      text: '# of Persons',
      sort: true,
      attrs: { title: '# of Persons' },
    },
    {
      dataField: 'special_instructions',
      text: 'Special Instruct.?',
      sort: true,
      attrs: { title: 'Special Instruct.?' },
    },
  ];

  const RowDetailResourceTable = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-3">
            <h6 className="mb-1">Reporting Info / Notes / Remarks</h6>
            <p className="m-0">{row.reporting_info}</p>
          </div>
          <div className="button-group mt-4">
            <StylishNewButton
              type="button"
              className="button--primary"
              onClick={() => onUpdateAssignmentResourceData(row)}
            >
              Edit
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button--tertiary button--reverse"
              onClick={() => onDeleteAssignmentResourceData(row)}
            >
              Delete
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const columnsAssignmentsTable = [
    {
      dataField: 'number',
      text: 'Number',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Number' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'status',
      text: 'Current / Planned',
      sort: true,
      attrs: { title: 'Current / Planned' },
    },
  ];

  const expandRowWorkAssignment = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-3">
            <h6 className="mb-1">Description</h6>
            <p className="m-0">{row.description}</p>
          </div>
          <div className="mb-3">
            <h6 className="mb-1">Overhead</h6>
            <p className="m-0">{row.overhead}</p>
          </div>
          <div className="mb-3">
            <h6 className="mb-1">Special Equipment</h6>
            <p className="m-0">{row.special_equipment}</p>
          </div>
          {row.objectives.every((objective) => !!objective.description) && (
            <>
              {row.objectives && row.objectives.length > 0 && (
                <div className="mb-3">
                  <h6 className="mb-1">
                    Objective(s) attached to this assignment:
                  </h6>
                  <ul className="list">
                    {row.objectives.map((objective) => {
                      return (
                        <li key={objective.id}>{objective.description}</li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </>
          )}
          {row.resources.every(
            (resource) => !!resource.type && !!resource.name
          ) && (
            <>
              {row.resources && row.resources.length > 0 && (
                <div className="mb-3">
                  <h6 className="mb-1">
                    Resource(s) attached to this assignment and Number of
                    required resource:
                  </h6>
                  <ul className="list">
                    {row.resources.map((resource) => {
                      return (
                        <li key={resource.id}>
                          {resource.type} ({resource.name}) ,{' '}
                          {resource.required_amount}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </>
          )}
          <div className="button-group mt-4">
            <StylishNewButton
              type="button"
              className="button--primary"
              onClick={() => onEditAssigmnent(row)}
            >
              Edit
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button--tertiary button--reverse"
              onClick={() => onDeleteAction(row)}
            >
              Delete
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRowWorkAssignment = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: selectedAssignments,
    nonSelectable: !isUserPSC
      ? allWorkAssignments.map((wa) => wa.action_id)
      : [],

    selectionHeaderRenderer: ({ mode, ...rest }) => {
      return (
        <div className="form-checkbox align-items-center">
          <input type={mode} {...rest} />
          <span className="icon"></span>
        </div>
      );
    },
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect) => {
      if (!reduxCurrentIncident || !reduxCurrentIncident.current_period) {
        toast.error('Please select a operational period first.', toastConfig);
        return;
      }
      reduxDispatch(startLoading());
      if (isSelect) {
        reduxDispatch(
          markPlanningWorkAssignmentToWorkAssignment(row.action_id)
        );
      } else {
        reduxDispatch(
          unmarkPlanningWorkAssignmentToWorkAssignment(row.action_id)
        );
      }
    },
  };
  const onDeleteAction = (row) => {
    setDeleteConfirmationData(row);
    setDeleteConfirmationDialogText(
      'Are you sure you want to delete this assignment?'
    );
    setDeleteConfirmationType('Work Assignment');
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(
      deleteAction(deleteConfirmationData.action_id, 'Work Assignment')
    );
    setShowConfirmDialog(false);
    setDeleteConfirmationDialogText('');
  };

  const onEditAssigmnent = (row) => {
    setUpdateAssignmentData(row);
    setWorkAssignmentModalType('Edit');
    setIsWorkAssignmentsDialogOpen(true);
  };
  const onAddCommunication = () => {
    const comm = structuredClone(communications);
    comm.push({
      assignment: comm.length + 1,
      frequency: '',
      channel_name: '',
      phone: '',
    });
    setCommunications(comm);
  };
  const onRemoveCommunication = (index) => {
    if (communications[index].id) {
      setDeleteCommunicationData(communications[index]);
      setDeleteConfirmationDialogText(
        'Are you sure you want to delete this communication?'
      );
      setShowConfirmDialog(true);
      setDeleteConfirmationType('Communication');
      return;
    }
    const comm = structuredClone(communications);
    comm.splice(index, 1);
    setCommunications(comm);
  };
  const onChangeCommunication = (value, index) => {
    const comm = structuredClone(communications);
    comm[index] = {
      ...comm[index],
      ...value,
    };
    setCommunications(comm);
  };
  const onConfirmedDeleteCommunicationData = () => {
    reduxDispatch(deleteCommunication(deleteCommunicationData.id));
    setDeleteCommunicationData(null);
    setShowConfirmDialog(false);
    setDeleteConfirmationDialogText('');
  };
  const onUpdateAssignmentResourceData = (row) => {
    setUpdateAssignmentResourceData(row);
    setResourceDialogType('Edit');
    setIsResourceDialogOpen(true);
  };
  const onDeleteAssignmentResourceData = (row) => {
    setDeleteAssignmentResourceData(row);
    setDeleteConfirmationDialogText(
      'Are you sure you want to delete this resource?'
    );
    setDeleteConfirmationType('Resource');
    setShowConfirmDialog(true);
  };
  const onConfirmedDeleteAssignmentResourceData = () => {
    reduxDispatch(
      deleteAssignmentResource(
        deleteAssignmentResourceData.id,
        deleteAssignmentResourceData.action_id
      )
    );
    setDeleteAssignmentResourceData(null);
    setShowConfirmDialog(false);
    setDeleteConfirmationDialogText('');
  };

  const updateReviewedByOSC = (row) => {
    reduxDispatch(upsertPlanningWorksheet('ReviewedByOSC', row?.value));
  };

  const updateReviewedByPSC = (row) => {
    reduxDispatch(upsertPlanningWorksheet('ReviewedByPSC', row?.value));
  };

  return (
    <>
      <div className="mb-4">
        <h4 className="mb-2">Planning Section</h4>
        <p className="fst-italic weight-500 m-0">
          Only the seats Planning Section Chief can modify this section. Seats
          can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>1. Incident Name</h4>
        <label className="form-label">Name:</label>
        <Controller
          control={control}
          name="incident_name"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => <p>{value}</p>}
        />
        <StylishNewButton
          disabled={
            !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
            errors?.incident_name ||
            !isUserPSC
          }
          type="button"
          className="button--primary"
          onClick={() => {
            if (!errors?.incident_name) {
              reduxDispatch(startLoading());
              reduxDispatch(
                updateIncident({
                  ...reduxCurrentIncident,
                  name: getValues('incident_name'),
                })
              );
            }
          }}
        >
          Save
        </StylishNewButton>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>2. Operational Period</h4>
        {!!reduxCurrentIncident && reduxCurrentIncident?.current_period && (
          <>
            <div className="form-group">
              <label className="form-label">From:</label>
            </div>
            <div className="mb-3">
              {moment(
                reduxCurrentIncident?.operational_period_start_date_time
              ).format('MM/DD/YYYY HH:mm')}
            </div>
            <div className="form-group">
              <label className="form-label">To:</label>
            </div>
            <div className="mb-3">
              {moment(reduxCurrentIncident?.operational_period_start_date_time)
                .add(reduxCurrentIncident?.operational_period_interval, 'hours')
                .format('MM/DD/YYYY HH:mm')}
            </div>
          </>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>3. Branch</h4>
        <Controller
          control={control}
          name="branch"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <StylishNewInput
              readOnly={true}
              value={value}
              onClick={() => {
                if (!isUserPSC) return;
                setShowBranchModal(true);
              }}
            />
          )}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>4. Division / Group / Staging</h4>
        <Controller
          control={control}
          name="division"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <StylishNewInput
              readOnly={true}
              value={value}
              onClick={isUserPSC ? onFocusDivision : null}
            />
          )}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>5. Operations Personnel</h4>
        <p className="fst-italic weight-500 m-0">
          Seats can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>6. Strike Team / Task Force / Resource Identifier</h4>
        <StylishNewTable
          keyField={'id'}
          columns={columnsResourceTable}
          rows={workAssignmentResource}
          expandRow={RowDetailResourceTable}
        />
      </div>

      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>7. Work Assignments</h4>
        <StylishNewButton
          className={'button--primary mb-4'}
          onClick={() => {
            setUpdateAssignmentData();
            setWorkAssignmentModalType('New');
            setIsWorkAssignmentsDialogOpen(true);
          }}
          disabled={!isUserPSC}
        >
          New Assignment
        </StylishNewButton>
        <StylishNewTable
          keyField={'action_id'}
          columns={columnsAssignmentsTable}
          rows={allWorkAssignments}
          expandRow={expandRowWorkAssignment}
          selectRow={selectRowWorkAssignment}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4 className="mb-1">8. Special Instructions</h4>
        <p className="fst-italic weight-500 mb-4">
          Special instructions for the entire form
        </p>
        <Controller
          control={control}
          name="form_special_instructions"
          rules={{ required: true, maxLength: 1000 }}
          render={({ field: { onChange, value } }) => (
            <StylishNewTextArea
              value={value || ''}
              onChange={onChange}
              className="mb-3"
            />
          )}
        />
        {errors?.form_special_instructions?.type === 'maxLength' && (
          <span className="form-text form-error">
            Max 1000 character allowed
          </span>
        )}
        <StylishNewButton
          className={'button--primary'}
          type="button"
          onClick={onSaveSpecialInstructions}
          disabled={!isUserPSC}
        >
          Save
        </StylishNewButton>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>
          9. Communications (radio and/or phone contact numbers needed for this
          assignment)
        </h4>
        <div className="row">
          {communications.map((communication, index) => (
            <div
              key={index}
              className={`${
                communications.length === 1
                  ? 'col-lg-12'
                  : communications.length === 2
                  ? 'col-lg-6'
                  : 'col-lg-4'
              }`}
            >
              <Communication
                total={communications.length}
                index={index}
                key={index}
                value={communication}
                onAdd={onAddCommunication}
                onDelete={() => onRemoveCommunication(index)}
                onChangeValue={(value, index) =>
                  onChangeCommunication(value, index)
                }
                disabled={!isUserPSC}
              />
            </div>
          ))}
        </div>
        <div className="pt-2">
          <h4>Emergency Communications</h4>
          <div className="row mb-4">
            <div className="col-md-4 mb-3 mb-md-0">
              <label className="form-label">Medical</label>
              <Controller
                control={control}
                name="medical_comm"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label className="form-label">Evacuation</label>
              <Controller
                control={control}
                name="evacuation_comm"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput onChange={onChange} value={value} />
                )}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Other</label>
              <Controller
                control={control}
                name="other_comm"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput onChange={onChange} value={value} />
                )}
              />
            </div>
          </div>
          <StylishNewButton
            className="button--primary"
            type="button"
            onClick={onSaveEmergencyCommunications}
            disabled={!isUserPSC}
          >
            Save
          </StylishNewButton>
        </div>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>10. Prepared By</h4>
        <div className="mb-4">
          <label className="form-label">Name:</label>
          <Controller
            control={control}
            name="prepared_by"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                value={
                  value ||
                  reduxUser.profile_settings?.userName ||
                  reduxUser.email_address
                }
                readOnly
              />
            )}
          />
        </div>
        <div className="m-0">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          <p className="fst-italic weight-500 m-0">
            Signatures are used to electronically sign Forms.
          </p>
        </div>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>11. Reviewed By (Planning Section Chief)</h4>
        {planningSectionChiefs && planningSectionChiefs.length > 0 ? (
          <>
            <div className="mb-4">
              <label className="form-label">Name:</label>
              <Controller
                control={control}
                name="reviewed_by_planning_section_chief"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={planningSectionChiefs}
                    value={value}
                    placeholder="Select a Type"
                    onChange={(e) => {
                      updateReviewedByPSC(e);
                      return onChange(e);
                    }}
                    isDisabled={false}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                  />
                )}
              />
            </div>
            <div className="m-0">
              <label className="form-label">
                Signature: <span className="color-danger">*</span>
              </label>
              <p className="fst-italic weight-500 m-0">
                Signatures are used to electronically sign Forms.
              </p>
            </div>
          </>
        ) : (
          <p className="fst-italic weight-500 m-0">
            Planning Section Chief can be managed at the{' '}
            <Link to="/incident_configuration">Duty Roster</Link>{' '}
          </p>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="m-0">
        <h4>12. Reviewed By (Operations Section Chief)</h4>
        {!!operationsSectionChiefs && operationsSectionChiefs.length > 0 ? (
          <>
            <div className="mb-4">
              <label className="form-label">Name:</label>
              <Controller
                control={control}
                name="reviewed_by_operations_section_chief"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={operationsSectionChiefs}
                    value={value}
                    placeholder="Select a Type"
                    onChange={(e) => {
                      onChange(e);
                      updateReviewedByOSC(e);
                    }}
                    isDisabled={false}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                  />
                )}
              />
            </div>
            <div className="m-0">
              <label className="form-label">
                Signature: <span className="color-danger">*</span>
              </label>
              <p className="fst-italic weight-500 m-0">
                Signatures are used to electronically sign Forms.
              </p>
            </div>
          </>
        ) : (
          <p className="fst-italic weight-500 m-0">
            Operations Section Chief can be managed at the{' '}
            <Link to="/incident_configuration">Duty Roster</Link>{' '}
          </p>
        )}
      </div>
      {showBranchModal && (
        <BranchModal
          selectedBranch={
            planningWorksheet?.branch_id
              ? {
                  id: planningWorksheet.branch_id,
                  name: planningWorksheet.branch_name,
                }
              : null
          }
          show={showBranchModal}
          rowSelectable={true}
          onClose={(e) => {
            if (showBranchModal) {
              setShowBranchModal(false);
            }
            return;
          }}
        />
      )}
      {showDivisionModal && (
        <DivisionModal
          selectedBranch={
            planningWorksheet?.branch_id
              ? {
                  id: planningWorksheet.branch_id,
                  name: planningWorksheet.branch_name,
                }
              : null
          }
          selectedDivision={
            planningWorksheet?.division_id
              ? {
                  id: planningWorksheet.division_id,
                  name: planningWorksheet.division_name,
                }
              : null
          }
          show={showDivisionModal}
          rowSelectable={true}
          onClose={() => setShowDivisionModal(false)}
        />
      )}
      {isWorkAssignmentsDialogOpen && (
        <AddUpdateWorkAssignment
          incidentObjectives={incidentObjectives}
          incidentResources={reduxIncidentResources}
          workAssignmentModalType={workAssignmentModalType}
          updateActionData={updateAssignmentData}
          show={isWorkAssignmentsDialogOpen}
          onClose={() => setIsWorkAssignmentsDialogOpen(false)}
          branch={{
            name: planningWorksheet?.branch_name,
            id: planningWorksheet.branch_id,
          }}
          division={{
            name: planningWorksheet?.division_name,
            id: planningWorksheet.division_id,
          }}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={deleteConfirmationDialogText}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() =>
            deleteConfirmationType === 'Work Assignment'
              ? onConfirmedDeleteActionData()
              : deleteConfirmationType === 'Communication'
              ? onConfirmedDeleteCommunicationData()
              : deleteConfirmationType === 'Resource'
              ? onConfirmedDeleteAssignmentResourceData()
              : null
          }
        />
      )}
      {isEditOperationalPeriodOpen && (
        <EditOperationalPeriodDialog
          show={isEditOperationalPeriodOpen}
          onClose={() => setIsEditOperationalPeriodOpen(false)}
        />
      )}
      {isResourceDialogOpen && (
        <IAPForm204ResourceDialog
          show={isResourceDialogOpen}
          onClose={() => setIsResourceDialogOpen(false)}
          updateAssignmentResourceData={updateAssignmentResourceData}
          resourceDialogType={resourceDialogType}
          allMembers={reduxRosterForIncident}
        />
      )}
    </>
  );
}
