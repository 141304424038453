import React from 'react';

export default function TransportationMapLayerPopup({
  features,
  handleReportSelected,
}) {
  const feature = features?.[0]?.properties;
  if (!feature) return <></>;

  return (
    <>
      <h6 className="m-0">Transportation Report</h6>
      <hr className="dashed thin my-1" />
      <div
        className="popup-container"
        onClick={() => handleReportSelected(feature)}
      >
        {feature?.code_icao ? (
          <>
            <span className="d-flex">
              Aircraft Type: {feature?.aircraft_type}
            </span>
            <span className="d-flex">Destination: {feature?.name}</span>
            <span className="d-flex">
              Current Time: {new Date(feature?.timestamp).toLocaleString()}
            </span>
          </>
        ) : (
          <>
            <span className="m-0 fw-bold">{feature?.name}</span>
            <span className="d-flex">Aircraft Type: {feature?.node_type}</span>
            <span className="d-flex">Status: {feature?.status}</span>
            <span className="d-flex">
              IATA: {feature?.iata_code}, ICAO: {feature?.icao_code}
            </span>
          </>
        )}
      </div>
      <hr className="thin my-1" />
    </>
  );
}
