// SitrepSection.js

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // Import remarkGfm
import rehypeRaw from 'rehype-raw'; // Import rehypeRaw
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize'; // Import rehypeSanitize and defaultSchema
import MDEditor from '@uiw/react-md-editor';
import Dropdown from 'react-bootstrap/Dropdown';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import dayjs from 'dayjs';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { Modal } from 'react-bootstrap';
import { AiOutlineFileWord } from 'react-icons/ai';
import { ImOnedrive } from "react-icons/im";
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { useSelector } from 'react-redux';
import {
  selectIncident,
} from 'slices/commonSelectors';
import { useSharepointDocxUpload } from './hooks/useSharepointFiles';
import AIContentReportDialog from './AIContentReportDialog'; // Import AIContentReportDialog

import {
  useUpdateSitrepSectionV2,
  useRefreshSitrepV2,
  useRemoveSitrepSectionV2,
  useMoveSitrepSectionV2, // Import the new hook
} from 'components/DisasterGPT/hooks/useSitrepsV2';

import { docxExport } from './DisasterGPTDataHelpers/DGPTExport';

import SitrepSectionEditDrawer from './SitrepSectionEditDrawer'; // Import the new component

import './ChatDisasterGPT.css';
import './SitrepSection.css'; // Import custom CSS for table styling

import { section_template_types } from './DisasterGPTConstants';

const SitrepSection = ({
  sitrep,
  section,
  index,
  totalSections,
  showMostRecent = true,
  timestampSelectedCallback,
  isSitrepReady = false,
  selectedDatetime,
}) => {

  const [version, setVersion] = useState(null);
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState('');
  const [priorText, setPriorText] = useState('');
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showEditSettingsDrawer, setShowEditSettingsDrawer] = useState(false);
  const [isSectionRefreshing, setIsSectionRefreshing] = useState(false);

  const { uploadDocxToSharepoint } = useSharepointDocxUpload();
  const currentIncident = useSelector(selectIncident);

  const updateSitrepSectionMutation = useUpdateSitrepSectionV2();
  const refreshSitrepMutation = useRefreshSitrepV2();
  const removeSitrepSectionMutation = useRemoveSitrepSectionV2();
  const moveSitrepSectionMutation = useMoveSitrepSectionV2(); // Use the move section mutation

  const isUpdateSitrepSectionLoading = updateSitrepSectionMutation.isLoading;
  const isRefreshSitrepLoading = refreshSitrepMutation.isLoading;
  const isRemoveSitrepSectionLoading = removeSitrepSectionMutation.isLoading;
  const isMoveSitrepSectionLoading = moveSitrepSectionMutation.isLoading;

  const [isProcessing, setIsProcessing] = useState(false);
  const [reported, setReported] = useState(undefined);

  useEffect(() => {
    if (version) {
      setIsProcessing(
        version.status &&
          version.status.toLowerCase() !== 'completed' &&
          version.status.toLowerCase() !== 'summarized'
      );
    }
  }, [version]);

  const isActionsDisabled =
    !isSitrepReady ||
    isUpdateSitrepSectionLoading ||
    isRefreshSitrepLoading ||
    isRemoveSitrepSectionLoading ||
    isMoveSitrepSectionLoading ||
    isProcessing;

  useEffect(() => {
    if (section && section.section_versions && section.section_versions[0]) {
      setVersion(section.section_versions[0]);
      setText(section.section_versions[0].sitrep_section_text);
      setPriorText(section.section_versions[0].sitrep_section_text);
      if (section.section_versions.length > 1) {
        setPriorText(section.section_versions[1].sitrep_section_text);
      }

      // Automatically enter edit mode for manual sections with empty text
      if (
        section.type === 'manual' &&
        section.section_versions[0].sitrep_section_text === ''
      ) {
        setEditing(true);
      }
    } else {
      setVersion(null);
      setText('');
      setPriorText('');
    }
  }, [section]);

  useEffect(() => {
    if (version) {
      setText(version.sitrep_section_text);
      setPriorText(version.sitrep_section_text);
      if (section.section_versions.length > 1) {
        const idx = section.section_versions.findIndex(
          (v) => v.version_id === version.version_id
        );
        if (section.section_versions[idx + 1]) {
          setPriorText(section.section_versions[idx + 1].sitrep_section_text);
        }
      }
    }
  }, [version, section.section_versions]);

  function editClicked() {
    if (!version) return;
    setEditing(true);
  }

  function saveClicked() {
    if (!version) return;
    setEditing(false);
    updateSitrepSectionMutation.mutate(
      {
        sitrepId: sitrep.id,
        sectionId: section.id,
        updatedFields: {
          sectionText: text,
        },
      },
      {
        onError: (error) => {
          toast.error('Failed to update section text.', toastConfig);
          console.error('Error updating section text:', error);
        },
      }
    );
  }

  function cancelClicked() {
    if (!version) return;
    setEditing(false);
    setText(version.sitrep_section_text);
  }

  function removeClicked() {
    setShowRemoveModal(true);
  }

  function handleConfirmRemove() {
    removeSitrepSectionMutation.mutate(
      {
        sitrepId: sitrep.id,
        sectionId: section.id,
      },
      {
        onError: (error) => {
          toast.error('Failed to remove section.', toastConfig);
          console.error('Error removing section:', error);
        },
      }
    );
    setShowRemoveModal(false);
  }

  function handleCancelRemove() {
    setShowRemoveModal(false);
  }

  function refreshSection(hardRefresh = false) {
    setIsSectionRefreshing(true);
    refreshSitrepMutation.mutate(
      {
        id: sitrep.id,
        name: sitrep.name,
        selectedDatetime: selectedDatetime,
        sections: [section.id], // Pass the section ID here
        hardRefresh,
      },
      {
        onError: (error) => {
          setIsSectionRefreshing(false);
          toast.error('Failed to refresh section.', toastConfig);
          console.error('Error refreshing section:', error);
        },
        onSuccess: () => {
          setIsSectionRefreshing(false);
        },
      }
    );
  }

  function moveSection(direction) {
    moveSitrepSectionMutation.mutate(
      {
        sitrepId: sitrep.id,
        sectionId: section.id,
        direction,
      },
      {
        onError: (error) => {
          toast.error('Failed to move section.', toastConfig);
          console.error('Error moving section:', error);
        },
      }
    );
  }

  function normalizeSectionForExport(section) {
    const title = section.name;
    const text = section.section_versions[0].sitrep_section_text;

    // Remove any '{{' or '}}' from the text
    const cleanedText = text
      .replace(/{{/g, '')
      .replace(/}}/g, '')
      .replace(/【.*?】/g, '');

    return `
**${title}:**

${cleanedText}
`;
  }

  // Function to export section as DOCX
  function downloadSectionAsDocx() {
    if (!version) return;
    const sectionContent = normalizeSectionForExport(section);

    // Create structured data as an array of objects
    const structuredData = [
      {
        role: section.name,
        timestamp: version.timestamp || '', // Ensure this exists; adjust as needed
        content: sectionContent,
      },
    ];

    // Pass the structured data to docxExport
    docxExport(structuredData, 'Download', `SITREP_${section.name}`);
  }

  function uploadSectionAsDocxToSharepoint() {
    if (!version) return;
    const sectionContent = normalizeSectionForExport(section);

    // Create structured data as an array of objects
    const structuredData = [
      {
        role: section.name,
        timestamp: version.timestamp || '', // Adjust as needed
        content: sectionContent,
      },
    ];

    // 1) Call docxExport, which returns a Promise<Blob> when destination is 'Sharepoint'
    docxExport(structuredData, 'Sharepoint', `SITREP_${section.name}`)
      .then(({ blob, filenameWithTimestamp }) => {
        // 2) Upload the blob to SharePoint using our hook
        if (blob) {
          // Append ".docx" if desired
          uploadDocxToSharepoint(blob, `${section.name}_${filenameWithTimestamp}`);
        }
      })
      .catch((err) => {
        console.error('Error exporting section to SharePoint:', err);
        // docxExport might already toast the error, but you can add more handling here
      });
  }

  function copySectionToClipboard() {
    if (!version) return;
    const title = section.name;
    const text = version.sitrep_section_text;

    // Remove any '{{' or '}}' from the text
    const cleanedText = text.replace(/{{/g, '').replace(/}}/g, '');

    const sectionContent = `
**${title}:**

${cleanedText}
`;

    navigator.clipboard.writeText(sectionContent).catch((err) => {
      console.error('Failed to copy section: ', err);
    });
  }

  const customSchema = {
    ...defaultSchema,
    attributes: {
      ...defaultSchema.attributes,
      span: [...(defaultSchema.attributes.span || []), 'style'],
    },
  };

  // Format text for markdown
  let formattedText = '';
  if (text) {
    formattedText = text
      .replaceAll(`{{`, `<span style="color: var(--warning-400);">`)
      .replaceAll(`}}`, `</span>`);
  }

  // Process formattedText to move any '<span>' tags occurring before header markers to after them
  if (formattedText) {
    let lines = formattedText.split('\n');
    lines = lines.map((line) => {
      // Match lines starting with optional whitespace, '<span...>', optional whitespace, '#' symbols, optional whitespace
      const headerMatch = line.match(/^(\s*)(<span[^>]*>)(\s*)(#+)(\s*)(.*)/);
      if (headerMatch) {
        const leadingWhitespace = headerMatch[1];
        const spanTag = headerMatch[2];
        const whitespaceBetweenSpanAndHash = headerMatch[3];
        const hashSymbols = headerMatch[4];
        const whitespaceAfterHash = headerMatch[5];
        const restOfLine = headerMatch[6];

        // Rearrange the line to move '<span...>' after the header markers and any whitespace following them
        line = `${leadingWhitespace}${hashSymbols}${whitespaceAfterHash}${spanTag}${restOfLine}`;
      }
      return line;
    });
    formattedText = lines.join('\n');
  }

  formattedText = formattedText.replaceAll('```json', '').replaceAll('```', '');
  if (
    formattedText[0] === `"` &&
    formattedText[formattedText.length - 1] === `"`
  ) {
    formattedText = formattedText.slice(1, -1);
  }
  formattedText = formattedText.replace('markdown', '');
  formattedText = formattedText.replaceAll(/\\n/g, '\n');
  formattedText = formattedText.replaceAll(/【[^】]*】/g, '');

  // If version is not available, show loading spinner
  if (!version) {
    return (
      <div className="SitrepSection">
        <div className="d-flex justify-content-center">
          <i className="fa fa-spinner fa-pulse fs-5 "></i>
        </div>
      </div>
    );
  }

  function editSettingsClicked() {
    setShowEditSettingsDrawer(true);
  }

  function handleSaveSettings(formData) {
    const updatedFields = {
      name: formData.name,
      type: formData.type,
      data_sources: formData.dataSources,
      ai_instructions: formData.ai_instructions,
      output_format: formData.output_format,
      output_format_structured: formData.output_format_structured,
    };

    updateSitrepSectionMutation.mutate(
      {
        sitrepId: sitrep.id,
        sectionId: section.id,
        updatedFields,
      },
      {
        onSuccess: () => {
          // After updating settings, refresh the section
          refreshSection(true);
        },
        onError: (error) => {
          toast.error('Failed to update section settings.', toastConfig);
          console.error('Error updating section settings:', error);
        },
      }
    );
    setShowEditSettingsDrawer(false);
  }

  // Custom components for ReactMarkdown to style tables
  const markdownComponents = {
    table({ node, ...props }) {
      return <table className="custom-markdown-table" {...props} />;
    },
    th({ node, ...props }) {
      return <th className="custom-markdown-th" {...props} />;
    },
    td({ node, ...props }) {
      return <td className="custom-markdown-td" {...props} />;
    },
    tr({ node, ...props }) {
      return <tr className="custom-markdown-tr" {...props} />;
    },
    // ... other custom components if needed
  };

  return (
    <div className="SitrepSection">
      <div key={'dpgt-message-'}>
        <div key={'EventAIAnalysis-item'}>
          <div className="paragraph-header">
            <h4>{section.name}</h4>
            <span>
              <Dropdown className="flex-shrink-0 ms-auto">
                <Dropdown.Toggle className="button--icon border-0">
                  <SharedIcon iconName="more_vert" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {editing ? (
                    <>
                      <Dropdown.Item as="button" onClick={() => saveClicked()}>
                        Save
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => cancelClicked()}
                      >
                        Cancel
                      </Dropdown.Item>
                    </>
                  ) : (
                    <>
                      <Dropdown.Item
                        as="button"
                        onClick={() => copySectionToClipboard()}
                        disabled={isActionsDisabled}
                      >
                        Copy Section to Clipboard
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => downloadSectionAsDocx()}
                        disabled={isActionsDisabled}
                      >
                        <AiOutlineFileWord className="me-2" />
                        Download Section as DOCX
                      </Dropdown.Item>
                      {currentIncident?.tenant_id && currentIncident?.sharepoint_location && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => uploadSectionAsDocxToSharepoint()}
                          disabled={isActionsDisabled}
                        >
                          <ImOnedrive className="me-2" />
                          Export Section DOCX to Sharepoint
                        </Dropdown.Item>     
                      )}
                      <Dropdown.Item
                        as="button"
                        onClick={() => refreshSection(false)}
                        disabled={section.type === 'manual'}
                      >
                        Update
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => refreshSection(true)}
                        disabled={section.type === 'manual'}
                      >
                        Refresh
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => moveSection('up')}
                        disabled={isActionsDisabled || index === 0}
                      >
                        Move Up
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => moveSection('down')}
                        disabled={
                          isActionsDisabled || index === totalSections - 1
                        }
                      >
                        Move Down
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => editClicked()}
                        disabled={isActionsDisabled}
                      >
                        Edit Text
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => editSettingsClicked()}
                        disabled={
                          isActionsDisabled ||
                          section.type === 'EVENT' ||
                          section.type === 'manual'
                        }
                      >
                        Edit Settings
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => removeClicked()}
                        disabled={isActionsDisabled || section.type === 'EVENT'}
                      >
                        Remove
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => setReported(section)}
                      >
                        Report
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
          <AIContentReportDialog 
            reportedContent={reported}
            onClose={() => setReported(undefined)}
          />
          <div style={{ marginLeft: '16px' }}>
            <i>
              {
                section_template_types.find((t) => t.value === section.type)
                  ?.label
              }
            </i>
          </div>
          <div className="p-3">
            <div className="date-section">
              {!editing &&
                (section.section_versions.length === 1 ? (
                  <>
                    <span className="material-symbols-outlined me-2 fs-5">
                      update
                    </span>
                    {dayjs(section.section_versions[0].timestamp).format(
                      'hh:mm, ddd MMMM D, YYYY'
                    )}
                  </>
                ) : (
                  <>
                    <span className="material-symbols-outlined fs-5">
                      update
                    </span>
                    <StylishNewSelect
                      options={section.section_versions.map((v) => {
                        return {
                          label: dayjs(v.timestamp).format(
                            'hh:mm, ddd MMMM D, YYYY'
                          ),
                          value: v,
                        };
                      })}
                      defaultValue={{
                        label: dayjs(version.timestamp).format(
                          'hh:mm, ddd MMMM D, YYYY'
                        ),
                        value: version,
                      }}
                      value={{
                        label: dayjs(version.timestamp).format(
                          'hh:mm, ddd MMMM D, YYYY'
                        ),
                        value: version,
                      }}
                      onChange={(e) => {
                        setVersion(e.value);
                      }}
                      placeholder={dayjs(version.timestamp).format(
                        'hh:mm, ddd MMMM D, YYYY'
                      )}
                      isClearable={true}
                      isSearchable={true}
                      isMulti={false}
                      isDisabled={false}
                    />
                  </>
                ))}
            </div>
            <div className="text-section">
              {isProcessing ? (
                <div className="d-flex justify-content-center">
                  <span>Processing...</span>
                  <i className="fa fa-spinner fa-pulse fs-5 "></i>
                </div>
              ) : editing ? (
                <div className="SitrepSection-editor-wrap">
                  <MDEditor value={text} onChange={setText} height="100%" />
                </div>
              ) : text ? (
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]} // Include remarkGfm here
                  rehypePlugins={[
                    [rehypeRaw, { passThrough: ['element'] }],
                    [rehypeSanitize, customSchema],
                  ]}
                  components={{
                    ...markdownComponents, // Include custom components for table styling
                    a: ({ node, ...props }) => (
                      <a {...props} target="_blank" rel="noopener noreferrer" />
                    ),
                  }}
                >{formattedText}</ReactMarkdown>
              ) : (
                // For manual sections with empty text, prompt user to edit
                <div className="d-flex justify-content-center">
                  <StylishNewButton
                    onClick={() => editClicked()}
                    disabled={isActionsDisabled}
                  >
                    Add Content
                  </StylishNewButton>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end p-3 pt-0">
            {editing ? (
              <>
                <StylishNewButton
                  className="button button--secondary me-3"
                  onClick={() => cancelClicked()}
                >
                  Cancel
                </StylishNewButton>
                <StylishNewButton
                  className="button button--primary"
                  onClick={() => saveClicked()}
                  disabled={isActionsDisabled}
                >
                  Save
                </StylishNewButton>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showRemoveModal}
        onHide={handleCancelRemove}
        centered
        backdrop={true}
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Remove Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove this Section from the SITREP?
        </Modal.Body>
        <Modal.Footer>
          <StylishNewButton
            className="button--secondary"
            onClick={handleCancelRemove}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--tertiary"
            onClick={handleConfirmRemove}
            disabled={isActionsDisabled}
          >
            Remove
          </StylishNewButton>
        </Modal.Footer>
      </Modal>

      {/* Include the SitrepSectionEditDrawer */}
      {showEditSettingsDrawer && (
        <SitrepSectionEditDrawer
          show={showEditSettingsDrawer}
          onHide={() => setShowEditSettingsDrawer(false)}
          onSave={handleSaveSettings}
          section={section}
        />
      )}
    </div>
  );
};

export default SitrepSection;
