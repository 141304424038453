import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSResourceSetDialog from 'components/ICS/NewICSResourceSetDialog';
import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { fetchResourceSets, updateResourceSet } from 'slices/ics_resourceSetsSlice';
import ExpandRightArrow from 'components/DesignSystems/buttons/ExpandRightArrow';
import ExpandDownArrow from 'components/DesignSystems/buttons/ExpandDownArrow';

export default function ResourceSetsDialog({ show, onClose, ics_resource_sets, ics_resource_sites, ics_resources }) {
  const dispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);

  const [isNewResourceSetDialogOpen, setIsNewResourceSetDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editResourceSetData, setEditResourceSetData] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  useEffect(() => {
    if (reduxCurrentlySelectedGroup) {
      dispatch(fetchResourceSets(reduxCurrentlySelectedGroup.group_guid));
    }
  }, [dispatch, reduxCurrentlySelectedGroup]);

  const columns = [
    { dataField: 'name', text: 'Name', sort: true, attrs: { title: 'Name' } },
    { dataField: 'kind', text: 'Kind', sort: true, attrs: { title: 'Kind' } },
    { dataField: 'type', text: 'Type', sort: true, attrs: { title: 'Type' } },
    { dataField: 'quantity', text: 'Quantity', sort: true, attrs: { title: 'Quantity' } },
    { dataField: 'status', text: 'Status', sort: true, attrs: { title: 'Status' } },
  ];

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewResourceSetDialogOpen(true);
    setEditResourceSetData(row);
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      archived: !archiveConfirmationData.archived,
    };
    dispatch(updateResourceSet(archiveConfirmationData.id, payload));
    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewResourceSet = () => {
    setModalType('New');
    setIsNewResourceSetDialogOpen(true);
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => (
      <>
        <div className="mb-4">
          <h6 className="mb-2">Resource ID:</h6>
          <p className="m-0">{row.resource_id}</p>
        </div>
        <div className="mb-4">
          <h6 className="mb-2">Resource Site ID:</h6>
          <p className="m-0">{row.resource_site_id}</p>
        </div>
        <div className="mb-4">
          <h6 className="mb-2">Quantity:</h6>
          <p className="m-0">{row.quantity}</p>
        </div>
        <div className="mb-4">
          <h6 className="mb-2">Status:</h6>
          <p className="m-0">{row.status}</p>
        </div>
        <div className="button-group">
          <StylishNewButton className="button--primary" onClick={() => onEdit(row)}>
            Edit
          </StylishNewButton>
          <StylishNewButton className="button--tertiary button--reverse" onClick={() => onArchive(row)}>
            {row.archived ? 'Unarchive' : 'Archive'}
          </StylishNewButton>
        </div>
      </>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <span className="sr-only">Details</span>
            <ExpandDownArrow />
          </>
        );
      }
      return (
        <>
          <span className="sr-only">Details</span>
          <ExpandRightArrow />
        </>
      );
    },
  };

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Resource Sets</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">{reduxCurrentlySelectedGroup?.group_name}</p>
        <StylishNewButton type="button" className="button--primary mb-4" onClick={onNewResourceSet}>
          Add Resource Set
        </StylishNewButton>
        <StylishNewTable keyField={'id'} rows={ics_resource_sets} columns={columns} expandRow={expandRow} />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton className="button--secondary button--reverse" type="button" onClick={onClose}>
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewResourceSetDialogOpen && (
        <NewICSResourceSetDialog
          show={isNewResourceSetDialogOpen}
          setEditResourceSetData={setEditResourceSetData}
          setModalType={setModalType}
          editResourceSetData={editResourceSetData}
          ics_resource_sites={ics_resource_sites}
          modalType={modalType}
          onClose={() => setIsNewResourceSetDialogOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Resource Set?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
