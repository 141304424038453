import React from 'react';

export default function ICSIncidentObjective({ row }) {
  // TODO: fetch versions so we can show them here.
  // const [versions, setVersions] = useState(row.versions);
  return (
    <div className="ICSIncidentObjective">
      {/*{!!versions && (*/}
      {/*  <div className="mb-4">*/}
      {/*    <h6 className="mb-2">Version:</h6>*/}
      {/*    <StylishNewSelect*/}
      {/*      options={versions.map((v) => {*/}
      {/*        return {*/}
      {/*          label: dayjs(v.timestamp).format('YYYY-MM-DD HH:mm'),*/}
      {/*          value: v,*/}
      {/*        };*/}
      {/*      })}*/}
      {/*      defaultValue={version}*/}
      {/*      value={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}*/}
      {/*      onChange={(e) => {*/}
      {/*        setVersion(e.value);*/}
      {/*      }}*/}
      {/*      placeholder={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}*/}
      {/*      isClearable={true}*/}
      {/*      isSearchable={true}*/}
      {/*      isMulti={false}*/}
      {/*      isDisabled={false}*/}
      {/*      className="SitrepSection-Select"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="mb-4">
        <h6 className="mb-2">Name:</h6>
        <p className="m-0">{row.name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Description:</h6>
        <p className="m-0">{row.description}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p className="m-0">{row.user_name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p className="m-0">{row.archived ? 'Yes' : 'No'}</p>
      </div>
    </div>
  );
}
