import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import generateUUID from 'utils/sharedUtils/generateUUID';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import {
  updateIcsResourceRequestItem,
} from 'slices/ics_resourceRequestItemsSlice';

import {rri_status_options} from './ICSConstants'

import {getResourceDetails} from './ICSHelpers'


export default function NewICSResourceRequestItemDialog({
  show,
  setEditResourceRequestItemData,
  editResourceRequestItemData,
  modalType,
  onClose,
  ics_resources,
  resourceRequestId,
  resourceRequestItems,
  setResourceRequestItems,
  isNewResourceRequest,
  ics_resource_sites
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [resourceId, setResourceId] = useState('');
  const [resourceSiteId, setResourceSiteId] = useState('')
  const reduxDispatch = useDispatch();



  useEffect(() => {
    if (modalType === 'Edit' && editResourceRequestItemData) {
      setResourceId(editResourceRequestItemData.resource_id);
      setValue('quantity', editResourceRequestItemData.quantity);
      setValue('priority', editResourceRequestItemData.priority);
      setValue('cost', editResourceRequestItemData.cost);
      setValue('requested_reporting_location_text', editResourceRequestItemData.requested_reporting_location_text);
      setValue('requested_reporting_datetime', editResourceRequestItemData.requested_reporting_datetime);
      setValue('arrival_date_time_estimated', editResourceRequestItemData.arrival_date_time_estimated);
      setValue('arrival_date_time_actual', editResourceRequestItemData.arrival_date_time_actual);
      setValue('description', editResourceRequestItemData.description);
      setValue('destination_resource_site_id',editResourceRequestItemData.destination_resource_site_id)
      setResourceSiteId(editResourceRequestItemData.destination_resource_site_id)
    }
  }, [modalType, editResourceRequestItemData]);

  const onNewResourceRequestItemDataSubmit = (data) => {
    const payload = {
      id: generateUUID(),
      resource_request_id: resourceRequestId,
      resource_request_item_id: editResourceRequestItemData?.resource_request_item_id || generateUUID(),
      resource_id: resourceId,
      quantity: data.quantity,
      priority: data.priority,
      cost: data.cost,
      requested_reporting_location_text: data.requested_reporting_location_text,
      requested_reporting_datetime: data.requested_reporting_datetime,
      arrival_date_time_estimated: data.arrival_date_time_estimated,
      arrival_date_time_actual: data.arrival_date_time_actual,
      description: data.description,
      user_guid: data.user_guid,
      status: editResourceRequestItemData?.status || rri_status_options[0].label,
      archived: false,
      destination_resource_site_id: resourceSiteId
    };

    if (modalType === 'New') {
      setResourceRequestItems([...resourceRequestItems, payload]);
      onClose();
    }
     else if (modalType === 'Edit' && !isNewResourceRequest) {
      payload.id = editResourceRequestItemData.id;
      const newRRI = [...resourceRequestItems.filter(rri=>rri.resource_id!==payload.resource_id).filter(rri=>!!rri),payload]
      setResourceRequestItems(newRRI)
      reduxDispatch(updateIcsResourceRequestItem(payload.id, payload));
      onClose();
    } else if (modalType === 'Edit' && isNewResourceRequest) {
      const newRRI = [...resourceRequestItems.filter(rri=>rri.resource_id!==payload.resource_id).filter(rri=>!!rri),payload]
      setResourceRequestItems(newRRI)
      onClose()
    }
  };

  const resourceOptions = ics_resources
  .filter(r=>!resourceRequestItems.find(rri=>rri.resource_id===r.id))
  .map((resource) => ({
    label: resource.name,
    value: resource.id,
  }))

  const resourceSiteOptions = ics_resource_sites
  .map((resource_site) => ({
    label: resource_site.name,
    value: resource_site.id,
  }))

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Resource Request Item</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewResourceRequestItemDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Resource:</label>
            {!editResourceRequestItemData && (
              <Controller
                control={control}
                name="resourceId"
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={resourceOptions}
                    defaultValue={ics_resources.find((resource) => resource.id === resourceId)}
                    onChange={(e) => {
                      onChange(e.value);
                      setResourceId(e.value);
                    }}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    className="SitrepSection-Select"
                  />
                )}
              />
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Kind:&nbsp;</label>
            <span>
              {getResourceDetails(ics_resources,resourceId).kind}
            </span>
          </div>
          <div className="m-0">
            <label className="form-label">Type:&nbsp;</label>
            <span>
              {getResourceDetails(ics_resources,resourceId).type}
            </span>
          </div>
          <div className="m-0">
            <label className="form-label">Quantity:</label>
            <Controller
              control={control}
              name="quantity"
              rules={{ required: true, validate: value => value > 0 }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="number"
                  onChange={(e) => onChange(parseFloat(e.target.value))}
                  value={value || ''}
                />
              )}
            />
            {errors.quantity && (
              <span className="form-text form-error">
                This field is required and must be greater than 0
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Priority:</label>
            <Controller
              control={control}
              name="priority"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Cost:</label>
            <Controller
              control={control}
              name="cost"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="number"
                  onChange={(e) => onChange(parseFloat(e.target.value))}
                  value={value || ''}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Resquestd Reporting Location (Resource Site/Staging Area):</label>
              <Controller
                control={control}
                name="resourceSiteId"
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={resourceSiteOptions}
                    defaultValue={ics_resource_sites.find((resource_site) => resource_site.id === resourceSiteId)}
                    onChange={(e) => {
                      onChange(e.value);
                      setResourceSiteId(e.value);
                    }}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    className="SitrepSection-Select"
                  />
                )}
              />
          </div>
          <div className="m-0">
            <label className="form-label">Requested Reporting Date/Time:</label>
            <Controller
              control={control}
              name="requested_reporting_datetime"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="datetime-local"
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Estimated Arrival Date/Time:</label>
            <Controller
              control={control}
              name="arrival_date_time_estimated"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="datetime-local"
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Actual Arrival Date/Time:</label>
            <Controller
              control={control}
              name="arrival_date_time_actual"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="datetime-local"
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label"><b>Status:</b></label>
            <div className="form-control-plaintext">
              <b>{editResourceRequestItemData?.status || 'Not Started'}</b>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
