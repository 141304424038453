import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import {
  CreateObjectiveReqData,
  keys as objectiveKeys,
  useCreateObjective,
} from './objectiveHooks';
import { useActualIncidentId } from './commonHooks';
import { keys as incidentStrategyKeys } from './incidentStrategyHooks';

export type IncidentObjective = {
  id: UUID;
  objective_id: UUID;
  incident_id: UUID;
  user_guid: UUID;
  version: number;
  name: string;
  description: string;
  timestamp: Date;
  archived: boolean;
  strategies: UUID[];
  user_name: string;
};

export const keys = {
  base: 'incident_objectives',
  get all() {
    return [keys.base];
  },
  list: (incidentId: UUID, userId: UUID) => [keys.base, incidentId, userId],
} as const;

export const useIncidentObjectives = <TData = IncidentObjective[]>(
  selector?: (i: IncidentObjective[]) => TData
) => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<IncidentObjective[]>(
        `${config.apiGateway.ics_incident_objectives}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
    select: selector,
    throwOnError: (error) => {
      toast.error(error.message, toastConfig);
      return false;
    },
  });
};

/** NOTE: this posts to the group objective which will create the incident version.  */
export const useCreateIncidentObjective = () => {
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  const mutation = useCreateObjective();
  return {
    ...mutation,
    mutateAsync: (data: CreateObjectiveReqData) => {
      data.incident_id = incidentId;
      return mutation.mutateAsync(data, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: objectiveKeys.all });
          queryClient.invalidateQueries({ queryKey: [keys.base] });
        },
      });
    },
  };
};

interface EditIncidentObjectiveReqData {
  objective_id: UUID;
  name: string;
  description: string;
  archived: boolean;
  strategies: UUID[];

  // user_guid: UUID;
}

export type EditResponse<A> = {
  data: A;
  meta: {
    [key: string]: boolean;
  };
};

export const useEditIncidentObjective = (objectiveId: UUID) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditIncidentObjectiveReqData) => {
      return await axios.put<EditResponse<IncidentObjective>>(
        `${config.apiGateway.ics_incident_objectives}/${incidentId}/${objectiveId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: (resp, _) => {
      queryClient.invalidateQueries({
        queryKey: keys.all,
      });
      queryClient.invalidateQueries({
        queryKey: objectiveKeys.all,
      });
      if (resp.data.meta?.updatedStrategies) {
        queryClient.invalidateQueries({
          queryKey: incidentStrategyKeys.all,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message, toastConfig);
    },
  });
};
