import React, { useEffect, useRef, useState } from "react";
import MapLegend from "components/MapComponent/mapComponents/MapLegend";
import mapboxgl from '!mapbox-gl';
import config from 'constants/apiConfig';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import getUserLayerNumber from "components/MapComponent/utils/getUserLayerNumber";
import { sampleDrawSource } from "components/MapComponent/constants/sampleSources";
import { sampleDrawLayer } from "components/GeoLocationComponent/GeoLocationComponent";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export default function OSINTMap() {

  const [weatherLayer, setWeatherLayer] = useState();
  const [lng, setLng] = useState(-93.33);//-70.9);
  const [lat, setLat] = useState(45);
  const [zoom, setZoom] = useState(3);
  const [layerClicked, setLayerClicked] = useState();
  const [drawActive, setDrawActive] = useState(false);
  const [drawComplete, setDrawComplete] = useState(false);
  const [bottomPanelActive, setBottomPanelActive] = useState();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const drawRef = useRef(React.createRef());


  const matches = window.matchMedia('(min-width: 768px)').matches;
  const basemap = 'mapbox://styles/mapbox/satellite-streets-v11';

  // Initialize Map
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: basemap,
      center: [lng, lat],
      zoom: zoom,
      projection: 'mercator',
      preserveDrawingBuffer: false,
      transformRequest: (url) => {
        if (url.startsWith(config.apiPrefix + '/tiles/')) {
          return {
            url: url,
            headers: {
              Authorization:
                'Bearer ' + process.env.REACT_APP_MAP_EVENT_TILES_TOKEN,
            },
          };
        }
      },
    });
    map.current.getCanvas().id = 'mapcanvas';
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
    map.current.addControl(geocoder);

    map.current.addControl(new mapboxgl.NavigationControl());
    //map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');
    //map.current.addControl(new mapboxgl.ScaleControl());

    map.current.addControl(
      new mapboxgl.AttributionControl({
        compact: true,
      })
    );

    const Draw = new MapboxDraw({
      controls: {
        direct_select: true,
        combine_features: false,
        uncombine_features: false,
        line_string: false,
        point: false,
        polygon: true,
        trash: true,
      },
    });

    drawRef.current = Draw;

    // Todo: The polygon is currently underneath all the data. It should be on top.
    // DRAW AND CUSTOM LAYERS POST-MVP
    //map.current.addControl(Draw, 'top-left');

    map.current.on('draw.create', (e) => {
      const feature = e.features[0];
      const layerNumber = getUserLayerNumber(map.current.getStyle().layers);
      const layerID = 'Custom Layer ' + layerNumber;
      const sourceID = layerID + ' Source';

      const newSource = {
        ...sampleDrawSource,
        data: {
          ...sampleDrawSource.data,
          features: [{ ...feature, id: 0 }],
        },
      };

      delete newSource.id;

      const newLayer = {
        ...sampleDrawLayer,
        id: layerID,
        source: sourceID,
      };

      // Layers have to be added directly here, then inserted into hooks, because event based programming "Freezes" state variables at declaration time
      map.current.addSource(sourceID, newSource);
      map.current.addLayer(newLayer);
      map.current.on('click', newLayer.id, (e) =>
        setLayerClicked({ layer: newLayer, e: e, features: e.features })
      );
      map.current.on('touchend', newLayer.id, (e) =>
        setLayerClicked({ layer: newLayer, e: e, features: e.features })
      );

      setDrawActive(false);
      setDrawComplete(true);
      map.current.getCanvas().style.cursor = 'grab';
      drawRef.current.trash();
    });

    map.current.on('draw.update', noop);

    map.current.on('draw.delete', noop);

    map.current.on('draw.modechange', (e) => {
      if (e.mode === 'draw_polygon') {
        setDrawActive(true);
        Draw.deleteAll().changeMode('draw_polygon');
        map.current.getCanvas().style.cursor = 'crosshair';
      }

      if (e.mode === 'direct_select') {
        setDrawActive(false);
        Draw.changeMode('simple_select');
        map.current.getCanvas().style.cursor = 'grab';
      }
    });

    map.current.on('idle', noop);
  });

  useEffect(() => {
    map.current.resize();
  }, [bottomPanelActive]);

  return (
    <div className="post-main">
      <h3>Location of Affected Areas of Responsibility</h3>
      <div className="bg-gray-800 post-map-container p-3 p-md-4">
        <div
          ref={mapContainer}
          className={`map-container ${
            bottomPanelActive ? 'with-bottom-panel' : ''
          }`}
        >
          {!!weatherLayer && (
            <MapLegend matches={matches} weatherLayer={weatherLayer} />
          )}
        </div>
      </div>
    </div>
  )
}
