import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import {
  describeSubscriptionLevel,
  useSubscriptionLevel,
} from './useSubscriptionLevel';

export default function SubscriptionStatusCard() {
  const { subscriptionLevel } = useSubscriptionLevel();
  return (
    <div className="card border-opacity-10 bg-dark border-light p-3 mt-3 subscription-status-card">
      <div className="d-flex align-items-center flex-column">
        <div className="d-flex align-items-center mb-3">
          <i className="material-symbols-outlined me-3">info</i>
          <span className="txt--semibold">
            Subscription status: {describeSubscriptionLevel(subscriptionLevel)}
          </span>
        </div>
        <StylishNewButton
          className="button--primary w-100 w-md-auto"
          onClick={() => {
            window.open('https://www.disastertech.com/contact-us', '_blank');
          }}
        >
          Contact Sales
        </StylishNewButton>
      </div>
    </div>
  );
}
