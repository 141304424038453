import React, { upsertDivision } from 'actions/IAPForm204Actions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import generateUUID from 'utils/sharedUtils/generateUUID';

export default function AddUpdateDivisionModal({
  show,
  onClose,
  selectedDivision,
  selectedBranch,
  parent,
}) {
  const reduxDixpatch = useDispatch();

  const [currentGroupUser, setCurrentGroupUser] = useState([]);

  const reduxRostersForGroups = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup && reduxRostersForGroups.length > 0) {
      const currentGroupUsers = reduxRostersForGroups
        .filter(
          (user) => user.group_guid === reduxCurrentlySelectedGroup.group_guid
        )
        .map((user) => ({
          value: user,
          label: user.profile_settings?.userName || user.email_address,
        }));
      setCurrentGroupUser(currentGroupUsers);
    }
  }, [reduxRostersForGroups]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const branchs = useSelector((state) =>
    state.app.allBranches.map((branch) => ({
      value: branch.id,
      label: branch.name,
    }))
  );

  const onSubmit = async (data) => {
    const division = {
      name: data.division_name,
      branch_id: data.branch.value,
      id: selectedDivision ? selectedDivision.id : generateUUID(),
      division_supervisor: data.division_supervisor.value.user_guid,
    };

    try {
      reduxDixpatch(startLoading());
      if(parent === 'IncidentWorkspace') {
        await reduxDixpatch(upsertDivision(division, 'getAll', selectedBranch?.id));
      } else {
        await reduxDixpatch(upsertDivision(division, '', selectedBranch?.id));

      }
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      reduxDixpatch(endLoading());
    }
  };

  useEffect(() => {
    if (selectedDivision) {
      setValue(
        'branch',
        branchs.find((b) => b.value === selectedDivision?.branch_id)
      );

      setValue('division_name', selectedDivision?.name?.trim());

      setValue(
        'division_supervisor',
        currentGroupUser.find(
          (cgu) => cgu.value.user_guid === selectedDivision?.division_supervisor
        )
      );
    }
  }, [selectedDivision, currentGroupUser]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Create Division / Group / Staging</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3 pt-1">
            <label className="form-label">Branch Name:</label>
            <Controller
              control={control}
              name="branch"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={branchs}
                  value={value}
                  onChange={onChange}
                  placeholder={'Select Branch'}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors?.branch && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
          <hr className="dashed my-4" />
          <div className="mb-3">
            <label className="form-label">
              Division / Group / Staging Name:
            </label>
            <Controller
              control={control}
              name="division_name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput onChange={onChange} value={value} />
              )}
            />
            {errors?.division_name && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
          <div className="mb-3 pt-1">
            <label className="form-label">Division/Group Supervisor:</label>
            <Controller
              control={control}
              name="division_supervisor"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={currentGroupUser}
                  value={value}
                  placeholder="Select a Branch Director"
                  onChange={onChange}
                  isDisabled={false}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                />
              )}
            />
            {errors?.division_supervisor && (
              <span className="form-text form-error">
                This field is required.
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              type="button"
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton type="submit" className="button--primary">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
