import React from 'react';
import { StylishNewButton } from './StylishNewButton';

export const StylishNewToggleButtonGroup = ({
  className,
  data,
  selected,
  onChange,
  disabled,
  size,
}) => {
  const handleClick = (e) => {
    onChange(e);
  };

  if (data.length === 1) {
    return (<div className="p-2 fw-bold">{data[0].label}</div>)
  }

  return (
    <>
      <div
        className={`button-group switcher ${className ? className : ''} ${
          size ? size : ''
        }`}
      >
        {data.map((e, idx) => (
          <StylishNewButton
            key={idx}
            value={e.value}
            onChange={onChange}
            className={selected === e.value ? 'active' : ''}
            onClick={() => handleClick(e.value)}
            disabled={disabled}
            type="button"
          >
            {e.label}
          </StylishNewButton>
        ))}
      </div>
    </>
  );
};
