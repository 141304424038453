import React from 'react';
import { StylishNewButton } from './StylishNewButton';

export const StylishNewChip = ({
  key,
  label,
  disabled,
  handleDisable,
  handleDelete,
  children,
  handleClick,
}) => {
  return (
    <>
      <div
        className={`form-chip ${disabled ? 'disabled' : ''} ${
          !handleDisable && !handleDelete ? 'raw' : ''
        } ${children ? 'has-children' : ''} ${
          handleClick ? 'cursor-pointer' : ''
        }`}
        key={key}
      >
        <span onClick={handleClick ? handleClick : undefined}>{label}</span>
        <div className="inner ms-auto">
          {children}
          <ul>
            {!!handleDisable && (
              <li>
                <StylishNewButton
                  customButton
                  type="button"
                  onClick={handleDisable}
                >
                  {disabled ? (
                    <svg
                      viewBox="0 0 640 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                    </svg>
                  ) : (
                    <svg
                      viewBox="0 0 576 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                    </svg>
                  )}
                </StylishNewButton>
              </li>
            )}
            {!!handleDelete && (
              <li>
                <StylishNewButton
                  customButton
                  type="button"
                  onClick={handleDelete}
                >
                  <svg viewBox="0 0 11 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.57305 6.84982C8.57305 5.85587 8.57091 4.86193 8.57391 3.86758C8.5752 3.44394 8.93507 3.16655 9.32365 3.31484C9.46889 3.3704 9.58285 3.52768 9.67924 3.66005C9.72937 3.72868 9.71994 3.84388 9.71994 3.93784C9.72208 5.89182 9.72808 7.8458 9.71866 9.79978C9.71437 10.6932 9.29966 11.38 8.4471 11.7672C8.14206 11.9057 7.77747 11.9801 7.43816 11.985C5.9104 12.0075 4.38221 11.9997 2.85446 11.9936C1.55762 11.9883 0.575671 11.0556 0.571816 9.82143C0.565818 7.85397 0.569245 5.8861 0.570959 3.91864C0.570959 3.45456 0.90213 3.18575 1.31899 3.30259C1.55548 3.36877 1.71057 3.5669 1.71742 3.82509C1.72342 4.04283 1.71871 4.26099 1.71871 4.47873C1.71871 6.24193 1.71871 8.00554 1.71871 9.76873C1.71871 10.4681 2.17155 10.8991 2.9063 10.8991C4.39849 10.8995 5.89069 10.8995 7.38289 10.8991C8.1185 10.8991 8.57134 10.4685 8.5722 9.76996C8.57262 8.79644 8.5722 7.82292 8.5722 6.849L8.57305 6.84982Z" />
                    <path d="M2.86541 1.63502C2.86541 1.28246 2.86369 0.957278 2.86584 0.6325C2.86884 0.212127 3.08219 0.00296181 3.52047 0.00214476C4.60524 -0.00071492 5.69 -0.00071492 6.77477 0.00214476C7.21176 0.00337034 7.42298 0.213353 7.42555 0.636177C7.42769 0.960955 7.42555 1.28614 7.42555 1.63502C7.5258 1.63502 7.60206 1.63502 7.67832 1.63502C8.33466 1.63502 8.99144 1.63053 9.64778 1.63666C10.1092 1.64074 10.3894 1.98472 10.251 2.36383C10.173 2.5779 10.0072 2.69188 9.77759 2.72579C9.72147 2.73396 9.66363 2.73028 9.60665 2.73028C6.63082 2.73028 3.65499 2.72946 0.679163 2.73232C0.39726 2.73232 0.169768 2.65552 0.0510942 2.39856C-0.124131 2.01904 0.170196 1.64074 0.646174 1.63666C1.29566 1.63094 1.94515 1.63502 2.59422 1.63502C2.67219 1.63502 2.75016 1.63502 2.86455 1.63502H2.86541ZM4.0123 1.1068V1.61868H6.27566V1.1068H4.0123Z" />
                    <path d="M3.43192 6.79466C3.43192 6.18881 3.43063 5.58297 3.43235 4.97712C3.43321 4.60986 3.65941 4.3676 3.99787 4.36434C4.33204 4.36107 4.57752 4.60496 4.57881 4.96691C4.58309 6.19903 4.58309 7.43114 4.57881 8.66325C4.57752 9.0248 4.33204 9.2691 3.99787 9.26583C3.65984 9.26256 3.43321 9.01949 3.43235 8.65263C3.43063 8.03331 3.43192 7.41357 3.43192 6.79425V6.79466Z" />
                    <path d="M6.8602 6.81509C6.8602 7.44136 6.8602 8.06763 6.8602 8.6939C6.8602 8.93411 6.75181 9.11713 6.52218 9.21722C6.31782 9.30628 6.09375 9.29933 5.94466 9.14246C5.82985 9.02194 5.72445 8.83974 5.72231 8.68328C5.70432 7.43768 5.7086 6.19209 5.7146 4.94608C5.71631 4.59802 5.96694 4.36066 6.29426 4.36393C6.62628 4.3672 6.85806 4.60578 6.85978 4.9563C6.86277 5.57562 6.86063 6.19535 6.86063 6.81468L6.8602 6.81509Z" />
                  </svg>
                </StylishNewButton>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
