import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import {
  addMedicalAidStationToMedicalPlan,
  deleteMedicalAidStation,
  fetchMedicalAidStationList,
} from 'actions/IAPForm206Actions';
import { AddMedicalAidStationDialog } from './AddMedicalAidStationDialog';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { startLoading } from 'reducers/loading/loading.action';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const Label = ({ label, value, onEdit, onDelete }) => {
  return (
    <>
      {label}
      <div className="react-select__custom-label">
        <span
          className="ms-3 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        >
          <SharedIcon iconName="stylus" />
        </span>
        <span
          className="ms-3 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <SharedIcon iconName="delete" />
        </span>
      </div>
    </>
  );
};

export const AddMedicalAidStationToMedicalPlanDialog = ({
  show,
  onClose,
  medicalPlanId,
  existingMedicalAidStations,
  editData,
}) => {
  const reduxDispatch = useDispatch();

  const [medicalAidStations, setMedicalAidStations] = useState([]);
  const [selectedMedicalAidStation, setSelectedMedicalAidStation] = useState(
    []
  );
  const [
    openAddNewMedicalAidStationModal,
    setOpenAddNewMedicalAidStationModal,
  ] = useState(false);

  const [
    medicalAidStationDialogType,
    setMedicalAidStationDialogType,
  ] = useState(null);
  const [editMedicalAidStationData, setEditMedicalAidStationData] = useState(
    null
  );

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [
    deleteMedicalAidStationData,
    setDeleteMedicalAidStationData,
  ] = useState(null);

  const reduxMedicalAidStationsList = useSelector(
    (state) => state.app.medicalAidStationsList
  );

  useEffect(() => {
    reduxDispatch(fetchMedicalAidStationList());
  }, []);

  useEffect(() => {
    if (reduxMedicalAidStationsList?.length) {
      setMedicalAidStations(
        reduxMedicalAidStationsList
          .map((mas) => ({
            value: mas.id,
            label: (
              <Label
                label={mas.name}
                value={mas.id}
                onEdit={() => onEditMedicalAidStation(mas)}
                onDelete={() => onDeleteMedicalAidStation(mas.id)}
              />
            ),
          }))
          .filter(
            (mas) =>
              !existingMedicalAidStations.includes(mas.value) ||
              mas.value === editData?.id
          )
      );
    } else {
      setMedicalAidStations([]);
    }
  }, [reduxMedicalAidStationsList]);

  const addMedicalAidStationDataToMedicalPlan = () => {
    reduxDispatch(
      addMedicalAidStationToMedicalPlan({
        id: editData?.mpmaId || generateUUID(),
        medical_aid_id: selectedMedicalAidStation.value,
        medical_plan_id: medicalPlanId,
      })
    );
    onClose();
  };

  const refreshMedicalAidStationList = () => {
    setOpenAddNewMedicalAidStationModal(false);
  };

  const onEditMedicalAidStation = (mas) => {
    setMedicalAidStationDialogType('Edit');
    setEditMedicalAidStationData(mas);
    setOpenAddNewMedicalAidStationModal(true);
  };

  const onDeleteMedicalAidStation = (masId) => {
    setDeleteMedicalAidStationData(masId);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteMedicalAidStation(deleteMedicalAidStationData));
    selectedMedicalAidStation?.value === deleteMedicalAidStationData &&
      setSelectedMedicalAidStation([]);
    setDeleteMedicalAidStationData(null);
    setShowConfirmDialog(false);
  };

  useEffect(() => {
    setSelectedMedicalAidStation(
      medicalAidStations.find((mas) => mas.value === editData?.id)
    );
  }, [medicalAidStations]);

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Select Medical Aid Station</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StylishNewSelect
            options={medicalAidStations}
            value={selectedMedicalAidStation}
            placeholder="Select a Medical Aid Station"
            onChange={(e) => setSelectedMedicalAidStation(e)}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--primary"
              onClick={addMedicalAidStationDataToMedicalPlan}
              disabled={!selectedMedicalAidStation?.value}
            >
              Save
            </StylishNewButton>
            <StylishNewButton
              className="button--primary"
              onClick={() => {
                setEditMedicalAidStationData(null);
                setMedicalAidStationDialogType('New');
                setOpenAddNewMedicalAidStationModal(true);
              }}
            >
              New
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>

      {openAddNewMedicalAidStationModal && (
        <AddMedicalAidStationDialog
          show={openAddNewMedicalAidStationModal}
          onClose={() => setOpenAddNewMedicalAidStationModal(false)}
          refreshMedicalAidStationList={refreshMedicalAidStationList}
          medicalAidStationDialogType={medicalAidStationDialogType}
          editMedicalAidStationData={
            editMedicalAidStationData ? editMedicalAidStationData : {}
          }
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this record?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteActionData()}
        />
      )}
    </>
  );
};
