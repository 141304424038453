import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { startLoading } from 'reducers/loading/loading.action';
import EditOperationalPeriodDialog from 'components/IncidentConfig/EditOperationalPeriodDialog';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import moment from 'moment';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewRadio } from 'components/DesignSystems/New/StylishNewRadio';
import {
  deleteOrder,
  updateResourceRequest,
} from 'actions/IAP213RRActions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { IAPForm213RRAddEditOrderDialog } from './IAPForm213RRAddEditOrderDialog';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

export const IAPForm213RR = () => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const reduxDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [incidentName, setIncidentName] = useState('');
  const [
    isEditOperationalPeriodOpen,
    setIsEditOperationalPeriodOpen,
  ] = useState(false);

  const [showAddEditOrderDialog, setShowAddEditOrderDialog] = useState(false);
  const [orderDialogType, setOrderDialogType] = useState(null);
  const [orderDialogExistingData, setOrderDialogExistingData] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });
  const reduxResourceRequest = useSelector(
    (state) => state.app.resource_request
  );

  const reduxAllResourceRequests = useSelector(
    (state) => state.app.all_resource_request
  );

  const reduxUser = useSelector((state) => {
    return state.app.user;
  });

  useEffect(() => {
    if (reduxCurrentIncident?.id) {
      setIncidentName(reduxCurrentIncident?.name);
    }
  }, [reduxCurrentIncident]);

  useEffect(() => {
    if (!reduxResourceRequest?.id) {
      navigate('/incident_management');
    }
  }, [reduxResourceRequest]);

  useEffect(() => {
    // POC Phone No
    setValue(
      'poc_phone_number',
      reduxResourceRequest?.poc_phone_number?.trim() || ''
    );

    // Requestor
    setValue(
      'requester',
      reduxResourceRequest?.requester?.profile_settings?.userName ||
        reduxUser?.user_name
    );

    setValue(
      'requester_date',
      (moment(reduxResourceRequest?.requester_date).isValid() &&
        moment(reduxResourceRequest?.requester_date)) ||
        moment()
    );

    // Approval
    setValue(
      'approval_user',
      reduxResourceRequest?.approval_user?.profile_settings?.userName ||
        reduxUser?.user_name
    );

    setValue(
      'approval_user_date',
      (moment(reduxResourceRequest?.approval_user_date).isValid() &&
        moment(reduxResourceRequest?.approval_user_date)) ||
        moment()
    );

    // Plans
    if (reduxResourceRequest?.resl === true) {
      setValue('resl', 'yes');
    } else if (reduxResourceRequest?.resl === false) {
      setValue('resl', 'no');
    } else {
      setValue('resl', 'yes');
    }

    if (reduxResourceRequest?.availability === true) {
      setValue('availability', 'available');
    } else if (reduxResourceRequest?.resl === false) {
      setValue('availability', 'not_available');
    } else {
      setValue('availability', 'available');
    }

    setValue(
      'resl_review_user',
      reduxResourceRequest?.resl_review_user?.profile_settings?.userName ||
        reduxUser?.user_name
    );

    setValue(
      'resl_review_user_date',
      (moment(reduxResourceRequest?.resl_review_user_date).isValid() &&
        moment(reduxResourceRequest?.resl_review_user_date)) ||
        moment()
    );

    // Logistics
    setValue(
      'requisition_purchase_order',
      reduxResourceRequest?.requisition_purchase_order || generateUUID()
    );

    setValue('supplier', reduxResourceRequest?.supplier || '');

    setValue('notes', reduxResourceRequest?.notes || '');

    setValue(
      'logistics_user',
      reduxResourceRequest?.logistics_user?.profile_settings?.userName ||
        reduxUser?.user_name
    );

    setValue(
      'logistics_user_date',
      (moment(reduxResourceRequest?.logistics_user_date).isValid() &&
        moment(reduxResourceRequest?.logistics_user_date)) ||
        moment()
    );

    // Order Placed By
    setValue(
      'order_placed_by_type',
      reduxResourceRequest?.order_placed_by_type?.trim() || 'spul'
    );

    setValue(
      'order_placed_by',
      reduxResourceRequest?.order_placed_by?.trim() || ''
    );

    // Finance
    setValue(
      'finance_section',
      reduxResourceRequest?.finance_section?.profile_settings?.userName ||
        reduxUser?.user_name
    );

    setValue(
      'finance_section_date',
      (moment(reduxResourceRequest?.finance_section_date).isValid() &&
        moment(reduxResourceRequest?.finance_section_date)) ||
        moment()
    );

    setValue(
      'finance_comments',
      reduxResourceRequest?.finance_comments?.trim() || ''
    );
  }, [reduxResourceRequest]);

  const orderColumns = [
    {
      dataField: 'quantity',
      text: 'Qty',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Qty' },
    },
    {
      dataField: 'kind',
      text: 'Kind',
      sort: false,
      attrs: { title: 'Kind' },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: false,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'cost',
      text: 'Cost',
      sort: true,
      attrs: { title: 'Cost' },
    },
    {
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Order"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => onDeleteOrder(row?.id)}
          onEdit={() => onEditOrder(row)}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">Priority U or R</h6>
              {row?.priority?.trim()}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">Item Description</h6>
              {row?.item_description?.trim()}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">Requested Reporting Location</h6>
              {row?.requested_reporting_location?.trim()}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">Requested Reporting Date/Time</h6>
              {row?.requested_reporting_time &&
                moment(row.requested_reporting_time).format('MM/DD/YYYY HH:mm')}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">Order # (LSC)</h6>
              {row?.order_no?.trim()}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">ETA (LSC)</h6>
              {row?.eta && moment(row.eta).format('MM/DD/YYYY HH:mm')}
            </div>
          </div>
          <div className="button-group mt-2">
            <StylishNewButton
              type="button"
              className="button--primary"
              onClick={() => onEditOrder(row)}
            >
              Edit Order
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button--tertiary button--reverse"
              onClick={() => onDeleteOrder(row?.id)}
            >
              Delete
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const onEditOrder = (row) => {
    setOrderDialogExistingData(row);
    setOrderDialogType('Edit');
    setShowAddEditOrderDialog(true);
  };

  const onDeleteOrder = (id) => {
    setDeleteConfirmationData(id);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    const deleteOrderData = {
      deleteOrderId: deleteConfirmationData,
      resourceRequestId: reduxResourceRequest?.id,
    };
    reduxDispatch(startLoading());
    reduxDispatch(deleteOrder(deleteOrderData));
    setShowConfirmDialog(false);
    setDeleteConfirmationData(null);
  };

  const addOrUpdatePOCPhoneNumber = () => {
    const submitData = {
      ...reduxResourceRequest,
      poc_phone_number: getValues('poc_phone_number'),
      approval_user: reduxResourceRequest?.approval_user
        ? reduxResourceRequest.approval_user?.user_guid
        : null,
      logistics_user: reduxResourceRequest?.logistics_user
        ? reduxResourceRequest.logistics_user?.user_guid
        : null,
      resl_review_user: reduxResourceRequest?.resl_review_user
        ? reduxResourceRequest.resl_review_user?.user_guid
        : null,
      requester: reduxResourceRequest?.requester
        ? reduxResourceRequest.requester?.user_guid
        : null,
      finance_section: reduxResourceRequest?.finance_section
        ? reduxResourceRequest.finance_section?.user_guid
        : null,
    };
    reduxDispatch(startLoading());
    reduxDispatch(updateResourceRequest(submitData));
  };

  const onEditOrUpdateRequestor = () => {
    const submitData = {
      ...reduxResourceRequest,
      requester: reduxUser?.user_guid,
      requester_date: moment(),
      approval_user: reduxResourceRequest?.approval_user
        ? reduxResourceRequest.approval_user?.user_guid
        : null,
      finance_section: reduxResourceRequest?.finance_section
        ? reduxResourceRequest.finance_section?.user_guid
        : null,
      resl_review_user: reduxResourceRequest?.resl_review_user
        ? reduxResourceRequest.resl_review_user?.user_guid
        : null,
      logistics_user: reduxResourceRequest?.logistics_user
        ? reduxResourceRequest.logistics_user?.user_guid
        : null,
    };
    reduxDispatch(startLoading());
    reduxDispatch(updateResourceRequest(submitData));
  };

  const onEditOrUpdateApproval = () => {
    const submitData = {
      ...reduxResourceRequest,
      approval_user: reduxUser?.user_guid,
      approval_user_date: moment(),
      requester: reduxResourceRequest?.requester
        ? reduxResourceRequest.requester?.user_guid
        : null,
      finance_section: reduxResourceRequest?.finance_section
        ? reduxResourceRequest.finance_section?.user_guid
        : null,
      resl_review_user: reduxResourceRequest?.resl_review_user
        ? reduxResourceRequest.resl_review_user?.user_guid
        : null,
      logistics_user: reduxResourceRequest?.logistics_user
        ? reduxResourceRequest.logistics_user?.user_guid
        : null,
    };
    reduxDispatch(startLoading());
    reduxDispatch(updateResourceRequest(submitData));
  };

  const addOrUpdatePlans = () => {
    const submitData = {
      ...reduxResourceRequest,
      resl: getValues('resl') === 'yes' ? true : false,
      availability: getValues('availability') === 'available' ? true : false,
      resl_review_user: reduxUser?.user_guid,
      resl_review_user_date: moment(),
      requester: reduxResourceRequest?.requester
        ? reduxResourceRequest.requester?.user_guid
        : null,
      finance_section: reduxResourceRequest?.finance_section
        ? reduxResourceRequest.finance_section?.user_guid
        : null,
      approval_user: reduxResourceRequest?.approval_user
        ? reduxResourceRequest.approval_user?.user_guid
        : null,
      logistics_user: reduxResourceRequest?.logistics_user
        ? reduxResourceRequest.logistics_user?.user_guid
        : null,
    };
    reduxDispatch(startLoading());
    reduxDispatch(updateResourceRequest(submitData));
  };

  const addOrUpdateLogistics = () => {
    const submitData = {
      ...reduxResourceRequest,
      requisition_purchase_order: getValues('requisition_purchase_order'),
      supplier: getValues('supplier'),
      notes: getValues('notes'),
      logistics_user: reduxUser?.user_guid,
      logistics_user_date: moment(),
      approval_user: reduxResourceRequest?.approval_user
        ? reduxResourceRequest.approval_user?.user_guid
        : null,
      finance_section: reduxResourceRequest?.finance_section
        ? reduxResourceRequest.finance_section?.user_guid
        : null,
      resl_review_user: reduxResourceRequest?.resl_review_user
        ? reduxResourceRequest.resl_review_user?.user_guid
        : null,
      requester: reduxResourceRequest?.requester
        ? reduxResourceRequest.requester?.user_guid
        : null,
    };
    reduxDispatch(startLoading());
    reduxDispatch(updateResourceRequest(submitData));
  };

  const addOrUpdateOrderPlacedBy = () => {
    if (
      getValues('order_placed_by_type') === 'other' &&
      !getValues('order_placed_by')
    ) {
      setError('order_placed_by', { type: 'custom' });
      return;
    } else {
      clearErrors('order_placed_by');
      const submitData = {
        ...reduxResourceRequest,
        order_placed_by_type: getValues('order_placed_by_type'),
        order_placed_by:
          getValues('order_placed_by_type') === 'other'
            ? getValues('order_placed_by')
            : '',
        approval_user: reduxResourceRequest?.approval_user
          ? reduxResourceRequest.approval_user?.user_guid
          : null,
        logistics_user: reduxResourceRequest?.logistics_user
          ? reduxResourceRequest.logistics_user?.user_guid
          : null,
        resl_review_user: reduxResourceRequest?.resl_review_user
          ? reduxResourceRequest.resl_review_user?.user_guid
          : null,
        requester: reduxResourceRequest?.requester
          ? reduxResourceRequest.requester?.user_guid
          : null,
        finance_section: reduxResourceRequest?.finance_section
          ? reduxResourceRequest.finance_section?.user_guid
          : null,
      };
      reduxDispatch(startLoading());
      reduxDispatch(updateResourceRequest(submitData));
    }
  };

  const addOrUpdateFinance = () => {
    const submitData = {
      ...reduxResourceRequest,
      finance_comments: getValues('finance_comments') || '',
      finance_section: reduxUser?.user_guid,
      finance_section_date: moment(),
      approval_user: reduxResourceRequest?.approval_user
        ? reduxResourceRequest.approval_user?.user_guid
        : null,
      logistics_user: reduxResourceRequest?.logistics_user
        ? reduxResourceRequest.logistics_user?.user_guid
        : null,
      resl_review_user: reduxResourceRequest?.resl_review_user
        ? reduxResourceRequest.resl_review_user?.user_guid
        : null,
      requester: reduxResourceRequest?.requester
        ? reduxResourceRequest.requester?.user_guid
        : null,
    };
    reduxDispatch(startLoading());
    reduxDispatch(updateResourceRequest(submitData));
  };

  return (
    <>
      <h3 className="mb-4">Requestor</h3>
      <div className="mb-4">
        <h4>1. Incident Name</h4>
        <label className="form-label">Name</label>
        <p className="mb-3">{incidentName}</p>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>2. Operational Period</h4>
        {!!reduxCurrentIncident && reduxCurrentIncident?.current_period ? (
          <>
            <div className="form-group">
              <label className="form-label">From:</label>
            </div>
            <div className="mb-3">
              {moment(
                reduxCurrentIncident?.operational_period_start_date_time
              ).format('MM/DD/YYYY HH:mm')}
            </div>
            <div className="form-group">
              <label className="form-label">To:</label>
            </div>
            <div className="mb-3">
              {moment(reduxCurrentIncident?.operational_period_start_date_time)
                .add(reduxCurrentIncident?.operational_period_interval, 'hours')
                .format('MM/DD/YYYY HH:mm')}
            </div>
          </>
        ) : (
          <p className="weight-500 m-0">No Operational Period Selected.</p>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>3. Order</h4>
        <StylishNewButton
          type="button"
          className="button--primary mb-3"
          onClick={() => {
            setOrderDialogExistingData(null);
            setOrderDialogType('New');
            setShowAddEditOrderDialog(true);
          }}
        >
          Add
        </StylishNewButton>
        <StylishNewTable
          keyField={'id'}
          columns={orderColumns}
          rows={reduxResourceRequest ? reduxResourceRequest?.orders : []}
          expandRow={expandRow}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>4. POC Phone Number if Known and Suitable Substitutes</h4>
        <Controller
          control={control}
          name="poc_phone_number"
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <StylishNewInput
              type="text"
              onChange={onChange}
              value={value || ''}
            />
          )}
        />
        <StylishNewButton
          onClick={addOrUpdatePOCPhoneNumber}
          type="button"
          className="button--primary mt-3"
        >
          Save
        </StylishNewButton>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4 className="mb-1">5. Requestor Position and Signature</h4>
        <p className="fst-italic weight-500 mb-3">
          Seats can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
        <div className="mb-4">
          <label className="form-label">Name</label>
          <Controller
            control={control}
            name="requester"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type="text"
                onChange={onChange}
                value={value || ''}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Date/Time</label>
          <Controller
            control={control}
            name="requester_date"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishDateTimePicker
                type="datetime-local"
                onChange={onChange}
                value={value}
                disableDateTimeLabel={true}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          <p className="fst-italic weight-500 m-0">
            Signatures are used to electronically sign Forms.
          </p>
        </div>
        <StylishNewButton
          onClick={onEditOrUpdateRequestor}
          type="button"
          className="button--primary"
        >
          Save
        </StylishNewButton>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4 className="mb-1">6. Section Chief/Command Staff Approval</h4>
        <p className="fst-italic weight-500 mb-3">
          Seats can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
        <div className="mb-4">
          <label className="form-label">Name</label>
          <Controller
            control={control}
            name="approval_user"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type="text"
                onChange={onChange}
                value={value || ''}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Date/Time</label>
          <Controller
            control={control}
            name="approval_user_date"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishDateTimePicker
                type="datetime-local"
                onChange={onChange}
                value={value}
                disableDateTimeLabel={true}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          <p className="fst-italic weight-500 m-0">
            Signatures are used to electronically sign Forms.
          </p>
        </div>
        <StylishNewButton
          onClick={onEditOrUpdateApproval}
          type="button"
          className="button--primary"
        >
          Save
        </StylishNewButton>
      </div>
      <hr />
      <h3 className="mb-4">Plans</h3>
      <div className="mb-4">
        <h4>7. RESL </h4>
        <div className="form-radio-group">
          <Controller
            control={control}
            name="resl"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewRadio
                label={'Yes'}
                value={'yes'}
                checked={watch('resl') === 'yes' ? true : false}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="resl"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewRadio
                label={'No'}
                value={'no'}
                checked={watch('resl') === 'no' ? true : false}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>
      <div className="mb-4">
        <h4>Availability</h4>
        <div className="form-radio-group">
          <Controller
            control={control}
            name="availability"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewRadio
                label={'Resources Available as Noted in Block 12'}
                value={'available'}
                checked={watch('availability') === 'available' ? true : false}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="availability"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewRadio
                label={'Resources Not Available'}
                value={'not_available'}
                checked={
                  watch('availability') === 'not_available' ? true : false
                }
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4 className="mb-1">8. RESL Review/Signature</h4>
        <p className="fst-italic weight-500 mb-3">
          Seats can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
        <div className="mb-4">
          <label className="form-label">Name</label>
          <Controller
            control={control}
            name="resl_review_user"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type="text"
                onChange={onChange}
                value={value || ''}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Date/Time</label>
          <Controller
            control={control}
            name="resl_review_user_date"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishDateTimePicker
                type="datetime-local"
                onChange={onChange}
                value={value}
                disableDateTimeLabel={true}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          <p className="fst-italic weight-500 m-0">
            Signatures are used to electronically sign Forms.
          </p>
        </div>
        <StylishNewButton
          onClick={addOrUpdatePlans}
          type="button"
          className="button--primary"
        >
          Save
        </StylishNewButton>
      </div>
      <hr />
      <h3 className="mb-4">Logistics</h3>
      <div className="mb-4">
        <h4>9. Requisition/Purchase Order #</h4>
        <Controller
          control={control}
          name="requisition_purchase_order"
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <StylishNewInput
              type="text"
              onChange={onChange}
              value={value || ''}
              readOnly
              disabled
            />
          )}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>10. Supplier Name/Phone/Fax/Email</h4>
        <Controller
          control={control}
          name="supplier"
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <StylishNewInput
              type="text"
              onChange={onChange}
              value={value || ''}
            />
          )}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>11. Notes</h4>
        <Controller
          control={control}
          name="notes"
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <StylishNewInput
              type="text"
              onChange={onChange}
              value={value || ''}
            />
          )}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4 className="mb-1">12. Logistics Section Signature </h4>
        <p className="fst-italic weight-500 mb-3">
          Seats can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
        <div className="mb-4">
          <label className="form-label">Name</label>
          <Controller
            control={control}
            name="logistics_user"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type="text"
                onChange={onChange}
                value={value || ''}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Date/Time</label>
          <Controller
            control={control}
            name="logistics_user_date"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishDateTimePicker
                type="datetime-local"
                onChange={onChange}
                value={value}
                disableDateTimeLabel={true}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          <p className="fst-italic weight-500 m-0">
            Signatures are used to electronically sign Forms.
          </p>
        </div>
        <StylishNewButton
          onClick={addOrUpdateLogistics}
          type="button"
          className="button--primary"
        >
          Save
        </StylishNewButton>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>13. Order Placed By</h4>
        <div className="form-radio-group">
          <Controller
            control={control}
            name="order_placed_by_type"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewRadio
                label={'SPUL'}
                value="spul"
                checked={
                  watch('order_placed_by_type') === 'spul' ? true : false
                }
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="order_placed_by_type"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewRadio
                label={'PROC'}
                value="proc"
                checked={
                  watch('order_placed_by_type') === 'proc' ? true : false
                }
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="order_placed_by_type"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewRadio
                label={'Other'}
                value="other"
                checked={
                  watch('order_placed_by_type') === 'other' ? true : false
                }
                onChange={onChange}
              />
            )}
          />
        </div>
        {watch('order_placed_by_type') === 'other' && (
          <>
            <Controller
              control={control}
              name="order_placed_by"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={onChange}
                  value={value || ''}
                  className={'mt-3'}
                />
              )}
            />
            {errors.order_placed_by && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </>
        )}

        <StylishNewButton
          type="button"
          className="button--primary mt-4"
          onClick={addOrUpdateOrderPlacedBy}
        >
          Save
        </StylishNewButton>
      </div>
      <hr />
      <h3 className="mb-4">Finance</h3>
      <div className="mb-4">
        <h4>14. Reply/Comments From Finance</h4>
        <Controller
          control={control}
          name="finance_comments"
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <StylishNewInput
              type="text"
              onChange={onChange}
              value={value || ''}
            />
          )}
        />
      </div>
      <hr className="dashed my-4" />
      <div className="mb-0">
        <h4 className="mb-1">15. Finance Section Signature</h4>
        <p className="fst-italic weight-500 mb-3">
          Seats can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
        <div className="mb-4">
          <label className="form-label">Name</label>
          <Controller
            control={control}
            name="finance_section"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type="text"
                onChange={onChange}
                value={value || ''}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Date/Time</label>
          <Controller
            control={control}
            name="finance_section_date"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishDateTimePicker
                type="datetime-local"
                onChange={onChange}
                value={value}
                disableDateTimeLabel={true}
                readOnly={true}
                disabled={true}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">
            Signature: <span className="color-danger">*</span>
          </label>
          <p className="fst-italic weight-500 m-0">
            Signatures are used to electronically sign Forms.
          </p>
        </div>
        <StylishNewButton
          type="button"
          className="button--primary"
          onClick={addOrUpdateFinance}
        >
          Save
        </StylishNewButton>
      </div>
      {isEditOperationalPeriodOpen && (
        <EditOperationalPeriodDialog
          show={isEditOperationalPeriodOpen}
          onClose={() => setIsEditOperationalPeriodOpen(false)}
        />
      )}

      {showAddEditOrderDialog && (
        <IAPForm213RRAddEditOrderDialog
          show={showAddEditOrderDialog}
          onClose={() => setShowAddEditOrderDialog(false)}
          resourceRequestId={reduxResourceRequest?.id}
          reduxDispatch={reduxDispatch}
          orderDialogType={orderDialogType}
          orderDialogExistingData={orderDialogExistingData}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this order?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </>
  );
};
