import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import {
  addTransportationToMedicalPlan,
  deleteTransportation,
  fetchTransportationList,
} from 'actions/IAPForm206Actions';
import { AddTransportationDialog } from './AddTransportationDialog';
import { startLoading } from 'reducers/loading/loading.action';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const Label = ({ label, value, onEdit, onDelete }) => {
  return (
    <>
      {label}
      <div className="react-select__custom-label">
        <span
          className="ms-3 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        >
          <SharedIcon iconName="stylus" />
        </span>
        <span
          className="ms-3 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <SharedIcon iconName="delete" />
        </span>
      </div>
    </>
  );
};

export const AddTransportationToMedicalPlanDialog = ({
  show,
  onClose,
  medicalPlanId,
  existingTransportations,
  editData,
}) => {
  const reduxDispatch = useDispatch();

  const [transportations, setTransportations] = useState([]);
  const [selectedTransportation, setSelectedTransportation] = useState([]);
  const [
    openAddNewTransportationModal,
    setOpenAddNewTransportationModal,
  ] = useState(false);

  const [transportDialogType, setTransportDialogType] = useState(null);
  const [editTransportData, setEditTransportData] = useState(null);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteTransportData, setDeleteTransportData] = useState(null);

  const reduxTransportationsList = useSelector(
    (state) => state.app.transportationsList
  );

  useEffect(() => {
    reduxDispatch(fetchTransportationList());
  }, []);

  useEffect(() => {
    setTransportations(
      reduxTransportationsList
        .map((trans) => ({
          value: trans.id,
          label: (
            <Label
              label={trans.ambulance_service}
              value={trans.id}
              onEdit={() => onEditTransport(trans)}
              onDelete={() => onDeleteTransport(trans.id)}
            />
          ),
        }))
        .filter(
          (trans) =>
            !existingTransportations.includes(trans.value) ||
            trans.value === editData?.id
        )
    );
  }, [reduxTransportationsList]);

  const addTransportationDataToMedicalPlan = () => {
    reduxDispatch(
      addTransportationToMedicalPlan({
        id: editData?.mptId || generateUUID(),
        transportation_id: selectedTransportation.value,
        medical_plan_id: medicalPlanId,
      })
    );
    onClose();
  };

  const refreshTransportationList = () => {
    setOpenAddNewTransportationModal(false);
  };

  const onEditTransport = (transport) => {
    setTransportDialogType('Edit');
    setEditTransportData(transport);
    setOpenAddNewTransportationModal(true);
  };

  const onDeleteTransport = (transportId) => {
    setDeleteTransportData(transportId);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteTransportation(deleteTransportData));
    selectedTransportation?.value === deleteTransportData &&
      setSelectedTransportation([]);
    setDeleteTransportData(null);
    setShowConfirmDialog(false);
  };

  useEffect(() => {
    setSelectedTransportation(
      transportations.find((trans) => trans.value === editData?.id)
    );
  }, [transportations]);

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Select Transportation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StylishNewSelect
            options={transportations}
            value={selectedTransportation}
            placeholder="Select a Transportation"
            onChange={(e) => setSelectedTransportation(e)}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--primary"
              onClick={addTransportationDataToMedicalPlan}
              disabled={!selectedTransportation?.value}
            >
              Save
            </StylishNewButton>
            <StylishNewButton
              className="button--primary"
              onClick={() => {
                setEditTransportData(null);
                setTransportDialogType('New');
                setOpenAddNewTransportationModal(true);
              }}
            >
              New
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>

      {openAddNewTransportationModal && (
        <AddTransportationDialog
          show={openAddNewTransportationModal}
          onClose={() => setOpenAddNewTransportationModal(false)}
          refreshTransportationList={refreshTransportationList}
          transportDialogType={transportDialogType}
          editTransportData={editTransportData ? editTransportData : {}}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this record?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteActionData()}
        />
      )}
    </>
  );
};
