import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useForm, Controller } from 'react-hook-form';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useState } from 'react';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { useBulkEditUsers } from './subscriptionHooks';
import { statusDisplayOptions } from 'utils/subscriptionStatus';

export const BulkUpgradeSubscriptionDialog = ({ show, onClose, users }) => {
  const { mutateAsync, isPending } = useBulkEditUsers();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const statusColors = {
    Trial: '#4A98CE !important',
    Paid: '#37CC70 !important',
    'Paid (Incident only)': '#37CC70 !important',
    'Paid (Exercise only)': '#37CC70 !important',
    Expired: '#FF2323 !important',
  };

  const [currentStatus, setCurrentStatus] = useState();

  const upgradeSubscriptionHandler = async (data) => {
    const userData = {
      users: users,
      subscription_end_on: data?.subscription_end_on,
      is_enterprise_client: data?.is_enterprise_client || false,
      current_status: data?.current_status?.value,
      subscribed_mass_notification: data?.subscribed_mass_notification,
      subscribed_international_messaging:
        data?.subscribed_international_messaging,
    };
    await mutateAsync({ userData });
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size=""
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Bulk Upgrade Subscription</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(upgradeSubscriptionHandler)}>
          <Modal.Body>
            <div className="mb-4">
              <label className="form-label">
                Status<span className="color-danger">*</span>
              </label>
              <Controller
                control={control}
                name="current_status"
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <StylishNewSelect
                    options={statusDisplayOptions}
                    value={
                      currentStatus
                        ? {
                            label: currentStatus?.label,
                            value: currentStatus?.value,
                          }
                        : null
                    }
                    placeholder="Select a Status"
                    onChange={(e) => {
                      onChange(e);
                      setCurrentStatus(e);
                    }}
                    isDisabled={false}
                    isClearable={false}
                    isSearchable={false}
                    isMulti={false}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: statusColors[currentStatus?.value] || null,
                        background: statusColors[currentStatus?.value] || null,
                      }),
                      indicatorSeparator: (baseStyles) => ({
                        ...baseStyles,
                        background: currentStatus ? `#fff !important` : null,
                      }),
                    }}
                  />
                )}
              />
              {errors?.current_status?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="mb-4">
              <label className="form-label">Subscription End Date</label>
              <Controller
                control={control}
                name="subscription_end_on"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    value={value}
                    onChange={(e) => onChange(e)}
                    type={'date'}
                    minDate={new Date()}
                    disableDateTimeLabel={true}
                  />
                )}
              />
            </div>
            <div className="d-flex mb-3 align-item-center">
              <label className="form-label m-0 me-3">
                Is Enterprise Client
              </label>
              <Controller
                control={control}
                name="is_enterprise_client"
                defaultValue={false}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishSwitcher
                    value={value}
                    checked={value || false}
                    onChange={onChange}
                  />
                )}
              />
            </div>

            <div className="d-flex mb-3 align-item-center">
              <label className="form-label m-0 me-3">Mass Notifications</label>
              <Controller
                control={control}
                name="subscribed_mass_notification"
                defaultValue={false}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishSwitcher
                    value={value}
                    checked={value || false}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="d-flex align-item-center">
              <label className="form-label m-0 me-3">
                International Messaging
              </label>
              <Controller
                control={control}
                name="subscribed_international_messaging"
                defaultValue={false}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishSwitcher
                    value={value}
                    checked={value || false}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                disabled={isPending}
                type="submit"
                className="button--primary"
              >
                Upgrade
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
