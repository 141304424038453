import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateIncident } from 'actions/diceActions';
import { Modal } from 'react-bootstrap';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { startLoading } from 'reducers/loading/loading.action';

export default function EditIncidentDialog({ show, incident, onClose }) {
  const reduxDispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(true);
  const old_group_guid = incident.group_guid;

  const isUpdateIncidentLoaded = useSelector((state) => {
    return state.app.isUpdateIncidentLoaded;
  });

  const areIncidentsLoaded = useSelector((state) => {
    return state.app.areIncidentsLoaded;
  });

  let initOperationalPeriodStartDateTime;
  let initOperationalPeriodInterval;

  if (!!incident.settings && !!incident.settings.operationalPeriod) {
    initOperationalPeriodStartDateTime =
      incident.settings.operationalPeriod.operationalPeriodStartDateTime;
    initOperationalPeriodInterval =
      incident.settings.operationalPeriod.operationalPeriodInterval;
  }

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const [name, setName] = useState(incident.name);
  const [nameError, setNameError] = useState(false);
  const [
    operationalPeriodStartDateTime,
    setOperationalPeriodStartDateTime,
  ] = useState(initOperationalPeriodStartDateTime);
  const [operationalPeriodInterval, setOperationalPeriodInterval] = useState(
    initOperationalPeriodInterval
  );
  const selectedGroupGuid = incident.group_guid;

  useEffect(() => {
    if (!isLoaded && isUpdateIncidentLoaded && areIncidentsLoaded) {
      onClose();
    }
  }, [isUpdateIncidentLoaded, areIncidentsLoaded, isLoaded, onClose]);

  useEffect(() => {
    if (name === '') {
      setNameError(true);
    } else {
      setNameError(false);
    }
  }, [name]);

  const handleUpdateIncidentClick = () => {
    setIsLoaded(false);

    let changedName = incident.name;
    let isIncidentNameUpdated = false;
    if (
      reduxValidateRBACPermissionForActionResult &&
      reduxValidateRBACPermissionForActionResult['Create Group Incident']
    ) {
      changedName = name;
    }
    if (changedName !== incident.name && changedName !== '') {
      isIncidentNameUpdated = true;
    }

    const updatedIncident = incident;
    // updatedIncident.name = name;
    updatedIncident.name = changedName;
    updatedIncident.group_guid = selectedGroupGuid;
    updatedIncident.old_group_guid = old_group_guid;
    updatedIncident.settings = incident.settings || {};
    updatedIncident.type = incident.type;
    if (
      operationalPeriodStartDateTime &&
      operationalPeriodStartDateTime &&
      !isNaN(parseInt(operationalPeriodInterval)) &&
      !(!!incident.settings && !!incident.settings.operationalPeriod)
    ) {
      updatedIncident.settings.operationalPeriod = {
        operationalPeriodStartDateTime: operationalPeriodStartDateTime.toISOString(),
        operationalPeriodInterval: operationalPeriodInterval,
      };
    }
    reduxDispatch(startLoading());
    reduxDispatch(updateIncident(updatedIncident, false, isIncidentNameUpdated));
    onClose();
  };

  console.log(
    'reduxValidateRBACPermissionForActionResult: ',
    reduxValidateRBACPermissionForActionResult
  );

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size=""
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Edit Incident Name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label className="form-label">Group</label>
          <StylishNewInput
            readOnly
            disabled
            value={reduxCurrentlySelectedGroup.group_name}
          />
        </div>
        <div className="m-0">
          <label className="form-label">Incident Name</label>
          <StylishNewInput
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && (
            <span className="form-text form-error">This field is required</span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
          <StylishNewButton
            disabled={!!nameError}
            onClick={() => handleUpdateIncidentClick()}
            className="button--primary"
          >
            Save Incident
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
