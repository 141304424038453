import {
  samplePointLayer,
  sampleDrawLayer,
} from '../constants/sampleLayers';


// AORs of Incident Reports
const incident_reports_aors_polygons_v2_source = "incident_reports_aors_polygons_v2_source"
const incident_reports_aors_polygons_v2_layer = "incident_reports_aors_polygons_v2_layer"
const incident_reports_aors_polygons_v2_source_layer = "public.incident_reports_aors_polygons_v2"

const incident_reports_aors_polygons_v2_layer_config = {
  ...sampleDrawLayer,
  id: incident_reports_aors_polygons_v2_layer,
  source: incident_reports_aors_polygons_v2_source,
  'source-layer': incident_reports_aors_polygons_v2_source_layer,
  generateId: true,
  paint: {
    ...sampleDrawLayer.paint,
    'fill-opacity': 0.3,
  },
  metadata: {
    eventlayer: false,
    usermade: undefined,
    model_layer: false,
    model_details: undefined,
    incident_report: true
  },
};

const incident_reports_aors_points_v2_source = "incident_reports_aors_points_v2_source"
const incident_reports_aors_points_v2_layer = "incident_reports_aors_points_v2_layer"
const incident_reports_aors_points_v2_source_layer = "public.incident_reports_aors_points_v2"

const incident_reports_aors_points_v2_layer_config = {
  ...samplePointLayer,
  id: incident_reports_aors_points_v2_layer,
  source: incident_reports_aors_points_v2_source,
  'source-layer': incident_reports_aors_points_v2_source_layer,
  generateId: true,
  paint: {
    ...samplePointLayer.paint,
    'circle-opacity': 0.3,
  },
  metadata: {
    eventlayer: false,
    usermade: undefined,
    model_layer: false,
    model_details: undefined,
    incident_report: true
  },
};
// /AORs

export default function incidentReportEffect(
  map,
  mapboxgl,
  filteredIncidentReportData,
  apiPrefix,
  reduxCurrentlySelectedGroup,
  incidentReportsVisibleToggle,
  setLayerClicked,
  markers,
  setMarkers
) {
  const incident_reports_aors_polygons_v2_tiles = `${apiPrefix}/incident_reports_tiles/${incident_reports_aors_polygons_v2_source_layer}/{z}/{x}/{y}.pbf`;
  const incident_reports_aors_points_v2_tiles = `${apiPrefix}/incident_reports_tiles/${incident_reports_aors_points_v2_source_layer}/{z}/{x}/{y}.pbf`;
  
  // aorS
  if(map.current.getLayer(incident_reports_aors_polygons_v2_layer)) {
    map.current.removeLayer(incident_reports_aors_polygons_v2_layer)
  }
  if(map.current.getSource(incident_reports_aors_polygons_v2_source)) {
    map.current.removeSource(incident_reports_aors_polygons_v2_source)
  }
  if(map.current.getLayer(incident_reports_aors_points_v2_layer)) {
    map.current.removeLayer(incident_reports_aors_points_v2_layer)
  }
  if(map.current.getSource(incident_reports_aors_points_v2_source)) {
    map.current.removeSource(incident_reports_aors_points_v2_source)
  }
  if (markers.length) {
    markers.forEach(marker => {
      marker.remove();
    });
  }
  // /aorS

  // Incident reports
  if (incidentReportsVisibleToggle) {
    map.current.addSource(incident_reports_aors_polygons_v2_source, {
      'type': 'vector',
      'tiles': [
        incident_reports_aors_polygons_v2_tiles+"?user_group_guid="+reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(incident_reports_aors_polygons_v2_layer_config);

    map.current.on('click', incident_reports_aors_polygons_v2_layer_config.id, (e) =>
      setLayerClicked({
        layer: incident_reports_aors_polygons_v2_layer_config,
        e: e,
        features: e.features,
      })
    );
    map.current.on('touchend', incident_reports_aors_polygons_v2_layer_config.id, (e) =>
      setLayerClicked({
        layer: incident_reports_aors_polygons_v2_layer_config,
        e: e,
        features: e.features,
      })
    );
    map.current.on('mouseenter', incident_reports_aors_polygons_v2_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', incident_reports_aors_polygons_v2_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'grab';
    });


    map.current.addSource(incident_reports_aors_points_v2_source, {
      'type': 'vector',
      'tiles': [
        incident_reports_aors_points_v2_tiles+"?user_group_guid="+reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(incident_reports_aors_points_v2_layer_config);

    map.current.on('click', incident_reports_aors_points_v2_layer_config.id, (e) =>
      setLayerClicked({
        layer: incident_reports_aors_points_v2_layer_config,
        e: e,
        features: e.features,
      })
    );
    map.current.on('touchend', incident_reports_aors_points_v2_layer_config.id, (e) =>
      setLayerClicked({
        layer: incident_reports_aors_points_v2_layer_config,
        e: e,
        features: e.features,
      })
    );
    map.current.on('mouseenter', incident_reports_aors_points_v2_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', incident_reports_aors_points_v2_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'grab';
    });

    const filtertedReportIds = [];
    const _markes = [];
    filteredIncidentReportData.forEach(reporter => {
      if (reporter?.aor_id && !filtertedReportIds.includes(reporter?.aor_id)) {
        filtertedReportIds.push(reporter?.aor_id);
      }
      const geoLngLat = reporter?.aor_location?.centroid?.geometry?.coordinates;
      if (geoLngLat?.length > 0) {
        const marker = new mapboxgl.Marker()
                                  .setLngLat(geoLngLat)
                                  .addTo(map.current);
        _markes.push(marker);
      }
    });

    setMarkers(_markes);

    map.current.setFilter(incident_reports_aors_polygons_v2_layer_config.id, ['in', 'id', ...filtertedReportIds]);
    map.current.setFilter(incident_reports_aors_points_v2_layer_config.id, ['in', 'id', ...filtertedReportIds]);
  }
}