import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchAllGroupsAIUsage } from 'slices/dchatSlice';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import GroupAIUsage from 'components/GroupsRoute/GroupAIUsage';
import Info from 'components/DesignSystems/text/Info';

function calculateAggregateUsageCost(records, selectedYear, selectedMonth) {
  let totalCost = 0;
  const recordsForMonth = records.filter((r) => {
    const date = new Date(r.timestamp);
    return (
      date.getMonth() + 1 === selectedMonth &&
      date.getFullYear() === selectedYear
    );
  });

  for (const record of recordsForMonth) {
    totalCost +=
      (record.prompt_tokens * 2.5) / 1000000 +
      (record.completion_tokens * 10) / 1000000;
  }
  return totalCost.toFixed(2);
}

const getMonthName = (month) =>
  new Date(0, month - 1).toLocaleString('en-US', { month: 'long' });

export default function AIUsages() {
  const reduxDispatch = useDispatch();

  const [selectedGroupGuid, setSelectedGroupGuid] = useState();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  const [tableKey, setTableKey] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
  );

  const colsRef = useRef();

  const allGroupsAIUsage = useSelector((state) => state.dchat.allGroupsAIUsage);

  const isFetchAllGroupsAIUsageLoaded = useSelector(
    (state) => state.dchat.isFetchAllGroupsAIUsageLoaded
  );

  useEffect(() => {
    reduxDispatch(fetchAllGroupsAIUsage());
  }, [reduxDispatch]);

  useEffect(() => {
    colsRef.current = setupColumns(selectedMonth, selectedYear);
    setTableKey(`${selectedYear}-${selectedMonth}`);
  }, [selectedYear, selectedMonth]);

  function inspectSelected(group_guid) {
    setSelectedGroupGuid(group_guid);
  }

  function setupColumns(selectedMonth, selectedYear) {
    const columns = [
      {
        dataField: 'group_name',
        text: 'Org Name',
        sort: true,
        attrs: { title: 'Org Name' },
      },
      {
        dataField: 'inspect',
        text: 'Inspect',
        sort: true,
        attrs: { title: 'Inspect' },
        formatter: (cell, row) => (
          <div>
            <StylishNewButton onClick={() => inspectSelected(row.group_guid)}>
              Details
            </StylishNewButton>
          </div>
        ),
      },
      {
        dataField: 'allotment',
        text: 'Allotment',
        sort: true,
        attrs: { title: 'Allotment' },
        formatter: () => <div>$50/month</div>,
      },
      {
        dataField: 'usage',
        text: 'Usage',
        sort: true,
        attrs: { title: 'Usage' },
        formatter: (cell, row) => {
          const usage = calculateAggregateUsageCost(
            row.usages,
            selectedYear,
            selectedMonth
          );
          return <div>${usage}</div>;
        },
      },
      {
        dataField: 'overage',
        text: 'Overage',
        sort: true,
        attrs: { title: 'Overage' },
        formatter: (cell, row) => {
          const usage = calculateAggregateUsageCost(
            row.usages,
            selectedYear,
            selectedMonth
          );
          let overage = usage - 50;
          if (overage < 0) overage = 0;
          return <div>${overage}</div>;
        },
      },
      {
        dataField: 'group_guid',
        text: 'ID',
        sort: true,
        attrs: { title: 'ID' },
      },
    ];
    return columns;
  }

  return (
    <div className="AIUsages">
      <h4>Organization DisasterGPT Usages</h4>
      {isFetchAllGroupsAIUsageLoaded ? (
        <>
          {selectedGroupGuid ? (
            <div>
              <StylishNewButton onClick={() => setSelectedGroupGuid()}>
                Back
              </StylishNewButton>
              <GroupAIUsage
                group_guid={selectedGroupGuid}
                group_name={
                  allGroupsAIUsage.find(
                    (g) => g.group_guid === selectedGroupGuid
                  )?.group_name
                }
              />
            </div>
          ) : (
            <>
              <div>
                <label>
                  <select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(Number(e.target.value))}
                  >
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(
                      (month) => (
                        <option key={month} value={month}>
                          {getMonthName(month)}
                        </option>
                      )
                    )}
                  </select>
                </label>
                <label>
                  <select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(Number(e.target.value))}
                  >
                    {Array.from(
                      { length: new Date().getFullYear() - 2023 },
                      (_, i) => 2024 + i
                    ).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              {colsRef.current && (
                <StylishNewTable
                  key={tableKey}
                  keyField={'group_guid'}
                  rows={allGroupsAIUsage}
                  columns={colsRef.current}
                />
              )}
            </>
          )}
        </>
      ) : (
        <div>
          <div className="d-flex justify-content-center">
            Fetching Organization AI Usage Data...
          </div>
          <div className="d-flex justify-content-center">
            <i className="fa fa-spinner fa-pulse fs-6"></i>
          </div>
          <Info className="d-flex justify-content-center">
            This may take a few moments.
          </Info>
        </div>
      )}
    </div>
  );
}
