import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { updateTransportation } from 'actions/IAPForm206Actions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { inputStringLength, contactStringLength } from 'assets/data/config';

export const AddTransportationDialog = ({
  show,
  onClose,
  refreshTransportationList,
  transportDialogType,
  editTransportData,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const reduxDispatch = useDispatch();

  useEffect(() => {
    setValue('als', false);
    setValue('bls', false);
  }, []);

  useEffect(() => {
    if (editTransportData?.id) {
      setValue(
        'ambulance_service',
        !!editTransportData.ambulance_service &&
          editTransportData.ambulance_service.trim()
      );
      setValue(
        'address',
        !!editTransportData.address && editTransportData.address.trim()
      );
      setValue(
        'contact',
        !!editTransportData.contact && editTransportData.contact.trim()
      );
      setValue('als', editTransportData.als);
      setValue('bls', editTransportData.bls);
    }
  }, [editTransportData]);

  const createNewTransportation = (transportationData) => {
    reduxDispatch(
      updateTransportation({
        id: editTransportData?.id || generateUUID(),
        ...transportationData,
      })
    );
    refreshTransportationList();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(createNewTransportation)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              {`${
                transportDialogType === 'New'
                  ? 'Add New'
                  : transportDialogType === 'Edit'
                  ? 'Edit'
                  : null
              } Transportation`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Ambulance Service</label>
              <Controller
                control={control}
                name="ambulance_service"
                rules={{ required: true, maxLength: inputStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.ambulance_service?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.name?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Address</label>
              <Controller
                control={control}
                name="address"
                rules={{ required: true, maxLength: inputStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.address?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.address?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Contact</label>
              <Controller
                control={control}
                name="contact"
                rules={{ required: true, maxLength: contactStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.contact?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.contact?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {contactStringLength} character allowed
                </span>
              )}
            </div>

            <div className="mt-4">
              <Controller
                control={control}
                name="als"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewCheckbox
                    onClick={onChange}
                    checked={value}
                    label={'ALS'}
                  />
                )}
              />
            </div>

            <div className="mt-4">
              <Controller
                control={control}
                name="bls"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewCheckbox
                    onClick={onChange}
                    checked={value}
                    label={'BLS'}
                  />
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton className="button--primary" type="submit">
                Save
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={onClose}
              >
                Cancel
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
