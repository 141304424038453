import React, { useEffect } from "react";
import { StylishSwitcher } from "components/DesignSystems/New/StylishSwitcher";
import { StylishNewButton } from "components/DesignSystems/New/StylishNewButton";
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';

import IconClose from '../../../assets/images/icon__times.svg';
import { sampleDrawLayer } from '../constants/sampleLayers.js';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { powerOutageTableRows, powerOutageScenarios, powerOutageTableCols } from "../constants/poweroutage";

dayjs.extend(utc);


const MapPowerOutage = (props) => {

  const { 
    map,
    mapHasLoaded,
    apiPrefix,
    mapSettings,
    handleMapSettings,
    setLayerClicked,
    onClose
  } = props;

  // constant variables
  const powerOutageScenario = mapSettings?.powerOutageScenario;
  const powerOutageActive = mapSettings?.powerOutageActive;
  const basemap = mapSettings?.basemap;
  const stateToCountyZoomThreshold = 5;
  const power_outage_source_id = 'power_outage_source_id';
  const power_outage_source_id_state = 'power_outage_source_id_state';
  const power_outage_layer_id = 'power_outage_layer_id';
  const power_outage_layer_id_state = 'power_outage_layer_id_state';
  const power_outage_source_layer = 'public.power_outage_layer';
  const power_outage_source_layer_state = 'public.power_outage_layer_state';
  const selectedDatetime = mapSettings?.selectedDatetime;

  const powerOutageTiles = `${apiPrefix}/power_outage_tiles/${power_outage_source_layer}/{z}/{x}/{y}.pbf`;
  const powerOutageTilesState = `${apiPrefix}/power_outage_tiles/${power_outage_source_layer_state}/{z}/{x}/{y}.pbf`;

  // State Declarations

  // functions 
  const handleClickClose = () => {
    onClose();
  }

  // useEffect
  useEffect(() => {
    if (!mapHasLoaded) return;

    if (powerOutageActive) {
      if (map.current.getStyle().layers.find((l) => l.id === power_outage_layer_id))
        map.current.removeLayer(power_outage_layer_id);
      if (
        map.current.getStyle().layers.find((l) => l.id === power_outage_layer_id_state)
      )
        map.current.removeLayer(power_outage_layer_id_state);
      if (map.current.getSource(power_outage_source_id))
        map.current.removeSource(power_outage_source_id);
      if (map.current.getSource(power_outage_source_id_state))
        map.current.removeSource(power_outage_source_id_state);

      const formattedDatetime = selectedDatetime
        .minute(0)
        .second(0)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');

      if (!map.current.getSource(power_outage_source_id)) {
        map.current.addSource(power_outage_source_id, {
          type: 'vector',
          tiles: [powerOutageTiles + '?datetime_selected=' + formattedDatetime],
        });
      }

      if (!map.current.getSource(power_outage_source_id_state)) {
        map.current.addSource(power_outage_source_id_state, {
          type: 'vector',
          tiles: [powerOutageTilesState + '?datetime_selected=' + formattedDatetime],
        });
      }

      if (
        !map.current.getStyle().layers.find((l) => l.id === power_outage_layer_id) &&
        !map.current.getStyle().layers.find((l) => l.id === power_outage_layer_id_state)
      ) {
        let powerOutageLayerConfig = {
          ...sampleDrawLayer,
          id: power_outage_layer_id,
          source: power_outage_source_id,
          'source-layer': power_outage_source_layer,
          generateId: true,
          paint: {
            ...sampleDrawLayer.paint,
            'fill-opacity': 0.4,
            'fill-outline-color': '#7bccc4',
          },
          metadata: {
            eventlayer: false,
            usermade: undefined,
            model_layer: false,
            poc_layer: true,
            model_details: {
              model_name: 'Power Outage By County',
              model_code: 'Power Outage',
              political_boundaries_type: 'county',
            },
          },
          minzoom: stateToCountyZoomThreshold,
        };

        if (powerOutageScenario === powerOutageScenarios[0].value) { // Relative
          powerOutageLayerConfig = {
            ...powerOutageLayerConfig,
            paint: {
              ...powerOutageLayerConfig.paint,
              'fill-color': [
                'step',
                ['*', ['/', ['get', 'CustomersOut'], ['get', 'CustomersTracked']], 100],
                '#FFFFFF',
                5, '#ffffcc',
                10, '#a1dab4',
                15, '#41b6c4',
                25, '#2c7fb8',
                35, '#253494',
              ]
            },
          }
        } else if (powerOutageScenario === powerOutageScenarios[1].value) { // Absolute
          powerOutageLayerConfig = {
            ...powerOutageLayerConfig,
            paint: {
              ...powerOutageLayerConfig.paint,
              'fill-color': [
                'step',
                ['get', 'CustomersOut'],
                '#FFFFFF',
                50, '#ffffcc',
                500, '#a1dab4',
                2000, '#41b6c4',
                5000, '#2c7fb8',
                10000, '#253494',
              ]
            },
          }
        }
        map.current.addLayer(powerOutageLayerConfig);
        map.current.on('click', powerOutageLayerConfig.id, (e) => {
          setLayerClicked({ layer: powerOutageLayerConfig, e: e, features: e.features })
        }
        );
        map.current.on('touchend', powerOutageLayerConfig.id, (e) =>
          setLayerClicked({ layer: powerOutageLayerConfig, e: e, features: e.features })
        );
        map.current.on('mouseenter', powerOutageLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', powerOutageLayerConfig.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });

        const powerOutageLayerConfigState = {
          ...powerOutageLayerConfig,
          metadata: {
            ...powerOutageLayerConfig.metadata,
            model_details: {
              ...powerOutageLayerConfig.metadata.model_details,
              political_boundaries_type: 'state',
            },
          },
          id: power_outage_layer_id_state,
          source: power_outage_source_id_state,
          'source-layer': power_outage_source_layer_state,
          minzoom: 0,
          maxzoom: stateToCountyZoomThreshold,
        };
        map.current.addLayer(powerOutageLayerConfigState);
        map.current.on('click', powerOutageLayerConfigState.id, (e) => {
          setLayerClicked({
            layer: powerOutageLayerConfigState,
            e: e,
            features: e.features,
          })
        }
        );
        map.current.on('touchend', powerOutageLayerConfigState.id, (e) =>
          setLayerClicked({
            layer: powerOutageLayerConfigState,
            e: e,
            features: e.features,
          })
        );
        map.current.on('mouseenter', powerOutageLayerConfigState.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', powerOutageLayerConfigState.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });
      }
    } else {
      if (map.current.getStyle().layers.find((l) => l.id === power_outage_layer_id))
        map.current.removeLayer(power_outage_layer_id);
      if (
        map.current.getStyle().layers.find((l) => l.id === power_outage_layer_id_state)
      )
        map.current.removeLayer(power_outage_layer_id_state);
      if (map.current.getSource(power_outage_source_id))
        map.current.removeSource(power_outage_source_id);
      if (map.current.getSource(power_outage_source_id_state))
        map.current.removeSource(power_outage_source_id_state);
    }
  }, [powerOutageActive, mapHasLoaded, selectedDatetime, powerOutageScenario, basemap]);

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        <h4 className="m-0">Power Outage</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        <div className="py-3 pt-0 pb-0">
          <div className="d-flex align-items-center">
            <span>Power Outage</span>
            <StylishSwitcher
              className={'ms-auto'}
              checked={powerOutageActive}
              onChange={() => handleMapSettings({
                ...mapSettings,
                powerOutageActive: !powerOutageActive
              })}
            />
          </div>
          <div className="my-3">
            <StylishNewSelect
              options={powerOutageScenarios}
              defaultValue={powerOutageScenario}
              value={powerOutageScenario}
              onChange={(e) => handleMapSettings({
                ...mapSettings,
                powerOutageScenario: e.value
              })}
              placeholder={
                powerOutageScenarios?.find(
                  (s) => s.value === powerOutageScenario
                )?.label
              }
              isClearable={true}
              isSearchable={true}
              isMulti={false}
              isDisabled={false}
            />
          </div>
          <StylishNewTable
            classes={'tbody-p-0 layout-auto'}
            keyField={`id`}
            rows={powerOutageTableRows}
            columns={powerOutageTableCols}
          />
        </div>
      </div>
    </div>
  )
};

export default MapPowerOutage;
