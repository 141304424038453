export const statusOptions = [
  'Trial',
  'Paid',
  'Paid (Incident only)',
  'Paid (Exercise only)',
  'Expired',
] as const;

export type SubscriptionStatus = (typeof statusOptions)[number];

export const getDisplayStatus = (status: SubscriptionStatus) =>
  status === 'Expired' ? 'Freemium' : status;

export const statusDisplayOptions = statusOptions.map((status) => ({
  label: getDisplayStatus(status),
  value: status,
}));
