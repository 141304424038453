// SitrepCreateDrawer.js

import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import FormTextField from 'components/IAP/formFields/FormTextField';
import FormTextAreaField from 'components/IAP/formFields/FormTextAreaField';
import { useSitrepTemplates } from './hooks/useSitrepTemplates';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoreComponents } from 'actions/ai2fpActions.js';
import CoreComponentCreationDialog from 'components/AI2FP/CoreComponentCreationDialog';
import { useSectionTemplates } from './hooks/useSectionTemplates';
import {
  useSharepointFiles,
  useSyncSharepointFiles,
} from './hooks/useSharepointFiles';
import { useSharepointFileRefs } from './hooks/useSharepointFileRefs';
import * as dayjs from 'dayjs';
import { defaultSitreps } from './SitrepDefaults.js';
import {
  useCurrentIncident,
  useIncidentsInCurrentGroup,
} from 'components/IAP/hooks/commonHooks';

const SitrepCreateDrawer = ({ onHide, onSave, sitrep }) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: sitrep?.name || '',
      description: sitrep?.description || '',
      sitrep_template_id: sitrep?.sitrep_template_id || '',
      aor_id: sitrep?.aor_id || '',
      incident_id: sitrep?.incident_id || '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
  } = methods;

  const { data: sitrepTemplates = [], isLoading: isTemplatesLoading } =
    useSitrepTemplates();

  const {
    data: sectionTemplates = [],
    isLoading: isSectionTemplatesLoading,
    isError: isSectionTemplatesError,
  } = useSectionTemplates();

  const sitrepTemplateId = watch('sitrep_template_id');

  const combinedTemplates = [...defaultSitreps, ...sitrepTemplates];

  // Get current incident using the hook
  const { data: currentIncident, isLoading: isLoadingCurrentIncident } =
    useCurrentIncident();

  // Get incidents for current group
  const incidents = useIncidentsInCurrentGroup();

  // Set default incident_id if not provided
  useEffect(() => {
    if (!sitrep?.incident_id && currentIncident?.id) {
      setValue('incident_id', currentIncident.id);
    }
  }, [currentIncident, sitrep, setValue]);

  // Find the selected SITREP template
  const selectedTemplate = useMemo(() => {
    if (!sitrepTemplateId) return null;
    return combinedTemplates.find((t) => t.id === sitrepTemplateId) || null;
  }, [sitrepTemplateId, sitrepTemplates]);

  // Get full section metadata from sectionTemplates based on selectedTemplate's sections
  const selectedTemplateSections = useMemo(() => {
    if (!selectedTemplate || !selectedTemplate.sections) return [];
    return selectedTemplate.sections.map((sec) => {
      const fullSection = sectionTemplates.find(
        (st) => st.id === sec.section_id
      );
      return fullSection
        ? { ...fullSection, order: sec.order }
        : {
            id: sec.section_id,
            order: sec.order,
            name: 'Unknown Section',
            data_sources: [],
          };
    });
  }, [selectedTemplate, sectionTemplates]);

  // Check if any section has data_sources with source === "Files"
  const templateHasFiles = useMemo(() => {
    if (!selectedTemplateSections) return false;
    return selectedTemplateSections.some(
      (section) =>
        section.data_sources &&
        section.data_sources.some((ds) => ds.source === 'Files')
    );
  }, [selectedTemplateSections]);

  const dispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector(
    (state) => state.app.currentlySelectedGroup
  );
  const reduxCoreComponents = useSelector((state) => state.app.coreComponents);

  const [aorCreationDialogActive, setAORCreationDialogActive] = useState(false);
  const [aorOptions, setAorOptions] = useState([]);

  // Create incident options from current group's incidents
  const incidentOptions = useMemo(() => {
    return incidents
      .filter((incident) => !incident.archived)
      .map((incident) => ({
        value: incident.id,
        label: incident.name,
      }));
  }, [incidents]);

  useEffect(() => {
    if (reduxCurrentlySelectedGroup && reduxCurrentlySelectedGroup.group_guid) {
      dispatch(fetchCoreComponents(reduxCurrentlySelectedGroup.group_guid));
    }
  }, [reduxCurrentlySelectedGroup, dispatch]);

  useEffect(() => {
    if (
      reduxCoreComponents &&
      reduxCoreComponents.CCs &&
      reduxCoreComponents.CCs['Areas of Responsibility']
    ) {
      const aors = reduxCoreComponents.CCs['Areas of Responsibility'];
      const options = aors.map((aor) => ({ value: aor.id, label: aor.name }));
      setAorOptions(options);
    }
  }, [reduxCoreComponents]);

  // File Sync logic
  const { data: filesData = [], isLoading: isLoadingSharepointFiles } =
    useSharepointFiles();
  const { data: sharepointFileRefs } = useSharepointFileRefs(
    reduxCurrentlySelectedGroup?.group_guid
  );
  const { syncSharepointFiles, isSyncing } = useSyncSharepointFiles();

  const [isOutOfSync, setIsOutOfSync] = useState(false);

  useEffect(() => {
    if (sharepointFileRefs && sharepointFileRefs.length > 0) {
      const outOfSyncFiles = sharepointFileRefs.filter((file) => {
        const timestamp = dayjs(file.timestamp);
        return dayjs().diff(timestamp, 'hour') >= 24;
      });
      setIsOutOfSync(outOfSyncFiles.length > 0);
    }
  }, [sharepointFileRefs]);

  const runFileSync = () => {
    syncSharepointFiles(true);
  };

  // Disable create if file sync is needed and currently running
  const disableCreate = !isValid || (templateHasFiles && isSyncing);

  return (
    <DrawerWrapper
      toggle={onHide}
      title={sitrep ? 'Edit SITREP' : 'Create New SITREP'}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSave)}
          className="d-flex flex-column justify-content-between flex-grow-1"
          style={{ margin: '8px' }}
        >
          <div className="drawer-body">
            {!sitrep && (
              <Row className="mb-3">
                <Col>
                  <label htmlFor="sitrep_template_id">
                    Select SITREP Template
                  </label>
                  {isTemplatesLoading || isSectionTemplatesLoading ? (
                    <div>Loading templates...</div>
                  ) : isSectionTemplatesError ? (
                    <div style={{ color: 'red' }}>
                      Error loading section templates.
                    </div>
                  ) : (
                    <Controller
                      name="sitrep_template_id"
                      control={control}
                      rules={{ required: 'Please select a SITREP template' }}
                      render={({ field }) => (
                        <StylishNewSelect
                          {...field}
                          options={combinedTemplates.map((template) => ({
                            value: template.id,
                            label: template.name,
                          }))}
                          isClearable
                          placeholder="Select SITREP Template"
                          onChange={(selectedOption) => {
                            const selectedTemplateName = selectedOption
                              ? selectedOption.label
                              : '';
                            if (selectedTemplateName && !sitrep) {
                              methods.setValue('name', selectedTemplateName);
                            }
                            field.onChange(
                              selectedOption ? selectedOption.value : ''
                            );
                          }}
                          value={
                            combinedTemplates
                              .map((t) => ({ value: t.id, label: t.name }))
                              .find((option) => option.value === field.value) ||
                            null
                          }
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: errors.sitrep_template_id
                                ? 'red'
                                : base.borderColor,
                            }),
                          }}
                        />
                      )}
                    />
                  )}
                  {errors.sitrep_template_id && (
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      {errors.sitrep_template_id.message}
                    </span>
                  )}
                </Col>
              </Row>
            )}

            <Row className="mb-3">
              <FormTextField
                title="SITREP Name"
                fieldName="name"
                register={methods.register}
                rules={{ required: 'SITREP name is required' }}
                error={errors.name}
              />
            </Row>

            <Row className="mb-3">
              <FormTextAreaField
                title="Description (used to focus News search)"
                fieldName="description"
                register={methods.register}
                error={errors.name}
              />
            </Row>

            <Row className="mb-3">
              <Col>
                <label htmlFor="incident_id">Select Incident (optional)</label>
                {isLoadingCurrentIncident ? (
                  <div>Loading incidents...</div>
                ) : (
                  <Controller
                    name="incident_id"
                    control={control}
                    rules={{ required: 'Please select an incident' }}
                    render={({ field }) => (
                      <StylishNewSelect
                        {...field}
                        options={incidentOptions}
                        isClearable
                        placeholder="Select Incident"
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : ''
                          );
                        }}
                        value={
                          incidentOptions.find(
                            (option) => option.value === field.value
                          ) || null
                        }
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.incident_id
                              ? 'red'
                              : base.borderColor,
                          }),
                        }}
                      />
                    )}
                  />
                )}
                {errors.incident_id && (
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    {errors.incident_id.message}
                  </span>
                )}
              </Col>
            </Row>

            {/* File Sync Section */}
            {!sitrep && templateHasFiles && (
              <div className="mb-3 p-3 border rounded">
                <p>
                  Run File Sync to ensure your files are up to date for this
                  SITREP.
                </p>
                <StylishNewButton
                  className="button--primary"
                  onClick={runFileSync}
                  disabled={isSyncing}
                >
                  {isSyncing ? (
                    <i className="fa fa-spinner fa-pulse"></i>
                  ) : isOutOfSync ? (
                    'File Sync!'
                  ) : (
                    'File Sync'
                  )}
                </StylishNewButton>
              </div>
            )}

            <Row className="mb-3">
              <Col>
                <label htmlFor="aor_id">Select AOR (optional)</label>
                <div className="d-flex align-items-center">
                  <Controller
                    name="aor_id"
                    control={control}
                    render={({ field }) => (
                      <StylishNewSelect
                        {...field}
                        options={aorOptions}
                        isClearable
                        placeholder="Select an AOR"
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : ''
                          );
                        }}
                        value={
                          aorOptions.find(
                            (option) => option.value === field.value
                          ) || null
                        }
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.aor_id
                              ? 'red'
                              : base.borderColor,
                          }),
                        }}
                      />
                    )}
                  />
                  <StylishNewButton
                    className="button--primary button--sml ms-2"
                    onClick={() => setAORCreationDialogActive(true)}
                  >
                    New AOR
                  </StylishNewButton>
                </div>
                {errors.aor_id && (
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    {errors.aor_id.message}
                  </span>
                )}
              </Col>
            </Row>
          </div>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton className="button--secondary" onClick={onHide}>
                Cancel
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={disableCreate}
              >
                {sitrep ? 'Update' : 'Create'}
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
      <CoreComponentCreationDialog
        show={aorCreationDialogActive}
        onClose={() => {
          setAORCreationDialogActive(false);
          // Refetch AORs after creation
          dispatch(fetchCoreComponents(reduxCurrentlySelectedGroup.group_guid));
        }}
        ccType="Areas of Responsibility"
      />
    </DrawerWrapper>
  );
};

export default SitrepCreateDrawer;
