import React from 'react';

function featureTypeFromTable(table) {
  if (!table) {
    return '';
  }

  if (table === 'ics_resource_sites') {
    return 'Resource Site';
  }
  if (table === 'ics_resource_sets') {
    return 'Resource Set';
  }
  if (table === 'ics_incident_work_assignments') {
    return 'Work Assignment';
  }
}

export default function GeolocationLayerPopup({
  features,
  AORs,
  setSelectedGeolocation,
  setSidebarSecondaryActiveItem,
}) {
  function geolocationSelected(feature) {
    //const event = events.find((ev) => ev.feature_id === feature.properties.id);

    const ifSelectedAOR = AORs.find((a) => a.id === feature.properties.id);

    if (ifSelectedAOR) {
      setSelectedGeolocation(ifSelectedAOR, 'AOR');
      setSidebarSecondaryActiveItem('Geolocations AORs');
    }
  }

  function isEqual(item1, item2) {
    return item1.properties.id === item2.properties.id;
  }

  const feature = features.filter(
    (item, index, self) => index === self.findIndex((i) => isEqual(i, item))
  );

  return (
    <>
      <h6 className="my-1">Geolocations</h6>
      <hr className="dashed thin my-1" />
      {!!feature && feature.length ? (
        <>
          <ul className="list">
            {feature.map((f) => {
              let name = f.properties.name;
              if (
                f.properties?.ics_table &&
                f.properties?.ics_table === 'ics_incident_work_assignments'
              ) {
                name = f.properties.work_assignment;
              }
              const featureType = featureTypeFromTable(f.properties.ics_table);
              return (
                <li
                  className="popup-container"
                  key={'GeolocationLayerPopup-Feature-' + f.properties.id}
                  onClick={() => geolocationSelected(f)}
                >
                  <strong>{`${(!!featureType && featureType + ': ') || ''}`}</strong>{' '}
                  {name}
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <p className="m-0 weight-500">No Geolocations.</p>
      )}
      <hr className="thin my-1" />
    </>
  );
}
