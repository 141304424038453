import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import generateUUID from 'utils/sharedUtils/generateUUID';
import validateEmailAddress from 'utils/validateEmailAddress';
import validatePagerAddress from 'utils/validatePagerAddress';
import NotificationRecipients from './NotificationRecipients';
import { createDistributionList } from 'actions/notificationActions';
import { useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { changeGroupAdminNameToOrgAdmin } from 'components/Roles/RolesUtils/changeGroupAdminNameToOrgAdmin';
export default function AddNewRecipientsDialog({
  show,
  groupMembersToNotify = [],
  setGroupMembersToNotify,
  setSelectedDistributionLists,
  roster,
  onClose,
  nonMembers,
  setNonMembers,
  recipientTypes,
  distributionLists,
}) {
  const reduxDispatch = useDispatch();

  const [selectedMembers, setSelectedMembers] = useState(
    roster.filter((member) => groupMembersToNotify.includes(member.user_guid))
  );

  const [emailRecipients, setEmailRecipients] = useState([]);
  const [SMSRecipients, setSMSRecipients] = useState([]);
  const [TTSHomeRecipients, setTTSHomeRecipients] = useState([]);
  const [TTSCellRecipients, setTTSCellRecipients] = useState([]);
  const [pagerRecipients, setPagerRecipients] = useState([]);
  const [templateTitle, setTemplateTitle] = useState();
  const [templateTitleError, setTemplateTitleError] = useState(false);
  const [saveAsTemplate, setSaveAsTemplate] = useState();

  const reduxValidateRBACPermissionForActionResult =
    useSelector((state) => {
      return state.app.validateRBACPermissionForActionResult;
    }) || [];

  useEffect(() => {
    const {
      emailRecipients,
      SMSRecipients,
      TTSHomeRecipients,
      TTSCellRecipients,
      pagerRecipients,
    } = nonMembers.reduce(
      (acc, item) => {
        switch (item.type) {
          case 'emailRecipients':
            acc.emailRecipients.push(item);
            break;
          case 'SMSRecipients':
            acc.SMSRecipients.push(item);
            break;
          case 'TTSHomeRecipients':
            acc.TTSHomeRecipients.push(item);
            break;
          case 'TTSCellRecipients':
            acc.TTSCellRecipients.push(item);
            break;
          case 'pagerRecipients':
            acc.pagerRecipients.push(item);
            break;
          default:
          // do nothing
        }
        return acc;
      },
      {
        emailRecipients: [],
        SMSRecipients: [],
        TTSHomeRecipients: [],
        TTSCellRecipients: [],
        pagerRecipients: [],
      }
    );

    setEmailRecipients(emailRecipients);
    setSMSRecipients(SMSRecipients);
    setTTSHomeRecipients(TTSHomeRecipients);
    setTTSCellRecipients(TTSCellRecipients);
    setPagerRecipients(pagerRecipients);
  }, [nonMembers]);

  const allColumn = [
    {
      dataField: 'email_address',
      text: 'Email Address',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Member Email' },
    },
    {
      dataField: 'user_name',
      text: 'Member Name',
      sort: true,
      attrs: { title: 'Member Name' },
    },
    {
      dataField: 'role_string',
      formatter: (cell) => changeGroupAdminNameToOrgAdmin(cell),
      text: 'Organization Role',
      sort: true,
      attrs: { title: 'Organization Role' },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: selectedMembers.map((item) => item.user_guid),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row) => {
      let selection = selectedMembers.map((i) => i.user_guid);
      if (selection.includes(row.user_guid)) {
        selection = selection.filter((i) => i !== row.user_guid);
      } else {
        selection.push(row.user_guid);
      }
      setSelectedMembers(
        roster.filter((item) => selection.includes(item.user_guid))
      );
    },
    onSelectAll: (isSelect, rows) => {
      let selection = selectedMembers.map((i) => i.user_guid);
      rows.forEach((row) => {
        if (selection.includes(row.user_guid)) {
          selection = selection.filter((i) => i !== row.user_guid);
        } else {
          selection.push(row.user_guid);
        }
      });
      setSelectedMembers(
        roster.filter((item) => selection.includes(item.user_guid))
      );
    },
  };

  const getUniqueValidations = (recipients, check) => {
    const recipientValidations = recipients
      .map((recipient) => check(recipient.content))
      .filter((invalid) => invalid.length)
      .reduce((acc, item) => {
        item.forEach((inner) => acc.push(inner));
        return acc;
      }, []);

    return [...new Set(recipientValidations)];
  };

  const onAddMembersClick = () => {
    const SMSValidations = getUniqueValidations(
      SMSRecipients,
      getSMSValidationsForItem
    );
    const emailValidations = getUniqueValidations(
      emailRecipients,
      getEmailValidationsForItem
    );
    const TTSCellValidations = getUniqueValidations(
      TTSCellRecipients,
      getTTSValidationsForItem
    );
    const TTSHomeValidations = getUniqueValidations(
      TTSHomeRecipients,
      getTTSValidationsForItem
    );
    const pagerValidations = getUniqueValidations(
      pagerRecipients,
      getPagerValidationsForItem
    );

    const aggregateValidations = [
      ...SMSValidations,
      ...emailValidations,
      ...TTSCellValidations,
      ...TTSHomeValidations,
      ...pagerValidations,
    ];

    if (aggregateValidations.length > 0) {
      toast.error(
        <div>
          {aggregateValidations.map((text) => (
            <>
              - {text}
              <br />
            </>
          ))}
        </div>,
        toastConfig
      );
    } else {
      setGroupMembersToNotify(
        selectedMembers.map((member) => member.user_guid)
      );

      const selectedContacts = [
        ...emailRecipients,
        ...SMSRecipients,
        ...TTSHomeRecipients,
        ...TTSCellRecipients,
        ...pagerRecipients,
      ];
      setNonMembers(selectedContacts);

      onClose();
    }
  };

  function getEmailValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [...validation_messages, `Email cannot be blank`];
    } else if (!validateEmailAddress(item)) {
      validation_messages = [...validation_messages, `Invalid email address`];
    }
    return validation_messages;
  }

  function getSMSValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `SMS number cannot be blank`,
      ];
    } else if (
      !item.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)
    ) {
      validation_messages = [...validation_messages, `Invalid SMS number`];
    }
    return validation_messages;
  }

  function getTTSValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `Text-To-Speech number cannot be blank`,
      ];
    } else if (
      !item.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)
    ) {
      validation_messages = [
        ...validation_messages,
        `Invalid Text-To-Speech number`,
      ];
    }
    return validation_messages;
  }

  function getPagerValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `Pager address cannot be blank`,
      ];
    } else if (!validatePagerAddress(item)) {
      validation_messages = [...validation_messages, `Invalid pager address`];
    }
    return validation_messages;
  }

  useEffect(() => {
    !!templateTitle && setTemplateTitleError(false);
  }, [templateTitle]);

  const handleTemplateSave = () => {
    const newList = {
      id: generateUUID(),
      name: templateTitle,
      members: selectedMembers.map((member) => member.user_guid),
      nonMembers: [
        ...emailRecipients,
        ...SMSRecipients,
        ...TTSHomeRecipients,
        ...TTSCellRecipients,
        ...pagerRecipients,
      ],
    };
    const currentList = distributionLists.findIndex(
      (ele) => ele.name === newList.name
    );
    if (templateTitle === '' || !templateTitle) {
      setTemplateTitleError(true);
      return;
    }
    if (currentList !== -1) {
      const errorMessage =
        `Duplicate Relevant Team Name.\n\n` + 'Failed Create New Relevant Team';
      console.warn(errorMessage);
      toast.error(errorMessage, toastConfig);
      return;
    }
    reduxDispatch(createDistributionList(newList));
    setSelectedDistributionLists((prev) => [...prev, newList.id]);
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Add New Recipients</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Select Organization Members</h5>
        <StylishNewTable
          keyField={'user_guid'}
          rows={roster}
          columns={allColumn}
          selectRow={selectRow}
          hasPagination={true}
          paginationPerPageItem="10"
        />

        {!!recipientTypes.length && (
          <>
            <hr className="dashed" />
            <h4 className="mt-4">Additional Recipients</h4>
            <div className="bg-gray-900 rounded p-3 p-md-4">
              {!!recipientTypes.find((t) => t === 'Email') && (
                <>
                  <h5>Email Recipients</h5>
                  <NotificationRecipients
                    recipients={emailRecipients}
                    onRecipientsChange={(newRecipients) =>
                      setEmailRecipients(newRecipients)
                    }
                    getValidationMessagesForItem={getEmailValidationsForItem}
                    type="emailRecipients"
                  />

                  {recipientTypes.findIndex((i) => i === 'Email') !==
                  recipientTypes.length - 1 ? (
                    <hr className="dashed my-4" />
                  ) : null}
                </>
              )}

              {!!recipientTypes.find((t) => t === 'SMS') && (
                <>
                  <h5>SMS Recipients</h5>
                  <NotificationRecipients
                    recipients={SMSRecipients}
                    onRecipientsChange={(newRecipients) =>
                      setSMSRecipients(newRecipients)
                    }
                    getValidationMessagesForItem={getSMSValidationsForItem}
                    type="SMSRecipients"
                  />
                  {recipientTypes.findIndex((i) => i === 'SMS') !==
                  recipientTypes.length - 1 ? (
                    <hr className="dashed my-4" />
                  ) : null}
                </>
              )}

              {!!recipientTypes.find((t) => t === 'Pager') && (
                <>
                  <h5>Pager Recipients</h5>
                  <NotificationRecipients
                    recipients={pagerRecipients}
                    onRecipientsChange={(newRecipients) =>
                      setPagerRecipients(newRecipients)
                    }
                    getValidationMessagesForItem={getPagerValidationsForItem}
                    type="pagerRecipients"
                  />
                  {recipientTypes.findIndex((i) => i === 'Pager') !==
                  recipientTypes.length - 1 ? (
                    <hr className="dashed my-4" />
                  ) : null}
                </>
              )}

              {!!recipientTypes.find((t) => t === 'Text-To-Speech (cell)') && (
                <>
                  <h5>Text-To-Speech (cell) Recipients</h5>
                  <NotificationRecipients
                    recipients={TTSCellRecipients}
                    onRecipientsChange={(newRecipients) =>
                      setTTSCellRecipients(newRecipients)
                    }
                    getValidationMessagesForItem={getTTSValidationsForItem}
                    type="TTSCellRecipients"
                  />
                  {recipientTypes.findIndex(
                    (i) => i === 'Text-To-Speech (cell)'
                  ) !==
                  recipientTypes.length - 1 ? (
                    <hr className="dashed my-4" />
                  ) : null}
                </>
              )}

              {!!recipientTypes.find((t) => t === 'Text-To-Speech (home)') && (
                <>
                  <h5>Text-To-Speech (home) Recipients</h5>
                  <NotificationRecipients
                    recipients={TTSHomeRecipients}
                    onRecipientsChange={(newRecipients) =>
                      setTTSHomeRecipients(newRecipients)
                    }
                    getValidationMessagesForItem={getTTSValidationsForItem}
                    type="TTSHomeRecipients"
                  />
                  {recipientTypes.findIndex(
                    (i) => i === 'Text-To-Speech (home)'
                  ) !==
                  recipientTypes.length - 1 ? (
                    <hr className="dashed my-4" />
                  ) : null}
                </>
              )}
            </div>
            <hr className="dashed" />
          </>
        )}
        <StylishNewCheckbox
          checked={saveAsTemplate}
          onClick={() => {
            setSaveAsTemplate(!saveAsTemplate);
          }}
          label="Save as distribution list?"
          disabled={
            !reduxValidateRBACPermissionForActionResult[
              'Manage Notification Templates'
            ]
          }
        />

        {saveAsTemplate && (
          <div className="mt-4">
            <label className="form-label">Template Title</label>
            <StylishNewInput
              value={templateTitle}
              onChange={(e) => setTemplateTitle(e.target.value)}
            />
            {templateTitleError ? (
              <span className="form-text form-error">
                This is a required field
              </span>
            ) : null}
            <StylishNewButton
              className="button--primary mt-3"
              onClick={handleTemplateSave}
              disabled={
                !reduxValidateRBACPermissionForActionResult[
                  'Manage Notification Templates'
                ]
              }
            >
              Save List
            </StylishNewButton>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--primary"
            onClick={onAddMembersClick}
          >
            Add Recipients
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
