import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSWorkAssignmentDialog from 'components/ICS/NewICSWorkAssignmentDialog';

import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';


import { updateIcsWorkAssignment } from 'slices/ics_workAssignmentsSlice';

import {
  createIcsIncidentWorkAssignment,
  updateIcsIncidentWorkAssignment,
} from 'slices/ics_incidentWorkAssignmentsSlice';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';
import ICSIncidentWorkAssignment from '../IAP/cards/ICSIncidentWorkAssignment';

export default function WorkAssignmentsDialog({
  show,
  onClose,
  incident_id = null,
  ics_work_assignments,
  ics_incident_work_assignments
}) {
  const reduxDispatch = useDispatch();

  const [isNewICSWorkAssignmentDialogOpen, setIsNewICSWorkAssignmentDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editWorkAssignmentData, setEditWorkAssignmentData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogWorkAssignments, setDialogWorkAssignments] = useState([
    ...ics_incident_work_assignments,
  ]);

  useEffect(() => {
    setDialogWorkAssignments(ics_incident_work_assignments);
  }, [ics_incident_work_assignments]);

  useEffect(() => {
    // Use to rerender
  }, [ics_work_assignments]);

  const allColumns = [
    {
      dataField: 'work_assignment',
      text: 'Work Assignment',
      sort: true,
      attrs: { title: 'Work Assignment' },
    },
    {
      dataField: 'branch',
      text: 'Branch',
      sort: true,
      attrs: { title: 'Branch' },
    },
    {
      dataField: 'division_group',
      text: 'Division/Group',
      sort: true,
      attrs: { title: 'Division/Group' },
    },
    {
      dataField: 'resources',
      text: 'Resources',
      sort: true,
      attrs: { title: 'Resources' },
    },
    {
      dataField: 'overhead_positions',
      text: 'Overhead Positions',
      sort: true,
      attrs: { title: 'Overhead Positions' },
    },
    {
      dataField: 'special_equipment_supplies',
      text: 'Special Equipment/Supplies',
      sort: true,
      attrs: { title: 'Special Equipment/Supplies' },
    },
    {
      dataField: 'reporting_location',
      text: 'Reporting Location',
      sort: true,
      attrs: { title: 'Reporting Location' },
    },
    {
      dataField: 'requested_arrival_time',
      text: 'Requested Arrival Time',
      sort: true,
      attrs: { title: 'Requested Arrival Time' },
    },
    {
      dataField: 'special_instructions',
      text: 'Special Instructions',
      sort: true,
      attrs: { title: 'Special Instructions' },
    },
    {
      dataField: 'user_name',
      text: 'Last Modified By',
      sort: true,
      attrs: { title: 'Last Modified By' },
    },
    {
      dataField: 'archived',
      text: 'Archived',
      sort: true,
      attrs: { title: 'Archived' },
      formatter: (cell) => (cell ? 'Yes' : 'No'),
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentWorkAssignment
            row={row}
            ics_work_assignments={ics_work_assignments}
            ics_incident_work_assignments={ics_incident_work_assignments}
          />
          {(!incident_id ||
            !!ics_incident_work_assignments.find(
              (workAssignment) => workAssignment.work_assignment_id === row.id
            )) && (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onArchive(row)}
              >
                {row.archived ? `Unarchive` : `Archive`}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <span className="sr-only">Details</span>
            <ExpandDownArrow />
          </>
        );
      }
      return (
        <>
          <span className="sr-only">Details</span>
          <ExpandRightArrow />
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_work_assignments
      .filter((workAssignment) => {
        const incWorkAssignment = ics_incident_work_assignments.find(
          (iworkAssignment) => iworkAssignment.work_assignment_id === workAssignment.id && !iworkAssignment.archived
        );
        return !!incWorkAssignment;
      })
      .map((workAssignment) => workAssignment.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentWorkAssignment = ics_incident_work_assignments.find(
        (iworkAssignment) => iworkAssignment.work_assignment_id === row.id
      );
      if (existingIncidentWorkAssignment) {
        reduxDispatch(
          updateIcsIncidentWorkAssignment(
            incident_id,
            existingIncidentWorkAssignment.id,
            {
              ...existingIncidentWorkAssignment,
              archived: !existingIncidentWorkAssignment.archived,
            }
          )
        );
      } else {
        // Create a new incident work assignment
        const incidentWorkAssignmentData = {
          work_assignment_id: row.id,
          branch: row.branch,
          division_group: row.division_group,
          work_assignment: row.work_assignment,
          resources: row.resources,
          overhead_positions: row.overhead_positions,
          special_equipment_supplies: row.special_equipment_supplies,
          reporting_location: row.reporting_location,
          requested_arrival_time: row.requested_arrival_time,
          special_instructions: row.special_instructions,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          archived: false,
        };
        reduxDispatch(
          createIcsIncidentWorkAssignment(incident_id, incidentWorkAssignmentData)
        );
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogWorkAssignments(rows) : setDialogWorkAssignments([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewICSWorkAssignmentDialogOpen(true);
    setEditWorkAssignmentData(row);
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (incident_id) {
      reduxDispatch(
        updateIcsIncidentWorkAssignment(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsWorkAssignment(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewWorkAssignment = () => {
    setModalType('New');
    setIsNewICSWorkAssignmentDialogOpen(true);
  };

  const tableRows = ics_work_assignments.map((workAssignment) => {
    const incWorkAssignment = ics_incident_work_assignments.find(
      (iworkAssignment) => iworkAssignment.work_assignment_id === workAssignment.id
    );
    if (incWorkAssignment) {
      return {
        ...incWorkAssignment,
        id: workAssignment.id,
      };
    } else {
      return workAssignment;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Work Assignments</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewWorkAssignment}
        >
          New Work Assignment
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewICSWorkAssignmentDialogOpen && (
        <NewICSWorkAssignmentDialog
          show={isNewICSWorkAssignmentDialogOpen}
          setEditWorkAssignmentData={setEditWorkAssignmentData}
          setModalType={setModalType}
          editWorkAssignmentData={editWorkAssignmentData}
          modalType={modalType}
          onClose={() => setIsNewICSWorkAssignmentDialogOpen(false)}
          incident_id={incident_id}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Work Assignment?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
