
export const mapStyles = [
  {
    key: "Dark",
    value: "mapbox://styles/mapbox/dark-v10",
  },
  {
    key: "Light",
    value: "mapbox://styles/mapbox/light-v10",
  },
  {
    key: "Streets",
    value: "mapbox://styles/mapbox/streets-v12",
  },
  {
    key: "Outdoors",
    value: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    key: "Satellite",
    value: "mapbox://styles/mapbox/satellite-v9",
  },
  {
    key: "Satellite Streets",
    value: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    key: "Navigation Preview Day",
    value: "mapbox://styles/mapbox/navigation-preview-day-v4",
  },
  {
    key: "Navigation Preview Night",
    value: "mapbox://styles/mapbox/navigation-preview-night-v4",
  },
  {
    key: "Navigation Guidance Day",
    value: "mapbox://styles/mapbox/navigation-guidance-day-v4",
  },
  {
    key: "Navigation Guidance Night",
    value: "mapbox://styles/mapbox/navigation-guidance-night-v4",
  },
]