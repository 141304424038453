import { useEffect, useState } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { sampleDrawSource } from '../constants/sampleSources.js';
import {
  MapSkySource,
  MapTerrainConfig,
  MapSkyConfig,
} from '../constants/MapSkyConfig.js';
import threeDBuildingsEffect from '../mapEffects/threeDBuildingsEffect.js';
import trafficToggleEffect from '../mapEffects/trafficToggleEffect.js';
import * as turf from '@turf/turf';
import { createLocationFromGeojson } from '../utils/createLocationFromGeojson.js';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const useMapInit = (params) => {
  const {
    map,
    mapboxgl,
    mapContainer,
    layers,
    setLayers,
    drawRef,
    mapSettings,
    mapHasLoaded,
    apiPrefix,
    setMapSettings,
    setMapHasLoaded,
    sources,
    setSources,
    updatedLayer,
    setUpdatedLayer,
  } = params;

  // State Declarations
  const [drawComplete, setDrawComplete] = useState(false);
  const [layerClicked, setLayerClicked] = useState();
  const [selectedLat, setSelectedLat] = useState(null);
  const [selectedLng, setSelectedLng] = useState(null);
  const [location, setLocation] = useState();

  const mapboxTokenActual =
    window.env.MAPBOX_ACCESS_TOKEN || process.env.MAPBOX_ACCESS_TOKEN || null;
  mapboxgl.accessToken = mapboxTokenActual || null;
  // functions
  const addSourceAndLayerToMap = (map, layer, sourceID, source) => {
    const newData = (!!source.data && source.data) || source._data;
    const newSource = {
      ...sampleDrawSource,
      id: sourceID,
      data: {
        ...sampleDrawSource.data,
        features: newData.features.map((f, i) => {
          return { ...f, id: i };
        }),
      },
    };
    delete newSource.id;

    // Remove and re-add source to accomidate changes (ie props updates)
    if (map.getSource(sourceID)) {
      map.removeSource(sourceID);
      map.addSource(sourceID, newSource);
    } else {
      map.addSource(sourceID, newSource);
    }
    map.addLayer(layer);

    map.on('mouseenter', layer.id, () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', layer.id, () => {
      map.getCanvas().style.cursor = 'grab';
    });

    map.on('click', layer.id, (e) =>
      setLayerClicked({ layer: layer, e: e, features: e.features })
    );
    map.on('touchend', layer.id, (e) =>
      setLayerClicked({ layer: layer, e: e, features: e.features })
    );
  };

  useEffect(() => {
    if (
      !!location &&
      !!location?.geojson?.data.features &&
      !!location?.geojson?.data.features.length
    ) {
      var box = turf.bbox(location.geojson.data.features[0].geometry);
      map?.current?.fitBounds(box, { padding: 50, maxZoom: 15 });
    }

    const lngLat = location?.centroid?.geometry?.coordinates;
    if (!!lngLat && lngLat?.length > 0) {
      setSelectedLat(lngLat[1]);
      setSelectedLng(lngLat[0]);
    }
    document.addEventListener('webkitfullscreenchange', () =>
      setTimeout(() => map.current.resize(), 0)
    );
    return () => {
      document.removeEventListener('webkitfullscreenchange', () =>
        setTimeout(() => map.current.resize(), 0)
      );
    };
  }, [location, map.current]);

  // Initialize Map
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapSettings.basemap,
      center: [mapSettings.lng, mapSettings.lat],
      zoom: mapSettings.zoom,
      projection: 'mercator',
      preserveDrawingBuffer: true,
      transformRequest: (url) => {
        if (url.startsWith(apiPrefix + '/tiles/')) {
          return {
            url: url,
            headers: {
              Authorization:
                'Bearer ' + process.env.REACT_APP_MAP_EVENT_TILES_TOKEN,
            },
          };
        }
      },
    });
    map.current.getCanvas().id = 'mapcanvas';
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
    map.current.addControl(geocoder);

    map.current.addControl(new mapboxgl.NavigationControl());
    //map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');
    //map.current.addControl(new mapboxgl.ScaleControl());

    map.current.addControl(
      new mapboxgl.AttributionControl({
        compact: true,
      })
    );

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 160, // Width of the scale bar in pixels
      unit: 'imperial', // Units for the scale ('imperial' for miles, 'metric' for kilometers)
    });
    map.current.addControl(scale, 'bottom-right');

    const Draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        direct_select: true,
        combine_features: false,
        uncombine_features: false,
        line_string: false,
        point: true,
        polygon: true,
        trash: true,
      },
    });

    drawRef.current = Draw;
    map.current.addControl(Draw, 'top-right');
    const controls = document.getElementsByClassName(
      'mapbox-gl-draw_ctrl-draw-btn'
    );
    controls[0].style.display = 'none';
    controls[1].style.display = 'none';
    controls[2].style.display = 'none';

    map.current.on('draw.create', (e) => {
      const feature = e.features[0];

      const newSource = {
        ...sampleDrawSource,
        data: {
          ...sampleDrawSource.data,
          features: [{ ...feature, id: 0 }],
        },
      };

      delete newSource.id;

      createLocationFromGeojson(
        feature,
        mapboxTokenActual,
        setLocation,
        setSelectedLat,
        setSelectedLng
      );
    });

    map.current.on('draw.update', noop);

    map.current.on('draw.delete', noop);

    map.current.on('draw.modechange', (e) => {
      if (e.mode === 'draw_polygon') {
        map.current.getCanvas().style.cursor = 'crosshair';
        Draw.deleteAll().changeMode('draw_polygon');
      }
      if (e.mode === 'draw_point') {
        map.current.getCanvas().style.cursor = 'crosshair';
      }
      if (e.mode === 'direct_select' || e.mode === 'simple_select') {
        map.current.getCanvas().style.cursor = 'grab';
        Draw.changeMode('simple_select');
      }
    });

    map.current.on('idle', noop);
  }, []);

  // Load map defaults once map has initialized
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      const _mapSettings = mapSettings;
      _mapSettings.lng = map.current.getCenter().lng.toFixed(4);
      _mapSettings.lat = map.current.getCenter().lat.toFixed(4);
      _mapSettings.zoom = map.current.getZoom().toFixed(2);
      setMapSettings(_mapSettings);
    });

    // Load extras
    map.current.on('style.load', () => {
      threeDBuildingsEffect(
        mapHasLoaded,
        map,
        mapSettings.threeDBuildingsToggle
      );
      trafficToggleEffect(map.current, mapSettings.trafficToggle);
      if (!map.current.getSource('mapbox-dem')) {
        map.current.addSource('mapbox-dem', MapSkySource);
        map.current.setTerrain(MapTerrainConfig);
        map.current.addLayer(MapSkyConfig(map.current));
      }
      setMapHasLoaded(true);
    });

    map.current.on('resize', noop);

    map.current.on('zoom', noop);

    map.current.on('click', noop);

    map.current.on('touchend', noop);
  }, [map.current, mapSettings, mapHasLoaded]);

  useEffect(() => {
    if (mapHasLoaded) {
      // First, remove all usermade sources and layers in map and not in layers hook
      const userMadeLayersInMap = map.current
        .getStyle()
        .layers.filter((l) => !!l.metadata && !!l.metadata.usermade);
      userMadeLayersInMap.forEach((ul) => {
        if (!layers.find((l) => l.id === ul.id)) {
          map.current.removeLayer(ul.id);
          map.current.removeSource(ul.source);
        }
      });

      // Second, add all usermade sources and layers not in userMadeLayersInMap
      layers.forEach((l) => {
        if (!userMadeLayersInMap.find((ul) => ul.id === l.id)) {
          let sourceToAdd = sources.find((s) => s.id === l.source);
          if (sourceToAdd) sourceToAdd = { ...sourceToAdd };
          if (sourceToAdd) {
            const sourceID = sourceToAdd.id;
            delete sourceToAdd.id;

            // Load icon image if not available
            if (
              l.type === 'symbol' &&
              !map.current.hasImage(l.layout['icon-image'])
            ) {
              if (!!l.metadata && !!l.metadata.icon_src) {
                // Add the icon image if the map hasn't seen it before (in this session)
                if (!map.current.hasImage(l.layout['icon-image'])) {
                  map.current.getCanvas().style.cursor = 'wait';
                  map.current.loadImage(l.metadata.icon_src, (error, image) => {
                    if (error) throw error;
                    map.current.addImage(l.layout['icon-image'], image);
                    addSourceAndLayerToMap(
                      map.current,
                      l,
                      sourceID,
                      sourceToAdd
                    );
                    map.current.getCanvas().style.cursor = 'crosshair';
                  });
                } else {
                  addSourceAndLayerToMap(map.current, l, sourceID, sourceToAdd);
                }
              }
            } else {
              addSourceAndLayerToMap(map.current, l, sourceID, sourceToAdd);
            }
          }
        }
      });

      // Last, if there is an updatedLayer, refresh it's place in the hooks and map state, then set updatedLayer to undefined
      if (updatedLayer) {
        if (map.current.getStyle().layers.find((l) => l.id === updatedLayer.id))
          map.current.removeLayer(updatedLayer.id);

        // Have to delete ID from source before adding in subsequent function, because of mapbox issues, but need to re-add to keep in array hook
        let sourceToRefresh = sources.find((s) => s.id === updatedLayer.source);
        sourceToRefresh = { ...sourceToRefresh };
        const sourceID = sourceToRefresh.id;
        delete sourceToRefresh.id;
        addSourceAndLayerToMap(
          map.current,
          updatedLayer,
          sourceID,
          sourceToRefresh
        );
        sourceToRefresh.id = sourceID;

        setSources([
          ...sources.filter((s) => s.id !== updatedLayer.source),
          { ...sourceToRefresh },
        ]);
        setLayers([
          ...layers.filter((l) => l.id !== updatedLayer.id),
          { ...updatedLayer },
        ]);
        setUpdatedLayer();
      }
    }
  }, [layers, sources, mapHasLoaded, updatedLayer, setLayers, setSources]);

  // Settings map
  useEffect(() => {
    if (map?.current) {
      threeDBuildingsEffect(
        mapHasLoaded,
        map,
        mapSettings?.threeDBuildingsToggle
      );
    }
  }, [map, mapHasLoaded, mapSettings?.threeDBuildingsToggle]);

  useEffect(() => {
    if (map?.current) {
      trafficToggleEffect(map.current, mapSettings?.trafficToggle);
    }
  }, [map, mapSettings?.trafficToggle]);

  // Base map
  useEffect(() => {
    if (!!mapSettings.basemap && !!mapHasLoaded) {
      const existingLayers = map.current.getStyle().layers;
      const existingSources = map.current.getStyle().sources;

      // Prevent accidental re-application of basemap, or else 3D terrain and sky gets removed accidentally
      if (
        map.current
          .getStyle()
          .sprite.replace('mapbox://sprites/mapbox/', '') !==
        mapSettings.basemap.replace('mapbox://styles/mapbox/', '')
      ) {
        map.current.setStyle(mapSettings.basemap);
      }

      map.current.once('style.load', function () {
        setTimeout(() => {
          for (const sourceId in existingSources) {
            if (!map.current.getSource(sourceId)) {
              map.current.addSource(sourceId, existingSources[sourceId]);
            }
          }

          // app layers are the layers to retain, and here satellite and composite are any layers which have a different source set,
          // so this should be removed from layer copy.
          existingLayers.forEach((layer) => {
            if (
              !map.current.getLayer(layer.id) &&
              layer.source &&
              layer.source != 'mapbox://mapbox.satellite' &&
              layer.source != 'mapbox' &&
              layer.source != 'composite'
            ) {
              map.current.addLayer(layer);
            }
          });

          // Restore 3d Terrain
          if (!map.current.getSource('mapbox-dem')) {
            map.current.addSource('mapbox-dem', MapSkySource);
          }
          map.current.setTerrain();
          map.current.setTerrain(MapTerrainConfig);
          if (map.current.getStyle().layers.find((l) => l.id === 'sky')) {
            map.current.removeLayer('sky');
            map.current.addLayer(MapSkyConfig(map.current));
          } else {
            map.current.addLayer(MapSkyConfig(map.current));
          }
        }, 100);
      });
    }
  }, [mapSettings.basemap, mapHasLoaded]);

  useEffect(() => {
    if (!!drawComplete && !!mapHasLoaded) {
      // Get user-made layers not already in Layers hook
      const usermadeLayers = map.current
        .getStyle()
        .layers.filter(
          (l) =>
            !!l.metadata &&
            !!l.metadata.usermade &&
            !layers.find((ll) => ll.id === l.id)
        );

      let newSources = [];
      let newLayers = [];
      usermadeLayers.forEach((l) => {
        const source = map.current.getSource(l.source);
        newSources = [...newSources, source];
        newLayers = [...newLayers, l];

        map.current.on('mouseenter', l.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.current.on('mouseleave', l.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });
      });

      setSources([...sources, ...newSources]);
      setLayers([...layers, ...newLayers]);
      setDrawComplete(false);
    }
  }, [drawComplete, mapHasLoaded]);

  return {
    location,
    setLocation,
    drawRef,
  };
};

export default useMapInit;
