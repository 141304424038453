import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import {
  addHospitalToMedicalPlan,
  deleteHospital,
  fetchHospitalList,
} from 'actions/IAPForm206Actions';
import { AddHospitalDialog } from './AddHospitalDialog';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { startLoading } from 'reducers/loading/loading.action';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const Label = ({ label, value, onEdit, onDelete }) => {
  return (
    <>
      {label}
      <div className="react-select__custom-label">
        <span
          className="ms-3 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        >
          <SharedIcon iconName="stylus" />
        </span>
        <span
          className="ms-3 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <SharedIcon iconName="delete" />
        </span>
      </div>
    </>
  );
};

export const AddHospitalToMedicalPlanDialog = ({
  show,
  onClose,
  medicalPlanId,
  existingHospitals,
  editData,
}) => {
  const reduxDispatch = useDispatch();

  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState([]);
  const [openAddNewHospitalModal, setOpenAddNewHospitalModal] = useState(false);

  const reduxHospitalsList = useSelector((state) => state.app.hospitalsList);

  const [hospitalDialogType, setHospitalDialogType] = useState(null);
  const [editHospitalData, setEditHospitalData] = useState(null);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteHospitalData, setDeleteHospitalData] = useState(null);

  useEffect(() => {
    reduxDispatch(fetchHospitalList());
  }, []);

  useEffect(() => {
    setHospitals(
      reduxHospitalsList
        .map((hosp) => ({
          value: hosp.id,
          label: (
            <Label
              label={hosp.name}
              value={hosp.id}
              onEdit={() => onEditHospital(hosp)}
              onDelete={() => onDeleteHospital(hosp.id)}
            />
          ),
        }))
        .filter(
          (hosp) =>
            !existingHospitals.includes(hosp.value) ||
            hosp.value === editData?.id
        )
    );
  }, [reduxHospitalsList]);

  const addhospitalDataToMedicalPlan = () => {
    reduxDispatch(
      addHospitalToMedicalPlan({
        id: editData?.mphId || generateUUID(),
        hospital_id: selectedHospital.value,
        medical_plan_id: medicalPlanId,
      })
    );
    onClose();
  };

  const refreshHospitalList = () => {
    setOpenAddNewHospitalModal(false);
  };

  const onEditHospital = (hospital) => {
    setHospitalDialogType('Edit');
    setEditHospitalData(hospital);
    setOpenAddNewHospitalModal(true);
  };

  const onDeleteHospital = (hospitalId) => {
    setDeleteHospitalData(hospitalId);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteHospital(deleteHospitalData));
    selectedHospital?.value === deleteHospitalData && setSelectedHospital([]);
    setDeleteHospitalData(null);
    setShowConfirmDialog(false);
  };

  useEffect(() => {
    setSelectedHospital(hospitals.find((hosp) => hosp.value === editData?.id));
  }, [hospitals]);

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Select Hospital</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StylishNewSelect
            options={hospitals}
            value={selectedHospital}
            placeholder="Select a Hospital"
            onChange={(e) => setSelectedHospital(e)}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--primary"
              onClick={addhospitalDataToMedicalPlan}
              disabled={!selectedHospital?.value}
            >
              Save
            </StylishNewButton>
            <StylishNewButton
              className="button--primary"
              onClick={() => {
                setEditHospitalData(null);
                setHospitalDialogType('New');
                setOpenAddNewHospitalModal(true);
              }}
            >
              New
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>

      {openAddNewHospitalModal && (
        <AddHospitalDialog
          show={openAddNewHospitalModal}
          onClose={() => setOpenAddNewHospitalModal(false)}
          refreshHospitalList={refreshHospitalList}
          hospitalDialogType={hospitalDialogType}
          editHospitalData={editHospitalData ? editHospitalData : {}}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this record?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteActionData()}
        />
      )}
    </>
  );
};
