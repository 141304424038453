import { Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEntityDrawerProps } from './EntityListProps';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import {
  selectGroupGuid,
  useAppSelector,
} from '../../slices/commonSelectors';
import { useCreateCustomResourceType } from './hooks/customResourceSiteTypeHooks';

type FormFields = {
  id: string;
  name: string;
  type: string;
  description?: string;
  group_guid: string;
  archived?: boolean;
};

const CustomResourceSiteTypesAddDrawer: React.FC<AddEntityDrawerProps> = ({ toggle }) => {
  const form = useForm<FormFields>({ mode: 'onChange' });
  const { handleSubmit } = form;

  const mutation = useCreateCustomResourceType();

  const group_guid = useAppSelector(selectGroupGuid);

  const onNewCustomResourceDataSubmit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      group_guid: group_guid,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Add Custom Resource Site Type">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewCustomResourceDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: { value: 255, message: 'The maximum length is 255 characters' },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                variant="full"
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default CustomResourceSiteTypesAddDrawer;
