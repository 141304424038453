import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import moment from 'moment';
import {
  addScheduleToIncident,
  deleteIncidentSchedule,
} from 'actions/incidentActions';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import StylishTimePicker from 'components/DesignSystems/form/StylishTimePicker';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { useForm, Controller } from 'react-hook-form';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { startLoading } from 'reducers/loading/loading.action';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

export default function AddScheduleActivationDialog({
  show,
  onClose,
  selectedIncidentMember,
  setSelectedIncidentMember,
  existingEvent,
  scheduleActivationType,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [allDayEvent, setAllDayEvent] = useState(false);
  const [isRecurringEvent, setIsRecurringEvent] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [daysOfWeekError, setDaysOfWeekError] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const dispatch = useDispatch();

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const isAllScheduleToIncidentLoaded = useSelector((state) => {
    return state.app.isAllScheduleToIncidentLoaded;
  });
  const isAddScheduleToIncidentLoaded = useSelector((state) => {
    return state.app.isAddScheduleToIncidentLoaded;
  });

  useEffect(() => {
    if (
      !isLoaded &&
      isAddScheduleToIncidentLoaded &&
      isAllScheduleToIncidentLoaded
    ) {
      onClose();
      setSelectedIncidentMember(undefined);
    }
  }, [
    isAddScheduleToIncidentLoaded,
    isAllScheduleToIncidentLoaded,
    isLoaded,
    onClose,
  ]);
  useEffect(() => {
    if (existingEvent) {
      if (existingEvent.extendedProps.isrecurringevent) {
        setValue(
          'startLog',
          moment(existingEvent.extendedProps.start_time).format(
            'MM/DD/YYYY HH:mm'
          )
        );
        setValue(
          'endLog',
          moment(existingEvent.extendedProps.end_time).format(
            'MM/DD/YYYY HH:mm'
          )
        );
        setValue(
          'recurringStartLog',
          existingEvent.extendedProps.recurringstarttime.trim()
        );
        setValue(
          'recurringEndLog',
          existingEvent.extendedProps.recurringendtime.trim()
        );
        setIsRecurringEvent(true);
        setDaysOfWeek(existingEvent.extendedProps.daysofweek);
      } else if (existingEvent.extendedProps.allday) {
        setAllDayEvent(existingEvent.extendedProps.allday);
        setValue(
          'startLog',
          moment(existingEvent.extendedProps.start_time).format(
            'MM/DD/YYYY HH:mm'
          )
        );
        setValue(
          'endLog',
          moment(existingEvent.extendedProps.end_time).format(
            'MM/DD/YYYY HH:mm'
          )
        );
      } else {
        setValue(
          'startLog',
          moment(existingEvent.extendedProps.start_time).format(
            'MM/DD/YYYY HH:mm'
          )
        );
        setValue(
          'endLog',
          moment(existingEvent.extendedProps.end_time).format(
            'MM/DD/YYYY HH:mm'
          )
        );
      }
    } else {
      setValue('startLog', moment().format('MM/DD/YYYY HH:mm'));
      setValue(
        'endLog',
        moment()
          .add(30, 'minutes')
          .set({ second: 0, microsecond: 0 })
          .format('MM/DD/YYYY HH:mm')
      );
    }
  }, [existingEvent]);

  const onRepeating = (e, val) => {
    e.preventDefault();
    let newListforDaysofWeeks = [];
    if (daysOfWeek.includes(val)) {
      const newDaysOfWeek = daysOfWeek.filter((e) => e !== val);
      newListforDaysofWeeks = newDaysOfWeek;
      setDaysOfWeek(newDaysOfWeek);
    } else {
      setDaysOfWeek([...daysOfWeek, val]);
      newListforDaysofWeeks = [...daysOfWeek, val];
    }
  };
  const onChangeEvents = (e) => {
    setAllDayEvent(false);
    setIsRecurringEvent(false);

    if (e === 'Recurring Event' && !isRecurringEvent) {
      setIsRecurringEvent(true);
      setValue('startLog', moment().format('MM/DD/YYYY'));
      setValue('endLog', moment().add(1, 'days').format('MM/DD/YYYY'));
      setValue('recurringStartLog', moment().format('HH:mm'));
      setValue('recurringEndLog', moment().add(30, 'minutes').format('HH:mm'));
    } else if (e === 'All Day Event' && !allDayEvent) {
      setAllDayEvent(true);
    } else {
      setValue('startLog', moment().format('MM/DD/YYYY HH:mm'));
      setValue(
        'endLog',
        moment().add(30, 'minutes').format('MM/DD/YYYY HH:mm')
      );
    }
  };

  const handleDeleteSchedule = () => {
    setShowConfirmDialog(true);
  };

  const onConfirmDeleteSchedule = () => {
    dispatch(startLoading());
    dispatch(
      deleteIncidentSchedule(
        existingEvent.id,
        existingEvent.extendedProps.user_guid,
        existingEvent.extendedProps.incident_member_id
      )
    );
    setShowConfirmDialog(false);
    onClose();
  };

  useEffect(() => {
    if (daysOfWeek.length) {
      setDaysOfWeekError(false);
    }
  }, [daysOfWeek]);

  useEffect(() => {
    if (!allDayEvent) {
      if (
        moment(getValues('startLog')).isAfter(moment(getValues('endLog'))) ||
        moment(getValues('startLog')).isSame(moment(getValues('endLog')))
      ) {
        setError('startLog', { type: 'custom' });
        setError('endLog', { type: 'custom' });
      } else {
        clearErrors('startLog');
        clearErrors('endLog');
      }
    } else {
      if (moment(getValues('startLog')).isAfter(moment(getValues('endLog')))) {
        setError('startLog', { type: 'custom' });
        setError('endLog', { type: 'custom' });
      } else {
        clearErrors('startLog');
        clearErrors('endLog');
      }
    }
  }, [watch('startLog'), watch('endLog'), allDayEvent]);

  const onScheduleActivationSubmit = (data) => {
    if (!allDayEvent) {
      if (
        moment(data.startLog).isAfter(moment(data.endLog)) ||
        moment(data.startLog).isSame(moment(data.endLog))
      ) {
        setError('startLog', { type: 'custom' });
        setError('endLog', { type: 'custom' });
        return;
      } else {
        clearErrors('startLog');
        clearErrors('endLog');
      }
    } else {
      if (moment(data.startLog).isAfter(moment(data.endLog))) {
        setError('startLog', { type: 'custom' });
        setError('endLog', { type: 'custom' });
        return;
      } else {
        clearErrors('startLog');
        clearErrors('endLog');
      }
    }

    if (!!data.recurringStartLog && !!data.recurringEndLog) {
      const startTime =
        data.recurringStartLog.split(':')[0] * 60 +
        data.recurringStartLog.split(':')[1];
      const endTime =
        data.recurringEndLog.split(':')[0] * 60 +
        data.recurringEndLog.split(':')[1];
      if (Number(startTime) > Number(endTime)) {
        setError('recurringStartLog', { type: 'custom' });
        setError('recurringEndLog', { type: 'custom' });
        return;
      } else {
        clearErrors('recurringStartLog');
        clearErrors('recurringEndLog');
      }
    }
    if (isRecurringEvent) {
      if (daysOfWeek.length) {
        setDaysOfWeekError(false);
      } else {
        setDaysOfWeekError(true);
        return;
      }
    }
    const addScheduleData = {
      id: existingEvent ? existingEvent.id : generateUUID(),
      allDay: allDayEvent,
      daysOfWeek: daysOfWeek,
      isRecurringEvent: isRecurringEvent,
      incident_member_id: selectedIncidentMember.incident_member_id,
      user_guid: selectedIncidentMember.user_guid,
      recurringStartTime: data.recurringStartLog,
      recurringEndTime: data.recurringEndLog,
    };
    if (allDayEvent || isRecurringEvent) {
      const startDatetime = moment(data.startLog).set({
        hour: 0,
        minute: 0,
        second: 0,
        microsecond: 0,
      });
      const endDatetime = moment(data.endLog).set({
        hour: 0,
        minute: 0,
        second: 0,
        microsecond: 0,
      });
      addScheduleData['start_time'] = startDatetime.toISOString();
      addScheduleData['end_time'] = endDatetime.toISOString();
    } else {
      addScheduleData['start_time'] = new Date(data.startLog);
      addScheduleData['end_time'] = new Date(data.endLog);
    }
    dispatch(startLoading());
    dispatch(addScheduleToIncident(addScheduleData));
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {scheduleActivationType} Schedule Activation
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onScheduleActivationSubmit)}>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Seat</label>
              <StylishNewInput
                readonly
                disabled
                value={selectedIncidentMember?.incident_seat}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Incident</label>
              <StylishNewInput
                readonly
                disabled
                value={reduxCurrentIncident?.name}
              />
            </div>
            <hr className="dashed" />
            {(!existingEvent ||
              existingEvent?.extendedProps.allday ||
              existingEvent?.extendedProps.isrecurringevent) && (
              <div className="form-checkbox-group mb-4">
                {(!existingEvent || existingEvent?.extendedProps.allday) && (
                  <Controller
                    control={control}
                    name={'all_day_event'}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewCheckbox
                        disabled={existingEvent?.extendedProps.allday}
                        checked={allDayEvent}
                        onClick={() => onChangeEvents('All Day Event')}
                        label={'All Day Event'}
                      />
                    )}
                  />
                )}
                {(!existingEvent ||
                  existingEvent?.extendedProps.isrecurringevent) && (
                  <Controller
                    control={control}
                    name={'recurring_event'}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewCheckbox
                        disabled={existingEvent?.extendedProps.isrecurringevent}
                        checked={isRecurringEvent}
                        onClick={() => onChangeEvents('Recurring Event')}
                        label={'Recurring Event'}
                      />
                    )}
                  />
                )}
              </div>
            )}
            <div className="mb-3">
              <label className="form-label">
                {allDayEvent
                  ? 'Start Date'
                  : isRecurringEvent
                  ? 'Recurring Event Start Date'
                  : 'Date / Time Start'}
              </label>
              <Controller
                control={control}
                name="startLog"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    value={value}
                    onChange={(e) => {
                      setValue(
                        'endLog',
                        moment(e)
                          .add(30, 'minutes')
                          .set({ second: 0, microsecond: 0 })
                          .format('MM/DD/YYYY HH:mm')
                      );
                      onChange(e);
                    }}
                    type={
                      allDayEvent || isRecurringEvent
                        ? 'date'
                        : 'datetime-local'
                    }
                    // minDate={existingEvent ? new Date(value) : new Date()}
                  />
                )}
              />
              {errors.startLog?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors.startLog?.type === 'custom' && (
                <span className="form-text form-error">
                  {allDayEvent || isRecurringEvent
                    ? 'Start Date Cannot Be Greater than End Date'
                    : 'Start Date/Time Cannot Be Greater than or Equal to End Date/Time'}
                </span>
              )}
            </div>
            <div className="mb-0">
              <label className="form-label">
                {allDayEvent
                  ? 'End Date'
                  : isRecurringEvent
                  ? 'Recurring Event End Date'
                  : 'Date / Time End'}
              </label>
              <Controller
                control={control}
                name="endLog"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    value={value}
                    onChange={(e) => onChange(e)}
                    type={
                      allDayEvent || isRecurringEvent
                        ? 'date'
                        : 'datetime-local'
                    }
                    minDate={new Date(watch('startLog'))}
                  />
                )}
              />
              {errors.endLog?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors.endLog?.type === 'custom' && (
                <span className="form-text form-error">
                  {allDayEvent || isRecurringEvent
                    ? 'End Date Cannot Be Less than Start Date'
                    : 'End Date/Time Cannot Be Less than or Equal to Start Date/Time'}
                </span>
              )}
            </div>
            {isRecurringEvent && (
              <>
                <div className="my-3">
                  <label className="form-label">Event Start Time</label>
                  <Controller
                    control={control}
                    name="recurringStartLog"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishTimePicker
                        value={value}
                        onChange={(e) => onChange(e)}
                        type="time"
                      />
                    )}
                  />
                  {errors.recurringStartLog?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                  {errors.recurringStartLog?.type === 'custom' && (
                    <span className="form-text form-error">
                      Event Start Time Cannot be Greater than Event End Time
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Event End Time</label>
                  <Controller
                    control={control}
                    name="recurringEndLog"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishTimePicker
                        value={value}
                        onChange={(e) => onChange(e)}
                        type="time"
                      />
                    )}
                  />
                  {errors.recurringEndLog?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                  {errors.recurringEndLog?.type === 'custom' && (
                    <span className="form-text form-error">
                      Event End Time Cannot be Less than Event Start Time
                    </span>
                  )}
                </div>
                <div className="m-0">
                  <label className="form-label m-0">Repeating Every</label>
                  <div className="button-group flex-wrap justify-content-center justify-content-md-start">
                    <StylishNewButton
                      type="button"
                      className="button--primary button--sml mt-3"
                      disabled={true}
                    >
                      Sun
                    </StylishNewButton>
                    <StylishNewButton
                      type="button"
                      className={`button--primary button--sml mt-3 ${
                        daysOfWeek.includes(1) ? '' : 'button--reverse'
                      }`}
                      onClick={(e) => onRepeating(e, 1)}
                    >
                      Mon
                    </StylishNewButton>
                    <StylishNewButton
                      type="button"
                      className={`button--primary button--sml mt-3 ${
                        daysOfWeek.includes(2) ? '' : 'button--reverse'
                      }`}
                      onClick={(e) => onRepeating(e, 2)}
                    >
                      Tue
                    </StylishNewButton>
                    <StylishNewButton
                      type="button"
                      className={`button--primary button--sml mt-3 ${
                        daysOfWeek.includes(3) ? '' : 'button--reverse'
                      }`}
                      onClick={(e) => onRepeating(e, 3)}
                    >
                      Wed
                    </StylishNewButton>
                    <StylishNewButton
                      type="button"
                      className={`button--primary button--sml mt-3 ${
                        daysOfWeek.includes(4) ? '' : 'button--reverse'
                      }`}
                      onClick={(e) => onRepeating(e, 4)}
                    >
                      Thu
                    </StylishNewButton>
                    <StylishNewButton
                      type="button"
                      className={`button--primary button--sml mt-3 ${
                        daysOfWeek.includes(5) ? '' : 'button--reverse'
                      }`}
                      onClick={(e) => onRepeating(e, 5)}
                    >
                      Fri
                    </StylishNewButton>
                    <StylishNewButton
                      type="button"
                      className="button--primary button--sml mt-3"
                      disabled={true}
                    >
                      Sat
                    </StylishNewButton>
                  </div>
                  {daysOfWeekError && (
                    <span className="form-text form-error mt-3">
                      Recurring Days is Required
                    </span>
                  )}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              {!existingEvent && (
                <StylishNewButton
                  className="button--secondary button--reverse"
                  type="button"
                  onClick={onClose}
                >
                  Close
                </StylishNewButton>
              )}
              <StylishNewButton type="submit" className="button--primary">
                Save
              </StylishNewButton>
              {!!existingEvent && (
                <StylishNewButton
                  type="button"
                  className="button--secondary button--reverse"
                  onClick={handleDeleteSchedule}
                >
                  Delete
                </StylishNewButton>
              )}
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this schedule?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmDeleteSchedule}
        />
      )}
    </>
  );
}
