import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminUser from './AdminUser';
import ProfileScreen from './ProfileScreen';
import { getAllUserWorkspace } from 'actions/profileActions';
import ManageUserSchedule from './ManageUserSchedule';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import SubscriptionStatusCard from 'components/SubscriptionManagement/SubscriptionStatusCard';
import StripeCheckout from 'components/SubscriptionManagement/StripeCheckout';
import { selectUser, useAppSelector } from '../../slices/commonSelectors';
import { useFocusedUser } from './useFocusedUser';
export default function ProfileContent() {
  const dispatch = useDispatch();

  const actualUser = useAppSelector(selectUser);
  const focusedUserQuery = useFocusedUser();
  const user = focusedUserQuery.data ?? actualUser;
  const userSortName =
    user?.user_name &&
    user?.user_name
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();

  const featureFlags = useSelector((state) => state.app.featureFlags);
  const [selectedTab, setSelectedTab] = useState('Manage Organizations');

  useEffect(() => {
    if (actualUser?.epic_user_id) {
      dispatch(getAllUserWorkspace(actualUser.epic_user_id));
    }
  }, [actualUser]);

  return (
    <>
      <div className="d-md-flex align-items-center mb-4 mb-md-5">
        <div className="d-md-flex align-items-center flex-grow-1 me-md-4">
          <div className="initials flex-shrink-0">
            <h1>{userSortName}</h1>
          </div>
          <div className="mt-3 mt-md-0 ms-md-4 flex-grow-1 overflow-hidden">
            <div>
              <h5 className="mb-1 text-truncate mt-0">{user?.user_name}</h5>
              <p className="m-0 text-truncate">{user?.email_address}</p>
            </div>
          </div>
              <SubscriptionStatusCard />
        </div>
      </div>

      {!!featureFlags.includes('STRIPE') && <StripeCheckout />}

      <div className="form-block mb-5">
        <ProfileScreen
          userId={user.user_guid}
          userEmail={user.email_address}
          userName={user.user_name}
          planId={user.plan_id}
          subscriptionId={user.subscription_id}
          subscriptionName={user.subscription_name}
          status={user.status}
          profileSettings={user.profile_settings}
        />
      </div>
      <div className="form-block">
        <div className="d-md-flex align-items-start mb-4">
          <div className="flex-grow-1 me-4">
            <h4 className="mb-1">User Sponsor Organization</h4>
            <p className="m-0">
              Note: Sponsor Organizations, Exercises, and Incidents that you are
              a part of will appear below
            </p>
          </div>
          <StylishNewToggleButtonGroup
            data={[
              { label: 'Manage Organizations', value: 'Manage Organizations' },
              { label: 'Manage Schedule', value: 'Manage Schedule' },
            ]}
            selected={selectedTab}
            onChange={(value) => setSelectedTab(value)}
            size={'sml'}
            className="d-md-inline-flex ms-auto mt-3 mt-md-0 flex-shrink-0 flex-column flex-md-row"
          />
        </div>
        {!focusedUserQuery.data && selectedTab === 'Manage Organizations' && (
          <>
            <AdminUser user={user} />
          </>
        )}
        {selectedTab === 'Manage Schedule' && (
          <ManageUserSchedule user={user} />
        )}
      </div>
    </>
  );
}
