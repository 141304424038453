import React from 'react';
import { useSelector } from 'react-redux';

export default function ICSIncidentStrategy({
  row,
  ics_strategies,
  ics_incident_strategies,
  ics_incident_objectives,
}) {
  // const versions = ics_incident_strategies.find(
  //   (is) => is.strategy_id === row.id
  // )?.versions;

  // const [version, setVersion] = useState(versions && versions[0]);
  //
  // const [name, setName] = useState(row.name);
  // const [description, setDescription] = useState(row.description);
  // const [archived, setArchived] = useState(row.archived);
  // const [lastModifiedBy, setLastModifiedBy] = useState(row.user_name);
  //
  // const [nameChanged, setNameChanged] = useState(false);
  // const [descriptionChanged, setDescriptionChanged] = useState(false);
  // const [archivedChanged, setArchivedChanged] = useState(false);
  // const [lastModifiedByChanged, setLastModifiedByChanged] = useState(false);

  // useEffect(() => {
  //   if (version) {
  //     setName(version.name);
  //     setDescription(version.description);
  //     setArchived(version.archived);
  //     setLastModifiedBy(version.user_name);
  //
  //     // Check if changed from prior
  //     const vi = versions.findIndex((v) => v.id === version.id);
  //     if (versions[vi + 1]) {
  //       setNameChanged(versions[vi + 1].name !== version.name);
  //       setDescriptionChanged(
  //         versions[vi + 1].description !== version.description
  //       );
  //       setArchivedChanged(versions[vi + 1].archived !== version.archived);
  //       setLastModifiedByChanged(
  //         versions[vi + 1].user_name !== version.user_name
  //       );
  //     } else {
  //       setNameChanged(false);
  //       setDescriptionChanged(false);
  //       setArchivedChanged(false);
  //       setLastModifiedByChanged(false);
  //     }
  //   } else {
  //     setName(row.name);
  //     setDescription(row.description);
  //     setNameChanged(false);
  //     setDescriptionChanged(false);
  //     setArchivedChanged(false);
  //     setLastModifiedByChanged(false);
  //   }
  // }, [version]);

  // useEffect(() => {
  //   if (!!versions) {
  //     setVersion(versions[0]);
  //   }
  // }, [versions]);

  const objectives = useSelector((state) => {
    return row?.objectives?.length > 0
      ? ics_incident_objectives.filter((io) => row.objectives.includes(io.objective_id))
      : [];
  });

  return (
    <div className="ICSIncidentStrategy">
      {/*{versions && (*/}
      {/*  <div className="mb-4">*/}
      {/*    <h6 className="mb-2">Version:</h6>*/}
      {/*    <StylishNewSelect*/}
      {/*      options={versions.map((v) => {*/}
      {/*        return {*/}
      {/*          label: dayjs(v.timestamp).format('YYYY-MM-DD HH:mm'),*/}
      {/*          value: v,*/}
      {/*        };*/}
      {/*      })}*/}
      {/*      defaultValue={version}*/}
      {/*      value={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}*/}
      {/*      onChange={(e) => {*/}
      {/*        setVersion(e.value);*/}
      {/*      }}*/}
      {/*      placeholder={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}*/}
      {/*      isClearable={true}*/}
      {/*      isSearchable={true}*/}
      {/*      isMulti={false}*/}
      {/*      isDisabled={false}*/}
      {/*      className="SitrepSection-Select"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="mb-4">
        <h6 className="mb-2">Name:</h6>
        <p className="m-0">{row.name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Description:</h6>
        <p className="m-0">{row.description}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Objectives:</h6>
        {objectives.map((ob) => (
          <p key={ob.id} className="m-0">
            {ob.name}
          </p>
        ))}
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p className="m-0">{row.user_name}</p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p className="m-0">{row.archived ? 'Yes' : 'No'}</p>
      </div>
    </div>
  );
}
