import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { createResourceSet, updateResourceSet } from 'slices/ics_resourceSetsSlice';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { ResourceTypes } from '../IncidentConfig/ResourceTypes';


import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';

import { resource_set_statuses } from 'components/ICS/ICSConstants';


export default function NewResourceSetDialog({
  show,
  setEditResourceSetData,
  editResourceSetData,
  modalType,
  setModalType,
  onClose,
  ics_resource_sites
}) {
  const mapboxToken = window.env.MAPBOX_ACCESS_TOKEN;
  const { handleSubmit, control, setValue, formState: { errors } } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [resourceSetId, setResourceSetId] = useState('');
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const [geolocation, setGeolocation] = useState()

  const [kind, setKind] = useState(''); // Add state for kind
  const [type, setType] = useState(''); // Add state for type

  const [resourceId, setResourceId] = useState('');
  const [resourceSiteId, setResourceSiteId] = useState('')


  const {user_guid} = useSelector((state) => {
    return state.app.user;
  });

  useEffect(() => {
    if (modalType === 'New') {
      setResourceSetId(generateUUID());
    } else if (modalType === 'Edit') {
      setResourceSetId(editResourceSetData?.resource_set_id);
      setResourceId(editResourceSetData?.resource_id);
      setResourceSiteId(editResourceSetData?.resource_site_id);
      setValue('quantity', editResourceSetData?.quantity);
      setValue('status', editResourceSetData?.status);
      setValue('name', editResourceSetData?.name);
      setValue('description', editResourceSetData?.description);
      setGeolocation(editResourceSetData?.geolocation)
      setKind(editResourceSetData?.kind)
      setType(editResourceSetData?.type)
    }
  }, [modalType]);

  const onNewResourceSetDataSubmit = (data) => {
    const payload = {
      id: resourceSetId,
      resource_set_id: resourceSetId,
      resource_id: resourceId,
      resource_site_id: resourceSiteId,
      quantity: data.quantity,
      status: data.status,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      user_guid,
      geolocation: geolocation,
      res_kind: kind, // Include kind in the payload
      type: type, // Include type in the payload
      name: data.name,
      description: data.description,
    };
    if (modalType === 'New') {
      reduxDispatch(createResourceSet(payload));
      onClose();
    } else if (modalType === 'Edit') {
      reduxDispatch(updateResourceSet(payload.id, payload));
      onClose();
    }
  };

  const resourceSiteOptions = ics_resource_sites
  .map((resource_site) => ({
    label: resource_site.name,
    value: resource_site.id,
  }))

  function resourceSelected(res)
  {
    const fullResource = ResourceTypes.find(r=>r.name===res.label)
    setKind(fullResource.name)
    setType(fullResource.Type)
  }

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Resource Set</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewResourceSetDataSubmit)}>
        <Modal.Body>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.name && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Resource:</label>
            <Controller
              control={control}
              name="type"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={ResourceTypes.map((type) => {
                    return { value: type.name, label: type.name };
                  })}
                  defaultValue={value}
                  value={value || ''}
                  onChange={(e) => {onChange(e);!!e && resourceSelected(e)}}
                  placeholder={'Please Select a Type'}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors?.type && (
              <span className="form-text form-error">This field is required</span>
            )}
            {!!kind && !!type && (
              <div>
                <div>
                  <label className="form-label">Resource Kind: {kind}</label>
                </div>
                <div>
                  <label className="form-label">Resource Type: {type}</label>
                </div>
              </div>
            )}
          </div>

          <div className="mb-3">
            <label className="form-label">Resource Site:</label>
              <Controller
                control={control}
                name="resourceSiteId"
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={resourceSiteOptions}
                    defaultValue={ics_resource_sites.find((resource_site) => resource_site.id === resourceSiteId)}
                    onChange={(e) => {
                      onChange(e.value);
                      setResourceSiteId(e.value);
                    }}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    className="SitrepSection-Select"
                  />
                )}
              />
          </div>
          <div className="m-0">
            <label className="form-label">Quantity:</label>
            <Controller
              control={control}
              name="quantity"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                />
              )}
            />
            {errors.quantity && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Status:</label>
            <Controller
              control={control}
              name="status"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={resource_set_statuses}
                  value={resource_set_statuses.find(
                    (option) => option.value === value
                  )}
                  onChange={(e) => onChange(e.value)}
                  className="checklist-select"
                />
              )}
            />
            {errors.description && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Location:</label>
            <GeoLocationComponent
              location={geolocation}
              setLocation={setGeolocation}
              mapboxToken={mapboxToken}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton className="button--secondary button--reverse" type="button" onClick={onClose}>
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
