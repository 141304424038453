import { useActualIncidentId, useGroupGuid } from './commonHooks';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';

export type Incident = {
  id: UUID;
  name: string;
  archived: number;
  settings?: Record<string, any>;
  current_period?: UUID;
  operational_period_interval: number;

  active?: boolean;

  severity?: 'Low' | 'Medium' | 'High';
  type?: string; // Medical, Fire, Earthquake, etc
  incident_types: string[]; // [Medical, Fire, Earthquake], etc

  teams_id?: UUID;
  sharepoint_location?: string;
  channel_id?: string;
  tenant_id?: UUID;

  status: 'unstarted' | 'inprogress' | 'done';
};

export const keys = {
  base: 'incident',
  get: (incidentId: UUID) => [keys.base, incidentId],
  all: (group_guid: UUID) => [keys.base, 'all', group_guid],
  periods: (incidentId: UUID) => [keys.base, 'periods', incidentId],
};

export const useIncident = () => {
  const incidentId = useActualIncidentId();
  return useQuery({
    queryKey: keys.get(incidentId),
    queryFn: async () => {
      const resp = await axios.get<Incident>(
        `${config.apiGateway.ics_incident}/${incidentId}`
      );
      return resp.data;
    },
  });
};

export const useAllIncidents = () => {
  const group_guid = useGroupGuid();
  return useQuery({
    queryKey: keys.all(group_guid),
    queryFn: async () => {
      const resp = await axios.get<Incident[]>(
        `${config.apiGateway.ics_incidents}/${group_guid}`
      );
      return resp.data;
    },
  });
};

type OperationalPeriod = {
  id: UUID;
  incident_id: UUID;
  start_date_time: Date;
  end_date_time: Date;
};

export const usePeriods = () => {
  const incidentId = useActualIncidentId();
  return useQuery({
    queryKey: keys.periods(incidentId),
    queryFn: async () => {
      const resp = await axios.get<OperationalPeriod[]>(
        `${config.apiGateway.ics_periods}/${incidentId}`
      );
      return resp.data;
    },
  });
};
