import config from 'constants/apiConfig';
import axios from 'axios';

import {
  TRACK_USER_LOGIN_REQUEST,
  TRACK_USER_LOGIN_SUCCESS,
} from 'constants/tracking-action-types';

export const trackUserLoginRequest = () => {
  return {
    type: TRACK_USER_LOGIN_REQUEST,
  };
};

export const trackUserLoginSuccess = () => {
  return {
    type: TRACK_USER_LOGIN_SUCCESS,
  };
};

export const trackUserLogin = () => {
  return (dispatch) => {
    dispatch(trackUserLoginRequest());
    return axios(config.apiGateway.trackUserLogin, {
      method: 'POST',
    })
      .then((response) => {
        dispatch(trackUserLoginSuccess(response));
        // toast.success('Track User Login successful', {
        //   autoClose: 2000,
        // });
        return response;
      })
      .catch((error) => {
        // dispatch(trackUserLoginError(error))
      });
  };
};
