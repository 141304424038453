import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewAction,
  updateActionDataToGroup,
} from 'actions/IAPForm201Actions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { startLoading } from 'reducers/loading/loading.action';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';

export default function NewActionDialog({
  show,
  onClose,
  actionModalType,
  setActionModalType,
  updateActionData,
  setUpdateActionData,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [actionType, setActionType] = useState('Planned Action');
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const [availableObjectives, setAvailableObjectives] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [availableResources, setAvailableResources] = useState([]);
  const [prevObjective, setPrevObjective] = useState([]);
  const [prevResource, setPrevResource] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Planned Action');
  const reduxDispatch = useDispatch();
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const isActionLoaded = useSelector((state) => {
    return state.app.isActionLoaded;
  });
  const isAddActionLoaded = useSelector((state) => {
    return state.app.isAddActionLoaded;
  });

  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );
  const incidentResources = useSelector(
    (state) => state.app.incidentResources
  );

  useEffect(() => {
    if (!isLoaded && isActionLoaded && isAddActionLoaded) {
      onClose();
      setActionModalType('New');
      setUpdateActionData(undefined);
    }
  }, [isLoaded, onClose, isActionLoaded, isAddActionLoaded]);

  useEffect(() => {
    if (actionModalType === 'Edit' && updateActionData) {
      setValue('description', updateActionData.description);
      setValue('location', updateActionData.required_location);
      setValue('arrivalTime', updateActionData.required_date_time);
      setSelectedTab(updateActionData.current);

      setSelectedObjectives(
        updateActionData.objectives.map((objective) => {
          return {
            ...objective,
            objective_id: objective.id?.substr(0, 4),
          };
        })
      );

      setPrevObjective(
        updateActionData.objectives
          ? updateActionData.objectives.map((objective) => objective.id)
          : []
      );

      setPrevResource(
        updateActionData.resources
          ? updateActionData.resources.map((resource) => resource.id)
          : []
      );

      setSelectedResources(
        updateActionData.resources.map((resource) => {
          return {
            ...resource,
            datetime_ordered: resource.datetime_ordered
              ? moment(resource.datetime_ordered).toISOString()
              : resource.datetime_ordered,
            eta: resource.eta
              ? moment(resource.eta).toISOString()
              : resource.eta,
          };
        })
      );
    }
  }, [actionModalType, updateActionData]);

  const onDeleteObjectiveToIncident = (row) => {
    setSelectedObjectives(
      selectedObjectives.filter((objective) => objective.id !== row.id)
    );
  };

  const onDeleteResourceToIncident = (row) => {
    setSelectedResources(
      selectedResources.filter((resource) => resource.id !== row.id)
    );
  };

  const handleSelectObjective = (objective) => {
    setSelectedObjectives([
      ...selectedObjectives,
      {
        ...objective.value,
        id: objective.value.objective_id,
        objective_id: objective.value.objective_id.substr(0, 4),
        name: null,
      },
    ]);
  };

  useEffect(() => {
    setAvailableObjectives(
      incidentObjectives
        .filter(
          (obj) =>
            !selectedObjectives.find(
              (selected) => selected.id === obj.objective_id
            )
        )
        .map((obj) => ({
          label: obj.description,
          value: obj,
        }))
    );
  }, [selectedObjectives, incidentObjectives]);

  const handleSelectResource = (resource) => {
    setSelectedResources([...selectedResources, resource.value]);
  };

  useEffect(() => {
    setAvailableResources(
      incidentResources
        .filter(
          (resource) =>
            !selectedResources.find((selected) => selected.id === resource.id)
        )
        .map((resource) => ({
          label: `${resource.type}, ${resource.name}`,
          value: resource,
        }))
    );
  }, [selectedResources, incidentResources]);

  const toggleData = [
    { label: 'Current Action', value: 'Current Action' },
    { label: 'Planned Action', value: 'Planned Action' },
  ];

  const handleToggleButtonClick = (value) => {
    setSelectedTab(value);
  };

  const onActionDataSubmit = (data) => {
    const current_location_geom =
      !!geoLocation &&
      !!geoLocation.geojson &&
      geoLocation.geojson.data.features[0].geometry;
    if (actionModalType === 'New') {
      const action = {
        id: generateUUID(),
        description: data.description,
        current: selectedTab === 'Current Action' ? true : false,
        is_action_201: true,
        required_date_time: data.arrivalTime,
        required_location: data.location,
        required_geo_location: geoLocation,
        required_location_geom: current_location_geom,
        selectedObjectives: selectedObjectives.map((objective) => objective.id),
        selectedResources: selectedResources.map((resource) => ({
          id: resource.id,
          required_amount: 1,
        })),
        incident_id: reduxCurrentIncident.id,
      };
      reduxDispatch(startLoading());
      reduxDispatch(addNewAction(action));
      onClose();
    } else {
      const action = {
        id: updateActionData?.action_id || generateUUID(),
        description: data.description,
        current: selectedTab === 'Current Action' ? true : false,
        is_action_201: true,
        required_date_time: data.arrivalTime,
        required_location: data.location,
        required_geo_location: geoLocation,
        required_location_geom: current_location_geom,
        addedObjectives: selectedObjectives
          .filter((objective) => !prevObjective.includes(objective.id))
          .map((objective) => objective.id),
        addedResources: selectedResources
          .filter((resource) => !prevResource.includes(resource.id))
          .map((resource) => ({ id: resource.id, required_amount: 1 })),
        removedObjectives: prevObjective.filter((prevObjective) => {
          const objective = selectedObjectives.findIndex(
            (objective) => objective.id === prevObjective
          );
          if (objective === -1) {
            return true;
          } else {
            return false;
          }
        }),
        removedResources: prevResource.filter((prevResource) => {
          const resource = selectedResources.findIndex(
            (resource) => resource.id === prevResource
          );
          if (resource === -1) {
            return true;
          } else {
            return false;
          }
        }),
        incident_id: reduxCurrentIncident.id,
      };
      reduxDispatch(startLoading());
      reduxDispatch(updateActionDataToGroup(action));
      onClose();
    }
  };

  const [geoLocation, setGeoLocation] = useState(
    updateActionData?.required_geo_location
  );

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Action / Key Decision</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label className="form-label">Description</label>
          <Controller
            control={control}
            name="description"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishNewTextArea
                onChange={(e) => onChange(e.target.value)}
                value={value || ''}
              />
            )}
          />
          {errors.description && (
            <span className="form-text form-error">
              This is a required field
            </span>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">
            Objective(s) attached to this action
          </label>
          {availableObjectives.length ? (
            <StylishNewSelect
              options={availableObjectives}
              onChange={(e) => handleSelectObjective(e)}
              value={''}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
              isDisabled={false}
              placeholder="Choose a Objective"
            />
          ) : null}
          {!!selectedObjectives && selectedObjectives.length > 0 && (
            <div
              className={`form-chip-group ${
                availableObjectives.length ? 'mt-3' : 'mt-0'
              }`}
            >
              {selectedObjectives.map((objective) => (
                <StylishNewChip
                  key={objective.objective_id}
                  label={objective.description}
                  handleDelete={() => onDeleteObjectiveToIncident(objective)}
                />
              ))}
            </div>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">
            Resource(s) attached to this action
          </label>
          {availableResources.length ? (
            <StylishNewSelect
              options={availableResources}
              onChange={(e) => handleSelectResource(e)}
              value={''}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
              isDisabled={false}
              placeholder="Choose a Resource"
            />
          ) : null}
          {!!selectedResources && selectedResources.length > 0 && (
            <div
              className={`form-chip-group ${
                availableResources.length ? 'mt-3' : 'mt-0'
              }`}
            >
              {selectedResources.map((resource) => (
                <StylishNewChip
                  key={resource.id}
                  label={`${resource.type}, ${resource.name}`}
                  handleDelete={() => onDeleteResourceToIncident(resource)}
                />
              ))}
            </div>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Required Location</label>
          <GeoLocationComponent
            location={geoLocation}
            setLocation={setGeoLocation}
            mapboxToken={
               window.env.MAPBOX_ACCESS_TOKEN
            }
          />
        </div>
        <div className="mb-3">
          <label className="form-label d-block">Action Type</label>
          <StylishNewToggleButtonGroup
            className="d-md-inline-flex"
            data={toggleData}
            selected={selectedTab}
            onChange={handleToggleButtonClick}
          />
        </div>
        <div className="m-0">
          <label className="form-label">Required Time of Arrival</label>
          <Controller
            control={control}
            name="arrivalTime"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishDateTimePicker
                value={value}
                onChange={(e) => onChange(e)}
                type="datetime-local"
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
              />
            )}
          />
          {errors.arrivalTime?.type === 'required' && (
            <span className="form-text form-error">
              This fields (Date &amp; Time) is required
            </span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
          <StylishNewButton
            type="submit"
            className="button--primary"
            onClick={handleSubmit(onActionDataSubmit)}
          >
            Submit
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
