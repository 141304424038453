import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import axios from 'axios';
import {
  selectGroup,
  selectUser,
  useAppSelector,
} from '../../slices/commonSelectors';
import apiConfig from '../../constants/apiConfig';
import StylishNewSelect from '../DesignSystems/New/StylishNewSelect';
import { useIsDev } from '../../hooks/useIsDev';
import SubscriptionStatusCard from 'components/SubscriptionManagement/SubscriptionStatusCard';
import { useRosters } from '../../hooks/useRosters';
import { statusDisplayOptions, statusOptions, getDisplayStatus } from 'utils/subscriptionStatus';

const OrgSubscription: React.FC = () => {
  const isDev = useIsDev();
  const selectedGroup = useAppSelector(selectGroup);
  const user = useAppSelector(selectUser);

  const { data: roster, isLoading: rosterLoading } = useRosters(
    selectedGroup ? [selectedGroup.group_guid] : []
  );

  const groupSubscription = user.subscriptions?.find(
    (s: Subscription) => s.group_guid === selectedGroup.group_guid
  );

  const currentUserAndGroupRosterItem = roster?.find(
    (r) =>
      r.user_guid === user.user_guid &&
      r.group_guid === selectedGroup.group_guid
  );

  const isGroupAdmin =
    !!currentUserAndGroupRosterItem?.role_assignments?.find(
      (ra) => ra.name === 'Group Admin'
    ) || false;

  const [subscriptionStatus, setSubscriptionStatus] = useState(
    groupSubscription?.status ?? statusOptions[0]
  );

  const createTrialSubscription = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        apiConfig.apiGateway.createOrgSubscription,
        {
          group_guid: selectedGroup.group_guid,
          details: {
            subscription_type: 'defaults to trial subscription',
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      window.location.reload();
    },
  });

  const updateSubscription = useMutation({
    mutationFn: async (status: string) => {
      const response = await axios.post(
        apiConfig.apiGateway.updateOrgSubscription,
        {
          group_guid: selectedGroup.group_guid,
          status,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      window.location.reload();
    },
  });

  return (
    <>
      <div className="nav-content-header">Subscription Information</div>
      <div className="card mt-2">
        <div className="d-flex flex-md-row flex-column gap-3 w-100">
          <div className="d-flex flex-column">
            <div className={'alert'}>
              <strong>Current subscription</strong>
              {groupSubscription ? (
                <div>
                  <span>{groupSubscription.group_name}:</span>
                  <span className={'ps-2 text-muted'}>
                    {getDisplayStatus(groupSubscription.status)}
                  </span>
                  {isGroupAdmin && !rosterLoading && (
                    <div>
                      <span>Total Seats: </span>
                      <span className="text-muted">
                        {groupSubscription?.quantity || 'Not specified'}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <p className="mt-2">
                  No subscription found for this organization
                </p>
              )}
            </div>
            {groupSubscription && isDev ? (
              <>
                <StylishNewSelect
                  classes={'secondary'}
                  options={statusDisplayOptions}
                  value={statusDisplayOptions.find(
                    (opt) => opt.value === subscriptionStatus
                  )}
                  placeholder="Select a Status"
                  onChange={(e) => setSubscriptionStatus(e.value)}
                />

                <StylishNewButton
                  className={'button--secondary mt-3'}
                  onClick={() => updateSubscription.mutate(subscriptionStatus)}
                >
                  Save (reloads page)
                </StylishNewButton>
              </>
            ) : null}

            {!groupSubscription ? (
              <StylishNewButton
                className={'button--secondary'}
                onClick={() => createTrialSubscription.mutate()}
              >
                Create Trial Subscription (reloads page)
              </StylishNewButton>
            ) : null}
          </div>
        </div>
      </div>
      {user.subscription && <SubscriptionStatusCard />}
    </>
  );
};

export default OrgSubscription;
