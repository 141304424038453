import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import ReactPaginate from 'react-paginate';
import cellEditFactory from 'react-bootstrap-table2-editor';

export default function StylishNewTable({
  classes,
  headerClasses,
  columns,
  rows,
  keyField,
  selectRow,
  expandRow,
  pagination,
  page,
  perPage,
  total,
  onPageChange,
  noDataIndication,
  filter,
  hasPagination,
  paginationPerPageItem,
  cellEditCallback,
  rowClasses,
}) {
  const options = {
    sizePerPage: paginationPerPageItem,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
  };
  return (
    <>
      <BootstrapTable
        classes={classes}
        headerClasses={headerClasses}
        keyField={keyField}
        columns={columns}
        data={rows}
        expandRow={expandRow}
        selectRow={selectRow}
        bordered={false}
        striped={false}
        noDataIndication={
          typeof noDataIndication === 'string' ? (
            <div className="weight-600 text-center">
              {noDataIndication ? noDataIndication : 'No Items found'}
            </div>
          ) : (
            noDataIndication
          )
        }
        filter={filter}
        pagination={
          !!rows.length && hasPagination
            ? paginationFactory(options)
            : undefined
        }
        paginationPerPageItem={paginationPerPageItem}
        cellEdit={
          cellEditCallback
            ? cellEditFactory({
                mode: 'click',
                afterSaveCell: (oldValue, newValue, row, column) => {
                  cellEditCallback(oldValue, newValue, row, column);
                },
                blurToSave: true,
              })
            : undefined
        }
        rowClasses={rowClasses}
      />
      {pagination && (
        <div className="d-flex justify-content-center justify-content-md-end mt-4">
          <ReactPaginate
            className="pagination"
            breakLabel="..."
            nextLabel="Next"
            onPageChange={({ selected }) => onPageChange(selected + 1)}
            pageRangeDisplayed={3}
            pageCount={Math.ceil(total / perPage)}
            previousLabel="Prev"
            forcePage={page - 1}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
}
