import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSResourceRequestDialog from 'components/ICS/NewICSResourceRequestDialog';

import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

import ICSIncidentResourceRequest from './ICSIncidentResourceRequest';

import {
  updateIcsResourceRequest,
} from 'slices/ics_resourceRequestsSlice';

// This whole file is largely unused. The following functions are were undefined. Creating stubs so we can enable linting
// without worrying about deleting more unused stuff.
// eslint-disable-next-line @typescript-eslint/no-empty-function
const updateIcsIncidentResourceRequest = (incident_id, id, payload) => {};
// eslint-disable-next-line @typescript-eslint/no-empty-function
const createIcsIncidentResourceRequest = (incident_id, incidentResourceRequestData) => {};

export default function ICSResourceRequestsDialog({
  show,
  onClose,
  ics_resources,
  ics_resource_requests,
  ics_resource_request_items,
  ics_incident_resource_requests,
  incident_id = null,
  ics_resource_sites
}) {
  const reduxDispatch = useDispatch();

  const [isNewResourceRequestDialogOpen, setIsNewResourceRequestDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editResourceRequestData, setEditResourceRequestData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogResourceRequests, setDialogResourceRequests] = useState([
    ...ics_incident_resource_requests,
  ]);

  useEffect(() => {
    setDialogResourceRequests(ics_incident_resource_requests);
  }, [ics_incident_resource_requests]);

  useEffect(() => {
    // Use to rerender
  }, [ics_resource_requests]);

  const allColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'status',
      text: 'status',
      sort: true,
      attrs: { title: 'status' },
    },
    {
      dataField: 'timestamp',
      text: 'Timestamp',
      sort: true,
      attrs: { title: 'Timestamp' },
    },
    {
      dataField: 'archived',
      text: 'Archived',
      sort: true,
      attrs: { title: 'Archived' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentResourceRequest
            row={row}
            ics_resource_requests={ics_resource_requests}
            ics_incident_resource_requests={ics_incident_resource_requests}
          />
          {(!incident_id ||
            !!ics_incident_resource_requests.find(
              (ir) => ir.resource_request_id === row.id
            )) && (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onArchive(row)}
              >
                {row.archived ? `Unarchive` : `Archive`}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_resource_requests
      .filter((r) => {
        const incResReq = ics_incident_resource_requests.find(
          (ir) => ir.resource_request_id === r.id && !ir.archived
        );
        return !!incResReq;
      })
      .map((r) => r.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentResourceRequest = ics_incident_resource_requests.find(
        (ir) => ir.resource_request_id === row.id
      );
      if (existingIncidentResourceRequest) {
        reduxDispatch(
          updateIcsIncidentResourceRequest(incident_id, existingIncidentResourceRequest.id, {
            ...existingIncidentResourceRequest,
            archived: !existingIncidentResourceRequest.archived,
          })
        );
      } else {
        // Create a new incident resource request
        const incidentResourceRequestData = {
          resource_request_id: row.id,
          name: row.name,
          description: row.description,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          archived: false,
        };
        reduxDispatch(
          createIcsIncidentResourceRequest(incident_id, incidentResourceRequestData)
        );
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogResourceRequests(rows) : setDialogResourceRequests([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewResourceRequestDialogOpen(true);
    setEditResourceRequestData({
      ...row,
      versions: ics_incident_resource_requests.find((ir) => ir.resource_request_id === row.id)
        ?.versions,
    });
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (incident_id) {
      reduxDispatch(
        updateIcsIncidentResourceRequest(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsResourceRequest(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewResourceRequest = () => {
    setModalType('New');
    setIsNewResourceRequestDialogOpen(true);
  };

  const tableRows = ics_resource_requests.map((r) => {
    const incResReq = ics_incident_resource_requests.find((ir) => ir.resource_request_id === r.id);
    if (incResReq) {
      return {
        ...incResReq.versions[0],
        id: r.id,
      };
    } else {
      return r;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Resource Requests</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewResourceRequest}
        >
          New Resource Request
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewResourceRequestDialogOpen && (
        <NewICSResourceRequestDialog
          show={isNewResourceRequestDialogOpen}
          setEditResourceRequestData={setEditResourceRequestData}
          setModalType={setModalType}
          editResourceRequestData={editResourceRequestData}
          modalType={modalType}
          onClose={() => setIsNewResourceRequestDialogOpen(false)}
          incident_id={incident_id}
          ics_resources={ics_resources}
          ics_resource_request_items={ics_resource_request_items}
          ics_resource_sites={ics_resource_sites}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Resource Request?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
