import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toastConfig } from 'assets/data/config';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { sanitizeHtml } from '../../utils/sanitizeHtml';

export default function PrepareLog() {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const user = useSelector((state) => state.prepare.user);
  const lastWorkspaceId = useSelector(
    (state) => state.app.user.epic_user_lastWorkspaceId
  );

  const [prepareActivityLogs, setPrepareActivityLogs] = useState([]);
  const [nonExpandableRows, setNonExpandableRows] = useState([]);

  const activityLogList = () => {
    reduxDispatch(startLoading());
    try {
      Network
        .get(
          API.allActivityLogList, {workspaceId:
            selectedWorkspace || lastWorkspaceId
          }
        )
        .then((resp) => {
          setPrepareActivityLogs(
            resp.data.response.dataset.map((element) => {
              return {
                ...element,
                injectname:
                  (element.number ? `#${element.number} - ` : '') +
                  (element.injectname || ''),

                createdAt: moment(element.createdAt).fromNow(),
              };
            })
          );
        })

        .catch(console.log)
        .finally(() => {
          reduxDispatch(endLoading());
        });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };

  const dataColPrepare = [
    {
      dataField: 'username',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'User' },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="me-2 flex-shrink-0 button--icon primary rounded-circle p-0">
              <span className="weight-600 txt--sml">
                {row.username?.charAt(0)?.toUpperCase()}
              </span>
            </div>
            <p className="mb-0">{row.username}</p>
          </div>
        );
      },
    },

    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
      formatter: (cell, row) => {
        return (
          <>
            {row.type === 'updatePlanInject'
              ? 'Update Plan Inject'
              : row.type === 'comment'
              ? 'Comment'
              : null}
          </>
        );
      },
    },
    {
      dataField: 'injectname',
      text: 'Inject Name',
      sort: true,
      attrs: { title: 'InjectName' },
      formatter: (cell, row) => {
        return (
          <>
            <a
              className="cursor-pointer"
              onClick={() => {
                navigate(
                  `${PreparePath}/dashboard/plan/prepare/msel-view/${row.injectId}`,
                  {
                    replace: true,
                  }
                );
              }}
            >
              {row.injectname}
            </a>
          </>
        );
      },
    },

    {
      dataField: 'createdAt',
      text: 'Time',
      sort: true,
      attrs: { title: 'Time' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      const clean = sanitizeHtml(row.content);
      return (
        <>
          <div className="row">
            <label className="form-label d-block">Comment</label>
            <div
              className="m-0"
              dangerouslySetInnerHTML={{ __html: clean }}
            ></div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    nonExpandable: prepareActivityLogs
      ?.filter((item) => item.type !== 'comment')
      ?.map((item) => item.logid),
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded, expandable }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      if (expandable) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </>
        );
      }
    },
  };

  useEffect(() => {
    activityLogList();
  }, []);

  return (
    <>
      <StylishNewTable
        keyField={'logid'}
        columns={dataColPrepare}
        rows={prepareActivityLogs}
        expandRow={expandRow}
        hasPagination={true}
        paginationPerPageItem="10"
      />
    </>
  );
}
