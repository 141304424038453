import React from 'react';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton.js';



import './ModelLayerPopup.css';

export default function ModelLayerPopup({
  layer,
  clickPopup,
  e,
  feature,
  setBottomPanelActive,
  eriScenario,
  eriScenarios,
  selectedDatetime
}) {

  const title = `${
    (feature.properties.political_boundaries_type === 'county' &&
      `${feature.properties.county_name} County, ${feature.properties.state_name}`) ||
    (feature.properties.political_boundaries_type === 'state' &&
      feature.properties.state_name)
  }`;

  function forecastClicked()
  {
    setBottomPanelActive({ layer: layer, feature: feature, isERI: true })
    clickPopup.remove()
  }

  return (
    <div className="Map-ModelLayerPopup">
      <h6 className="m-0">{layer.metadata.model_details.model_name}</h6>
      <h6 className="m-0">{title}</h6>

      <hr className="dashed thin my-1" />

      <div className="Map-ModelLayerPopup-Data">
        <span className="Map-ModelLayerPopup-Model-Key">ERI {eriScenarios.find(s=>s.value===eriScenario).label} (1-5):</span>
        <span className="Map-ModelLayerPopup-Model-Value">
          {` ${feature.properties[eriScenario]}`}
        </span>
        <div>
          <strong>{selectedDatetime.format('MMMM D, YYYY, hh:mm a')}</strong>
        </div>
        <div className="ModelLayerPopup-Button-Wrap">
          <StylishNewButton
            className={`button--primary ModelLayerPopup-Button`}
            onClick={() => forecastClicked()}
          >
            {layer.metadata.model_details.model_code} Forecast
          </StylishNewButton>
        </div>
      </div>

      <hr className="thin my-1" />
    </div>
  );
}
