import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';

import generateUUID from 'utils/sharedUtils/generateUUID';

import { CoreComponentsToSingular } from './constants/CoreComponentsToSingular';

import { upsertCoreComponent } from 'actions/ai2fpActions';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import { defaultRiskLevels } from 'components/MapComponent/constants/defaultRiskLevels';

import { fetchNestedCategories } from 'actions/locationAction';


export default function CoreComponentCreationDialog({
  show,
  onClose,
  ccType,
  existingCC = null,
}) {
  const mapboxToken = window.env.MAPBOX_ACCESS_TOKEN;

  const reduxDispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(true);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const isFetchCoreComponentsLoaded = useSelector((state) => {
    return state.app.isFetchCoreComponentsLoaded;
  });

  const isUpsertCoreComponentLoaded = useSelector((state) => {
    return state.app.isUpsertCoreComponentLoaded;
  });

  const [name, setName] = useState(
    (!!existingCC && !!existingCC.name && existingCC.name) || ''
  );
  const [description, setDescription] = useState(
    (!!existingCC && !!existingCC.description && existingCC.description) || ''
  );
  const [location, setLocation] = useState(
    !!existingCC && !!existingCC.location && existingCC.location
  );

  const [HVAID, setHVAID] = useState(
    !!existingCC && !!existingCC.hva_id && existingCC.hva_id
  );

  const [riskLevels, setRiskLevels] = useState(defaultRiskLevels);

  const reduxHVAs = useSelector((state) => {
    return state.app.HVAs;
  });

  useEffect(() => {
    if (
      !isLoaded &&
      isUpsertCoreComponentLoaded &&
      isFetchCoreComponentsLoaded
    ) {
      onClose();
    }
  }, [
    isUpsertCoreComponentLoaded,
    isFetchCoreComponentsLoaded,
    isLoaded,
    onClose,
  ]);

  function createCC() {
    const current_location_geom =
      !!location &&
      !!location.geojson &&
      location.geojson.data.features[0].geometry;
    const cc = {
      id: (!!existingCC && existingCC.id) || generateUUID(),
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      name: name,
      description: description,
      location: location,
      current_location_geom: current_location_geom,
      cc_type: ccType,
      category_id: selectedCategory?.value,
    };
    return cc;
  }

  const handleCCCreateClick = () => {
    setIsLoaded(false);
    reduxDispatch(upsertCoreComponent(createCC()));
  };

  const saveName =
    (!!existingCC && existingCC.name) || CoreComponentsToSingular[ccType];

  const nestedCategories = useSelector((state) => {
    return state.app.nestedCategories;
  });

  const [locationTypes, setLocationTypes] = useState([]);

  useEffect(() => {
    reduxDispatch(fetchNestedCategories());
  }, []);

  useEffect(() => {
    if (nestedCategories) {
      const formattedTypes = nestedCategories.reduce((acc, category) => {
        const type = {
          label: category.category_name,
          value: category.id,
        };

        return [...acc, { ...type }];
      }, []);

      setLocationTypes(formattedTypes);

      if (existingCC && existingCC.category_id) {
        const foundCategory = nestedCategories.find(
          (item) => item.id === existingCC.category_id
        );
        setSelectedCategory({
          label: foundCategory?.category_name,
          value: foundCategory?.id,
        });
      }
    }
  }, [nestedCategories]);

  const [selectedCategory, setSelectedCategory] = useState(
    existingCC && { value: existingCC.category_id }
  );

  //const relations = !!existingCC && getCCRelationalObjects(CoreComponents,existingCC)

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {(!!existingCC && <>Edit {existingCC.name}</>) || (
            <>New {CoreComponentsToSingular[ccType]}</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          //!!ccType === "Areas of Responsibility" &&
          !!locationTypes.length && !!locationTypes[0].label && (
            <>
              <div className="mb-3">
                <label className="form-label">{`Select AOR Category`}</label>
                <StylishNewSelect
                  options={locationTypes}
                  onChange={(e) => setSelectedCategory(e)}
                  value={selectedCategory}
                  isClearable={false}
                  isSearchable={false}
                  isMulti={false}
                  isDisabled={false}
                />
              </div>
              <hr className="dashed my-4" />
            </>
          )
        }
        <div className="mb-3">
          <label className="form-label">Name</label>
          <StylishNewInput
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Description</label>
          <StylishNewTextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="m-0">
          <label className="form-label">Location</label>
          <GeoLocationComponent
            location={location}
            setLocation={setLocation}
            mapboxToken={mapboxToken}
          />
        </div>
        {/* {!!existingCC && <div></div>} */}
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--primary"
            disabled={!isLoaded || !name}
            onClick={() => handleCCCreateClick()}
          >
            Save {saveName}
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {!isLoaded && <div>loading...</div>}
    </Modal>
  );
}
