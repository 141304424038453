import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { deleteNotificationByGroup } from 'actions/notificationActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { setSelectedTemplate } from '../../slices/notificationsSlice';

export default function SelectTemplateDialog({
  show,
  selectedNotification,
  setSaveAsTemplate,
  availableNotifications,
  onClose,
}) {
  const [componentSelection, setComponentSelection] = useState(
    selectedNotification
  );
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    if (selectedNotification) {
      setComponentSelection(selectedNotification);
    }
  }, [selectedNotification]);

  const reduxValidateRBACPermissionForActionResult =
    useSelector((state) => {
      return state.app.validateRBACPermissionForActionResult;
    }) || [];

  const reduxDispatch = useDispatch();

  const handleDeleteClick = () => {
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(deleteNotificationByGroup(componentSelection));
    reduxDispatch(setSelectedTemplate());
    setSaveAsTemplate(false);
    onClose();
  };

  const columns = [
    {
      dataField: 'template_title',
      text: 'Template Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Template Title' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          {!!row.message && (
            <>
              <h6 className="mb-1">Email message:</h6>
              <p>{row.message}</p>
            </>
          )}
          {!!row.settings.recipientSettings.SMSRecipientSettings.SMSMessage && (
            <>
              <h6 className="mb-1">SMS message:</h6>
              <p>
                {row.settings.recipientSettings.SMSRecipientSettings.SMSMessage}
              </p>
            </>
          )}
          {!!row.settings.contentSettings.pagerMessage && (
            <>
              <h6 className="mb-1">Pager message:</h6>
              <p>{row.settings.contentSettings.pagerMessage}</p>
            </>
          )}
          {!!row.settings.contentSettings.TTSMessage && (
            <>
              <h6 className="mb-1">Text to speech message:</h6>
              <p>{row.settings.contentSettings.TTSMessage}</p>
            </>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectAll: true,
    selected: componentSelection ? [componentSelection.id] : [],
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setComponentSelection(row);
      } else {
        setComponentSelection();
      }
    },
  };

  const handleUseTemplateClick = () => {
    reduxDispatch(setSelectedTemplate(componentSelection));
    setSaveAsTemplate(false);
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Notification Templates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select Template</h5>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={
              availableNotifications?.filter((i) => !!i.template_title) || []
            }
            expandRow={expandRow}
            hasPagination={true}
            paginationPerPageItem="10"
            selectRow={selectRow}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className={'button--primary'}
              disabled={!componentSelection}
              onClick={() => handleUseTemplateClick()}
            >
              Use Template
            </StylishNewButton>
            <StylishNewButton
              className={'button--secondary button--reverse'}
              onClick={() => handleDeleteClick()}
              disabled={
                !componentSelection ||
                !reduxValidateRBACPermissionForActionResult[
                  'Manage Notification Templates'
                ]
              }
            >
              Delete Template
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={
            'Are you sure you want to delete this Notification?  This action cannot be undone.'
          }
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </>
  );
}
