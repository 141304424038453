// SitrepTemplateEditDrawer.js

import React, { useEffect } from 'react';
import { Offcanvas, Row, Col } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import FormTextField from 'components/IAP/formFields/FormTextField';
import { useSectionTemplates } from './hooks/useSectionTemplates';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableSection from './DraggableSection';
import './SitrepTemplateEditDrawer.css';
import { defaultSectionTemplates } from './SitrepDefaults';

const SitrepTemplateEditDrawer = ({ show, onHide, onSave, template }) => {
  const methods = useForm({
    mode: 'onChange', // Validate on change
    defaultValues: { id: '', name: '', sections: [] },
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = methods;

  const { data: sectionTemplates = [] } = useSectionTemplates();
  const sections = watch('sections');

  // Register 'sections' with validation rule on mount
  useEffect(() => {
    register('sections', {
      validate: (value) =>
        (value && value.length > 0) || 'At least one section is required',
    });
  }, [register]);

  // Reset form when template changes
  useEffect(() => {
    if (template) {
      reset({
        id: template.id || '',
        name: template.name || '',
        sections: template.sections || [],
      });
    } else {
      reset({ id: '', name: '', sections: [] });
    }
  }, [template, reset]);

  // Handle form submission
  const handleSaveTemplate = (formData) => {
    onSave({
      id: formData.id,
      name: formData.name,
      sections: formData.sections,
    });
  };

  // Add a section to the selected sections
  const handleAddSection = (sectionId) => {
    const updatedSections = [
      ...sections,
      {
        section_id: sectionId,
        order: sections.length + 1, // Increment order based on the array length
      },
    ];
    setValue('sections', updatedSections);
    trigger('sections'); // Trigger validation
  };

  // Remove a section from the selected sections
  const handleRemoveSection = (index) => {
    const newSections = [...sections];
    newSections.splice(index, 1);
    setValue('sections', newSections);
    trigger('sections'); // Trigger validation
  };

  // Move a section within the selected sections
  const moveSection = (fromIndex, toIndex) => {
    const updatedSections = Array.from(sections);
    const [movedSection] = updatedSections.splice(fromIndex, 1);
    updatedSections.splice(toIndex, 0, movedSection);
    setValue('sections', updatedSections);
    trigger('sections'); // Trigger validation
  };

  // Filter available sections that are not already selected
  const availableSections = [
    ...defaultSectionTemplates,
    ...sectionTemplates,
  ].filter((st) => !sections.some((section) => section.section_id === st.id));

  return (
    <DndProvider backend={HTML5Backend}>
      <DrawerWrapper
        toggle={onHide}
        title={template ? 'Edit SITREP Template' : 'Add SITREP Template'}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(handleSaveTemplate)}
            className="d-flex flex-column justify-content-between flex-grow-1"
          >
            <Offcanvas.Body>
              {template && <input type="hidden" {...register('id')} />}
              <Row className="mb-3">
                <FormTextField
                  title="Template Name"
                  fieldName="name"
                  register={register}
                  rules={{ required: 'Template name is required' }}
                  error={errors.name}
                />
              </Row>
              <Row>
                <Col>
                  <h5>Available Sections</h5>
                  <div
                    style={{
                      border: '1px solid #ccc',
                      padding: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    {availableSections.length === 0 ? (
                      <div>No available sections.</div>
                    ) : (
                      <ul className="available-sections-list">
                        {availableSections.map((st) => (
                          <li
                            key={st.id}
                            className="available-sections-list-item"
                          >
                            <span>
                              {!!defaultSectionTemplates.find(
                                (t) => t.id === st.id || t.id === st.section_id
                              ) && '(Default) '}
                              {st.name}
                            </span>
                            <StylishNewButton
                              className="button--secondary"
                              size="sm"
                              onClick={() => handleAddSection(st.id)}
                            >
                              Add
                            </StylishNewButton>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </Col>
                <Col>
                  <h5>Selected Sections</h5>
                  <div className="selected-sections-container">
                    {sections.length === 0 ? (
                      <div>No sections selected.</div>
                    ) : (
                      <div className="selected-sections-list">
                        {sections.map((selectedSection, index) => {
                          const sectionId =
                            selectedSection?.section_id || selectedSection;
                          const foundSection = [
                            ...defaultSectionTemplates,
                            ...sectionTemplates,
                          ].find((st) => st.id === sectionId);
                          return (
                            <div
                              key={sectionId}
                              className="selected-sections-item"
                            >
                              <DraggableSection
                                key={sectionId}
                                index={index}
                                section={sectionId}
                                foundSection={foundSection}
                                moveSection={moveSection}
                                removeSection={() => handleRemoveSection(index)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {/* Display validation error for sections */}
                  {errors.sections && (
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      {errors.sections.message}
                    </span>
                  )}
                </Col>
              </Row>
            </Offcanvas.Body>
            <DrawerFooter>
              <div className="button-group">
                <StylishNewButton
                  className="button--secondary"
                  onClick={onHide}
                >
                  Cancel
                </StylishNewButton>
                <StylishNewButton
                  className="button--primary"
                  type="submit"
                  disabled={!isValid || sections.length === 0}
                >
                  Save
                </StylishNewButton>
              </div>
            </DrawerFooter>
          </form>
        </FormProvider>
      </DrawerWrapper>
    </DndProvider>
  );
};

export default SitrepTemplateEditDrawer;
