
import {owmLayers} from '../constants/owmLayers'
import dayjs from 'dayjs';

export function formatDataForVisualMap(layer,data)
{
	if(!data) return 

  const legendArray = owmLayers.find(l=>l.key===layer.key)["legend"].split(";").map(item=>item.trim())

	const result = {
    top: 30,
    right: 50,
    precision:2,
    pieces: legendArray.map((item,index)=> {
      if(legendArray.length-1 > index)
      {
        return {
          gt:parseFloat(item.split(":")[0]),
          lte:parseFloat(legendArray[index+1].split(":")[0]),
          color:"#"+item.split(":")[1]
        }
      }
      else
      {
        return {
          gt:parseFloat(item.split(":")[0]),
          color:"#"+item.split(":")[1]
        }
      }
    }),
    outOfRange: {
      color: '#999'
    }
  }
  return result
}

export function formatDataForMarklineConfig(layer,data)
{
	if(!data) return 

  const legendArray = owmLayers.find(l=>l.key===layer.key)["legend"].split(";").map(item=>item.trim())

	const result = {
    lineStyle: {
      color: '#333'
    },
    data: legendArray.map((item,index)=> {
      return {
        yAxis: parseFloat(item.split(":")[0]),
        lineStyle: {
          color:"#"+item.split(":")[1],
          type: "solid"
        }
      }
    })
  }
  return result
}

function getDataItemForLayerType(layer,timestep)
{
  let finalData = ""
  switch(layer.key) {
    case 'Convective precipitation':
    case 'Precipitation intensity':
    case 'Accumulated precipitation':
    case 'Accumulated precipitation - rain':
    case 'Accumulated precipitation - snow':
    case 'Depth of snow':
       finalData = "N/A"
       break
    case 'Wind Speed':
    case 'Wind Speed and Direction':
       finalData = timestep.wind.speed
       break
    case 'Pressure':
       finalData = timestep.main.pressure 
       break
    case 'Temperature (Dew Point)':
       finalData = timestep.main.feels_like
       break
    case 'Temperature':
       finalData = timestep.main.temp
       break
    case 'Humidity':
       finalData = timestep.main.humidity
       break
    case 'Cloudiness':
       finalData = timestep.clouds.all
       break
    default:
      finalData = "N/A"
  }
  
  return finalData
}

export function formatDataForChart(layer,data,selectedDatetime)
{
  if(!data) return 

  // Filter out data beyond 5 day forecast range 
  let forecastDataArray = data.list
  .filter(timestep=>(new Date(dayjs(timestep.dt_txt).subtract(4,'hour')))>=selectedDatetime.minute(0).second(0))
  .map(timestep => {
    const dataItem = getDataItemForLayerType(layer,timestep)
    return [new Date(dayjs(timestep.dt_txt).subtract(4,'hour').format('MM/DD/YYYY hh:mm A')).toISOString(),dataItem]
  })

  //..as well as any data prior to the selectedDatetime
  if(selectedDatetime.minute(0).second(0).millisecond(0)<dayjs().minute(0).second(0).millisecond(0))
  {
    forecastDataArray = []
  }

  
  return {
    name: layer.key,
    type: 'line',
    stack: 'all',
    data: forecastDataArray,
    lineStyle: {
      type: 'dotted',
    },
    markLine: {
      ...formatDataForMarklineConfig(layer,data),
      name: layer.key,
    },
  }
}