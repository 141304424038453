import React from 'react';
import dayjs from 'dayjs';

export default function ICSIncidentResource({
  row,
  ics_incident_resources,
}) {

  return (
    <div className="ICSIncidentResource">
      <div className="mb-4">
        <h6 className="mb-2">Name:</h6>
        <p className="m-0">
          {row.name}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Description:</h6>
        <p
          className="m-0"
        >
          {row.description}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Kind:</h6>
        <p className="m-0" >
          {row.kind}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Type:</h6>
        <p className="m-0">
          {row.type}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Quantity:</h6>
        <p
          className="m-0"
        >
          {row.required_quantity}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Date Time Ordered:</h6>
        <p
          className="m-0"
        >
          {dayjs(row.date_time_ordered).format('YYYY-MM-DD HH:mm')}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">ETA:</h6>
        <p className="m-0">
          {row.eta ? dayjs(row.eta).format('YYYY-MM-DD HH:mm') : 'N/A'}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Arrived:</h6>
        <p
          className="m-0"
        >
          {row.arrived ? 'Yes' : 'No'}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Location:</h6>
        <p
          className="m-0"
        >
          {row.location}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Status:</h6>
        <p
          className="m-0"
        >
          {row.status}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Notes:</h6>
        <p className="m-0">
          {row.notes}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p
          className="m-0"
        >
          {row.user_guid}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p
          className="m-0"
        >
          {row.archived ? 'Yes' : 'No'}
        </p>
      </div>
    </div>
  );
}
