import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

import {
  deleteDivision,
  getAllDivisionByGroupId,
} from 'actions/IAPForm204Actions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import DrawerWrapper, { DrawerFooter } from '../DrawerWrapper';
import AddUpdateDivisionDrawer from './AddUpdateDivisionDrawer';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';

export default function DivisionDrawer({
  show,
  onClose,
  selectedDivision,
  selectedBranch,
  parent,
  rowSelectable,
  updateSelectedDivision,
}) {
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (!rowSelectable && selectedBranch) {
      reduxDispatch(getAllDivisionByGroupId(selectedBranch.id));
    }
  }, [reduxDispatch, selectedBranch, rowSelectable]);

  const [division, setSelectedDivision] = useState(selectedDivision);
  const [showNewDivisionDrawer, setShowNewDivisionDrawer] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editDivisionData, setEditDivisionData] = useState(null);

  const divisions = useSelector((state) => state.app.allDivisions);

  const onDeleteAction = (row) => {
    setDeleteConfirmationData(row);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteDivision(deleteConfirmationData.id, deleteConfirmationData.branch_id, 'getAll'));
    setDeleteConfirmationData(null);
    setShowConfirmDialog(false);
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Division',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Branch' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row) => (
        <StylishNewTableAction
          name="Division"
          cell={cell}
          row={row}
          onDelete={() => onDeleteAction(row)}
          onEdit={() => {
            setEditDivisionData(row);
            setShowNewDivisionDrawer(true);
          }}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectAll: true,
    selected: !!selectedDivision && [selectedDivision.id],
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedDivision(row);
      }
    },
  };

  const onSave = async () => {
    try {
      reduxDispatch(startLoading());
      updateSelectedDivision(divisions.find((d) => d.id === division.id));
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  return (
    <DrawerWrapper toggle={onClose} title="Division" show={show}>
      <div className="d-flex flex-column flex-grow-1">
        <StylishNewButton
          onClick={() => {
            setEditDivisionData(null);
            setShowNewDivisionDrawer(true);
          }}
          className={'button--primary mb-4'}
        >
          New Division
        </StylishNewButton>
        <Row className="flex-grow-1">
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={divisions}
            selectRow={rowSelectable ? selectRow : undefined}
          />
        </Row>
      </div>
      <DrawerFooter>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          {parent !== 'IncidentWorkspace' && (
            <StylishNewButton
              className={'button--primary'}
              onClick={onSave}
              disabled={!division || !division.id}
            >
              Save
            </StylishNewButton>
          )}
        </div>
      </DrawerFooter>

      {showNewDivisionDrawer && (
        <AddUpdateDivisionDrawer
          show={showNewDivisionDrawer}
          onClose={() => setShowNewDivisionDrawer(false)}
          selectedBranch={selectedBranch}
          selectedDivision={editDivisionData}
          onDivisionSelect={setSelectedDivision}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this division?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </DrawerWrapper>
  );
}
