import React from 'react';
import OutagePredictionMap from './OutagePredictionMap';
import OutagePredictionHeatmap from './OutagePredictionHeatmap';
import OutagePredictionSettings from './OutagePredictionSettings';
import OutagePredictionTable from './OutagePredictionTable';

const OutagePrediction: React.FC = () => {
  return (
    <div>
      <h4 className="ms-2">Outage Prediction</h4>
      <div className="row">
        <div className="col-md-6 outage-map">
          <OutagePredictionMap />
        </div>
        <div className="col-md-6">
          <div className="outage-settings">
            <OutagePredictionSettings />
          </div>
          <div className="outage-heatmap">
            <OutagePredictionHeatmap />
          </div>
          <div className="outage-table">
            <OutagePredictionTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutagePrediction;
