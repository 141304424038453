// Sitrep.js

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Info from 'components/DesignSystems/text/Info';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useNavigate } from 'react-router-dom';
import {
  setCurrentSitrepId,
  setDgptSitrepToInitialIncidentReportSummary,
  setSelectedDChat,
} from 'slices/dchatSlice';
import SitrepSection from './SitrepSection';
import { AiOutlineFileWord } from 'react-icons/ai';
import { ImOnedrive } from "react-icons/im";
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { fetchCoreComponents } from 'actions/ai2fpActions.js';
import CoreComponentCreationDialog from 'components/AI2FP/CoreComponentCreationDialog';
import * as turf from '@turf/turf';
import { MdLocationOn } from 'react-icons/md';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  selectIncident,
} from 'slices/commonSelectors';
import { useSharepointDocxUpload } from './hooks/useSharepointFiles';
import { defaultSectionTemplates } from './SitrepDefaults';
import { useFeatureFlag } from '../../utils/featureFlags';


import {
  useAddSitrepSectionV2,
  useRefreshSitrepV2,
  useUpdateSitrepSectionV2,
  useRemoveSitrepSectionV2,
  useUpdateSitrepV2,
} from 'components/DisasterGPT/hooks/useSitrepsV2';

import {
  useCreateSectionTemplate,
  useSectionTemplates,
  useUpdateSectionTemplate,
} from 'components/DisasterGPT/hooks/useSectionTemplates';

import { docxExport } from './DisasterGPTDataHelpers/DGPTExport';
import { useToggle } from 'react-use';

// Import the sharepoint files logic
import {
  useSharepointFiles,
  useSyncSharepointFiles,
} from './hooks/useSharepointFiles';
import { useSharepointFileRefs } from './hooks/useSharepointFileRefs';
import * as dayjs from 'dayjs';

const Sitrep = ({
  sitrep,
  map,
  mapHasLoaded,
  toggleDGPTOpen,
  name,
  selectedDatetime,
  fromSidebar = false,
}) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const divRef = useRef();

  const currentIncident = useSelector(selectIncident);
  const { uploadDocxToSharepoint } = useSharepointDocxUpload();

  const [addDrawerOpen, setAddDrawerOpen] = useToggle(false);
  const [createReport, setCreateReport] = useState(false);
  const [showMostRecent, setShowMostRecent] = useState(true);
  const [addingSection, setAddingSection] = useState(false);
  const [addSectionTemplate, setAddSectionTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const createSectionTemplateMutation = useCreateSectionTemplate();
  const updateSectionTemplateMutation = useUpdateSectionTemplate();

  const reduxCurrentlySelectedGroup = useSelector(
    (state) => state.app.currentlySelectedGroup
  );
  const reduxCoreComponents = useSelector((state) => state.app.coreComponents);
  const reduxCurrentIncident = useSelector(
    (state) => state.app.currentIncident
  );
  const dgptSitrepToInitialIncidentReport = useSelector(
    (state) => state.dchat.dgptSitrepToInitialIncidentReport
  );

  const refreshSitrepMutation = useRefreshSitrepV2();
  const addSitrepSectionMutation = useAddSitrepSectionV2();
  const updateSitrepSectionMutation = useUpdateSitrepSectionV2();
  const removeSitrepSectionMutation = useRemoveSitrepSectionV2();
  const updateSitrepMutation = useUpdateSitrepV2();

  const {
    data: sectionTemplates = [],
    isLoading: isSectionTemplatesLoading,
  } = useSectionTemplates();
  const isRefreshSitrepLoading = refreshSitrepMutation.isLoading;
  const isAddSectionLoading = addSitrepSectionMutation.isLoading;
  const isUpdateSectionLoading = updateSitrepSectionMutation.isLoading;
  const isRemoveSectionLoading = removeSitrepSectionMutation.isLoading;
  const isUpdateSitrepLoading = updateSitrepMutation.isLoading;

  // Determine if the sitrep is still processing
  const isSitrepProcessing =
    (sitrep && sitrep.status && sitrep.status.toLowerCase() !== 'summarized') ||
    isRefreshSitrepLoading ||
    isUpdateSitrepLoading;

  // Overall actions disabled state
  const isActionsDisabled =
    isSitrepProcessing ||
    isRefreshSitrepLoading ||
    isAddSectionLoading ||
    isUpdateSectionLoading ||
    isRemoveSectionLoading ||
    isUpdateSitrepLoading;

  const [aorCreationDialogActive, setAORCreationDialogActive] = useState(false);
  const [aorOptions, setAorOptions] = useState([]);

  const neoFlag = useFeatureFlag('NEO');

  const filteredDefaultTemplates = defaultSectionTemplates.filter((template) =>
    !neoFlag ? template.id !== '00000000-0000-0000-0000-000000000006' : true
  );

  const filteredTemplates = [
    ...filteredDefaultTemplates,
    ...sectionTemplates,
  ];

  useEffect(() => {
    if (reduxCurrentlySelectedGroup && reduxCurrentlySelectedGroup.group_guid) {
      reduxDispatch(
        fetchCoreComponents(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup, reduxDispatch]);

  useEffect(() => {
    if (
      reduxCoreComponents &&
      reduxCoreComponents.CCs &&
      reduxCoreComponents.CCs['Areas of Responsibility']
    ) {
      const aors = reduxCoreComponents.CCs['Areas of Responsibility'];
      const options = aors.map((aor) => ({ value: aor.id, label: aor.name }));
      setAorOptions(options);
    }
  }, [reduxCoreComponents]);

  const selectedAor =
    reduxCoreComponents &&
    reduxCoreComponents.CCs &&
    reduxCoreComponents.CCs['Areas of Responsibility'] &&
    reduxCoreComponents.CCs['Areas of Responsibility'].find(
      (aor) => aor.id === sitrep.aor_id
    );

  const flyToGeolocation = (map, item) => {
    if (
      !!item.geolocation &&
      item.geolocation.geojson &&
      item.geolocation.geojson.data &&
      item.geolocation.geojson.data.features &&
      item.geolocation.geojson.data.features.length
    ) {
      var box = turf.bbox(item.geolocation.geojson.data.features[0].geometry);
      map.current.fitBounds(box, { padding: 50, maxZoom: 15 });
    }
  };

  function prepareMessagesForReport(sitrep) {
    const pattern = /【[^】]*】/g;
    const markdownString = sitrep.sections
      .map((s) => {
        const title = s.name;
        let text = s.section_versions[0].sitrep_section_text;

        text = text?.replaceAll('```json', '').replaceAll('```', '');
        if (text && text[0] === `"` && text[text.length - 1] === `"`) {
          text = text.slice(1, -1);
        }

        return `
### ${title}:  

${text}

---
`
          .replaceAll('{{', '')
          .replaceAll('}}', '');
      })
      .map((line) => line.trim())
      .join('\n')
      .replaceAll(pattern, '');
    return markdownString;
  }

  function sendDgptSitrepToInitialIncidentReportSummary() {
    setCreateReport(true);

    const sitrepMarkdown = prepareMessagesForReport(sitrep);

    const canvas = map.current.getCanvas();
    const mapScreenshot = canvas.toDataURL('image/png');

    reduxDispatch(
      setDgptSitrepToInitialIncidentReportSummary({
        sitrep: sitrep,
        sitrep_id: sitrep.id,
        sitrepMarkdown: sitrepMarkdown,
        reference: sitrep.sitrep_source,
        reference_id: sitrep.sitrep_source_id,
        mapScreenshot: mapScreenshot,
      })
    );
  }

  function timestampSelectedCallback() {
    setShowMostRecent(false);
  }

  useEffect(() => {
    if (!!dgptSitrepToInitialIncidentReport && !!createReport) {
      setCreateReport(false);
      navigate(`/report/new`, { replace: true });
    }
  }, [dgptSitrepToInitialIncidentReport, createReport, navigate]);

  function updateSitrep(hardRefresh = false) {
    refreshSitrepMutation.mutate({
      id: sitrep.id,
      selectedDatetime,
      hardRefresh,
    });
  }

  function addSectionClicked() {
    setAddingSection(true);
  }

  useEffect(() => {
    if (addingSection) {
      const { current } = divRef;
      if (current !== null) {
        current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }, [addingSection]);

  function addSectionSaveClicked() {
    if (Array.isArray(addSectionTemplate)) {
      addSectionTemplate.forEach((template, index) => {
        addSitrepSectionMutation.mutate({
          sitrepId: sitrep.id,
          sectionTemplateId: template.value,
          selectedDatetime: selectedDatetime,
          sitrep_source: sitrep.sitrep_source,
          sitrep_source_id: sitrep.sitrep_source_id,
          order: sitrep.sections.length + index + 1,
          aor_id: sitrep.aor_id,
        });
      });
    }
    setAddingSection(false);
    setAddSectionTemplate(null);
  }

  function addSectionCancelClicked() {
    setAddingSection(false);
    setAddSectionTemplate(null);
  }

  const sortedSections = sitrep && sitrep.sections ? sitrep.sections : [];

  function chatClicked() {
    reduxDispatch(setSelectedDChat({}));
    reduxDispatch(setCurrentSitrepId(sitrep.id));
    toggleDGPTOpen();
  }

  function prepareSitrepForClipboard() {
    return sitrep.sections
      .map((section) => {
        const title = section.name;
        const text = section.section_versions[0].sitrep_section_text;

        return `
**${title}:**  

${text}
`;
      })
      .join('\n');
  }

  function copySitrepToClipboard() {
    const sitrepContent = prepareSitrepForClipboard();
    navigator.clipboard.writeText(sitrepContent).catch((err) => {
      console.error('Failed to copy SITREP: ', err);
    });
  }

  function downloadSitrepDocx() {
    let messages = sitrep.sections.map((section) => ({
      role: section.name,
      content:
        section.section_versions[0].sitrep_section_text.replace(
          /【.*?】/g,
          ''
        ) || '',
      timestamp: section.section_versions[0].timestamp,
    }));

    const headerMessage = {
      role: sitrep.name,
      content: '',
      timestamp: '',
    };

    messages = [headerMessage, ...messages];
    docxExport(messages, 'Download', sitrep.name || 'Sitrep');
  }

  function uploadSitrepSharepoint() {
    let messages = sitrep.sections.map((section) => ({
      role: section.name,
      content: section.section_versions[0].sitrep_section_text.replace(/【.*?】/g, '') || '',
      timestamp: section.section_versions[0].timestamp,
    }));

    const headerMessage = {
      role: sitrep.name,
      content: '',
      timestamp: '',
    };

    messages = [headerMessage, ...messages];

    // 1) docxExport for 'Sharepoint' => returns Promise<Blob>
    docxExport(messages, 'Sharepoint', sitrep.name || 'Sitrep')
      .then(({ blob, filenameWithTimestamp }) => {
        if (blob) {
          uploadDocxToSharepoint(blob, filenameWithTimestamp);
        }
      })
      .catch((err) => {
        console.error('Error exporting SITREP to SharePoint:', err);
        // docxExport may already toast the error
      });
  }

  const handleUpdateSection = (sectionId, newText) => {
    updateSitrepSectionMutation.mutate({
      sitrepId: sitrep.id,
      sectionId,
      sectionText: newText,
    });
  };

  const handleRemoveSection = (sectionId) => {
    removeSitrepSectionMutation.mutate({
      sitrepId: sitrep.id,
      sectionId,
    });
  };

  const handleAorChange = (selectedOption) => {
    updateSitrepMutation.mutate({
      sitrep_id: sitrep.id,
      aor_id: selectedOption ? selectedOption.value : null,
    });
  };

  // Determine if the SITREP has sections requiring file syncing
  const sitrepHasFiles = useMemo(() => {
    if (!sitrep || !sitrep.sections) return false;
    return sitrep.sections.some(
      (section) =>
        section.data_sources &&
        section.data_sources.some((ds) => ds.source === 'Files')
    );
  }, [sitrep]);

  // File Sync logic (similar to SitrepCreateDrawer)
  const {
    data: filesData = [],
    isLoading: isLoadingSharepointFiles,
  } = useSharepointFiles();
  const { data: sharepointFileRefs } = useSharepointFileRefs(
    reduxCurrentlySelectedGroup?.group_guid
  );
  const { syncSharepointFiles, isSyncing } = useSyncSharepointFiles();

  const [isOutOfSync, setIsOutOfSync] = useState(false);

  useEffect(() => {
    if (sharepointFileRefs && sharepointFileRefs.length > 0) {
      const outOfSyncFiles = sharepointFileRefs.filter((file) => {
        const timestamp = dayjs(file.timestamp);
        return dayjs().diff(timestamp, 'hour') >= 24;
      });
      setIsOutOfSync(outOfSyncFiles.length > 0);
    }
  }, [sharepointFileRefs]);

  const runFileSync = () => {
    syncSharepointFiles(true);
  };

  return (
    <div className="pb-5 Sitrep">
      <div>{!!sitrep?.name && <h5>{sitrep.name}</h5>}</div>

      <div>{!!sitrep?.description && <p>{sitrep.description}</p>}</div>

      <div className="mb-3">
        <label htmlFor="aor_id">Select AOR (optional)</label>
        <div className="d-flex align-items-center">
          <StylishNewSelect
            options={aorOptions}
            isClearable
            placeholder="Select an AOR"
            onChange={handleAorChange}
            value={
              aorOptions.find((option) => option.value === sitrep.aor_id) ||
              null
            }
            isDisabled={isActionsDisabled}
          />
          <StylishNewButton
            className="button--primary button--sml ms-2"
            onClick={() => setAORCreationDialogActive(true)}
          >
            New AOR
          </StylishNewButton>
          {selectedAor && (
            <span
              className="anchor anchor--white cursor-pointer bg-gray-900 p-3 rounded mb-3"
              onClick={() => flyToGeolocation(map, selectedAor)}
            >
              <MdLocationOn className="img-h-20 me-3" />
            </span>
          )}
        </div>
      </div>

      {sortedSections.map((section, index) => (
        <SitrepSection
          key={'SitrepSection-' + index}
          sitrep={sitrep}
          section={section}
          index={index}
          totalSections={sortedSections.length}
          timestampSelectedCallback={timestampSelectedCallback}
          showMostRecent={showMostRecent}
          isSitrepReady={!isSitrepProcessing}
          selectedDatetime={selectedDatetime}
          onUpdateSection={handleUpdateSection}
          onRemoveSection={handleRemoveSection}
        />
      ))}

      {isSitrepProcessing && (
        <div className="pb-5 text-center">
          <span>DisasterGPT is analyzing...</span>
          <div>
            <i className="fa fa-spinner fa-pulse fs-6"></i>
          </div>
          <Info>AutoSITREP can take between 10-60 seconds to complete.</Info>
        </div>
      )}

      {addingSection ? (
        <div className="SitrepSection mb-5" ref={divRef}>
          <div className="paragraph-header">
            <h4>Add Sections - select one or more in order desired</h4>
          </div>
          <div className="SitrepSection-text">
            {isSectionTemplatesLoading ? (
              <div className="d-flex justify-content-center">
                <i className="fa fa-spinner fa-pulse fs-5 "></i>
              </div>
            ) : (
              <StylishNewSelect
                options={filteredDefaultTemplates.map((template) => ({
                  value: template.id,
                  label: template.name,
                }))}
                value={addSectionTemplate}
                onChange={(selectedOptions) =>
                  setAddSectionTemplate(selectedOptions)
                }
                placeholder={'Select a section template'}
                isDisabled={isActionsDisabled}
                isMulti={true}
              />
            )}
            <div className="mt-2 d-flex justify-content-end p-3">
              <StylishNewButton
                className="button--secondary me-3"
                onClick={() => addSectionCancelClicked()}
              >
                Cancel
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                onClick={() => addSectionSaveClicked()}
                disabled={isActionsDisabled || !addSectionTemplate}
              >
                Save
              </StylishNewButton>
            </div>
          </div>
        </div>
      ) : (
        !isSitrepProcessing && (
          <div
            className="add-section"
            disabled={isSitrepProcessing}
            onClick={() => addSectionClicked()}
          >
            <span className="material-symbols-outlined me-2 fs-5">add</span> Add
            Sections
          </div>
        )
      )}

      {!addingSection && (
        <div className="tab-footer zIndex--3">
          
          <Dropdown>
            <Dropdown.Toggle
              as={StylishNewButton}
              className="button--secondary me-3 mb-2"
              disabled={isActionsDisabled}
            >
              SITREP Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={chatClicked}
                disabled={isActionsDisabled}
              >
                <span className="material-symbols-outlined">chat</span> DisasterChat
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => updateSitrep(false)}
              >
                Update
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => updateSitrep(true)}
              >
                Refresh
              </Dropdown.Item>
              {sitrepHasFiles && (
                <Dropdown.Item
                  as="button"
                  onClick={runFileSync}
                  disabled={isSyncing}
                >
                  {isSyncing ? (
                    <i className="fa fa-spinner fa-pulse"></i>
                  ) : isOutOfSync ? (
                    'File Sync!'
                  ) : (
                    'File Sync'
                  )}
                </Dropdown.Item>
              )}
              <Dropdown.Item
                as="button"
                onClick={copySitrepToClipboard}
                disabled={isActionsDisabled}
              >
                Copy to Clipboard
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={downloadSitrepDocx}
                disabled={!sitrep.sections || sitrep.sections.length === 0 || isActionsDisabled}
              >
                <AiOutlineFileWord className="me-2" />
                Download DOCX
              </Dropdown.Item>
              {currentIncident?.tenant_id && currentIncident?.sharepoint_location && (
                <Dropdown.Item
                  as="button"
                  onClick={uploadSitrepSharepoint}
                  disabled={!sitrep.sections || sitrep.sections.length === 0 || isActionsDisabled}
                >
                  <ImOnedrive className="me-2" />
                  Export DOCX to Sharepoint
                </Dropdown.Item>
              )}
              <Dropdown.Item
                as="button"
                onClick={sendDgptSitrepToInitialIncidentReportSummary}
                disabled={isActionsDisabled}
              >
                <span className="material-symbols-outlined">electric_bolt</span>
                Create Incident Report
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {isActionsDisabled && (
            <div>
              <i className="fa fa-spinner fa-pulse me-2"></i>Updating Sitrep...
            </div>
          )}
        </div>
      )}

      <CoreComponentCreationDialog
        show={aorCreationDialogActive}
        onClose={() => {
          setAORCreationDialogActive(false);
          // Refetch AORs after creation
          reduxDispatch(
            fetchCoreComponents(reduxCurrentlySelectedGroup.group_guid)
          );
        }}
        ccType="Areas of Responsibility"
      />
    </div>
  );
};

export default Sitrep;
