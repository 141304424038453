import {
  upsertNestedCategoryItem,
  getAllLocationEnterprise,
  getAllLocationEntity,
  getAllLocationSubEntity,
  deleteAOR,
} from 'actions/locationAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';

import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

import { productionEnv } from 'constants/debug';
import { AddUpdateAor } from './AddUpdateAor';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { locationNameStringLength } from 'assets/data/config';

const Label = ({ label, value, onEdit, onDelete }) => {
  return (
    <>
      {label}
      <div className="react-select__custom-label">
        <span
          className="ms-3"
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        >
          <SharedIcon iconName="stylus" />
        </span>
        <span
          className="ms-3"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <SharedIcon iconName="delete" />
        </span>
      </div>
    </>
  );
};

export default function LocationAddUpdateModal({
  show,
  onClose,
  mode,
  updateLocationData,
  updateLocationType,
  nestedCategories,
}) {
  const [geoLocation, setGeoLocation] = useState();
  const [showCreateAor, setShowCreateAor] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [createAorMode, setCreateAorMode] = useState('Add');
  const [updateAorData, setUpdateAorData] = useState(null);
  const [allAvaliableAor, setAllAvaliableAor] = useState([]);

  const reduxDispatch = useDispatch();

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const [locationTypes, setLocationTypes] = useState([]);

  const locationEntity = useSelector((state) => {
    return state.app.locationEntity.map((entity) => {
      return { label: entity.location_entity, value: entity.id };
    });
  });
  const locationSubEntity = useSelector((state) => {
    return state.app.locationSubEntity.map((subEntity) => {
      return { label: subEntity.location_sub_entity, value: subEntity.id };
    });
  });
  const locationEnterprise = useSelector((state) => {
    return state.app.locationEnterprise.map((enterprise) => {
      return { label: enterprise.location_enterprise, value: enterprise.id };
    });
  });

  const onEditAor = (aor) => {
    setCreateAorMode('Edit');
    setShowCreateAor(true);
    setUpdateAorData(aor);
  };
  const onDeleteAor = (aor) => {
    setShowConfirmModal(true);
    setUpdateAorData(aor);
  };

  const onDeleteAorConfirm = () => {
    reduxDispatch(deleteAOR(updateAorData.id));
    setShowConfirmModal(false);
    setUpdateAorData(null);
  };
  const allAors = useSelector((state) => state.app.allAor);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (nestedCategories) {
      const formattedTypes = nestedCategories.reduce((acc, category) => {
        const parentId = category.parent_category_id;
        const dependsOn =
          !!parentId &&
          nestedCategories.find((nested) => nested.id === parentId)
            ?.category_name;

        const type = {
          label: category.category_name,
          value: category.id,
          ...(dependsOn ? { dependsOn } : {}),
        };

        return [...acc, { ...type }];
      }, []);

      setValue('locationType', formattedTypes[0]);
      setValue('locationName', updateLocationData?.category_item_name);
      setDescription(updateLocationData?.aor_description)
      setLocationTypes(formattedTypes);
    }
  }, [nestedCategories, updateLocationData]);

  useEffect(() => {
    if (nestedCategories.length > 0 && allAors.length > 0) {
      const allSelectedAor = [];
      nestedCategories.forEach((category) => {
        category.items.forEach((item) => {
          allSelectedAor.push(item.aor_id);
        });
      });
      const allAor = allAors
        .filter((aor) => !allSelectedAor.includes(aor.id))
        .map((aor) => ({
          label: (
            <Label
              label={aor.name}
              value={aor.id}
              onEdit={() => onEditAor(aor)}
              onDelete={() => onDeleteAor(aor)}
            />
          ),
          value: aor.id,
        }));
      setAllAvaliableAor([
        { label: 'Create AOR', value: 'Create AOR' },
        ...allAor,
      ]);
    } else {
      setAllAvaliableAor([]);
    }
  }, [allAors, nestedCategories]);

  useEffect(() => {
    if (!!updateLocationData && updateLocationType) {
      const formattedTypes = nestedCategories.reduce((acc, category) => {
        const parentId = category.parent_category_id;
        const dependsOn =
          !!parentId &&
          nestedCategories.find((nested) => nested.id === parentId)
            ?.category_name;

        const type = {
          label: category.category_name,
          value: category.id,
          ...(dependsOn ? { dependsOn } : {}),
        };

        return [...acc, { ...type }];
      }, []);

      setValue(
        'locationType',
        formattedTypes.find(
          (type) => type.value === updateLocationData.category_id
        )
      );
      setValue('locationName', updateLocationData?.category_item_name);
      setLocationTypes(formattedTypes);

      if (updateLocationData.parent_item_id) {
        const parentId = updateLocationData.parent_item_id;
        const label = nestedCategories.reduce((acc, category) => {
          const foundItem = category?.items.find(
            (item) => item.id === parentId
          );

          return foundItem || acc;
        }, {})?.category_item_name;

        setValue('refLocation', {
          label,
          value: parentId,
        });
      }
    }

    if (updateLocationData?.geo_location) {
      setGeoLocation(updateLocationData.geo_location);
    }
  }, [updateLocationData, updateLocationType]);

  const onAddLocation = async (data) => {
    const current_location_geom = !!geoLocation
          && !!geoLocation.geojson
          &&  geoLocation.geojson.data.features[0].geometry
      const item = {
        id: mode === 'Edit' ? updateLocationData.id : generateUUID(),
        category_id: data.locationType?.value,
        category_item_name: data.locationName,
        geoLocation,
        current_location_geom: current_location_geom,
        parent_item_id: data.refLocation?.value,
        aor_id: data.locationName?.value,
        description: description,
      }

      reduxDispatch(upsertNestedCategoryItem(item))

    onClose();
  };

  const onLocationTypeChange = (e, onChange) => {
    const locationRefId = getValues('refLocation');
    setValue('refLocation', null);
    onChange(e);
    if (e.value === 'Sub Entity') {
      reduxDispatch(getAllLocationEntity());
    } else if (e.value === 'Enterprise') {
      reduxDispatch(getAllLocationSubEntity('All'));
    } else if (e.value === 'Asset') {
      reduxDispatch(getAllLocationEnterprise('All'));
    }
  };

  const handleSaveClick = () => {
    onAddLocation(getValues());
  };

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const [description, setDescription] = useState()

  const mapboxToken = productionEnv
    ? window.env.MAPBOX_ACCESS_TOKEN
    : process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  const generateReferenceLocationOptions = (locationType) => {
    return nestedCategories
      .find((category) => category.category_name === locationType?.dependsOn)
      ?.items?.map((item) => {
        return {
          label: item.category_item_name,
          value: item.id,
        };
      });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              {`${
                mode === 'Edit' ? 'Update' : 'Add'
              } Area of Responsibility (AOR)`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!!locationTypes.length && !!locationTypes[0].label && (
              <>
                <div className="mb-3">
                  <label className="form-label">{`Select AOR Category`}</label>
                  <Controller
                    control={control}
                    name="locationType"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        options={locationTypes}
                        onChange={(e) => onLocationTypeChange(e, onChange)}
                        value={value}
                        isClearable={false}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors.locationType && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
                <hr className="dashed my-4" />
              </>
            )}
            {!!watch('locationType')?.dependsOn && (
              <div className="mb-3">
                <label className="form-label">
                  {`Select AOR ${watch('locationType')?.dependsOn}`}
                </label>
                <Controller
                  control={control}
                  name="refLocation"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={generateReferenceLocationOptions(
                        watch('locationType')
                      )}
                      onChange={(e) => onChange(e)}
                      placeholder={`Select Location ${
                        watch('locationType')?.dependsOn
                      }`}
                      value={value}
                      isClearable={false}
                      isSearchable={false}
                      isMulti={false}
                      isDisabled={false}
                    />
                  )}
                />
                {errors.refLocation && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
            )}

            <div className="mb-3">
              <label className="form-label">{`Name`}</label>
              <Controller
                control={control}
                name="locationName"
                rules={{ required: true, maxLength: locationNameStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={onChange}
                    value={value}
                    placeholder={`Enter Name`}
                  />
                )}
              />
              {errors?.locationName?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.locationName?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {locationNameStringLength} character allowed
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <StylishNewTextArea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <hr className="dashed my-4" />
            <h4>Geolocation</h4>
            <div className="m-0">
              <GeoLocationComponent
                location={geoLocation}
                setLocation={setGeoLocation}
                mapboxToken={mapboxToken}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                //onClick={() => handleSubmit(handleSaveClick)}
                type="submit"
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
          {showCreateAor && (
            <AddUpdateAor
              show={showCreateAor}
              onClose={() => setShowCreateAor(false)}
              mode={createAorMode}
              editAorData={updateAorData}
            />
          )}
          {showConfirmModal && (
            <StylishConfirmDialog
              show={showConfirmModal}
              dialogTitle={'Delete Aria of Responsibility (AOR)?'}
              dialogContent={
                'Are you sure you want to delete this Aria of Responsibility (AOR)?'
              }
              onClose={() => setShowConfirmModal(false)}
              onConfirm={onDeleteAorConfirm}
            />
          )}
        </form>
      </Modal>
    </>
  );
}
