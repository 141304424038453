import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as turf from '@turf/turf';
import { MdLocationOn } from 'react-icons/md';
import moment from 'moment';
import mapboxgl from '!mapbox-gl';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import IconClose from '../../../assets/images/icon__times.svg';
import IconArrowLeft from '../../../assets/images/icon__arrow--left.svg';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput.js';
import { fetchTaskListDataAction } from 'actions/TaskManagementActions';
import { fetchRostersForGroups } from 'actions/roleActions';
import { RTO_TIME_FRAME_OPTIONS, TASK_COLOR_TAB_DATA, TASK_STATUS_LIST } from 'components/TaskManagement/TaskManagementConstant';
import {
  getAbbreviationName,
  getProcessStability,
  getTaskStatusColor,
} from 'components/TaskManagement/TaskManagementUtil';
import TaskManagementMapEffect from 'components/TaskManagement/TaskManagementMapEffect';
import TaskManagementGraph from 'components/TaskManagement/TaskManagementGraph';
import TaskManagementMapDetail from 'components/TaskManagement/TaskManagementMapDetail';
import ReactPaginate from 'react-paginate';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import IconSort from 'assets/images/icon__sort--blue.svg';
import TaskManagementFilter from 'components/TaskManagement/TaskManagementFilter';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';

const MapTaskManagement = (props) => {
  const {
    map,
    mapHasLoaded,
    apiPrefix,
    selectedTask,
    setSelectedTask,
    setLayerClicked,
    onClose,
  } = props;

  const reduxDispatch = useDispatch();

  // Selector variables
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const rostersForGroup = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });
  const reduxTaskList = useSelector((state) => {
    return state.app.taskList;
  });
  const totalTaskList = useSelector((state) => {
    return state.app.totalTaskList;
  });
  // State Declarations
  const [showSidebarSecondary, setShowSidebarSecondary] = useState(false);
  const [sidebarSecondaryActiveItem, setSidebarSecondaryActiveItem] = useState(null);
  const [showTasksVisibleToggle, setShowTasksVisibleToggle] = useState(false);
  const [taskSearchInput, setTaskSearchInput] = useState('');
  const [markers, setMarkers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState(null)
  const [selectedColorTab, setSelectedColorTab] = useState(TASK_COLOR_TAB_DATA[0].value);

  // functions
  const handleClickClose = () => {
    onClose();
  };

  const sidebarSecondaryHandler = (item) => {
    setShowSidebarSecondary(true);
    setSidebarSecondaryActiveItem('Selected Task');
    setSelectedTask(item);
  };

  const handleClickFilter = () => {
    setShowSidebarSecondary(true);
    setSidebarSecondaryActiveItem('Filter Task');
  }

  const flyToGeolocation = (item) => {
    if (
      !!item.geolocation &&
      item.geolocation.geojson &&
      item.geolocation.geojson.data &&
      item.geolocation.geojson.data.features &&
      item.geolocation.geojson.data.features.length
    ) {
      var box = turf.bbox(item.geolocation.geojson.data.features[0].geometry);
      map.current.fitBounds(box, { padding: 50, maxZoom: 10 });
    }
  };

  const getAssigneeName = (task) => {
    let res = '';
    if (task?.process_owner?.length > 0 && rostersForGroup?.length > 0) {
      const person = task?.process_owner[0];
      if (person?.user_name) {
        res = getAbbreviationName(person?.user_name);
      }
    }

    return res;
  };

  const handleChangeFilters = (val) => {
    setSelectedFilters(val);
  }

  const tasks = useMemo(() => {
    let filterTaskList = [];
    if (reduxTaskList?.length > 0) {
      const sortedTaskList = [...reduxTaskList].sort((a, b) => {
        // Assuming 'created_at' is a date string in a format that Moment.js can parse
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateB - dateA;
      });

      filterTaskList = sortedTaskList.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          status: TASK_STATUS_LIST.find((elem) => elem.value === item.status)
            ?.label,
        };
      });
    }

    return filterTaskList;
  }, [reduxTaskList]);

  useEffect(() => {
    if (reduxCurrentlySelectedGroup.group_guid) {
      const filter = {
        assessment: selectedFilters?.assessment?.value,
        restoration: selectedFilters?.restoration?.value,
        stability: selectedFilters?.stability?.value,
        priority: selectedFilters?.priority?.value,
        team: selectedFilters?.team?.value,
      }
      reduxDispatch(
        fetchTaskListDataAction(
          reduxCurrentlySelectedGroup.group_guid,
          filter,
          currentPage,
          taskSearchInput
        )
      );
    }
  }, [taskSearchInput, currentPage, selectedFilters, reduxCurrentlySelectedGroup]);

  // useEffect
  useEffect(() => {
    if (reduxCurrentlySelectedGroup?.group_guid) {
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (selectedTask) {
      setShowSidebarSecondary(true);
      setSidebarSecondaryActiveItem('Selected Task');
    }
  }, selectedTask);

  useEffect(() => {
    if (map?.current && mapHasLoaded && !!reduxCurrentlySelectedGroup) {
      TaskManagementMapEffect(
        map,
        mapboxgl,
        apiPrefix,
        tasks,
        true,
        reduxCurrentlySelectedGroup,
        setLayerClicked,
        markers,
        setMarkers,
        showTasksVisibleToggle,
        selectedColorTab
      );
    }
  }, [
    map,
    mapHasLoaded,
    tasks,
    reduxCurrentlySelectedGroup,
    mapboxgl,
    showTasksVisibleToggle,
    selectedColorTab
  ]);

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {showSidebarSecondary && (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => {
              setSelectedTask(null);
              setShowSidebarSecondary(false);
              setSidebarSecondaryActiveItem(null);
            }}
          >
            <img src={IconArrowLeft} alt="" />
          </StylishNewButton>
        )}
        <h4 className="m-0">Task List</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {!showSidebarSecondary ? (
          <>
            <div className="d-flex align-items-center mb-3">
              <span>Show Tasks</span>
              <StylishSwitcher
                className="ms-auto"
                checked={showTasksVisibleToggle}
                onChange={() =>
                  setShowTasksVisibleToggle(!showTasksVisibleToggle)
                }
              />
            </div>
            <hr className="thin w-10 mt-3 mb-2" />

            <div className="d-flex">
              <StylishNewToggleButtonGroup
                data={TASK_COLOR_TAB_DATA}
                selected={selectedColorTab}
                onChange={(val) => {setSelectedColorTab(val)}}
                className="w-100"
              />
            </div>
            <hr className="thin w-10 mt-3 mb-2" />
            
            <div className="d-flex">
              <StylishNewInput
                placeholder="Search Task..."
                value={taskSearchInput}
                onChange={(e) => setTaskSearchInput(e.target.value)}
                className={'mb-3'}
              />
              <StylishNewButton
                type="button"
                className={`button--primary filter-btn ms-2`}
                onClick={() => handleClickFilter()}
              >
                <img src={IconSort} alt="" />
              </StylishNewButton>
            </div>
            <TaskManagementGraph 
              tasks={tasks} 
              height={selectedColorTab === TASK_COLOR_TAB_DATA[0].value ? 180 : 140} 
              selectedColorTab={selectedColorTab} 
              className="p-0"
            />
            <hr className="dashed w-10 mt-3 mb-2" />
            
            <div className="scroll scroll-map-tasks">
              {!!tasks?.length > 0 ? (
                <>
                  {tasks.map((task, index) => {
                    let stability = null;
                    let bgColor = getTaskStatusColor(task.status);
                    let statusLabel = task.status;
                    if (selectedColorTab === TASK_COLOR_TAB_DATA[1].value) {
                      const rtoTimeFrameObj = RTO_TIME_FRAME_OPTIONS.find(frame => frame.value === task?.rto_time_frame);
                      stability = getProcessStability(
                        task?.status, 
                        task?.deadline_date, 
                        task?.restoration_status, 
                        parseInt(task?.rto),
                        rtoTimeFrameObj,
                        task?.is_critical
                      );
                      bgColor = stability?.color;
                      statusLabel = stability?.label;
                    }
                    return (
                      <div
                        className="d-flex-column task-map-card cursor-pointer"
                        key={'tasklist-card' + index}
                        onClick={() => sidebarSecondaryHandler(task)}
                      >
                        <h5 className="mb-1">{task.title}</h5>
                        <div className="d-flex justify-content-between">
                          <div
                            className="task-status-badge"
                            style={{background: bgColor}}
                          >
                            {statusLabel}
                          </div>
                          <div className="">
                            {moment(task.deadline_date).format('MMM D YYYY')}
                          </div>
                          <span className="task-person-name">
                            {getAssigneeName(task)}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="m-0">No Task Found</p>
              )}
            </div>
            <div className="d-flex justify-content-center justify-content-md-end mt-3">
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={({ selected }) => setCurrentPage(selected + 1)}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(totalTaskList / 50)}
                previousLabel="Prev"
                forcePage={currentPage - 1}
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        ) : (
          <>
            {/* Show Filter Task options */}
            {sidebarSecondaryActiveItem === 'Filter Task' ? (
              <>
                <div className="task-detail-container">
                  <h4 className="mb-1">Filter Task</h4>

                  <TaskManagementFilter isVertical={true} onChange={(val) => handleChangeFilters(val)} filters={selectedFilters} />
                </div>
              </>
            ) : null}

            {/* Show Selected Task */}
            {sidebarSecondaryActiveItem === 'Selected Task' ? (
              <div className="tab-wrapper map-tab-container">
                <div
                  className="anchor anchor--white cursor-pointer d-flex align-items-center bg-gray-900 p-3 rounded mb-3"
                  onClick={() => flyToGeolocation(selectedTask)}
                >
                  <MdLocationOn className="img-h-20 me-3" />
                  {selectedTask?.geolocation ? (
                    <>
                      {selectedTask?.geolocation?.name ||
                        selectedTask?.geolocation?.address ||
                        (selectedTask?.geolocation?.centroid?.geometry?.coordinates
                          ? JSON.stringify(
                              selectedTask?.geolocation?.centroid?.geometry
                                ?.coordinates
                            )
                          : '')}
                    </>
                  ) : (
                    <>No Geolocation Selected</>
                  )}
                </div>
                <TaskManagementMapDetail task={selectedTask} />
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default MapTaskManagement;
