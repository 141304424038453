import config from 'constants/apiConfig';
import axios from 'axios';
import {
  FETCH_ALL_ROLES_IN_GROUP_REQUEST,
  FETCH_ALL_ROLES_IN_GROUP_SUCCESS,
  FETCH_ALL_ROLES_IN_GROUP_ERROR,
  FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_REQUEST,
  FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_SUCCESS,
  FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_ERROR,
  FETCH_ROLE_ACTIONS_LOG_REQUEST,
  FETCH_ROLE_ACTIONS_LOG_SUCCESS,
  FETCH_ROLE_ACTIONS_LOG_ERROR,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_ERROR,
  EDIT_ROLE_REQUEST,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_ERROR,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  ASSIGN_ROLE_REQUEST,
  ASSIGN_ROLE_SUCCESS,
  ASSIGN_ROLE_ERROR,
  UNASSIGN_ROLE_REQUEST,
  UNASSIGN_ROLE_SUCCESS,
  UNASSIGN_ROLE_ERROR,
  FETCH_ROSTERS_FOR_GROUPS_REQUEST,
  FETCH_ROSTERS_FOR_GROUPS_SUCCESS,
  FETCH_ROSTERS_FOR_GROUPS_ERROR,
  FETCH_ALL_PERMISSIONS_REQUEST,
  FETCH_ALL_PERMISSIONS_SUCCESS,
  FETCH_ALL_PERMISSIONS_ERROR,
  FETCH_ALL_PERMISSIONS_IN_ROLE_REQUEST,
  FETCH_ALL_PERMISSIONS_IN_ROLE_SUCCESS,
  FETCH_ALL_PERMISSIONS_IN_ROLE_ERROR,
  REMOVE_USER_FROM_GROUP_REQUEST,
  REMOVE_USER_FROM_GROUP_SUCCESS,
  REMOVE_USER_FROM_GROUP_ERROR,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_ERROR,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_ERROR,
  FETCH_INCIDENTS_FOR_GROUP_REQUEST,
  FETCH_INCIDENTS_FOR_GROUP_SUCCESS,
  FETCH_INCIDENTS_FOR_GROUP_ERROR,
} from 'constants/role-action-types';

import { updateCurrentlySelectedGroup } from 'actions/diceActions';

import { fetchGroups } from './profileActions';

import { validateRBACPermissionForAction } from 'actions/validationActions';
import { endLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

function fetchAllRolesInGroupRequest() {
  return {
    type: FETCH_ALL_ROLES_IN_GROUP_REQUEST,
  };
}

function fetchAllRolesInGroupError(error) {
  const errorMessage =
    'fetchAllRolesInGroupError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ALL_ROLES_IN_GROUP_ERROR,
  };
}

export const fetchAllRolesInGroupSuccess = (allRolesInGroup) => ({
  type: FETCH_ALL_ROLES_IN_GROUP_SUCCESS,
  payload: allRolesInGroup,
});

export function fetchAllRolesInGroup(group_guid) {
  return (dispatch) => {
    dispatch(fetchAllRolesInGroupRequest());
    return axios(config.apiGateway.fetchAllRolesInGroup, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
      }),
    })
      .then((response) => {
        const allRolesInGroup = response.data || {};
        dispatch(fetchAllRolesInGroupSuccess(allRolesInGroup));

        return allRolesInGroup;
      })
      .catch((error) => {
        dispatch(fetchAllRolesInGroupError(error));
      });
  };
}

function fetchAllRoleAssignmentsInGroupRequest() {
  return {
    type: FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_REQUEST,
  };
}

export const fetchAllRoleAssignmentsInGroupSuccess = (
  allRoleAssignmentsInGroup
) => ({
  type: FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_SUCCESS,
  payload: allRoleAssignmentsInGroup,
});

function fetchAllRoleAssignmentsInGroupError(error) {
  const errorMessage =
    'fetchAllRoleAssignmentsInGroupError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ALL_ROLE_ASSIGNMENTS_IN_GROUP_ERROR,
  };
}

export function fetchAllRoleAssignmentsInGroup(group_guid) {
  return (dispatch) => {
    dispatch(fetchAllRoleAssignmentsInGroupRequest());
    return axios(config.apiGateway.fetchAllRoleAssignmentsInGroup, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
      }),
    })
      .then((response) => {
        const allRoleAssignmentsInGroup = response.data || {};
        dispatch(
          fetchAllRoleAssignmentsInGroupSuccess(allRoleAssignmentsInGroup)
        );
        return allRoleAssignmentsInGroup;
      })
      .catch((error) => {
        dispatch(fetchAllRoleAssignmentsInGroupError(error));
      });
  };
}

function fetchRoleActionsLogRequest() {
  return {
    type: FETCH_ROLE_ACTIONS_LOG_REQUEST,
  };
}

export const fetchRoleActionsLogSuccess = (roleActionsLog) => ({
  type: FETCH_ROLE_ACTIONS_LOG_SUCCESS,
  payload: roleActionsLog,
});

function fetchRoleActionsLogError(error) {
  const errorMessage =
    'fetchRoleActionsLogError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ROLE_ACTIONS_LOG_ERROR,
  };
}

export function fetchRoleActionsLog(group_guid) {
  return (dispatch) => {
    dispatch(fetchRoleActionsLogRequest());
    return axios(config.apiGateway.fetchRoleActionsLog, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
      }),
    })
      .then((response) => {
        const roleActionsLog = response.data || {};
        dispatch(fetchRoleActionsLogSuccess(roleActionsLog));

        return roleActionsLog;
      })
      .catch((error) => {
        dispatch(fetchRoleActionsLogError(error));
      });
  };
}

function fetchIncidentsForGroupRequest() {
  return {
    type: FETCH_INCIDENTS_FOR_GROUP_REQUEST,
  };
}

export const fetchIncidentsForGroupSuccess = (incidentsForGroup) => ({
  type: FETCH_INCIDENTS_FOR_GROUP_SUCCESS,
  payload: incidentsForGroup,
});

function fetchIncidentsForGroupError(error) {
  const errorMessage =
    'fetchIncidentsForGroupsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_INCIDENTS_FOR_GROUP_ERROR,
  };
}

export function fetchIncidentsForGroup(group_guid) {
  return (dispatch) => {
    dispatch(fetchIncidentsForGroupRequest());
    return axios(config.apiGateway.fetchIncidentsForGroup, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
      }),
    })
      .then((response) => {
        const incidentsForGroup = response.data || {};
        dispatch(fetchIncidentsForGroupSuccess(incidentsForGroup));

        return incidentsForGroup;
      })
      .catch((error) => {
        dispatch(fetchIncidentsForGroupError(error));
      });
  };
}

function createRoleRequest() {
  return {
    type: CREATE_ROLE_REQUEST,
  };
}

export const createRoleSuccess = (createdRole) => ({
  type: CREATE_ROLE_SUCCESS,
  payload: createdRole,
});

function createRoleError(error) {
  const errorMessage =
    'createRoleError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CREATE_ROLE_ERROR,
  };
}

export function createRole(role) {
  return (dispatch) => {
    dispatch(createRoleRequest());
    return axios(config.apiGateway.createRole, {
      method: 'POST',
      data: JSON.stringify({
        role: role,
      }),
    })
      .then((response) => {
        const createdRole = response.data || {};
        dispatch(fetchAllRolesInGroup(role.group_guid));
        dispatch(createRoleSuccess(createdRole));
        // toast.success('Create Role successful', {
        //   autoClose: 2000,
        // });
        return createdRole;
      })
      .catch((error) => {
        dispatch(createRoleError(error));
      });
  };
}

function editRoleRequest() {
  return {
    type: EDIT_ROLE_REQUEST,
  };
}

export const editRoleSuccess = (editedRole) => ({
  type: EDIT_ROLE_SUCCESS,
  payload: editedRole,
});

function editRoleError(error) {
  const errorMessage =
    'editRoleError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: EDIT_ROLE_ERROR,
  };
}

export function editRole(role) {
  return (dispatch) => {
    dispatch(editRoleRequest());
    return axios(config.apiGateway.editRole, {
      method: 'POST',
      data: JSON.stringify({
        role: role,
      }),
    })
      .then((response) => {
        const editedRole = response.data || {};
        dispatch(fetchAllRolesInGroup(role.group_guid));
        dispatch(editRoleSuccess(editedRole));
        // toast.success('Edit Role successful', {
        //   autoClose: 2000,
        // });
        return editedRole;
      })
      .catch((error) => {
        dispatch(editRoleError(error));
      });
  };
}

function deleteRoleRequest() {
  return {
    type: DELETE_ROLE_REQUEST,
  };
}

export const deleteRoleSuccess = (deletedRole) => ({
  type: DELETE_ROLE_SUCCESS,
  payload: deletedRole,
});

function deleteRoleError(error) {
  const errorMessage =
    'deleteRoleError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_ROLE_ERROR,
  };
}

export function deleteRole(role) {
  return (dispatch) => {
    dispatch(deleteRoleRequest());
    return axios(config.apiGateway.deleteRole, {
      method: 'POST',
      data: JSON.stringify({
        role,
      }),
    })
      .then((response) => {
        const deletedRole = response.data || {};
        dispatch(deleteRoleSuccess(deletedRole));
        dispatch(fetchAllRolesInGroup(role.group_guid));
        dispatch(fetchRostersForGroups([role.group_guid]));
        // toast.success('Delete Role successful', {
        //   autoClose: 2000,
        // });
        return deletedRole;
      })
      .catch((error) => {
        dispatch(deleteRoleError(error));
      });
  };
}

function assignRoleRequest() {
  return {
    type: ASSIGN_ROLE_REQUEST,
  };
}

export const assignRoleSuccess = (assignedRole) => ({
  type: ASSIGN_ROLE_SUCCESS,
  payload: assignedRole,
});

function assignRoleError(error) {
  const errorMessage =
    'assignRoleError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ASSIGN_ROLE_ERROR,
  };
}

export function assignRole(role) {
  return (dispatch) => {
    dispatch(assignRoleRequest());
    return axios(config.apiGateway.assignRole, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: role.user_guid,
        group_guid: role.group_guid,
        role_id: role.role_id,
      }),
    })
      .then((response) => {
        const assignedRole = response.data || {};
        dispatch(assignRoleSuccess(assignedRole));
        dispatch(fetchRostersForGroups([role.group_guid]));
        dispatch(validateRBACPermissionForAction(role.group_guid));
        // toast.success('Assign Role successful', {
        //   autoClose: 2000,
        // });
        return assignedRole;
      })
      .catch((error) => {
        dispatch(assignRoleError(error));
      });
  };
}

function unassignRoleRequest() {
  return {
    type: UNASSIGN_ROLE_REQUEST,
  };
}

export const unassignRoleSuccess = (unassignedRole) => ({
  type: UNASSIGN_ROLE_SUCCESS,
  payload: unassignedRole,
});

function unassignRoleError(error) {
  const errorMessage =
    'unassignRoleError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UNASSIGN_ROLE_ERROR,
  };
}

export function unassignRole(role) {
  return (dispatch) => {
    dispatch(unassignRoleRequest());
    return axios(config.apiGateway.unassignRole, {
      method: 'POST',
      data: JSON.stringify({
        role_id: role.id,
        group_guid: role.group_guid,
        user_guid: role.user_guid,
      }),
    })
      .then((response) => {
        const unassignedRole = response.data || {};
        dispatch(unassignRoleSuccess(unassignedRole));
        dispatch(fetchRostersForGroups([role.group_guid]));
        dispatch(validateRBACPermissionForAction(role.group_guid));
        // toast.success('Unassign Role successful', {
        //   autoClose: 2000,
        // });
        return unassignedRole;
      })
      .catch((error) => {
        dispatch(unassignRoleError(error));
      });
  };
}

function fetchRostersForGroupsRequest() {
  return {
    type: FETCH_ROSTERS_FOR_GROUPS_REQUEST,
  };
}

export const fetchRostersForGroupsSuccess = (rostersForGroups) => ({
  type: FETCH_ROSTERS_FOR_GROUPS_SUCCESS,
  payload: rostersForGroups,
});

function fetchRostersForGroupsError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchRostersForGroupsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ROSTERS_FOR_GROUPS_ERROR,
  };
}

export function fetchRostersForGroups(group_guids = []) {
  return (dispatch, getState) => {
    dispatch(fetchRostersForGroupsRequest());
    const { user_guid } = getState().app.user;
    return axios(config.apiGateway.fetchRostersForGroups, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: user_guid,
        group_guids: group_guids,
      }),
    })
      .then((response) => {
        const rostersForGroups = response.data || {};
        dispatch(fetchRostersForGroupsSuccess(rostersForGroups));

        return rostersForGroups;
      })
      .catch((error) => {
        dispatch(fetchRostersForGroupsError(error));
      });
  };
}

function fetchAllPermissionsRequest() {
  return {
    type: FETCH_ALL_PERMISSIONS_REQUEST,
  };
}

export const fetchAllPermissionsSuccess = (allPermissions) => ({
  type: FETCH_ALL_PERMISSIONS_SUCCESS,
  payload: allPermissions,
});

function fetchAllPermissionsError(error) {
  const errorMessage =
    'fetchAllPermissionsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ALL_PERMISSIONS_ERROR,
  };
}

export function fetchAllPermissions() {
  return (dispatch) => {
    dispatch(fetchAllPermissionsRequest());
    return axios(config.apiGateway.fetchAllPermissions, {
      method: 'POST',
    })
      .then((response) => {
        const allPermissions = response.data || {};
        dispatch(fetchAllPermissionsSuccess(allPermissions));

        return allPermissions;
      })
      .catch((error) => {
        dispatch(fetchAllPermissionsError(error));
      });
  };
}

function fetchAllPermissionsInRoleRequest() {
  return {
    type: FETCH_ALL_PERMISSIONS_IN_ROLE_REQUEST,
  };
}

export const fetchAllPermissionsInRoleSuccess = (allPermissionsInRole) => ({
  type: FETCH_ALL_PERMISSIONS_IN_ROLE_SUCCESS,
  payload: allPermissionsInRole,
});

function fetchAllPermissionsInRoleError(error) {
  const errorMessage =
    'fetchAllPermissionsInRoleError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ALL_PERMISSIONS_IN_ROLE_ERROR,
  };
}

export function fetchAllPermissionsInRole(role) {
  return (dispatch) => {
    dispatch(fetchAllPermissionsInRoleRequest());
    return axios(config.apiGateway.fetchAllPermissionsInRole, {
      method: 'POST',
      data: JSON.stringify({
        role_id: role.id,
        group_guid: role.group_guid,
      }),
    })
      .then((response) => {
        const allPermissionsInRole = response.data || {};
        dispatch(fetchAllPermissionsInRoleSuccess(allPermissionsInRole));

        return allPermissionsInRole;
      })
      .catch((error) => {
        dispatch(fetchAllPermissionsInRoleError(error));
      });
  };
}

function removeUserFromGroupRequest() {
  return {
    type: REMOVE_USER_FROM_GROUP_REQUEST,
  };
}

export const removeUserFromGroupSuccess = (removedUserFromGroup) => ({
  type: REMOVE_USER_FROM_GROUP_SUCCESS,
  payload: removedUserFromGroup,
});

function removeUserFromGroupError(error) {
  let errorMessage = '';

  if (error.message === 'Request failed with status code 401') {
    errorMessage =
      'You do not have permission to remove users from this group.';
  }

  errorMessage +=
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: REMOVE_USER_FROM_GROUP_ERROR,
  };
}

export function removeUserFromGroup(remove_user_guid, group_guid) {
  return (dispatch, getState) => {
    const { user_guid } = getState().app.user;
    dispatch(removeUserFromGroupRequest());
    return axios(config.apiGateway.removeUserFromGroup, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: remove_user_guid,
        group_guid: group_guid,
      }),
    })
      .then((response) => {
        const removedUserFromGroup = response.data || {};
        // If the user removes themselves from the group, the page should simply reload to prevent any data from that group to remain.
        if (remove_user_guid === user_guid) {
          window.location.reload();
        }
        dispatch(removeUserFromGroupSuccess(removedUserFromGroup));
        dispatch(fetchRostersForGroups([group_guid]));
        // toast.success('Remove User from Group successful', {
        //   autoClose: 2000,
        // });
        return removedUserFromGroup;
      })
      .catch((error) => {
        dispatch(removeUserFromGroupError(error));
      });
  };
}

function editGroupRequest() {
  return {
    type: EDIT_GROUP_REQUEST,
  };
}

export const editGroupSuccess = (editedGroup) => ({
  type: EDIT_GROUP_SUCCESS,
  payload: editedGroup,
});

function editGroupError(error) {
  let errorMessage = '';

  if (error.message === 'Request failed with status code 401') {
    errorMessage = 'You do not have permission to edit the group.';
  } else {
    errorMessage =
      'editGroupError\n\n' +
      ((!!error &&
        !!error.message &&
        typeof error.message === 'string' &&
        error.message) ||
        '') +
      '\n\nIf you continue to experience this error, contact support@disastertech.com';
  }

  // errorMessage += "\n\nIf you continue to experience this error, contact support@disastertech.com"
  // const errorMessage = "editGroupError\n\n"+((!!error && !!error.message && typeof error.message === "string" && error.message) || "")+"\n\nIf you continue to experience this error, contact support@disastertech.com"
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: EDIT_GROUP_ERROR,
  };
}

export function editGroup(group) {
  return (dispatch) => {
    dispatch(editGroupRequest());
    return axios(config.apiGateway.editGroup, {
      method: 'POST',
      data: JSON.stringify({
        group: group,
      }),
    })
      .then((response) => {
        const editedGroup = response.data || {};
        dispatch(editGroupSuccess(editedGroup));
        // toast.success('Edit Group successful', {
        //   autoClose: 2000,
        // });
        dispatch(endLoading());
        return editedGroup;
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          JSON.stringify(error.response.data) ===
            '{"ERROR":"Group Name Exists"}'
        ) {
          dispatch(
            editGroupError({
              message: 'This Group name is in use.  Please enter a new one.',
            })
          );
        } else {
          dispatch(editGroupError(error));
        }
      });
  };
}

function deleteGroupRequest() {
  return {
    type: DELETE_GROUP_REQUEST,
  };
}

export const deleteGroupSuccess = (deletedGroup) => ({
  type: DELETE_GROUP_SUCCESS,
  payload: deletedGroup,
});

function deleteGroupError(error) {
  // const errorMessage = "deleteGroupError\n\n"+((!!error && !!error.message && typeof error.message === "string" && error.message) || "")+"\n\nIf you continue to experience this error, contact support@disastertech.com"
  const errorMessage =
    'This group cannot be deleted as it has data associated with it.';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_GROUP_ERROR,
  };
}

export function deleteGroup(group_guid) {
  return (dispatch, getState) => {
    const state = getState();
    const groups = state.app.groups;
    dispatch(deleteGroupRequest());
    return axios(config.apiGateway.deleteGroup, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
      }),
    })
      .then((response) => {
        const deletedGroup = response.data || {};
        //alert("This group has been deleted.  For security and performance purposes, this page will now reload.")
        window.location.reload();
        dispatch(fetchGroups());
        dispatch(deleteGroupSuccess(deletedGroup));
        dispatch(
          updateCurrentlySelectedGroup(
            groups.find((group) => group.group_guid !== group_guid)
          )
        );
        // toast.success('Delete Group successful', {
        //   autoClose: 2000,
        // });
        return deletedGroup;
      })
      .catch((error) => {
        dispatch(deleteGroupError(error));
        dispatch(endLoading());
      });
  };
}

export function updateGroupWebsocketResult(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.app.user.user_guid;
    const groups = state.app.groups;
    const implicatedGroup = groups.find(
      (group) => group.group_guid === payload.group
    );
    if (implicatedGroup && userId !== payload.requesting_user) {
      toast.error(`Changes have been made to the ${implicatedGroup.group_name} group. Reload your browser to see updates.`)
    }
  };
}
