import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { updateHospital } from 'actions/IAPForm206Actions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { inputStringLength, contactStringLength } from 'assets/data/config';

export const AddHospitalDialog = ({
  show,
  onClose,
  refreshHospitalList,
  hospitalDialogType,
  editHospitalData,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [traumaCenter, setTraumaCenter] = useState(false);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    setValue('trauma_center', false);
    setValue('burn_ctr', false);
    setValue('helipad', false);
  }, []);

  useEffect(() => {
    if (editHospitalData?.id) {
      setValue('name', !!editHospitalData.name && editHospitalData.name.trim());
      setValue(
        'address',
        !!editHospitalData?.address && editHospitalData.address.trim()
      );
      setValue(
        'contact',
        !!editHospitalData?.contact && editHospitalData.contact.trim()
      );
      setValue(
        'travel_time_ground',
        !!editHospitalData?.travel_time_ground &&
          editHospitalData.travel_time_ground
      );
      setValue(
        'travel_time_air',
        !!editHospitalData?.travel_time_air && editHospitalData.travel_time_air
      );
      setValue('trauma_center', editHospitalData.trauma_center);
      setTraumaCenter(editHospitalData.trauma_center);
      setValue(
        'trauma_center_level',
        editHospitalData.trauma_center && editHospitalData?.trauma_center_level
          ? editHospitalData.trauma_center_level.trim()
          : ''
      );
      setValue('burn_ctr', editHospitalData.burn_ctr);
      setValue('helipad', editHospitalData.helipad);
    }
  }, [editHospitalData]);

  const createNewHospital = (hospitalData) => {
    reduxDispatch(
      updateHospital({
        id: editHospitalData?.id || generateUUID(),
        ...hospitalData,
        trauma_center_level: hospitalData?.trauma_center
          ? hospitalData?.trauma_center_level
          : '',
      })
    );
    refreshHospitalList();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(createNewHospital)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              {`${
                hospitalDialogType === 'New'
                  ? 'Add New'
                  : hospitalDialogType === 'Edit'
                  ? 'Edit'
                  : null
              } Hospital`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <Controller
                control={control}
                name="name"
                rules={{ required: true, maxLength: inputStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.name?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.name?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Address</label>
              <Controller
                control={control}
                name="address"
                rules={{ required: true, maxLength: inputStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.address?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.address?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Contact</label>
              <Controller
                control={control}
                name="contact"
                rules={{ required: true, maxLength: contactStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.contact?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.contact?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {contactStringLength} character allowed
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Travel Time (Ground)</label>
              <Controller
                control={control}
                name="travel_time_ground"
                rules={{
                  required: true,
                  max: 999.99,
                  min: 0,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="number"
                    onChange={onChange}
                    value={value}
                    step={'0.01'}
                  />
                )}
              />
              {errors?.travel_time_ground?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.travel_time_ground?.type === 'max' && (
                <span className="form-text form-error">
                  Maximum value can be 999.99
                </span>
              )}
              {errors?.travel_time_ground?.type === 'min' && (
                <span className="form-text form-error">
                  Minimum value can be 0
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Travel Time (Air)</label>
              <Controller
                control={control}
                name="travel_time_air"
                rules={{
                  required: true,
                  max: 999.99,
                  min: 0,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="number"
                    onChange={onChange}
                    value={value}
                    step={'0.01'}
                  />
                )}
              />
              {errors?.travel_time_air?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.travel_time_air?.type === 'max' && (
                <span className="form-text form-error">
                  Maximum value can be 999.99
                </span>
              )}
              {errors?.travel_time_air?.type === 'min' && (
                <span className="form-text form-error">
                  Minimum value can be 0
                </span>
              )}
            </div>

            <div className="mt-4">
              <Controller
                control={control}
                name="trauma_center"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewCheckbox
                    onClick={(e) =>
                      onChange(e) && setTraumaCenter(!traumaCenter)
                    }
                    checked={value}
                    label={'Trauma Center'}
                  />
                )}
              />
            </div>

            {traumaCenter ? (
              <div className="mt-3">
                <label className="form-label">Trauma Center Level</label>
                <Controller
                  control={control}
                  name="trauma_center_level"
                  rules={{
                    required: traumaCenter,
                    maxLength: inputStringLength,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="text"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors?.trauma_center_level?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
                {errors?.trauma_center_level?.type === 'maxLength' && (
                  <span className="form-text form-error">
                    Max {inputStringLength} character allowed
                  </span>
                )}
              </div>
            ) : null}

            <div className="mt-4">
              <Controller
                control={control}
                name="burn_ctr"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewCheckbox
                    onClick={onChange}
                    checked={value}
                    label={'Burn ctr'}
                  />
                )}
              />
            </div>

            <div className="mt-4">
              <Controller
                control={control}
                name="helipad"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewCheckbox
                    onClick={onChange}
                    checked={value}
                    label={'Helipad'}
                  />
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton className="button--primary" type="submit">
                Save
              </StylishNewButton>
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={onClose}
              >
                Cancel
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
