import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSStrikeTeamDialog from 'components/ICS/NewICSStrikeTeamDialog';
import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { updateStrikeTeam } from 'slices/ics_strikeTeamsSlice';

export default function StrikeTeamsDialog({ show, onClose, ics_strike_teams, incident_id }) {
  const dispatch = useDispatch();
  const [isNewStrikeTeamDialogOpen, setIsNewStrikeTeamDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editStrikeTeamData, setEditStrikeTeamData] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);

  const columns = [
    { dataField: 'name', text: 'Name', sort: true, attrs: { title: 'Name' } },
    { dataField: 'description', text: 'Description', sort: true, attrs: { title: 'Description' } },
    { dataField: 'status', text: 'Status', sort: true, attrs: { title: 'Status' } },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          {/*<ICSIncidentTactic
            row={row}
            ics_tactics={ics_tactics}
            ics_incident_tactics={ics_incident_tactics}
            ics_incident_strategies={ics_incident_strategies}
          />*/}
          <div className="button-group">
            <StylishNewButton
              className="button--primary"
              onClick={() => onEdit(row)}
            >
              Edit
            </StylishNewButton>
            <StylishNewButton
              className="button--tertiary button--reverse"
              onClick={() => onArchive(row)}
            >
              {row.archived ? `Unarchive` : `Archive`}
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewStrikeTeamDialogOpen(true);
    setEditStrikeTeamData(row);
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      archived: !archiveConfirmationData.archived,
    };
    dispatch(updateStrikeTeam(archiveConfirmationData.id, payload));
    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewStrikeTeam = () => {
    setModalType('New');
    setIsNewStrikeTeamDialogOpen(true);
  };

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Strike Teams</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">{reduxCurrentlySelectedGroup?.group_name}</p>
        <StylishNewButton type="button" className="button--primary mb-4" onClick={onNewStrikeTeam}>
          New Strike Team
        </StylishNewButton>
        <StylishNewTable 
          keyField={'id'} 
          rows={ics_strike_teams} 
          columns={columns} 
          expandRow={expandRow}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton className="button--secondary button--reverse" type="button" onClick={onClose}>
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewStrikeTeamDialogOpen && (
        <NewICSStrikeTeamDialog
          show={isNewStrikeTeamDialogOpen}
          setEditStrikeTeamData={setEditStrikeTeamData}
          setModalType={setModalType}
          editStrikeTeamData={editStrikeTeamData}
          modalType={modalType}
          onClose={() => setIsNewStrikeTeamDialogOpen(false)}
          
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Strike Team?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
