import {
  LOGOUT,
  UPDATE_ACCESS_REFRESH_TOKEN,
  UPDATE_SELECTED_WORKSPACE,
  UPDATE_USER_ID_NAME,
  UPDATE_USER_NAME,
  UPDATE_USER_ROLE,
  UPDATE_USER_TIME_FORMAT,
  VALIDATING_TOKEN_REQUEST,
  VALIDATING_TOKEN_SUCCESS,
} from './user.constants';
import Network from '../../service/Network';

export const validateAndUpdateToken = (token, id, refresh_token) => {
  return (dispatch) => {
    dispatch({ type: VALIDATING_TOKEN_REQUEST });
    // let isValid = false;
    if (!token) {
      // isValid = false;
      dispatch({ type: VALIDATING_TOKEN_SUCCESS });
      dispatch({ type: LOGOUT });
      return;
    }
    // if (isTokenExpired(token, refresh_token)) {
    //   toast.error("Session Expired Please Log In");
    //   dispatch({ type: VALIDATING_TOKEN_SUCCESS });
    //   dispatch({ type: LOGOUT });
    //   return;
    // }
    Network.http.defaults.headers.common.Authorization = 'Bearer ' + token;
    dispatch({
      type: VALIDATING_TOKEN_SUCCESS,
      payload: { validToken: true },
    });
  };
};

export const updateSelectedWorkspaceId = (workspaceId) => {
  console.log('workspaceId', workspaceId);
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_WORKSPACE,
      payload: { selectedWorkspaceId: workspaceId },
    });
  };
};

export const updateUserRole = (userRoles) => {
  return (dispatch) => {
    const roles = userRoles.map((role) => role.name);
    dispatch({ type: UPDATE_USER_ROLE, payload: roles });
  };
};

export const updateUserName = (name) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_NAME, payload: name });
  };
};
export const updateUserTimeFormat = (timeFormat) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_TIME_FORMAT, payload: timeFormat });
  };
};

export const updateAccessAndRefreshToken = (access_token, refresh_token) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCESS_REFRESH_TOKEN,
      payload: { token: access_token, refresh_token },
    });
  };
};

export const updateUserIdAndName = (name, id) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_ID_NAME,
      payload: { name, id },
    });
  };
};
