import React, { useState } from 'react';
import { I18n, useTranslate } from 'react-polyglot';
import { Navigate, NavLink, Outlet, Route, Routes } from 'react-router-dom';
import OrgProfile from './OrgProfile';
import OrgMembers from './OrgMembers';
import OrgTeams from './OrgTeams';
import ManageLocation from './ManageLocation';
import GroupRoles from 'components/Roles/GroupRoles';
import GroupIncidents from './GroupIncidents';
import GroupWorkspaceUpdated from './GroupWorkspaceUpdated';
import GroupAIUsage from './GroupAIUsage';
import PageTitle from '../headers/PageTitle';
import RouteError from '../Error/RouteError';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import NewOrganizationModal from './NewOrganizationModal';
import ICS from 'components/ICS/ICS';
import { useStreamlinePlatformFlag } from '../../utils/featureFlags';
import { useSubscriptionLevel } from 'components/SubscriptionManagement/useSubscriptionLevel';
import classnames from 'classnames';
import MobileWarningDialog from '../DesignSystems/MobileWarning/MobileWarningDialog';
import LockIcon from '../DesignSystems/LockIcon';
import { useIsDev } from '../../hooks/useIsDev';
import OrgSubscription from './OrgSubscription';

const Wrapper: React.FC = () => {
  const messages = {
    ORGANIZATION_SETTINGS: 'Organization Settings',
    ORG_PROFILE: 'Organization Profile',
  };
  return (
    <I18n locale="en" messages={messages}>
      <Routes>
        <Route element={<OrganizationSettings />} errorElement={<RouteError />}>
          <Route index element={<Navigate to="profile" />} />
          <Route path="/profile" element={<OrgProfile />} />
          <Route path="/members" element={<OrgMembers />} />
          <Route path="/teams" element={<OrgTeams />} />
          <Route path="/aor" element={<ManageLocation />} />
          <Route path="/roles" element={<GroupRoles />} />
          <Route path="/incidents" element={<GroupIncidents />} />
          <Route path="/exercises" element={<GroupWorkspaceUpdated />} />
          <Route
            path="/aiusage"
            element={<GroupAIUsage group_guid={null} group_name={null} />}
          />
          <Route path="/ics" element={<ICS reduxCurrentIncident={null} />} />
          <Route path="/subscription" element={<OrgSubscription />} />
          <Route path="*" element={<OrgProfile />} />
        </Route>
      </Routes>
    </I18n>
  );
};

const OrganizationSettings: React.FC = () => {
  const t = useTranslate();
  const isDev = useIsDev();

  const streamlinePlatform = useStreamlinePlatformFlag();

  const [showNewOrganizationModal, setShowNewOrganizationModal] = useState(
    false
  );

  const { lockIncident, lockExercise, freemium } = useSubscriptionLevel();

  return (
    <MobileWarningDialog>
      <div className="p-4 pt-0">
        <PageTitle title={t('ORGANIZATION_SETTINGS')}>
          <StylishNewButton
            className="button--secondary w-100 w-md-auto"
            onClick={() => setShowNewOrganizationModal(true)}
            disabled={freemium}
          >
            Create Organization
          </StylishNewButton>
        </PageTitle>
        <div className="d-flex p-3 secondary-nav">
          <div className="secondary-left-nav">
            <ul>
              <SecondaryNavLinkTab to="/groups/profile" label="Profile" />
              <SecondaryNavLinkTab to="/groups/members" label="Members" />
              <SecondaryNavLinkTab
                to="/groups/roles"
                label="Roles and Positions"
              />
              <SecondaryNavLinkTab to="/groups/teams" label="Teams" />
              <SecondaryNavLinkTab
                to="/groups/aor"
                label="Areas of Responsibility"
              />
              <SecondaryNavLinkTab
                to="/groups/incidents"
                label="Incidents"
                locked={lockIncident}
              />
              <SecondaryNavLinkTab
                to="/groups/exercises"
                label="Exercises"
                locked={lockExercise}
              />
              {!streamlinePlatform && (
                <>
                  <SecondaryNavLinkTab
                    to="/groups/aiusage"
                    label="AI Usage"
                    locked={freemium}
                  />
                  <SecondaryNavLinkTab
                    to="/groups/ics"
                    label="ICS"
                    locked={freemium}
                  />
                </>
              )}
              <SecondaryNavLinkTab
                to="/groups/subscription"
                label="Subscription"
              />
            </ul>
          </div>
          <div className="secondary-right-content pe-0">
            <Outlet />
          </div>
        </div>
      </div>
      {showNewOrganizationModal && (
        <NewOrganizationModal
          show={true}
          onClose={() => setShowNewOrganizationModal(false)}
        />
      )}
    </MobileWarningDialog>
  );
};

export default Wrapper;

const SecondaryNavLinkTab: React.FC<{
  to: string;
  label: string;
  locked?: boolean;
}> = ({ to, label, locked }) => {
  return (
    <li>
      <NavLink
        to={to}
        className={classnames(
          'tab d-flex align-items-center justify-content-between',
          { locked }
        )}
        onClick={(e) => locked && e.preventDefault()}
      >
        {label}
        {locked ? <LockIcon /> : null}
      </NavLink>
    </li>
  );
};
