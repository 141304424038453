const API = {
  userDetails: 'user-details',
  dashboard: 'dashboard',
  updateUserDetails: 'update-user-details',
  createJMET: 'create-plan-jmet',
  updateJMET: 'update-plan-jmet',
  getAllJMETs: 'plan-jmet-list',
  deleteJMETs: 'delete-plan-jmet',
  addWorkspaceLocation: 'add-workspace-location',
  deleteWorkspace: 'delete-workspace',
  inviteNewUser: 'invite-user',
  workSpaceUserList: 'workspace-user-list',
  getTEContent: 'get-te-content',
  getAllWorkspaceByOrganizationId: 'get-all-workspace-by-organizationId',
  getWorkspaceTimezone: 'get-workspace-timezone',
  getAllWorkspacesByOrgAndUser: 'get-all-workspaces-by-org-and-user',
  getAllOrganisations: 'plan-organization-list',
  deleteOrganisations: 'delete-plan-organization',
  createPlanOrganisation: 'create-plan-organization',
  updatePlanOrganisation: 'update-plan-organization',
  getAllPlatform: 'plan-platform-list',
  createPlanPlatform: 'create-plan-platform',
  updatePlanPlatform: 'update-plan-platform',
  planOrganisationListbyID: 'plan-organization-details-by-platformId',
  deletePlatform: 'delete-plan-platform',
  allTrainingPriorities: 'plan-training-priority-list',
  deleteTrainingPriorites: 'delete-plan-training-priority',
  createTrainingPriorites: 'create-plan-training-priority',
  updateTrainingPriorites: 'update-plan-training-priority',
  changePassword: 'change-password',
  planDepartmentList: 'plan-department-list',
  deletePlanDepartment: 'delete-plan-department',
  createPlanDepartment: 'create-plan-department',
  updatePlanDepartment: 'update-plan-department ',
  planDepartmentType: 'plan-department-type-list',
  deletePlanDepartmentType: 'delete-plan-department-type',
  createPlanDepartmentType: 'create-plan-department-type',
  updatePlanDepartmentType: 'update-plan-department-type',
  planCommandAgency: 'plan-command-agency-list',
  deletePlanCommand: 'delete-plan-command-agency',
  createPlanCommandAgency: 'create-plan-command-agency',
  updatePlanCommandAgency: 'update-plan-command-agency',
  planJSTPlist: 'plan-joint-staff-training-priority-list',
  deleteJSTP: 'delete-plan-joint-staff-training-priority',
  createJSTP: 'create-plan-joint-staff-training-priority',
  updateJSTP: 'update-plan-joint-staff-training-priority',
  planExerciseType: 'plan-exercise-type-list',
  createPlanExerciseType: 'create-plan-exercise-type',
  updatePlanExerciseType: 'update-plan-exercise-type',
  deletePlanExerciseType: 'delete-plan-exercise-type',
  getAllInjectMethods: 'plan-method-list',
  createPlanInjectMethod: 'create-plan-method',
  updatePlanInjectMethod: 'update-plan-method',
  deletePlanInjectMethod: 'delete-plan-method',
  getOrganizationType: 'plan-participant-type-list',
  deleteOrganizationType: 'delete-plan-participant-type',
  createOrganizationType: 'create-plan-participant-type',
  updateOrganizationType: 'update-plan-participant-type',
  getAssessmentReasonList: 'plan-assessment-reason-list',
  deleteAssessmentList: 'delete-plan-assessment-reason',
  createAssessmentReason: 'create-plan-assessment-reason',
  updateAssessmentReason: 'update-plan-assessment-reason',
  planStatusLabelList: 'plan-status-label-list',
  createPlanStatusLabel: 'create-plan-status-label',
  updatePlanStatusLabel: 'update-plan-status-label',
  deletePlanStatusLabel: 'delete-plan-status-label',
  createWorkspace: 'create-workspace',
  planStatusBoardList: 'plan-status-board-list',
  deletePlanStatusBoard: 'delete-plan-status-board',
  createPlanStatusBoard: 'create-plan-status-board',
  planStatusBoardDetails: 'plan-status-board-details-by-boardId',
  updatePlanStatusBoard: 'update-plan-status-board',
  getWorkspacebyID: 'get-workspace-by-id',
  updateWorkspace: 'update-workspace',
  removeuser: 'remove-user-from-workspace',
  addUser: 'assign-user-to-workspace',
  getUserWorkspaceRole: 'get-user-workspace-role',
  roleList: 'role-list',
  shareInviteLink: 'share-invite-link',
  updateUserCurrentExerciseWorkspace: 'update-user-current-workspace',
  assignUserRole: 'assign-user-role',
  resendInvite: 're-invite-user',
  removeUserFromWorkspace: 'remove-user-from-workspace',
  getPlanInjectOwnerList: 'plan-inject-owner-list',
  deletePlanInjectOwner: 'delete-plan-inject-owner',
  updatePlanInjectOwner: 'update-plan-inject-owner',
  createPlanInjectOwner: 'create-plan-inject-owner',
  createEvent_Exercise: 'add-event',
  updateEvent_Exercise: 'event',
  fetchAllMeeting: 'fetch-all-meeting',

  addMeetings: 'add-meeting',
  updateMeetings: 'meeting',
  deleteMeetings: 'delete-meeting',
  fetchAllFundingSources: 'fetch-all-funding',
  deleteFunding: 'delete-funding',
  addFunding: 'add-funding',
  editFunding: 'funding',
  fetchAllObjectives: 'fetch-all-objective',
  deleteObjective: 'delete-objective',
  addObjective: 'add-objective',
  editObjective: 'objective',
  deleteAccreditedTask: 'delete-task',
  fetchAllAccreditedTasks: 'fetch-all-task',
  createAccreditedTasks: 'add-task',
  editAccreditedTasks: 'task',
  fetchAllCommandPriority: 'fetch-all-priority',
  deleteCommandPriority: 'delete-priority',
  createCommandPriority: 'add-priority',
  editCommandPriority: 'priority',
  primarySource: 'plan-primary-source-list',
  subSource: 'plan-sub-source-list',
  planExerciseTypeList: 'exercise-details',
  getExersiceEvent: 'event',
  addParticipant: 'add-participant',
  updateParticipant: 'participant',
  fetchParticipant: 'participant',
  getSupportList: 'support-list',
  createSupport: 'create-support',
  updateSupport: 'update-support',
  deleteSupport: 'delete-support',

  addPlatform: 'add-platform',
  allParticipants: 'fetch-all-participant',
  fetchSingleParticipantsDetails: 'fetch-participant-details',
  deleteParticipants: 'delete-participant',
  fetchAllPlatform: 'fetch-all-platform',
  deletePlanPlatform: 'delete-platform',
  fetchAllPlanner: 'fetch-all-planner',
  addPlanner: 'add-planner',
  deletePlanner: 'delete-planner',
  updateplatform: 'platform',
  updateplanner: 'planner',
  fetchAllParticipantFunding: 'fetch-all-fsource',
  fetchAllFunding: 'fetch-all-funding',
  addFundingParticipant: 'add-fsource',
  deleteFundingSourceParticipant: 'delete-fsource',
  updateFunding: 'fsource',
  fetchAllOganizationalCapabilityTarget:
    'fetch-all-organizational-capability-target',
  addOrganizationalCapabilityTarget: 'add-organizational-capability-target',
  updateOrganizationalCapabilityTarget: 'organizational-capability-target',
  deleteOrganizationalCapabilityTarget:
    'delete-organizational-capability-target',
  fetchAllCoreCapabilities: 'fetch-all-exercise-core-capability',
  fetchAllCoreData: 'fetch-all-core-capability',
  updateExerciseCoreCapabilities: 'update-exercise-core-capability',
  fetchEvents: 'fetch-events',
  createEvent: 'add-event',
  deleteEvent: 'delete-event',
  updateEvent: 'event',
  fetchSingleEvent: 'event',
  fetchAllBoardInject: 'fetch-all-board-inject',
  createTrainingObjective: 'add-to-details',
  getAllTrainingObjectives: 'fetch-all-to',
  addTrainingObjective: 'add-to-details',
  updateTrainingObjective: 'to-details',
  deleteTrainingObjective: 'delete-to-details',
  addTrainingObjectiveAssociation: 'add-association',
  addTrainingObjectiveAssociationHseep:
    'add-or-update-exercise-objective-association',
  fetchAllTrainingObjectivesAssociation: 'fetch-all-to-details',
  fetchAllTrainingObjectivesAssociationHseep:
    'fetch-all-exercise-objective-details',
  reviewTrainingObjective: 'to-review',
  reviewTrainingObjectiveHseep: 'review-exercise-objective-details',
  fetchParticipantListForTo: 'fetch-participant-list',
  createSourceTrainingObjective: 'create-plan-objective-source',
  getSourceTrainingObjective: 'plan-objective-source-list',
  createReferenceTrainingObjective: 'create-plan-objective-reference',
  getReferenceTrainingObjective: 'plan-objective-reference-list',
  createParagraphTrainingObjective: 'create-plan-objective-paragraph',
  getParagraphTrainingObjective: 'plan-objective-paragraph-list',
  createCompetenceTrainingObjective: 'create-plan-objective-competence',
  getCompetenceTrainingObjective: 'plan-objective-competence-list',
  createTaskTrainingObjective: 'create-plan-objective-task',
  getTaskTrainingObjective: 'plan-objective-task-list',
  createStandardTrainingObjective: 'create-plan-objective-measure',
  getStandardTrainingObjective: 'plan-objective-measure-list',
  createCodeTrainingObjective: 'create-plan-objective-code',
  getCodeTrainingObjective: 'plan-objective-code-list',
  addExerciseObjectiveDetail: 'add-exercise-objective-details',
  getAllExerciseObjectives: 'exercise-objective-details',
  addInject: 'add-inject',
  inject: 'inject',
  fetchAllInject: 'fetch-all-inject',
  multiplefileupload: 'multiple-file-upload',
  fetchSingleInject: 'inject',
  activityLogList: 'activity-log-list',
  addLogComment: 'add-log-comment',
  deleteInject: 'delete-inject',
  trainDashboard: 'train-dashboard',
  planDashhboard: 'plan-dashboard',
  prepareDashboard: 'prepare-dashboard',
  fetchAllInjectObjective: 'fetch-all-inject-objective',
  fetchInjectLocation: 'inject-location',
  addInjectTrainingObjective: 'add-inject-objective',
  removeInjectTrainingObjective: 'delete-inject-objective',
  getAllMSELTimeLine: 'train-timeline',
  fetchActivityLog: 'activity-log-list',
  generateSignedUrl: 'generate-signed-url',
  fetchParticipantList: 'fetch-participant-list',
  updateFeedback: 'update-feedback',
  getAllFeedback: 'get-all-feedback',
  fetchAllParticipant: 'fetch-all-participant',
  fetchAllObjective: 'fetch-all-objective',
  addFeedback: 'add-feedback',
  deleteFeedback: 'delete-feedback',
  getFeedback: 'get-feedback',
  allMissionTask: 'all-mission-task',
  addMissionTask: 'add-mission-task',
  allQualification: 'all-qualification',
  addQualification: 'add-qualification',
  planAssessmentReasonList: 'plan-assessment-reason-list',
  createPlanAssessmentReason: 'create-plan-assessment-reason',
  fetchParticipantTo: 'fetch-participant-to',
  fetchAllTo: 'fetch-all-to',
  fetchParticipantToAssessedCount: 'fetch-participant-to-assessed-count',
  fetchParticipantToFeedbackCount: 'fetch-participant-to-feedback-count',
  fetchAllAssess: 'fetch-all-assess',
  addAssess: 'add-assess',
  assess: 'assess',
  deleteAssess: 'delete-assess',
  allAssessemetList: 'all-assessemet-list',
  deleteFeedbackMissionTask: 'delete-feedback-mission-task',
  deleteFeedbackQualification: 'delete-feedback-qualification',
  deleteFeedbackLessionLearned: 'delete-feedback-lession-learned',
  getFeedbackHseep: 'get-feedback-hseep',
  getAssessemetHseep: 'get-assessemet-hseep',
  updateInjectLocation: 'location',
  addInjectLocation: 'add-location',
  deleteInjectLocation: 'delete-location',
  getReportsAnalytics: 'get-reports-analytics',
  addCustomReport: 'add-custom-report',
  allCustomReport: 'all-custom-report',
  deleteCustomReport: 'delete-custom-report',
  editCustomReport: 'edit-custom-report',
  getSingleCustomReport: 'get-custom-report',
  createNotebook: 'create-notebook',
  notebookList: 'notebook-list',
  createNoteFolder: 'create-notefolder',
  updateNotefolder: 'update-notefolder',
  notefolderAndList: 'notefolder-list',
  noteFile: 'notefile-list',
  notebookDetailsByNotebookId: 'notebook-details-by-notebookId',
  createNotepage: 'create-notepage',
  createNotesection: 'create-notesection',
  updateNotesection: 'update-notesection',
  deleteNotebook: 'delete-notebook',
  deleteNotesection: 'delete-notesection',
  deleteNotepage: 'delete-notepage',
  updateNotepage: 'update-notepage',
  updateNotebook: 'update-notebook',
  notebookFolderDetails: 'notefolder-details-by-notefolderId',
  createNotefile: 'create-notefile',
  deleteNoteFolder: 'delete-notefolder',
  deleteNoteFile: 'delete-notefile',
  updateNoteFile: 'update-notefile',
  createNetwork: 'create-media-network',
  getAllNetwork: 'media-network-list',
  getNetworkById: 'media-network-details-by-networkId',
  editNetworkById: 'update-media-network',
  sample: 'create-post',
  createMediaSite: 'create-mediasite',
  mediasiteList: 'mediasite-list',
  updateMediaSite: 'update-mediasite',
  moveNotefile: 'move-notefile',
  deleteMediaSite: 'delete-mediasite',
  mediaDashboardDetails: 'media-dashboard-details',
  createFromTo: 'create-fromto',
  updateFromTo: 'update-fromto',
  fromToList: 'fromto-list',
  deleteFromTo: 'delete-fromto',
  positionList: 'position-list',
  createPostion: 'create-position',
  updatePosition: 'update-position',
  deletePosition: 'delete-position',
  allActivityLogList: 'all-activity-log-list',
  planMetricList: 'plan-metric-list',
  deletePlanMetric: 'delete-plan-metric',
  createMetric: 'create-plan-metric',
  updateMetric: 'update-plan-metric',
  planPrimarySourceList: 'plan-primary-source-list',
  deletePlanPrimarySource: 'delete-plan-primary-source',
  planSubSourceList: 'plan-sub-source-list',
  deletePlanSubSource: 'delete-plan-sub-source',
  createPlanSubSource: 'create-plan-sub-source',
  updatePlanSubSource: 'update-plan-sub-source',
  updatePlanPrimarySource: 'update-plan-primary-source',
  createPlanPrimarySource: 'create-plan-primary-source',
  notefolderNotebookList: 'notefolder-notebook-list',
  attachedNotebookList: 'attached-notebook-list',
  attachNotebook: 'attach-notebook',
  detachNotebook: 'detach-notebook',
  addMeeting: 'add-meeting-test-route',
  updateMeeting: 'update-meeting-test-route',
  fetchListMeetingTestRoute: 'fetch-list-meeting-test-route',
  createMediaPersona: 'create-media-persona',
  postAnalyticsData: 'media-analytics-details',
  singleFileUpload: 'single-file-upload',
  publicSingleFileUpload: 'public-single-file-upload',
  createMediaProfile: 'create-media-profile',
  mediaProfileList: 'media-profile-list',
  deleteMediaProfile: 'delete-media-profile',
  mediaProfileDetailsByProfileId: 'media-profile-details-by-profileId',
  updateMediaProfile: 'update-media-profile',
  profileDetailsPostByProfileId: 'profile-details-post-by-profileId',
  fetchAllPostCalendarData: 'media-calender-details',
  fetchSelectedPostCalendarData: 'media-post-details-by-postId',
  deleteMediaNetwork: 'delete-media-network',
  deleteMediaNetworkPersona: 'delete-media-network-persona',
  mediaPersonaList: 'media-persona-list',
  deleteMediaPersona: 'delete-media-persona',
  mediaPersonaDetailsByPersonaId: 'media-persona-details-by-personaId',
  updateMediaPersona: 'update-media-persona',
  removeMediaPersonaProfile: 'remove-media-persona-profile',
  mediaPostList: 'media-post-list',
  createMediaPost: 'create-media-post',
  mediaPostDetailsByPostId: 'media-post-details-by-postId',
  updateMediaPost: 'update-media-post',
  deleteMediaPost: 'delete-media-post',
  getProfileSitePostList: 'get-profile-site-post-list',
  fetchMediaSitePostList: 'media-site-post-list',
  fetchMediaSitePostDetails: 'media-site-post-details',
  fetchProfileSitePostList: 'get-profile-site-post-list',
  viewMediaPost: 'view-media-post',
  fetchAssessmentHseep: 'view-hseep-assessement',
  fetchAssessmentDod: 'view-dod-assessement',
  fetchFeedbackHseep: 'view-hseep-feedback',
  fetchFeedbackDod: 'view-dod-feedback',
  updateMediaNoise: 'update-media-noise',
  mediaNoiseDetails: 'media-noise-details',
  removePersonaRelatedProfile: 'remove-media-persona-related-persona',
  getExerciseWorkspaceTeamSiteStatus: 'get-exercise-workspace-team-site-status',
  generateExerciseWorkspaceTeamSite: 'generate-exercise-workspace-team-site',

  // Technical Discipline
  createTd: 'create-td',
  updateTd: 'update-td',
  fetchTd: 'fetch-td',
  deleteTd: 'delete-td',
  fetchSpecificTd: 'fetch-specific-td',
  getTestObjective: 'get-all-test-objective',
  addTestObjective: 'add-test-objective',
  updateTestObjective: 'update-test-objective',
  deleteTestObjective: 'delete-test-objective',
  getCoicNumber: 'get-coic-number',

  // Data Collection
  getDataCollectionList: 'data-source-list',
  createDataCollection: 'create-data-source',
  getDataCollectionById: 'get-data-source-by-id',
  updateDataCollection: 'update-data-source',
  deleteDataCollection: 'delete-data-source',

  // TRR
  createTRR: 'create-trr',
  updateTRR: 'update-trr',
  fetchTRR: 'fetch-trr',
  deleteTRR: 'delete-trr',
  fetchTRRById: 'fetch-trr-by-id',

  // POAM
  createPOAM: 'create-poam',
  updatePOAM: 'update-poam',
  fetchPOAMs: 'get-poam-list',
  deletePOAM: 'delete-poam',
  fetchPOAMTaskId: 'get-task-id',

  // Cell
  fetchAllCell: 'fetch-all-event-cells',
  createEventCell: 'create-event-cell',
  getAllEventCell: 'get-all-event-cell',
  getEventCellById: 'get-event-cell-by-id',
  removeUserFromEventCell: 'remove-user-from-event-cell',
  assignUserToEventCell: 'assign-user-to-event-cell',
  updateEventCell: 'update-event-cell',
  deleteEventCell: 'delete-event-cell',
  primaryUserEventCell: 'primary-user-event-cell',

  // Workgroup
  getAllWorkgroup: 'get-all-workgroup',
  createWorkgroup: 'create-workgroup',
  updateWorkgroup: 'update-workgroup',
  deleteWorkgroup: 'delete-workgroup',

  // System
  fetchAllSystemDetails: 'get-all-system-details',
  createOrUpdateSystem: 'create-update-system',
  getSystemDetails: 'get-system-details-by-id',
  deleteSystem: 'delete-system-details',
  fetchContacts: 'fetch-user-details',
  fetchPoinitOfContactList: 'point-of-contact-list',
  createOrUpdatePointOfContacts: 'create-update-point-of-contact',
  createOrUpdateLogisticsRequirements: 'create-update-logistic-requirement',
  fetchLogisticsRequirements: 'logistic-requirement-list',
  fetchReviewData: 'review-system',
  fetchIndividualSystemData: 'get-individual-system-details',

  // Mission Types
  createMissionType: 'create-mission-type',
  updateMissionType: 'update-mission-type',
  getAllMissionType: 'mission-type-list',
  deleteMissionType: 'delete-mission-type',

  // Mission Profile
  createMissionProfile: 'add-mission-profile',
  updateMissionProfile: 'update-mission-profile',
  getAllMissionProfile: 'get-all-mission-profile',
  deleteMissionProfile: 'delete-mission-profile',
  // Mission Location
  upsertMissionLocation: 'upsert-mission-location',
  fetchMissionLocations: 'fetch-mission-locations',
  deleteMissionLocation: 'delete-mission-location',

  // Mission List
  fetchAllMissions: 'mission-list',

  // Mission Create , Edit & Delete
  createMission: 'create-mission',
  fetchMissionById: 'get-mission-by-id',
  updateMission: 'update-mission',
  deleteMission: 'delete-mission',
  addMissionTimeline: 'add-mission-timeline',
  updateMissionTimeline: 'update-mission-timeline',
  getMissionTimelineById: 'get-mission-timeline',
  getMissionTimelineObjectiveList: 'mission-objective-list',
  createUpdateMissionObjective: 'create-update-mission-objective',
  fetchMissionTimelineDataCollectionMethodList:
    'mission-data-collection-method-list',
  createUpdateMissionDataCollectionMethod:
    'create-update-mission-data-collection-method',
  fetchMissionSystemList: 'mission-system-list',
  upsertMissionSystem: 'create-update-mission-system',
  fetchMissionTimelineReview: 'review-mission-timeline',
  fetchAllMissionTimeline: 'get-all-mission-timeline',
  deleteMissionTimeline: 'delete-mission-timeline',
  getMissionTimelineData: 'mission-timeline-list',
  getMissionTimelineStats: 'mission-timeline-stats',
  getMissionTimelineCategoryStats: 'mission-timeline-system-categories',
  getMissionTimelineTypeStats: 'mission-timeline-system-types',
  fetchSpecificMissionTimeline: 'single-mission-timeline',
  missionTimelinePopupDetails: 'mission-timeline-popup-details',
  updateMissionTimelineStatus: 'update-mission-timeline-status',
  getTimelineActivityLog: 'timeline-activities',
  deleteVignette: 'delete-vignette',
};

export default API;
