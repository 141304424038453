import React, { useEffect, useState } from 'react';
import NewActionDialog from './NewActionDialog';
import ManageObjectivesDialog from 'components/FormBuilder/ManageObjectivesDialog';
import ManageResourcesDialog from 'components/FormBuilder/ManageResourcesDialog';
import {
  getAllObjectivesToGroup,
  getAllObjectiveToIncident,
  getAllResourcesToGroup,
  getAllResourcesToIncident,
  getRosterForIncident,
  updateIncidentObjectiveSelection,
  updateIncidentResourceSelection,
} from 'actions/incidentActions';
import {
  fetchGroupImages,
  fetchBriefing,
  setBriefingSketch,
  uploadImageToBlobStorage,
} from 'actions/diceActions';
import {
  deleteAction,
  getAllActionByIncident,
  updateIncidentBriefingSituation,
} from 'actions/IAPForm201Actions';
import { validateRBACPermissionForAction } from 'actions/validationActions';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import './IAPForm201.css';

import { Config201 } from 'components/FormBuilder/FormTemplates/FormConfig/201Config';
import { Link } from 'react-router-dom';
import { fetchSignatures } from 'actions/signatureActions';

import EditOperationalPeriodDialog from 'components/IncidentConfig/EditOperationalPeriodDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toast } from 'react-toastify';

export default function IAPForm201({ onFormRecordDataUpdate, setDataLoaded }) {
  const [actionModalType, setActionModalType] = useState('New');
  const [currentSituation, setCurrentSituation] = useState('');
  const [formState, setFormState] = useState(Config201);
  const [
    isEditOperationalPeriodOpen,
    setIsEditOperationalPeriodOpen,
  ] = useState(false);
  const [
    isManageObjectivesDialogOpen,
    setIsManageObjectivesDialogOpen,
  ] = useState(false);
  const [
    isManageResourcesDialogOpen,
    setIsManageResourcesDialogOpen,
  ] = useState(false);
  const [isNewActionDialogOpen, setIsNewActionDialogOpen] = useState(false);
  const [memberLoaded, setMemberLoaded] = useState(false);
  const [nameOpLoaded, setNmeOpLoaded] = useState(false);
  const [selectedActions, setSelectedActions] = useState([]);
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const [groupObjectives, setGroupObjectives] = useState([]);
  const [groupResources, setGroupResources] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [signatureLoaded, setSignatureLoaded] = useState(false);
  const [updateActionData, setUpdateActionData] = useState();
  const [userLoaded, setUserLoaded] = useState(false);
  const [incidentImages, setIncidentImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [isUserAdminOrPSC, setIsUserAdminOrPSC] = useState(false);
  // TICKET-4297 Location
  const [location, setLocation] = useState();

  const [
    isOperationalPeriodExpired,
    setIsOperationalPeriodExpired,
  ] = useState();

  const reduxDispatch = useDispatch();

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxRosterForIncident = useSelector((state) => {
    return state.app.rosterForIncident;
  });

  const reduxUser = useSelector((state) => {
    return state.app.user;
  });

  const reduxSignatures = useSelector((state) => {
    return state.app.signatures;
  });

  const isFetchSignaturesLoaded = useSelector((state) => {
    return state.app.isFetchSignaturesLoaded;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const isValidateRBACPermissionForActionLoaded = useSelector((state) => {
    return state.app.isValidateRBACPermissionForActionLoaded;
  });

  useEffect(() => {
    if (
      (!reduxSignatures || !reduxSignatures.length) &&
      !!isFetchSignaturesLoaded
    ) {
      reduxDispatch(fetchSignatures());
    }
  }, []);

  const briefingLoaded = useSelector((state) => {
    return state.app.isBriefingLoaded;
  });

  const groupImagesLoaded = useSelector((state) => {
    return state.app.isGroupImagesLoaded;
  });

  useEffect(() => {
    reduxCurrentIncident?.operational_period_start_date_time &&
      updateFormState(
        'Date',
        moment(reduxCurrentIncident?.operational_period_start_date_time).format(
          'DD/MM/YYYY'
        )
      );
    reduxCurrentIncident?.operational_period_start_date_time &&
      updateFormState(
        'Time',
        moment(reduxCurrentIncident?.operational_period_start_date_time).format(
          'HH:mm'
        )
      );
    updateFormState('Incident Name', reduxCurrentIncident?.name);

    // Todo: We need to add a separate number column so that the user can manually edit it.
    updateFormState('Incident Number', reduxCurrentIncident?.id);

    setNmeOpLoaded(true);
    const periodStartMoment = moment(
      reduxCurrentIncident.operational_period_start_date_time
    );
    const periodEndMoment = moment(
      reduxCurrentIncident.operational_period_start_date_time
    ).add(reduxCurrentIncident?.operational_period_interval, 'hours');
    const isWithinPeriod = moment().isBetween(
      periodStartMoment,
      periodEndMoment
    );
    setIsOperationalPeriodExpired(!isWithinPeriod);
  }, [reduxCurrentIncident]);

  const onChange = (type, value) => {
    // updateFormState method will be used to update the pdf preview.
    // we can send it a url to a png to show an image for the sketch section.
    if (type === 'Current Situation') {
      setCurrentSituation(value || '');
      updateFormState(
        '5 Situation Summary and Health and Safety Briefing for briefings or transfer of command Recognize potential incident Health and Safety Hazards and develop necessary measures remove hazard provide personal protective equipment warn people of the hazard to protect responders from those hazards',
        value
      );
    }
  };

  const updateFormState = (key, value) => {
    const index = formState.field.findIndex((field) => field.key === key);
    if (index !== -1) {
      let obj = { ...formState.field[index] };
      obj = { ...obj, value: value };
      formState.field[index] = obj;
      setFormState(formState);
      onFormRecordDataUpdate(formState);
    }
  };

  const [actions, setActions] = useState([]);

  const reduxActions = useSelector((state) => state.app.incidentActions);

  const isAddActionLoaded = useSelector((state) => state.app.isAddActionLoaded);

  useEffect(() => {
    const formattedActions = reduxActions
      ? reduxActions
          .filter((item) => item.is_action_201 === true)
          ?.map((action) => {
            return {
              ...action,
              id: action.action_id.substr(0, 4),
              current: action.current ? 'Current Action' : 'Planned Action',
            };
          })
      : [];

    setActions(formattedActions);
    reduxDispatch(endLoading());
  }, [reduxActions]);

  useEffect(() => {
    if (!!selectedObjectives && selectedObjectives.length > 0) {
      updateFormState(
        '7 Current and Planned Objectives',
        selectedObjectives.map((obj) => {
          if (obj?.description?.length > 300) {
            return obj.description.substring(0, 300);
          } else {
            return obj.description;
          }
        })
      );
    } else {
      updateFormState('7 Current and Planned Objectives', []);
    }
  }, [selectedObjectives]);

  useEffect(() => {
    let resourcesValue = [];
    if (!!selectedResources && selectedResources.length > 0) {
      selectedResources.forEach((resource, index) => {
        const singleSesource = [];
        singleSesource.push({
          key: 'ResourceRow' + (index + 1),
          type: 'Text',
          value: resource.name,
        });
        singleSesource.push({
          key: 'Resource IdentifierRow' + (index + 1),
          type: 'Text',
          value: resource.type,
        });
        singleSesource.push({
          key: 'DateTime OrderedRow' + (index + 1),
          type: 'Text',
          value: resource.datetime_ordered
            ? moment(resource.datetime_ordered).format('MM/DD/YYYY hh:mm')
            : '',
        });
        singleSesource.push({
          key: 'ETARow' + (index + 1),
          type: 'Text',
          value: resource.eta
            ? moment(resource.eta).format('MM/DD/YYYY hh:mm')
            : '',
        });
        singleSesource.push({
          key: 'Check Box' + (index + 1),
          type: 'Check',
          value: resource.arrived,
        });

        let notesKey;
        if (index == 0) {
          notesKey = 'Notes locationassignmentstatus';
        } else if (index > 0 && index < 7) {
          notesKey = 'Notes locationassignmentstatus_' + (index + 1);
        } else {
          notesKey = 'Notes locationassignmentstatusRow' + (index + 1);
        }
        singleSesource.push({
          key: notesKey,
          type: 'Text',
          value: resource.description,
        });
        resourcesValue = [...resourcesValue, ...singleSesource];
      });
    }
    updateFormState('Resource', resourcesValue);
    reduxDispatch(endLoading);
  }, [selectedResources]);

  useEffect(() => {
    let actionValue = [];
    if (!!actions && actions.length > 0) {
      actions.forEach((action, index) => {
        const singleAction = [];
        singleAction.push({
          key: 'TimeRow' + (index + 1),
          type: 'Text',
          value: action.required_date_time
            ? moment(action.required_date_time).format('MM/DD/YYYY hh:mm')
            : '',
        });
        singleAction.push({
          key: 'ActionsRow' + (index + 1),
          type: 'Text',
          value: action.description,
        });
        actionValue = [...actionValue, ...singleAction];
      });
    }
    updateFormState('Action', actionValue);
  }, [actions]);

  useEffect(() => {
    if (!reduxRosterForIncident) return;
    const incidentCommanders = reduxRosterForIncident
      .filter((member) => member.incident_seat === 'Incident Commander')
      .map(
        (member) => member.profile_settings.userName || member.email_address
      );
    const publicInformationOfficers = reduxRosterForIncident
      .filter((member) => member.incident_seat === 'Public Information Officer')
      .map(
        (member) => member.profile_settings.userName || member.email_address
      );
    const safetyOfficers = reduxRosterForIncident
      .filter((member) => member.incident_seat === 'Safety Officer')
      .map(
        (member) => member.profile_settings.userName || member.email_address
      );
    const liaisonOfficers = reduxRosterForIncident
      .filter((member) => member.incident_seat === 'Liaison officer')
      .map(
        (member) => member.profile_settings.userName || member.email_address
      );
    const operationsSectionChiefs = reduxRosterForIncident
      .filter((member) => member.incident_seat === 'Operations Section Chief')
      .map(
        (member) => member.profile_settings.userName || member.email_address
      );
    const planningSectionChiefs = reduxRosterForIncident
      .filter((member) => member.incident_seat === 'Planning Section Chief')
      .map(
        (member) => member.profile_settings.userName || member.email_address
      );
    const logisticsSectionChiefs = reduxRosterForIncident
      .filter((member) => member.incident_seat === 'Logistics Section Chief')
      .map(
        (member) => member.profile_settings.userName || member.email_address
      );
    const financeSectionChiefs = reduxRosterForIncident
      .filter((member) => member.incident_seat === 'Finance Section Chief')
      .map(
        (member) => member.profile_settings.userName || member.email_address
      );

    /* note that the "key" is goofy for 

          Planning Section Chief_2
          Operations Section Chief_2
          FinanceAdministration Section Chief
          Logistics Section Chief

        The keys in the underlying PDF are wrong, but I don't have a copy of Adobe Acrobat Pro to fix them
        */
    const orgChartValues = [
      {
        key: 'Incident Commanders',
        type: 'List',
        value: incidentCommanders,
      },
      {
        key: 'Liaison Officer',
        type: 'List',
        value: liaisonOfficers,
      },

      {
        key: 'Safety Officer',
        type: 'List',
        value: safetyOfficers,
      },
      {
        key: 'Public Information Officer',
        type: 'List',
        value: publicInformationOfficers,
      },
      {
        key: 'Planning Section Chief_2',
        type: 'List',
        value: operationsSectionChiefs,
      },
      {
        key: 'Operations Section Chief_2',
        type: 'List',
        value: planningSectionChiefs,
      },
      {
        key: 'FinanceAdministration Section Chief',
        type: 'List',
        value: logisticsSectionChiefs,
      },
      {
        key: 'Logistics Section Chief',
        type: 'List',
        value: financeSectionChiefs,
      },
    ];
    updateFormState('Organization Chart', orgChartValues);
    setMemberLoaded(true);
  }, [reduxRosterForIncident]);

  useEffect(() => {
    if (!!reduxSignatures && !!reduxSignatures.length > 0) {
      updateFormState('Signature', reduxSignatures[0].signature_image);
      updateFormState('Signature_2', reduxSignatures[0].signature_image);
      updateFormState('Signature_3', reduxSignatures[0].signature_image);
      updateFormState('Signature_4', reduxSignatures[0].signature_image);
      setSignatureLoaded(true);
    }
    if (reduxUser) {
      updateFormState(
        '6 Prepared by Name',
        reduxUser.user_name || reduxUser.email_address
      );
      updateFormState(
        '6 Prepared by Name_2',
        reduxUser.user_name || reduxUser.email_address
      );
      updateFormState(
        '6 Prepared by Name_3',
        reduxUser.user_name || reduxUser.email_address
      );
      updateFormState(
        '6 Prepared by Name_4',
        reduxUser.user_name || reduxUser.email_address
      );
      updateFormState('DateTime', moment().format('MM/DD/YYYY HH:mm:ss'));
      updateFormState('DateTime_2', moment().format('MM/DD/YYYY HH:mm:ss'));
      updateFormState('DateTime_3', moment().format('MM/DD/YYYY HH:mm:ss'));
      updateFormState('DateTime_4', moment().format('MM/DD/YYYY HH:mm:ss'));
      setUserLoaded(true);
    }
  }, [reduxSignatures, reduxUser]);

  useEffect(() => {
    if (
      !!reduxUser &&
      !!reduxRosterForIncident &&
      !!reduxRosterForIncident.length > 0
    ) {
      const currentUserPosition = reduxRosterForIncident.find(
        (member) => member.user_guid === reduxUser.user_guid
      );
      if (currentUserPosition) {
        updateFormState('PositionTitle', currentUserPosition.incident_seat);
        updateFormState('PositionTitle_2', currentUserPosition.incident_seat);
        updateFormState('PositionTitle_3', currentUserPosition.incident_seat);
        updateFormState('PositionTitle_4', currentUserPosition.incident_seat);
      }
    }
  }, [reduxUser, reduxRosterForIncident]);

  useEffect(() => {
    if (
      !!reduxUser &&
      !!isValidateRBACPermissionForActionLoaded &&
      !!reduxRosterForIncident &&
      !!reduxRosterForIncident.length > 0
    ) {
      const user = reduxRosterForIncident.find(
        (user) => user.user_guid === reduxUser.user_guid
      );
      if (user) {
        if (user.incident_seat === 'Planning Section Chief') {
          setIsUserAdminOrPSC(true);
        } else {
          const adminOrPSC = Object.values(
            reduxValidateRBACPermissionForActionResult
          ).reduce((acc, cur) => acc && cur, true);
          setIsUserAdminOrPSC(adminOrPSC);
        }
      } else {
        setIsUserAdminOrPSC(false);
      }
    }
  }, [
    reduxValidateRBACPermissionForActionResult,
    reduxUser,
    reduxRosterForIncident,
  ]);

  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );

  const isIncidentObjectivesLoaded = useSelector(
    (state) => state.app.isIncidentObjectivesLoaded
  );

  useEffect(() => {
    setSelectedObjectives(
      incidentObjectives
        ? incidentObjectives.map((ele) => {
            return {
              id: ele.objective_id,
              group_guid: ele.group_guid,
              name: null,
              description: ele.description,
              objective_id: ele.objective_id
                ? ele.objective_id.substring(0, 4)
                : ele.objective_id,
            };
          })
        : []
    );
    reduxDispatch(endLoading());
  }, [incidentObjectives]);

  const reduxGroupObjectives = useSelector(
    (state) => state.app.groupObjectives
  );

  const isGroupObjectivesLoaded = useSelector(
    (state) => state.app.isGroupObjectivesLoaded
  );

  useEffect(() => {
    setGroupObjectives(
      reduxGroupObjectives
        ? reduxGroupObjectives.map((ele) => {
            return {
              ...ele,
              objective_id: ele.id ? ele.id.substring(0, 4) : ele.id,
            };
          })
        : []
    );
    reduxDispatch(getAllActionByIncident());
  }, [reduxGroupObjectives]);

  const reduxGroupResources = useSelector((state) => state.app.groupResources);

  const isGroupResourcesLoaded = useSelector(
    (state) => state.app.isGroupResourcesLoaded
  );

  useEffect(() => {
    setGroupResources(reduxGroupResources);
    reduxDispatch(getAllActionByIncident());
  }, [reduxGroupResources]);

  const reduxIncidentResources = useSelector(
    (state) => state.app.incidentResources
  );

  const isIncidentResourcesLoaded = useSelector(
    (state) => state.app.isIncidentResourcesLoaded
  );

  useEffect(() => {
    setSelectedResources(
      reduxIncidentResources
        ? reduxIncidentResources.map((ele) => ({
            id: ele.resource_id,
            group_guid: ele.group_guid,
            name: ele.name,
            description: ele.description,
            type: ele.type,
            arrived: ele.arrived,
            location: ele.location,
            datetime_ordered: ele.datetime_ordered,
            eta: ele.eta,
          }))
        : []
    );
    reduxDispatch(endLoading());
  }, [reduxIncidentResources]);

  const groupImages = useSelector(
    (state) => state.app.groupImages?.blobResponse
  );
  const briefing = useSelector((state) => state.app.briefing);

  useEffect(() => {
    setIncidentImages(
      groupImages?.reduce((acc, item) => {
        if (reduxCurrentIncident?.id === item.incidentId) {
          // check on the valdity of the operational period next
          const imageCreationMoment = moment(item.createdOn);
          const periodStartMoment = moment(
            reduxCurrentIncident.operational_period_start_date_time
          );

          const isAfterPeriodStart = imageCreationMoment.isAfter(
            periodStartMoment
          );
          const isWithinPeriod =
            imageCreationMoment.diff(periodStartMoment, 'hours') <
            reduxCurrentIncident.operational_period_interval;

          // if (isAfterPeriodStart && isWithinPeriod) {
          // check for duplicate image names, and only select the most recent
          const displayName = item.name.slice(37).replace('_snapshot.png', '');

          const existingFileIdx = acc.findIndex(
            (prev) => prev.displayName === displayName
          );
          const existingFile = acc[existingFileIdx];

          let useNew = true;

          if (existingFile) {
            useNew = imageCreationMoment.isAfter(
              moment(existingFile.createdOn)
            );
          }

          if (useNew) {
            acc.push({
              ...item,
              displayName,
            });

            if (existingFile) {
              acc.splice(existingFileIdx, 1);
            }
          }
          // }
        }

        return acc;
      }, [])
    );

    if (
      isValidateRBACPermissionForActionLoaded &&
      reduxValidateRBACPermissionForActionResult['Read Pod Data'] &&
      !!reduxCurrentIncident
    ) {
      reduxDispatch(fetchBriefing());
    }
    reduxDispatch(endLoading());
  }, [
    reduxCurrentIncident,
    groupImages,
    isValidateRBACPermissionForActionLoaded,
  ]);

  useEffect(() => {
    if (
      !!briefing &&
      !!briefing.sketch &&
      Array.isArray(briefing.sketch) &&
      !!incidentImages
    ) {
      handleSelectedImages(
        incidentImages.filter((image) =>
          briefing.sketch.find(
            (selected) => (selected && selected.name) === image.name
          )
        )
      );
    }

    onChange('Current Situation', briefing?.situation);
  }, [briefing, incidentImages]);

  useEffect(() => {
    if (
      isValidateRBACPermissionForActionLoaded &&
      reduxValidateRBACPermissionForActionResult['Read Pod Data']
    ) {
      reduxDispatch(fetchGroupImages());
    }
  }, [isValidateRBACPermissionForActionLoaded]);

  useEffect(() => {
    reduxDispatch(
      validateRBACPermissionForAction(reduxCurrentlySelectedGroup.group_guid)
    );
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    reduxDispatch(endLoading());
    if (
      reduxCurrentlySelectedGroup &&
      !!reduxCurrentIncident &&
      reduxCurrentIncident.id
    ) {
      reduxDispatch(getAllObjectivesToGroup());
      reduxDispatch(getAllObjectiveToIncident());

      reduxDispatch(getAllResourcesToGroup());
      reduxDispatch(getAllResourcesToIncident());

      reduxDispatch(getAllActionByIncident());
      reduxDispatch(getRosterForIncident());
    }
  }, [reduxCurrentIncident, reduxCurrentlySelectedGroup]);

  const incident_id =
    (reduxCurrentIncident && reduxCurrentIncident.id) || undefined;

  const onSaveCurrentSituation = () => {
    reduxDispatch(updateIncidentBriefingSituation(currentSituation));
  };

  const allColumns = [
    {
      dataField: 'id',
      text: 'Number',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Number' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'current',
      text: 'Current / Planned',
      sort: true,
      attrs: { title: 'Current / Planned' },
    },
  ];

  const captureSnapshotColumns = [
    {
      dataField: 'displayName',
      text: 'Panel Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Panel Name' },
    },
  ];

  const onDeleteObjectiveToIncident = (row) => {
    const filtered = selectedObjectives.filter(
      (objective) => objective.id !== row.id
    );
    setSelectedObjectives(filtered);
    reduxDispatch(startLoading());
    reduxDispatch(
      updateIncidentObjectiveSelection(filtered.map((item) => item.id))
    );
  };

  const onDeleteResourceToIncident = (row) => {
    const filtered = selectedResources.filter(
      (resource) => resource.id !== row.id
    );
    setSelectedResources(filtered);
    reduxDispatch(startLoading());
    reduxDispatch(
      updateIncidentResourceSelection(filtered.map((item) => item.id))
    );
  };

  const onDeleteAction = (row) => {
    setDeleteConfirmationData(row);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteAction(deleteConfirmationData.action_id));
    setShowConfirmDialog(false);
  };

  const onEditAction = (row) => {
    setUpdateActionData(row);
    setActionModalType('Edit');
    setIsNewActionDialogOpen(true);
  };

  const onAddNewAction = () => {
    setActionModalType('New');
    setIsNewActionDialogOpen(true);
  };
  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-3">
            <h6 className="mb-1">Description</h6>
            <p className="m-0">{row.description}</p>
          </div>
          {row.objectives.every((objective) => !!objective.description) && (
            <>
              {row.objectives && row.objectives.length > 0 && (
                <div className="mb-3">
                  <h6 className="mb-1">
                    Objective(s) attached to this action:
                  </h6>
                  <ul className="list">
                    {row.objectives.map((objective) => {
                      return (
                        <li key={objective.id}>{objective.description}</li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </>
          )}
          {row.resources.every(
            (resource) => !!resource.type && !!resource.name
          ) && (
            <>
              {row.resources && row.resources.length > 0 && (
                <div className="mb-3">
                  <h6 className="mb-1">Resource(s) attached to this action:</h6>
                  <ul className="list">
                    {row.resources.map((resource) => {
                      return (
                        <li key={resource.id}>
                          {resource.type} ({resource.name})
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </>
          )}
          <div className="button-group mt-4">
            <StylishNewButton
              type="button"
              className="button--primary"
              onClick={() => onEditAction(row)}
            >
              Edit Action
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button--tertiary button--reverse"
              onClick={() => onDeleteAction(row)}
            >
              Delete
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const ImageTableDetail = ({ row }) => {
    /*Now we need to get the signed url to show up as an actual image */

    return (
      <>
        <div>Created on {moment(row.createdOn).format('MM/DD/YYYY HH:mm')}</div>
        <div>
          <img src={row.signedUrl} />
        </div>
      </>
    );
  };

  const captureSnapshotExpandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <h6 className="m-0">
            Created on {moment(row.createdOn).format('MM/DD/YYYY HH:mm')}
          </h6>
          {!!row.signedUrl && (
            <div className="bg-gray-700 p-2 mt-3">
              <img className="w-100 d-block" src={row.signedUrl} />
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected:
      !!selectedImages &&
      selectedImages.length &&
      selectedImages.map((e) => e?.name),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        handleSelectedImages([row]);
        reduxDispatch(setBriefingSketch([row]));
      } else {
        handleSelectedImages([{ url: null }]);
        reduxDispatch(setBriefingSketch([]));
      }
    },
  };

  const handleSelectedImages = (selection) => {
    setSelectedImages(selection);
    updateFormState(
      '4 MapSketch include sketch showing the total area of operations the incident sitearea impacted and threatened areas overflight results trajectories impacted shorelines or other graphics depicting situational status and resource assignment',
      selection?.[0]?.url
    );
  };

  useEffect(() => {
    if (
      (nameOpLoaded &&
        signatureLoaded &&
        userLoaded &&
        memberLoaded &&
        briefingLoaded &&
        groupImagesLoaded &&
        isIncidentObjectivesLoaded &&
        isGroupResourcesLoaded &&
        isIncidentResourcesLoaded &&
        isGroupObjectivesLoaded &&
        isAddActionLoaded) ||
      selectedImages?.[0]?.url
    ) {
      setDataLoaded();
    }
  }, [
    nameOpLoaded,
    signatureLoaded,
    memberLoaded,
    userLoaded,
    groupImagesLoaded,
    briefingLoaded,
    isIncidentObjectivesLoaded,
    isGroupObjectivesLoaded,
    isIncidentResourcesLoaded,
    isAddActionLoaded,
    actions,
    reduxCurrentIncident,
    selectedImages?.[0]?.url,
  ]);

  if (!reduxCurrentIncident) {
    return (
      <>
        <div>An incident is needed before Incident Management can continue</div>
        <Link to="/incident_configuration">Incident Workspace</Link>
      </>
    );
  }
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onImagePicke = async (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (Math.ceil(file?.size / 1000) > 100 ||
        checkFileFormat(file.name) === false)
    ) {
      toast.error('Please upload a PNG image, Max upload size 100KB');
      event.target.value = null;
      return;
    }
    if (file) {
      reduxDispatch(startLoading());
      reduxDispatch(
        uploadImageToBlobStorage({
          file: await toBase64(file),
          fileName: `${file.name}_snapshot.png`,
        })
      );
    }
  };

  const checkFileFormat = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();

    if (extension === 'png') {
      return true;
    } else {
      return false;
    }
  };
  
  return (
    <>
      <div className="mb-4">
        <h4 className="mb-2">Planning Section</h4>
        <p className="fst-italic weight-500 m-0">
          Only the seats Planning Section Chief should modify this section.
        </p>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>1. Incident Name</h4>
        <label className="form-label">Name:</label>
        <p>{reduxCurrentIncident?.name}</p>
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>2. Operational Period</h4>
        {!!reduxCurrentIncident && reduxCurrentIncident?.current_period && (
          <>
            <div className="form-group">
              <label className="form-label">From:</label>
            </div>
            <div className="mb-3">
              {moment(
                reduxCurrentIncident?.operational_period_start_date_time
              ).format('MM/DD/YYYY HH:mm')}
            </div>
            <div className="form-group">
              <label className="form-label">To:</label>
            </div>
            <div className="mb-3">
              {moment(reduxCurrentIncident?.operational_period_start_date_time)
                .add(reduxCurrentIncident?.operational_period_interval, 'hours')
                .format('MM/DD/YYYY HH:mm')}
            </div>
          </>
        )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>3. Map/Sketch</h4>
        <StylishNewButton
          type="button"
          className="button--primary button--upload"
          disabled={
            isOperationalPeriodExpired ||
            !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
            !isUserAdminOrPSC
          }
        >
          Upload Image
          <input type="file" onInput={(e) => onImagePicke(e)} />
        </StylishNewButton>
        {isOperationalPeriodExpired ? (
          <span className="form-text form-error">
            A valid operational period is required.
          </span>
        ) : !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
          !isUserAdminOrPSC ? (
          <span className="form-text form-error">
            You do not have permission to edit the form.
          </span>
        ) : null}
        {incidentImages &&
          Array.isArray(incidentImages) &&
          !!incidentImages?.length && (
            <>
              <StylishNewTable
                classes="mt-4"
                keyField={'name'}
                rows={incidentImages}
                columns={captureSnapshotColumns}
                expandRow={captureSnapshotExpandRow}
                selectRow={selectRow}
              />
            </>
          )}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>4. Current Situation</h4>
        <StylishNewTextArea
          value={currentSituation}
          onChange={(e) => onChange('Current Situation', e.target.value)}
          maxLength={1000}
        />
        <StylishNewButton
          type="button"
          className="button--primary mt-3"
          onClick={onSaveCurrentSituation}
          disabled={
            isOperationalPeriodExpired ||
            !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
            !isUserAdminOrPSC
          }
        >
          Save
        </StylishNewButton>
        {!reduxValidateRBACPermissionForActionResult['Write Pod Data'] ? (
          <span className="form-text form-error">
            You do not have permission to edit the form.
          </span>
        ) : null}
      </div>
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>
          5. Initial Response Objectives, Current Actions, Planned Actions
        </h4>
        <h4>5.1. Objectives</h4>
        <StylishNewButton
          type="button"
          className="button--primary"
          onClick={() => setIsManageObjectivesDialogOpen(true)}
          disabled={
            isOperationalPeriodExpired ||
            !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
            !isUserAdminOrPSC
          }
        >
          Manage Objectives
        </StylishNewButton>
        {!reduxValidateRBACPermissionForActionResult['Write Pod Data'] ? (
          <span className="form-text form-error">
            You do not have permission to edit the form.
          </span>
        ) : null}
        {!!selectedObjectives && selectedObjectives.length > 0 && (
          <div className="form-chip-group mt-3">
            {selectedObjectives.map((objectives) => {
              return (
                <StylishNewChip
                  key={objectives.id}
                  label={objectives.description}
                  handleDelete={() => onDeleteObjectiveToIncident(objectives)}
                />
              );
            })}
          </div>
        )}
        <h4 className="mt-4">5.2. Actions</h4>
        <StylishNewButton
          type="button"
          className="button--primary"
          onClick={onAddNewAction}
          disabled={
            isOperationalPeriodExpired ||
            !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
            !isUserAdminOrPSC
          }
        >
          New Action
        </StylishNewButton>
        {!reduxValidateRBACPermissionForActionResult['Write Pod Data'] ? (
          <span className="form-text form-error">
            You do not have permission to edit the form.
          </span>
        ) : null}
        {actions && actions.length > 0 && (
          <>
            <StylishNewTable
              classes="mt-4"
              keyField={'action_id'}
              rows={actions}
              columns={allColumns}
              expandRow={expandRow}
            />
          </>
        )}
      </div>
      {isManageObjectivesDialogOpen && (
        <ManageObjectivesDialog
          show={isManageObjectivesDialogOpen}
          onClose={() => setIsManageObjectivesDialogOpen(false)}
          groupObjectives={groupObjectives}
          selectedObjectives={selectedObjectives}
          setSelectedObjectives={setSelectedObjectives}
        />
      )}
      {isNewActionDialogOpen && (
        <NewActionDialog
          show={isNewActionDialogOpen}
          onClose={() => setIsNewActionDialogOpen(false)}
          incidentResources={selectedResources}
          incidentObjectives={incidentObjectives}
          updateActionData={updateActionData}
          actionModalType={actionModalType}
          setActionModalType={setActionModalType}
          setUpdateActionData={setUpdateActionData}
        />
      )}
      <hr className="dashed my-4" />
      <div className="mb-4">
        <h4>6. Current Organization</h4>
        <p className="fst-italic weight-500 m-0">
          Seats can be managed at the{' '}
          <Link to="/incident_configuration">Duty Roster</Link>{' '}
        </p>
      </div>
      <hr className="dashed my-4" />
      <div className="m-0">
        <h4>7. Resource Summary</h4>
        <StylishNewButton
          type="button"
          className="button--primary"
          onClick={() => setIsManageResourcesDialogOpen(true)}
          disabled={
            isOperationalPeriodExpired ||
            !reduxValidateRBACPermissionForActionResult['Write Pod Data'] ||
            !isUserAdminOrPSC
          }
        >
          Manage Resources
        </StylishNewButton>
        {!reduxValidateRBACPermissionForActionResult['Write Pod Data'] && (
          <span className="form-text form-error">
            You do not have permission to edit the resources
          </span>
        )}
        {!!selectedResources && selectedResources.length > 0 && (
          <div className="form-chip-group mt-3">
            {selectedResources.map((resource) => {
              return (
                <StylishNewChip
                  key={resource.id}
                  label={`${resource.type}, ${resource.name}`}
                  handleDelete={() => onDeleteResourceToIncident(resource)}
                />
              );
            })}
          </div>
        )}
      </div>
      {isManageResourcesDialogOpen && (
        <ManageResourcesDialog
          show={isManageResourcesDialogOpen}
          onClose={() => setIsManageResourcesDialogOpen(false)}
          groupName={reduxCurrentlySelectedGroup.group_name}
          groupResources={groupResources}
          selectedResources={selectedResources}
          setSelectedResources={setSelectedResources}
        />
      )}
      {isEditOperationalPeriodOpen && (
        <EditOperationalPeriodDialog
          show={isEditOperationalPeriodOpen}
          onClose={() => setIsEditOperationalPeriodOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this action?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </>
  );
}
