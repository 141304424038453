import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSStrategyDialog from 'components/ICS/NewICSStrategyDialog';

import { useDispatch, useSelector } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

import ICSIncidentStrategy from './ICSIncidentStrategy';

import { updateIcsStrategy } from 'slices/ics_strategiesSlice';

import {
  createIcsIncidentStrategy,
  updateIcsIncidentStrategy,
} from 'slices/ics_incidentStrategiesSlice';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';

export default function ICSStrategiesDialog({
  show,
  onClose,
  ics_strategies,
  ics_incident_strategies,
  incident_id = null,
  ics_incident_objectives,
}) {
  const reduxDispatch = useDispatch();

  const [isNewStrategyDialogOpen, setIsNewStrategyDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editStrategyData, setEditStrategyData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogStrategies, setDialogStrategies] = useState([
    ...ics_incident_strategies,
  ]);

  useEffect(() => {
    setDialogStrategies(ics_incident_strategies);
  }, [ics_incident_strategies]);

  useEffect(() => {
    // Use to rerender
  }, [ics_strategies]);

  const allColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'user_name',
      text: 'Last Modified By',
      sort: true,
      attrs: { title: 'Last Modified By' },
    },
    {
      dataField: 'archived',
      text: 'Archived',
      sort: true,
      attrs: { title: 'Archived' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentStrategy
            row={row}
            ics_strategies={ics_strategies}
            ics_incident_strategies={ics_incident_strategies}
            ics_incident_objectives={ics_incident_objectives}
          />
          {(!incident_id ||
            !!ics_incident_strategies.find(
              (is) => is.strategy_id === row.id
            )) && (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onArchive(row)}
              >
                {row.archived ? `Unarchive` : `Archive`}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />;
    },
    expandColumnRenderer: ({ expanded }) => {
      return expanded ? <ExpandDownArrow /> : <ExpandRightArrow />;
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_strategies
      .filter((s) => {
        const incStrat = ics_incident_strategies.find(
          (is) => is.strategy_id === s.id && !is.archived
        );
        return !!incStrat;
      })
      .map((s) => s.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentStrategy = ics_incident_strategies.find(
        (is) => is.strategy_id === row.id
      );
      if (existingIncidentStrategy) {
        reduxDispatch(
          updateIcsIncidentStrategy(incident_id, existingIncidentStrategy.id, {
            ...existingIncidentStrategy,
            archived: !existingIncidentStrategy.archived,
          })
        );
      } else {
        // Create a new incident strategy
        const incidentStrategyData = {
          strategy_id: row.id,
          name: row.name,
          description: row.description,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          archived: false,
        };
        reduxDispatch(
          createIcsIncidentStrategy(incident_id, incidentStrategyData)
        );
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogStrategies(rows) : setDialogStrategies([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewStrategyDialogOpen(true);
    setEditStrategyData({
      ...row,
      versions: ics_incident_strategies.find((is) => is.strategy_id === row.id)
        ?.versions,
    });
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (incident_id) {
      reduxDispatch(
        updateIcsIncidentStrategy(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsStrategy(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewStrategy = () => {
    setModalType('New');
    setIsNewStrategyDialogOpen(true);
  };

  const tableRows = ics_strategies.map((s) => {
    const incStrat = ics_incident_strategies.find(
      (is) => is.strategy_id === s.id
    );
    if (incStrat) {
      return {
        ...incStrat,
        id: s.id,
      };
    } else {
      return s;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Strategies</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewStrategy}
        >
          New Strategy
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewStrategyDialogOpen && (
        <NewICSStrategyDialog
          show={isNewStrategyDialogOpen}
          setEditStrategyData={setEditStrategyData}
          setModalType={setModalType}
          editStrategyData={editStrategyData}
          modalType={modalType}
          onClose={() => {
            setIsNewStrategyDialogOpen(false);
          }}
          incident_id={incident_id}
          ics_incident_objectives={ics_incident_objectives}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Strategy?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
