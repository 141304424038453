import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';

import {
  createIcsHospital,
  updateIcsHospital,
} from 'slices/ics_hospitalsSlice';

import { updateIcsIncidentHospital } from 'slices/ics_incidentHospitalsSlice';

export default function NewICSHospitalDialog({
  show,
  setEditHospitalData,
  editHospitalData,
  modalType,
  setModalType,
  onClose,
  incident_id,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [descriptionError, setDescriptionError] = useState({
    hasError: true,
    message: '',
    touched: false,
  });

  const [hospitalId, setHospitalId] = useState('');
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (!isLoaded) {
      onClose();
      setModalType('New');
      setEditHospitalData(undefined);
    }
  }, [isLoaded, onClose]);

  useEffect(() => {
    errorCheck(descriptionError);
  }, [descriptionError]);

  const errorCheck = (descriptionErrorNew) => {
    if (descriptionErrorNew.touched) {
      if (!descriptionErrorNew.message) {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: true,
          message: '*Description is required',
        });
      } else {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: false,
          message: '',
        });
      }
    }
  };
  const onTouched = () => {
    const descriptionErrorNew = { ...descriptionError, touched: true };
    setDescriptionError(descriptionErrorNew);
    errorCheck(descriptionErrorNew);
  };

  useEffect(() => {
    if (modalType === 'New') {
      setHospitalId(generateUUID());
    } else if (modalType === 'Edit') {
      setHospitalId(editHospitalData.id);
      setValue('description', editHospitalData.description);
      setValue('hospital_name', editHospitalData.hospital_name);
      setValue('address', editHospitalData.address);
      setValue('contact_number', editHospitalData.contact_number);
      setValue('travel_time_air', editHospitalData.travel_time_air);
      setValue('travel_time_ground', editHospitalData.travel_time_ground);
      setValue('burn_center', editHospitalData.burn_center);
      setValue('heli_pad', editHospitalData.heli_pad);
    }
  }, [modalType]);

  const onNewHospitalDataSubmit = (data) => {
    const payload = {
      id: hospitalId,
      hospital_id: hospitalId,
      hospital_name: data.hospital_name,
      description: data.description,
      address: data.address,
      contact_number: data.contact_number,
      travel_time_air: data.travel_time_air,
      travel_time_ground: data.travel_time_ground,
      burn_center: data.burn_center || false,
      heli_pad: data.heli_pad || false,
      incident_id: incident_id,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
    };
    if (modalType === 'New') {
      reduxDispatch(createIcsHospital(payload));
      onClose();
    } else if (modalType === 'Edit') {
      if (!incident_id) {
        reduxDispatch(updateIcsHospital(payload.id, payload));
      } else if (incident_id) {
        payload.id = editHospitalData?.id;
        reduxDispatch(
          updateIcsIncidentHospital(incident_id, payload.id, payload)
        );
      }
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Hospital</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewHospitalDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={hospitalId} readOnly disabled />
          </div>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="hospital_name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.hospital_name && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Address:</label>
            <Controller
              control={control}
              name="address"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Contact Number:</label>
            <Controller
              control={control}
              name="contact_number"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={20}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Travel Time (Air):</label>
            <Controller
              control={control}
              name="travel_time_air"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={50}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Travel Time (Ground):</label>
            <Controller
              control={control}
              name="travel_time_ground"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={50}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Burn Center:</label>
            <Controller
              control={control}
              name="burn_center"
              render={({ field: { onChange, value } }) => (
                <StylishNewCheckbox
                  checked={value || false}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Helipad:</label>
            <Controller
              control={control}
              name="heli_pad"
              render={({ field: { onChange, value } }) => (
                <StylishNewCheckbox
                  checked={value || false}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
