import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { upsertOperationalPeriod } from 'actions/incidentActions';
import { updateIncidentActiveStatus } from 'actions/incidentActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { startLoading } from 'reducers/loading/loading.action';

export default function EditOperationalPeriodDialog({ show, onClose }) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const reduxDispatch = useDispatch();

  const toggleData = [
    { label: 'Active', value: true },
    { label: 'Not Active', value: false },
  ];

  const [incidentActiveStatus, setIncidentActiveStatus] = useState();

  const { currentIncident: incident } = useSelector((state) => {
    return state.app;
  });

  const [isLoaded, setIsLoaded] = useState(true);

  const isUpdateIncidentLoaded = useSelector((state) => {
    return state.app.isUpdateIncidentLoaded;
  });

  const areIncidentsLoaded = useSelector((state) => {
    return state.app.areIncidentsLoaded;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  useEffect(() => {
    if (incident.operational_period_interval) {
      setValue('interval_hours', {
        label: incident.operational_period_interval,
        value: incident.operational_period_interval,
      });
    } else {
      setValue('interval_hours', { label: 12, value: 12 });
    }

    if (incident.operational_period_start_date_time) {
      setValue('start_date_time', incident.operational_period_start_date_time);
    }
  }, [incident]);

  useEffect(() => {
    if (!isLoaded && isUpdateIncidentLoaded && areIncidentsLoaded) {
      onClose();
    }
  }, [isUpdateIncidentLoaded, areIncidentsLoaded, isLoaded, onClose]);

  useEffect(() => {
    if (reduxCurrentIncident) {
      setIncidentActiveStatus(!!reduxCurrentIncident.active);
    }
  }, [reduxCurrentIncident]);

  const handleToggleActiveStatusButtonClick = (selection) => {
    setIncidentActiveStatus(selection);
    reduxDispatch(updateIncidentActiveStatus(selection));
  };

  const onEditOperationalPeriodSubmit = (data) => {
    reduxDispatch(startLoading());
    reduxDispatch(
      upsertOperationalPeriod({
        startDateTime: data.start_date_time,
        operationalPeriodInterval: data.interval_hours.value,
      })
    );
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Edit Operational Period</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onEditOperationalPeriodSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Start Date / Time</label>
            <Controller
              control={control}
              name="start_date_time"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishDateTimePicker
                  value={value || ''}
                  type="datetime-local"
                  onChange={(e) => onChange(e)}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                />
              )}
            />
            {errors.start_date_time && (
              <span className="form-text form-error">
                This field (date &amp; time) is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Interval (Hours)</label>
            <Controller
              control={control}
              name="interval_hours"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={Array.from({ length: 24 }, (_, i) => ({
                    label: i + 1,
                    value: i + 1,
                  }))}
                  defaultValue={value}
                  value={value || ''}
                  onChange={(e) => onChange(e)}
                  placeholder={'Please choose an Interval'}
                  isClearable={false}
                  isSearchable={false}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors.interval_hours && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">
              Toggle operational period activation
            </label>
            <span className="d-block txt--sml mb-3">
              ** Active incidents will automatically keep their operational
              period current.
            </span>
            <StylishNewToggleButtonGroup
              data={toggleData}
              selected={incidentActiveStatus}
              onChange={(value) => handleToggleActiveStatusButtonClick(value)}
              disabled={
                !reduxValidateRBACPermissionForActionResult[
                  'Create Group Incident'
                ]
              }
              className="d-md-inline-flex"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton type="submit" className="button--primary">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
