import config from 'constants/apiConfig';
import axios from 'axios';

import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

import parseErrorMessage from 'utils/parseErrorMessage';

import { Stripe } from 'stripe';

import {
  STRIPE_CREATE_SESSION_REQUEST,
  STRIPE_CREATE_SESSION_SUCCESS,
  STRIPE_CREATE_SESSION_ERROR,
  CREATE_STRIPE_SUBSCRIPTION_REQUEST,
  CREATE_STRIPE_SUBSCRIPTION_SUCCESS,
  CREATE_STRIPE_SUBSCRIPTION_ERROR,
  FETCH_ASSIGNABLE_USERS_BY_GROUP_REQUEST,
  FETCH_ASSIGNABLE_USERS_BY_GROUP_SUCCESS,
  FETCH_ASSIGNABLE_USERS_BY_GROUP_ERROR,
  UPDATE_STRIPE_SUBSCRIPTION_STATUS_REQUEST,
  UPDATE_STRIPE_SUBSCRIPTION_STATUS_SUCCESS,
  UPDATE_STRIPE_SUBSCRIPTION_STATUS_ERROR,
  ASSIGN_STRIPE_SEATS_REQUEST,
  ASSIGN_STRIPE_SEATS_SUCCESS,
  ASSIGN_STRIPE_SEATS_ERROR,
  UNASSIGN_STRIPE_SEATS_REQUEST,
  UNASSIGN_STRIPE_SEATS_SUCCESS,
  UNASSIGN_STRIPE_SEATS_ERROR,
  FETCH_USER_SUBSCRIPTION_INFO_REQUEST,
  FETCH_USER_SUBSCRIPTION_INFO_SUCCESS,
  FETCH_USER_SUBSCRIPTION_INFO_ERROR,
} from 'constants/stripe-action-types';

function stripeCreateSessionRequest() {
  return {
    type: STRIPE_CREATE_SESSION_REQUEST,
  };
}

function stripeCreateSessionSuccess() {
  return {
    type: STRIPE_CREATE_SESSION_SUCCESS,
  };
}

function stripeCreateSessionError(error) {
  const errorMessage =
    'stripeCreateSessionError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: STRIPE_CREATE_SESSION_ERROR,
  };
}

export const stripeCreateSession = () => {
  return (dispatch, getState) => {
    dispatch(stripeCreateSessionRequest());

    // loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY).then(stripe1=>{
    //   console.log("stripe1",stripe1)
    // })

    const stripe2 = Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);
    //console.log("stripe2?",stripe2)

    const priceKey = process.env.REACT_APP_STRIPE_PRATUS_PREMIUM_PRICE_KEY;
    const domainURL = window.location.href;

    const sessionPayload = {
      mode: 'subscription',
      line_items: [
        {
          price: priceKey,
          quantity: 1,
        },
      ],
      // ?session_id={CHECKOUT_SESSION_ID} means the redirect will have the session ID set as a query param
      success_url: `${domainURL}?stripeCreateSessionResult=success&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${domainURL}?stripeCreateSessionResult=canceled`,
      // automatic_tax: { enabled: true }
    };
    //console.log("sessionPayload",sessionPayload)

    stripe2.checkout.sessions
      .create(sessionPayload)
      .then((session) => {
        console.log('session', session);
        dispatch(stripeCreateSessionSuccess());
        window.location.replace(session.url);
        /*
      toast.success("Pratus Premium purchase successful!", {
          autoClose: 2000,
        });
        
        */
      })
      .catch((error) => {
        dispatch(stripeCreateSessionError(error));
      });
  };
};

function createStripeSubscriptionRequest() {
  return {
    type: CREATE_STRIPE_SUBSCRIPTION_REQUEST,
  };
}

function createStripeSubscriptionSuccess() {
  return {
    type: CREATE_STRIPE_SUBSCRIPTION_SUCCESS,
  };
}

function createStripeSubscriptionError(error) {
  const errorMessage =
    'createStripeSubscriptionError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CREATE_STRIPE_SUBSCRIPTION_ERROR,
  };
}

export const createStripeSubscription = ({
  stripe_customer_id,
  stripe_subscription_id,
  amount_total,
}) => {
  return (dispatch, getState) => {
    dispatch(createStripeSubscriptionRequest());
    return axios(config.apiGateway.createStripeSubscription, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: getState().app.user.user_guid,
        stripe_customer_id,
        stripe_subscription_id,
        amount_total,
      }),
    })
      .then((response) => {
        dispatch(fetchAssignableUsersByGroup());
        dispatch(fetchUserSubscriptionInfo());
        dispatch(createStripeSubscriptionSuccess());
        toast.success('Subscription created successfully!', {
          autoClose: 2000,
        });
      })
      .catch((error) => {
        dispatch(createStripeSubscriptionError(error));
      });
  };
};

function fetchAssignableUsersByGroupRequest() {
  return {
    type: FETCH_ASSIGNABLE_USERS_BY_GROUP_REQUEST,
  };
}

function fetchAssignableUsersByGroupSuccess(assignableUsersByGroup) {
  return {
    type: FETCH_ASSIGNABLE_USERS_BY_GROUP_SUCCESS,
    payload: assignableUsersByGroup,
  };
}

function fetchAssignableUsersByGroupError(error) {
  const errorMessage =
    'fetchUserSubscriptionInfoError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ASSIGNABLE_USERS_BY_GROUP_ERROR,
  };
}

export const fetchAssignableUsersByGroup = () => {
  return (dispatch, getState) => {
    dispatch(fetchAssignableUsersByGroupRequest());
    if (getState().app.currentlySelectedGroup.group_guid) {
      return axios(config.apiGateway.fetchAssignableUsersByGroup, {
        method: 'POST',
        data: JSON.stringify({
          user_guid: getState().app.user.user_guid,
          group_guid: getState().app.currentlySelectedGroup.group_guid,
        }),
      })
        .then((response) => {
          const assignableUsersByGroup = response.data || {};
          dispatch(endLoading());
          dispatch(fetchAssignableUsersByGroupSuccess(assignableUsersByGroup));
        })
        .catch((error) => {
          dispatch(fetchAssignableUsersByGroupError(error));
        });
    } else {
      dispatch(fetchAssignableUsersByGroupSuccess([]));
    }
  };
};

function updateStripeSubscriptionStatusRequest() {
  return {
    type: UPDATE_STRIPE_SUBSCRIPTION_STATUS_REQUEST,
  };
}

function updateStripeSubscriptionStatusSuccess() {
  return {
    type: UPDATE_STRIPE_SUBSCRIPTION_STATUS_SUCCESS,
  };
}

function updateStripeSubscriptionStatusError(error) {
  const errorMessage =
    'updateStripeSubscriptionStatusError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_STRIPE_SUBSCRIPTION_STATUS_ERROR,
  };
}

export const updateStripeSubscriptionStatus = ({
  stripe_subscription_id,
  status,
}) => {
  return (dispatch, getState) => {
    dispatch(updateStripeSubscriptionStatusRequest());
    return axios(config.apiGateway.updateStripeSubscriptionStatus, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: getState().app.user.user_guid,
        stripe_subscription_id,
        status,
      }),
    })
      .then((response) => {
        dispatch(fetchAssignableUsersByGroup());
        dispatch(fetchUserSubscriptionInfo());
        dispatch(updateStripeSubscriptionStatusSuccess());
      })
      .catch((error) => {
        dispatch(updateStripeSubscriptionStatusError(error));
      });
  };
};

function assignStripeSeatsRequest() {
  return {
    type: ASSIGN_STRIPE_SEATS_REQUEST,
  };
}

function assignStripeSeatsSuccess() {
  return {
    type: ASSIGN_STRIPE_SEATS_SUCCESS,
  };
}

function assignStripeSeatsError(error) {
  const errorMessage =
    'assignStripeSeatsRequest\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ASSIGN_STRIPE_SEATS_ERROR,
  };
}

export const assignStripeSeats = ({ stripe_subscription_id, users }) => {
  return (dispatch, getState) => {
    dispatch(assignStripeSeatsRequest());
    dispatch(startLoading());
    return axios(config.apiGateway.assignStripeSeats, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: getState().app.user.user_guid,
        stripe_subscription_id,
        users,
      }),
    })
      .then((response) => {
        dispatch(fetchAssignableUsersByGroup());
        dispatch(fetchUserSubscriptionInfo());
        dispatch(assignStripeSeatsSuccess());
      })
      .catch((error) => {
        dispatch(assignStripeSeatsError(error));
      });
  };
};

function unassignStripeSeatsRequest() {
  return {
    type: UNASSIGN_STRIPE_SEATS_REQUEST,
  };
}

function unassignStripeSeatsSuccess() {
  return {
    type: UNASSIGN_STRIPE_SEATS_SUCCESS,
  };
}

function unassignStripeSeatsError(error) {
  const errorMessage =
    'unassignStripeSeatsRequest\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UNASSIGN_STRIPE_SEATS_ERROR,
  };
}

export const unassignStripeSeats = ({ stripe_subscription_id, users }) => {
  return (dispatch, getState) => {
    dispatch(unassignStripeSeatsRequest());
    dispatch(startLoading());
    return axios(config.apiGateway.unassignStripeSeats, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: getState().app.user.user_guid,
        stripe_subscription_id,
        users,
      }),
    })
      .then((response) => {
        dispatch(fetchAssignableUsersByGroup());
        dispatch(fetchUserSubscriptionInfo());
        dispatch(unassignStripeSeatsSuccess());
      })
      .catch((error) => {
        dispatch(unassignStripeSeatsError(error));
      });
  };
};

function fetchUserSubscriptionInfoRequest() {
  return {
    type: FETCH_USER_SUBSCRIPTION_INFO_REQUEST,
  };
}

function fetchUserSubscriptionInfoSuccess(userSubscriptionInfo) {
  return {
    type: FETCH_USER_SUBSCRIPTION_INFO_SUCCESS,
    payload: userSubscriptionInfo,
  };
}

function fetchUserSubscriptionInfoError(error) {
  const errorMessage =
    'userSubscriptionInfo\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_USER_SUBSCRIPTION_INFO_ERROR,
  };
}

export const fetchUserSubscriptionInfo = () => {
  return (dispatch, getState) => {
    dispatch(fetchUserSubscriptionInfoRequest());
    return axios(config.apiGateway.fetchUserSubscriptionInfo, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: getState().app.user.user_guid,
      }),
    })
      .then((response) => {
        const userSubscriptionInfo = response.data || {};
        dispatch(endLoading());
        dispatch(fetchUserSubscriptionInfoSuccess(userSubscriptionInfo));
      })
      .catch((error) => {
        dispatch(fetchUserSubscriptionInfoError(error));
      });
  };
};
