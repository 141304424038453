import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { Controller, useForm } from 'react-hook-form';
import { assignRole } from 'actions/roleActions';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { changeGroupAdminNameToOrgAdmin } from './RolesUtils/changeGroupAdminNameToOrgAdmin';

export default function NewRoleAssignmentDialog({
  show,
  onClose,
  group_guid,
  assignee_guid,
}) {
  const reduxDispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const [isLoaded, setIsLoaded] = useState(true);
  const [currentMemberRoles, setCurrentMemberRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);

  const isAssignRoleLoaded = useSelector((state) => {
    return state.app.isAssignRoleLoaded;
  });

  const isFetchRostersForGroupsLoaded = useSelector((state) => {
    return state.app.isFetchRostersForGroupsLoaded;
  });

  useEffect(() => {
    if (!isLoaded && (isAssignRoleLoaded && isFetchRostersForGroupsLoaded)) {
      onClose()
    }
  }, [isAssignRoleLoaded, isFetchRostersForGroupsLoaded, isLoaded, onClose])

  const reduxRostersForGroups = useSelector(state => {
    const rostersForGroups = state.app.rostersForGroups || []
    return rostersForGroups.filter((member) => member.group_guid === group_guid);
  })

  const reduxAllRolesInGroup = useSelector(state => {
    return state.app.allRolesInGroup;
  }) || []

  useEffect(() => {
    if (assignee_guid && reduxRostersForGroups.length) {
      setCurrentMemberRoles(reduxRostersForGroups.find(member => member.user_guid === assignee_guid).role_assignments)
    }
  }, [reduxRostersForGroups, assignee_guid]);

  useEffect(() => {
    if (assignee_guid) {
      setValue('groupMember', reduxRostersForGroups.find(member => member.user_guid === assignee_guid).email_address)

    }
  }, [reduxRostersForGroups, assignee_guid]);

  useEffect(() => {
    setFilteredRoles(reduxAllRolesInGroup.filter(o1 => !currentMemberRoles.some(o2 => o1.id === o2.id)))
  }, [currentMemberRoles])

  const onNewRoleAssignmentSubmit = async (data) => {
    const assignedRole = {};

    assignedRole['user_guid'] = assignee_guid;
    assignedRole['role_id'] = data.availableRoles.value;
    assignedRole['group_guid'] = group_guid;

    reduxDispatch(startLoading());
    await reduxDispatch(assignRole(assignedRole));
    reduxDispatch(endLoading());
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(onNewRoleAssignmentSubmit)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>New Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">
                Group Member:
              </label>
              <Controller
                control={control}
                name="groupMember"
                disabled="true"
                rules={{ required: false }}
                render={({ field: { value } }) => (
                  <StylishNewInput
                    readOnly
                    disabled
                    type="text"
                    value={value}
                  />
                )}
              />
            </div>
            <div className="m-0">
              <label className="form-label">Role:</label>
              <Controller
                control={control}
                name="availableRoles"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={filteredRoles.map(role => {
                      return {
                        value: role.id,
                        label: changeGroupAdminNameToOrgAdmin(role.name),
                      };
                    })}                  
                    defaultValue={value}
                    value={value || ''}
                    onChange={(e) => onChange(e)}
                    placeholder={'Assign a Role'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                    noOptionsMessage={() => 'All available roles are assigned.'}
                  />
                )}
              />
              {errors.availableRoles && (
                <span className="form-text form-error">This field is required</span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={onClose}
              >
                Cancel
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button--primary"
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
