import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editIncidentMemberByAdmin } from 'actions/incidentActions';
import { AVAILABLE_ROLES } from './AvailableRoles';
import { Link } from 'react-router-dom';
import { fetchAllRolesInGroup } from 'actions/roleActions';
import { fetchGroupsForUser } from 'actions/profileActions';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { startLoading } from 'reducers/loading/loading.action';

export default function IncidentEditMemberDialog({
  show,
  onClose,
  member,
  incident_id,
}) {
  const reduxDispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const [isLoaded, setIsLoaded] = useState(true);
  const [groups, setGroups] = useState([]);

  const isEditIncidentMemberByAdminLoaded = useSelector((state) => {
    return state.app.isEditIncidentMemberByAdminLoaded;
  });

  const isGetRosterForIncidentLoaded = useSelector((state) => {
    return state.app.isGetRosterForIncidentLoaded;
  });

  const reduxGroupsForUser = useSelector((state) => {
    return state.app.groupsForUser;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxAllRolesInGroup =
    useSelector((state) => {
      return state.app.allRolesInGroup;
    }) || [];

  useEffect(() => {
    if (
      !isLoaded &&
      isEditIncidentMemberByAdminLoaded &&
      isGetRosterForIncidentLoaded
    ) {
      onClose();
    }
  }, [
    isEditIncidentMemberByAdminLoaded,
    isGetRosterForIncidentLoaded,
    isLoaded,
    onClose,
  ]);

  useEffect(() => {
    reduxDispatch(fetchGroupsForUser({ user_guid: member.user_guid }));
  }, [member]);

  useEffect(() => {
    if (
      reduxGroupsForUser &&
      reduxGroupsForUser.userGuid === member.user_guid
    ) {
      setGroups(reduxGroupsForUser.groups.map((group) => group.group_guid));
    }
  }, [reduxGroupsForUser]);

  useEffect(() => {
    if (
      (!reduxAllRolesInGroup ||
        (Array.isArray(reduxAllRolesInGroup) &&
          !reduxAllRolesInGroup.length)) &&
      !!reduxCurrentlySelectedGroup
    ) {
      reduxDispatch(
        fetchAllRolesInGroup(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  // const { description: roleDescription } = reduxAllRolesInGroup.find(item => item.id === incidentSeat) || { description: '' }
  const isInGroup = !!groups.includes(reduxCurrentlySelectedGroup.group_guid);

  useEffect(() => {
    setValue('member', member.email_address);
    setValue('roles', {
      value: member.incident_seat,
      label: member.incident_seat,
    });
  }, [member]);

  const onEditMemberSeatSubmit = (data) => {
    console.log('m', member);
    const editedMember = {};
    editedMember['user_guid'] = member.user_guid;
    editedMember['incident_seat'] = data.roles.value;
    editedMember['incident_id'] = incident_id;

    reduxDispatch(startLoading());
    reduxDispatch(
      editIncidentMemberByAdmin(editedMember, member.incident_member_id)
    );
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(onEditMemberSeatSubmit)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>Edit Member Seat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Member:</label>
              <Controller
                control={control}
                name="member"
                disabled="true"
                rules={{ required: false }}
                render={({ field: { value } }) => (
                  <StylishNewInput
                    readOnly
                    disabled
                    type="text"
                    value={value}
                  />
                )}
              />
            </div>
            <div>
              <label className="form-label">Seat:</label>
              {isInGroup ? (
                <>
                  <Controller
                    control={control}
                    name="roles"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        options={AVAILABLE_ROLES.map((role) => {
                          return { value: role, label: role };
                        })}
                        defaultValue={value}
                        value={value || ''}
                        onChange={(e) => onChange(e)}
                        placeholder={'Assign a Role'}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors.roles && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </>
              ) : (
                <>
                  <span className="form-text form-error multiline">
                    You must add the user to the incident group before assigning
                    seats.
                  </span>
                </>
              )}
            </div>
            {/* <div className="incidentEditMemberDialog-roleDescription">{roleDescription && (roleDescription + " A user may only occupy one seat at a time.")}</div> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              {isInGroup ? (
                <StylishNewButton type="submit" className="button--primary">
                  Save Seat
                </StylishNewButton>
              ) : (
                <Link to="/groups" className="button button--primary">
                  Manage Groups
                </Link>
              )}
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
