import { Offcanvas, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEntityDrawerProps } from './EntityListProps';
import {
  useCreateIncidentResourceRequirement,
 } from './hooks/incidentResourceRequirementHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormSelectField from './formFields/FormSelectField';
import { ResourceTypes } from '../IncidentConfig/ResourceTypes';
import { useCustomResources, CustomResourceType } from './hooks/customResourceTypeHooks'; // Import custom resource type
import CustomResourceAddDrawer from './CustomResourceAddDrawer'; // Import custom resource drawer
import { useActualIncidentId } from './hooks/commonHooks';

type FormFields = {
  name: string;
  description: string;
  kind: string;
  type: string;
  unit?: string;
  required_quantity: number;
  archived?: boolean;
  arrived?: boolean;
};

const IncidentResourceRequirementAddDrawer: React.FC<AddEntityDrawerProps> = ({ toggle }) => {
  const form = useForm<FormFields>({ mode: 'onChange' });
  const { handleSubmit } = form;

  const { data: customResources = [] } = useCustomResources(); // Handle custom resources safely with default empty array
  const [showCustomResourceDrawer, setShowCustomResourceDrawer] = useState(false); // State to manage the custom resource drawer visibility

  const mutation = useCreateIncidentResourceRequirement();

  const incidentId = useActualIncidentId();

  const onNewResourceRequirementDataSubmit = async (formData: FormFields) => {
    const kind = formData.kind;
    const fullResource = ResourceTypes.find((r) => r.name === kind) || customResources.find((r) => r.name === kind);

    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      arrived: formData.arrived ?? false,
      incident_id: incidentId,
      type: fullResource
        ? 'Type' in fullResource
          ? (fullResource as { Type: string }).Type
          : (fullResource as CustomResourceType).type
        : '', // Handle undefined fullResource safely
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Add Incident Resource Requirement">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewResourceRequirementDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: { value: 255, message: 'This maximum length is 255 characters' },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{ maxLength: { value: 300, message: 'This maximum length is 300 characters' } }}
                variant="full"
              />
            </Row>
            <Row>
              <FormSelectField
                title="Kind"
                fieldName="kind"
                rules={{ required: { value: true, message: 'This field is required' } }}
                options={[
                  ...ResourceTypes.map((type) => ({ value: type.name, label: type.name })),
                  ...customResources.map((resource) => ({ value: resource.name, label: resource.name })),
                ]}
                variant="half"
              />
              <span
                className="text-primary mt-2 cursor-pointer"
                onClick={() => setShowCustomResourceDrawer(true)}
              >
                Add Custom Resource
              </span>
            </Row>
            <Row>
              <FormTextField
                title="Unit"
                fieldName="unit"
                rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Required Quantity"
                fieldName="required_quantity"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                variant="half"
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
      {showCustomResourceDrawer && <CustomResourceAddDrawer toggle={() => setShowCustomResourceDrawer(false)} />}
    </DrawerWrapper>
  );
};

export default IncidentResourceRequirementAddDrawer;

