import React from 'react'

import './Info.css';

export default function Info({children, className}) {
  let builtClassName = 'Info';
  builtClassName = `${builtClassName}${className 
    ? ` ${className}`
    : ''}`;
  return (
    <div className={builtClassName} >{children}</div>
  )
}