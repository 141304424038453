import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from '../DesignSystems/New/StylishNewSearchBar';
import { ListGroup } from 'react-bootstrap';
import SimpleEntityList from '../DesignSystems/New/SimpleEntityList';
import SitrepSidebarItem from './SitrepSidebarItem';
import React, { useMemo, useState } from 'react';
import SitrepCreateDrawer from './SitrepCreateDrawer';
import generateUUID from '../../utils/sharedUtils/generateUUID';
import sitrepName from './DisasterGPTDataHelpers/sitrepName';
import { toast } from 'react-toastify';
import {
  useInitSitrepV2,
  useSitrepsV2,
  useUpdateSitrepV2,
} from './hooks/useSitrepsV2';

type SitrepManagerProps = {
  handleSitrepSelect: (sitrep: UUID) => void;
};

const SitrepManager: React.FC<SitrepManagerProps> = ({
  handleSitrepSelect,
}) => {
  const { data: sitreps = [], isLoading, isError } = useSitrepsV2();
  const initSitrepMutation = useInitSitrepV2();
  const updateSitrepMutation = useUpdateSitrepV2();
  const [filterText, setFilterText] = useState('');
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [editingSitrep, setEditingSitrep] = useState<any>(null);

  const handleCreateSitrep = async (formData: any) => {
    try {
      const sitrepIdResult = await initSitrepMutation.mutateAsync({
        sitrep_template_id: formData.sitrep_template_id || null,
        sitrep_source: 'MANUAL',
        sitrep_source_id: generateUUID(),
        name: formData.name || sitrepName('MANUAL'),
        description: formData.description || '',
        selectedDatetime: new Date().toISOString(),
        aor_id: formData.aor_id || null,
        incident_id: formData.incident_id || null,
      });
      handleSitrepSelect(sitrepIdResult);
      setShowCreateDrawer(false);
      setEditingSitrep(null);
    } catch (e: any) {
      toast.error('Error creating SITREP: ' + e.message);
    }
  };

  const handleUpdateSitrep = async (formData: any) => {
    if (!editingSitrep) return;

    try {
      await updateSitrepMutation.mutateAsync({
        sitrep_id: editingSitrep.id,
        name: formData.name,
        description: formData.description,
        aor_id: formData.aor_id,
        incident_id: formData.incident_id,
      });
      // if (selectedSitrepId === editingSitrep.id) {
      //   refetchSitrep();
      // }
      setEditingSitrep(null);
      setShowCreateDrawer(false);
    } catch (e: any) {
      toast.error('Error updating SITREP: ' + e.message);
    }
  };

  const handleSaveSitrep = (formData: any) => {
    if (editingSitrep) {
      handleUpdateSitrep(formData);
    } else {
      handleCreateSitrep(formData);
    }
  };

  const handleSitrepClick = (sitrep: any) => {
    handleSitrepSelect(sitrep.id);
  };

  const handleEditSitrep = (sitrep: any) => {
    setEditingSitrep(sitrep);
    setShowCreateDrawer(true);
  };

  const filteredSitreps = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    if (lowerCaseFilter) {
      return sitreps.filter((sitrep: any) => {
        if (typeof sitrep.name === 'string') {
          return sitrep.name.toLowerCase().includes(lowerCaseFilter);
        } else {
          console.warn('Encountered sitrep with invalid name:', sitrep);
          return false;
        }
      });
    } else {
      return sitreps;
    }
  }, [sitreps, filterText]);

  if (isError) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <span>Error loading sitreps.</span>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          margin: '8px',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: '8px' }}>
          <StylishNewButton
            className="button--primary"
            onClick={() => {
              setEditingSitrep(null);
              setShowCreateDrawer(true);
            }}
          >
            Add
          </StylishNewButton>
        </div>
        <div style={{ flexGrow: 1 }}>
          <StylishNewSearchBar
            value={filterText}
            onChangeSearchTerm={(val) => setFilterText(val)}
            placeholder="Search SITREPs by name..."
          />
        </div>
      </div>

      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <span>Loading...</span>
        </div>
      ) : (
        <>
          {filteredSitreps.length > 0 ? (
            <div
              className="d-flex flex-column flex-grow-1"
              style={{
                overflowY: 'auto',
              }}
            >
              <ListGroup className="py-0 flex-grow-1">
                <SimpleEntityList
                  entities={filteredSitreps}
                  header={<></>}
                  renderEntity={(sitrep) => (
                    <SitrepSidebarItem
                      key={sitrep.id}
                      sitrep={sitrep}
                      onClick={() => handleSitrepClick(sitrep)}
                      onEdit={() => handleEditSitrep(sitrep)}
                    />
                  )}
                />
              </ListGroup>
            </div>
          ) : (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <span>No SITREPs found.</span>
            </div>
          )}
        </>
      )}
      {showCreateDrawer && (
        <SitrepCreateDrawer
          onHide={() => {
            setShowCreateDrawer(false);
            setEditingSitrep(null);
          }}
          onSave={handleSaveSitrep}
          sitrep={editingSitrep}
        />
      )}
    </>
  );
};

export default SitrepManager;
