import isUserOnlyAdminInGroup from './isUserOnlyAdminInGroup'

// User may be removed from group if the target person is not the only org admin
// AND if the user has permission to remove
export default function isUserAllowedToBeRemovedFromGroup(row,current_user_guid,reduxValidateRBACPermissionForActionResult,reduxCurrentlySelectedGroup,reduxRostersForGroups)
{
	if(reduxValidateRBACPermissionForActionResult['Remove Member from Group'])
	{
		if(!(isUserOnlyAdminInGroup(reduxCurrentlySelectedGroup.group_guid,reduxRostersForGroups) && row.user_guid===current_user_guid))
		{
			return true
		}
	}
	return false
}

