import {
  Selector as ReduxSelector,
  TypedUseSelectorHook,
  useSelector,
} from 'react-redux';
import { RootState } from '../reducers/combinedReducer';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type AppSelector<A> = ReduxSelector<RootState, A>;

export const selectGroup: AppSelector<Group> = (state) =>
  state.app.currentlySelectedGroup;

export const selectGroupGuid: AppSelector<UUID> = (state) =>
  state.app.currentlySelectedGroup.group_guid as UUID;

export const selectUser: AppSelector<User> = (state) => state.app.user as User;

export const selectCombinedUser: AppSelector<User & PrepareUser> = (state) => ({
  ...state.app.user,
  ...state.prepare.user,
});

export const selectUserGuid: AppSelector<UUID> = (state) =>
  state.app.user.user_guid as UUID;

export const selectExerciseUserGuid: AppSelector<UUID> = (state) =>
  state.prepare.user.id as UUID;

export const selectIncidentGuid: AppSelector<UUID> = (state) =>
  state.app.currentIncident?.id;

export const selectIncident: AppSelector<IncidentAugmented> = (state) =>
  state.app.currentIncident;

export const selectIncidents: AppSelector<IncidentAugmented[]> = (state) =>
  state.app.incidents;

export const selectGroups: AppSelector<Group[]> = (state) => state.app.groups;

export const selectExerciseWorkspaceId: AppSelector<UUID> = (state) =>
  state.prepare.workspace.selectedWorkspace as UUID;

export const selectAllExerciseWorkspaces: AppSelector<ExerciseWorkspace[]> = (
  state
) => state.prepare.workspace.allWorkspace as ExerciseWorkspace[];

export const selectExerciseWorkspaceName: AppSelector<string> = (state) =>
  selectExerciseWorkspace(state)?.displayName as string;

export const selectExerciseWorkspaceType: AppSelector<string> = (state) =>
  selectExerciseWorkspace(state)?.ownertype as string;

export const selectIsUploadFileToTeamsLoaded: AppSelector<boolean> = (state) =>
  state.app.isUploadFileToTeamsLoaded as boolean;

export const selectExerciseWorkspace: AppSelector<
  ExerciseWorkspace | undefined
> = (state) => {
  const allWorkspaces = selectAllExerciseWorkspaces(state);
  return allWorkspaces.find(
    (workspace: ExerciseWorkspace) =>
      workspace.id === selectExerciseWorkspaceId(state)
  );
};

export const selectExerciseWorkspaceTenant: AppSelector<UUID | undefined> = (
  state
) => {
  const workspace = selectExerciseWorkspace(state);
  return workspace ? (workspace.tenant_id as UUID) : undefined;
};

export const selectExerciseWorkspaceSharepointLocation: AppSelector<
  string | undefined
> = (state) => {
  const workspace = selectExerciseWorkspace(state);
  return workspace ? (workspace.sharepoint_location as string) : undefined;
};
