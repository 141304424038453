import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import moment from 'moment';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { createUpdateOrder } from 'actions/IAP213RRActions';
import { startLoading } from 'reducers/loading/loading.action';

export const IAPForm213RRAddEditOrderDialog = ({
  show,
  onClose,
  resourceRequestId,
  reduxDispatch,
  orderDialogType,
  orderDialogExistingData,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const priorityOptions = [
    { value: 'U', label: 'U' },
    { value: 'R', label: 'R' },
  ];

  useEffect(() => {
    if (orderDialogExistingData?.order_no) {
      setValue('quantity', orderDialogExistingData?.quantity);
      setValue(
        'kind',
        !!orderDialogExistingData.kind && orderDialogExistingData.kind.trim()
      );
      setValue(
        'type',
        !!orderDialogExistingData.type && orderDialogExistingData.type.trim()
      );
      setValue(
        'item_description',
        !!orderDialogExistingData.item_description &&
          orderDialogExistingData.item_description.trim()
      );
      setValue(
        'requested_reporting_location',
        !!orderDialogExistingData.requested_reporting_location &&
          orderDialogExistingData.requested_reporting_location.trim()
      );
      setValue('cost', orderDialogExistingData?.cost);
      setValue(
        'requested_reporting_time',
        orderDialogExistingData?.requested_reporting_time
      );
      setValue('eta', orderDialogExistingData?.eta);
      setValue(
        'priority',
        !!orderDialogExistingData.priority && {
          value: orderDialogExistingData.priority.trim(),
          label: orderDialogExistingData.priority.trim(),
        }
      );
    }
  }, [orderDialogExistingData]);

  const onAddEditOrderSubmit = (data) => {
    const orderData = {
      ...data,
      id: orderDialogExistingData?.id || generateUUID(),
      resource_request_id: resourceRequestId,
      priority: data.priority.value,
    };
    reduxDispatch(startLoading());
    reduxDispatch(createUpdateOrder(orderData));
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <form onSubmit={handleSubmit(onAddEditOrderSubmit)}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>
              {`${
                orderDialogType === 'New'
                  ? 'Create New'
                  : orderDialogType === 'Edit'
                  ? 'Edit'
                  : null
              } Order`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Quantity</label>
              <Controller
                control={control}
                name="quantity"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="number"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.quantity && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Kind</label>
              <Controller
                control={control}
                name="kind"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.kind && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Type</label>
              <Controller
                control={control}
                name="type"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="Text"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.type && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Priority</label>
              <Controller
                control={control}
                name="priority"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    onChange={onChange}
                    value={value}
                    options={priorityOptions}
                  />
                )}
              />
              {errors.priority && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Item Description</label>
              <Controller
                control={control}
                name="item_description"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea onChange={onChange} value={value || ''} />
                )}
              />
              {errors.item_description && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Requested Reporting Location</label>
              <Controller
                control={control}
                name="requested_reporting_location"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.requested_reporting_location && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Requested Reporting Time</label>
              <Controller
                control={control}
                name="requested_reporting_time"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    type="datetime-local"
                    onChange={onChange}
                    value={value || ''}
                    maxDate={moment()}
                    disableDateTimeLabel={true}
                  />
                )}
              />
              {errors.requested_reporting_time && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Order #</label>
              <Controller
                control={control}
                defaultValue={
                  orderDialogExistingData?.order_no
                    ? orderDialogExistingData.order_no
                    : generateUUID()
                }
                name="order_no"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value || ''}
                    readOnly
                    disabled
                  />
                )}
              />
              {errors.order_no && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">ETA</label>
              <Controller
                control={control}
                name="eta"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    type="datetime-local"
                    onChange={onChange}
                    value={value || ''}
                    minDate={moment()}
                    disableDateTimeLabel={true}
                  />
                )}
              />
              {errors.eta && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="m-0">
              <label className="form-label">Cost</label>
              <Controller
                control={control}
                name="cost"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="number"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.cost && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              <StylishNewButton type="submit" className="button--primary">
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
