import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSHospitalDialog from 'components/ICS/NewICSHospitalDialog';

import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

import ICSIncidentHospital from './ICSIncidentHospital';

import { updateIcsHospital } from 'slices/ics_hospitalsSlice';

import {
  createIcsIncidentHospital,
  updateIcsIncidentHospital,
} from 'slices/ics_incidentHospitalsSlice';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';

export default function HospitalsDialog({
  show,
  onClose,
  incident_id = null,
  ics_hospitals,
  ics_incident_hospitals
}) {
  const reduxDispatch = useDispatch();

  const [isNewICSHospitalDialogOpen, setIsNewICSHospitalDialogOpen] = useState(
    false
  );
  const [modalType, setModalType] = useState('New');
  const [editHospitalData, setEditHospitalData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogHospitals, setDialogHospitals] = useState([
    ...ics_incident_hospitals,
  ]);

  useEffect(() => {
    setDialogHospitals(ics_incident_hospitals);
  }, [ics_incident_hospitals]);

  useEffect(() => {
    // Use to rerender
  }, [ics_hospitals]);

  const allColumns = [
    {
      dataField: 'hospital_name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'address',
      text: 'Address',
      sort: true,
      attrs: { title: 'Address' },
    },
    {
      dataField: 'contact_number',
      text: 'Contact Number',
      sort: true,
      attrs: { title: 'Contact Number' },
    },
    {
      dataField: 'travel_time_air',
      text: 'Travel Time (Air)',
      sort: true,
      attrs: { title: 'Travel Time (Air)' },
    },
    {
      dataField: 'travel_time_ground',
      text: 'Travel Time (Ground)',
      sort: true,
      attrs: { title: 'Travel Time (Ground)' },
    },
    {
      dataField: 'burn_center',
      text: 'Burn Center',
      sort: true,
      attrs: { title: 'Burn Center' },
      formatter: (cell) => (cell ? 'Yes' : 'No'),
    },
    {
      dataField: 'heli_pad',
      text: 'Helipad',
      sort: true,
      attrs: { title: 'Helipad' },
      formatter: (cell) => (cell ? 'Yes' : 'No'),
    },
    {
      dataField: 'user_name',
      text: 'Last Modified By',
      sort: true,
      attrs: { title: 'Last Modified By' },
    },
    {
      dataField: 'archived',
      text: 'Archived',
      sort: true,
      attrs: { title: 'Archived' },
      formatter: (cell) => (cell ? 'Yes' : 'No'),
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentHospital
            row={row}
            ics_hospitals={ics_hospitals}
            ics_incident_hospitals={ics_incident_hospitals}
          />
          {(!incident_id ||
            !!ics_incident_hospitals.find(
              (hospital) => hospital.hospital_id === row.id
            )) && (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onArchive(row)}
              >
                {row.archived ? `Unarchive` : `Archive`}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) =>
      isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <span className="sr-only">Details</span>
            <ExpandDownArrow />
          </>
        );
      }
      return (
        <>
          <span className="sr-only">Details</span>
          <ExpandRightArrow />
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_hospitals
      .filter((hospital) => {
        const incHospital = ics_incident_hospitals.find(
          (ihospital) => ihospital.hospital_id === hospital.id && !ihospital.archived
        );
        return !!incHospital;
      })
      .map((hospital) => hospital.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentHospital = ics_incident_hospitals.find(
        (ihospital) => ihospital.hospital_id === row.id
      );
      if (existingIncidentHospital) {
        reduxDispatch(
          updateIcsIncidentHospital(
            incident_id,
            existingIncidentHospital.id,
            {
              ...existingIncidentHospital,
              archived: !existingIncidentHospital.archived,
            }
          )
        );
      } else {
        // Create a new incident hospital
        const incidentHospitalData = {
          hospital_id: row.id,
          hospital_name: row.hospital_name,
          description: row.description,
          address: row.address,
          contact_number: row.contact_number,
          travel_time_air: row.travel_time_air,
          travel_time_ground: row.travel_time_ground,
          burn_center: row.burn_center,
          heli_pad: row.heli_pad,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          archived: false,
        };
        reduxDispatch(
          createIcsIncidentHospital(incident_id, incidentHospitalData)
        );
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogHospitals(rows) : setDialogHospitals([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewICSHospitalDialogOpen(true);
    setEditHospitalData(row);
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (incident_id) {
      reduxDispatch(
        updateIcsIncidentHospital(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsHospital(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewHospital = () => {
    setModalType('New');
    setIsNewICSHospitalDialogOpen(true);
  };

  const tableRows = ics_hospitals.map((hospital) => {
    const incHospital = ics_incident_hospitals.find(
      (ihospital) => ihospital.hospital_id === hospital.id
    );
    if (incHospital) {
      return {
        ...incHospital,
        id: hospital.id,
      };
    } else {
      return hospital;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Hospitals</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewHospital}
        >
          New Hospital
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewICSHospitalDialogOpen && (
        <NewICSHospitalDialog
          show={isNewICSHospitalDialogOpen}
          setEditHospitalData={setEditHospitalData}
          setModalType={setModalType}
          editHospitalData={editHospitalData}
          modalType={modalType}
          onClose={() => setIsNewICSHospitalDialogOpen(false)}
          incident_id={incident_id}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Hospital?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
