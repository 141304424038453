import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useDispatch, useSelector } from 'react-redux';
import { createIcsComs, updateIcsComs } from 'slices/ics_comsSlice';
import { updateIcsIncidentComs } from 'slices/ics_incidentComsSlice';
import generateUUID from 'utils/sharedUtils/generateUUID';


export default function NewICSComsDialog({
  show,
  setEditComsData,
  editComsData,
  modalType,
  setModalType,
  onClose,
  incident_id,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const [comsId, setComsId] = useState('');
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (modalType === 'New') {
      setComsId(generateUUID());
    } else if (modalType === 'Edit') {
      setComsId(editComsData.id);
      setValue('type', editComsData.type||"radio");
      setValue('branch', editComsData.branch);
      setValue('division_group', editComsData.division_group);
      setValue('method_of_contact', editComsData.method_of_contact);
      setValue('zone_group', editComsData.zone_group);
      setValue('channel_number', editComsData.channel_number);
      setValue('function', editComsData.function);
      setValue('channel_name', editComsData.channel_name);
      setValue('assignment', editComsData.assignment);
      setValue('rx_frequency', editComsData.rx_frequency);
      setValue('rx_tone_nac', editComsData.rx_tone_nac);
      setValue('tx_frequency', editComsData.tx_frequency);
      setValue('tx_tone_nac', editComsData.tx_tone_nac);
      setValue('mode', editComsData.mode);
      setValue('remarks', editComsData.remarks);
      setValue('special_instructions', editComsData.special_instructions);
    }
  }, [modalType]);

  const onNewComsDataSubmit = (data) => {
    const payload = {
      id: comsId,
      coms_id: comsId,
      type: data.type,
      branch: data.branch,
      division_group: data.division_group,
      method_of_contact: data.method_of_contact,
      zone_group: data.zone_group,
      channel_number: data.channel_number,
      function: data.function,
      channel_name: data.channel_name,
      assignment: data.assignment,
      rx_frequency: data.rx_frequency,
      rx_tone_nac: data.rx_tone_nac,
      tx_frequency: data.tx_frequency,
      tx_tone_nac: data.tx_tone_nac,
      mode: data.mode,
      remarks: data.remarks,
      special_instructions: data.special_instructions,
      incident_id: incident_id,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
    };
    if (modalType === 'New') {
      reduxDispatch(createIcsComs(payload));
      onClose();
    } else if (modalType === 'Edit') {
      if (!incident_id) {
        reduxDispatch(updateIcsComs(payload.id, payload));
      } else if (incident_id) {
        payload.id = editComsData?.id;
        reduxDispatch(
          updateIcsIncidentComs(incident_id, payload.id, payload)
        );
      }
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Communication</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewComsDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={comsId} readOnly disabled />
          </div>
          <div className="m-0">
            <label className="form-label">Type:</label>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  maxLength={50}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Branch:</label>
            <Controller
              control={control}
              name="branch"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={255}
                />
              )}
            />
          </div>
          <div className="m-0">
            <label className="form-label">Division/Group:</label>
            <Controller
              control={control}
              name="division_group"
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={255}
                />
              )}
            />
          </div>
            <div className="m-0">
              <label className="form-label">Method of Contact:</label>
              <Controller
                control={control}
                name="method_of_contact"
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => onChange(e.target.value)}
                    value={value || ''}
                    maxLength={255}
                  />
                )}
              />
            </div>
              <div className="m-0">
                <label className="form-label">Zone Group:</label>
                <Controller
                  control={control}
                  name="zone_group"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={255}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Channel Number:</label>
                <Controller
                  control={control}
                  name="channel_number"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={255}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Function:</label>
                <Controller
                  control={control}
                  name="function"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={255}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Channel Name:</label>
                <Controller
                  control={control}
                  name="channel_name"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={255}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Assignment:</label>
                <Controller
                  control={control}
                  name="assignment"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={255}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">RX Frequency:</label>
                <Controller
                  control={control}
                  name="rx_frequency"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={255}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">RX Tone/NAC:</label>
                <Controller
                  control={control}
                  name="rx_tone_nac"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={255}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">TX Frequency:</label>
                <Controller
                  control={control}
                  name="tx_frequency"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={255}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">TX Tone/NAC:</label>
                <Controller
                  control={control}
                  name="tx_tone_nac"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={255}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Mode:</label>
                <Controller
                  control={control}
                  name="mode"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={50}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Remarks:</label>
                <Controller
                  control={control}
                  name="remarks"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewTextArea
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={1000}
                    />
                  )}
                />
              </div>
          <div className="m-0">
            <label className="form-label">Special Instructions:</label>
            <Controller
              control={control}
              name="special_instructions"
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={1000}
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
