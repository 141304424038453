// components/IAP/formFields/FormKeyValuePairs.js

import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import classNames from 'classnames';
import StylishNewInput from '../../DesignSystems/New/StylishNewInput';
import { StylishNewButton } from '../../DesignSystems/New/StylishNewButton';
import { MdAdd } from 'react-icons/md';
import { SharedIcon } from 'components/SharedIcon/SharedIcon'; // Imported Modal

const FormKeyValuePairs = ({ title, fieldName, variant = 'full' }) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  const keyValuePairs = watch(fieldName) || [];

  // Function to check for duplicate keys
  const hasDuplicateKeys = () => {
    const keys = keyValuePairs
      .map((pair) => pair.key.trim())
      .filter(Boolean); // Exclude empty strings
    return new Set(keys).size !== keys.length;
  };

  return (
    <div
      className={classNames('mt-2', {
        'col-6': variant === 'half',
        'col-12': variant === 'full',
      })}
    >
      <label className="form-label">{title}</label>

      {fields.map((item, index) => (
        <div key={item.id} className="d-flex align-items-center mb-2">
          {/* Key Field */}
          <div className="me-2 flex-grow-1">
            <StylishNewInput
              placeholder="Key"
              {...register(`${fieldName}.${index}.key`, {
                required: 'Key is required',
                validate: {
                  unique: () =>
                    !hasDuplicateKeys() || 'Duplicate keys are not allowed',
                },
              })}
              aria-invalid={errors[fieldName]?.[index]?.key ? 'true' : 'false'}
            />
            {errors[fieldName]?.[index]?.key && (
              <div className="form-text form-error">
                {errors[fieldName][index].key.message}
              </div>
            )}
          </div>

          {/* Value Field */}
          <div className="me-2 flex-grow-1">
            <StylishNewInput
              placeholder="Value"
              {...register(`${fieldName}.${index}.value`)}
              aria-invalid={errors[fieldName]?.[index]?.value ? 'true' : 'false'}
            />
            {/* Since Value is optional, no error message is displayed */}
          </div>

          {/* Delete Button */}
          <StylishNewButton
            className="button--secondary button--icon"
            onClick={() => remove(index)}
            type="button"
            aria-label="Delete key/value pair"
          >
            <SharedIcon iconName={'delete'} size={'sm'} />
          </StylishNewButton>
        </div>
      ))}

      {/* Add Key/Value Pair Button */}
      <div>
        <StylishNewButton
          onClick={() => append({ key: '', value: '' })}
          className="button--secondary"
          type="button"
          aria-label="Add key/value pair"
        >
          <MdAdd className="me-1" /> Add Key/Value Pair
        </StylishNewButton>
      </div>

      {/* Display general errors for key_value_pairs if any */}
      {errors[fieldName]?.message && (
        <div className="form-text form-error">
          {errors[fieldName].message}
        </div>
      )}
    </div>
  );
};

export default FormKeyValuePairs;
