import { RTO_TIME_FRAME_OPTIONS, TASK_COLOR_TAB_DATA, TASK_STATUS_LIST } from "./TaskManagementConstant"
import { getProcessStability } from "./TaskManagementUtil"

const sampleDrawLayer = {
  'id': '',
  'type': 'fill',
  'source': '', // reference the data source
  'layout': {},
  'paint': {
    'fill-color': '#0080ff', // blue color fill
    'fill-opacity': 0.5,
    'fill-antialias': true,
    'fill-outline-color': '#000000'
  },
  metadata: {
    usermade: true,
  }
}

const samplePointLayer = {
  id: '',
  type: 'circle',
  source: '', // reference the data source
  layout: {},
  'paint': {
    'circle-color': '#0080ff', // blue color fill
    'circle-opacity': 0.5,
    "circle-radius": [
      "interpolate",
      [
        "linear"
      ],
      [
        "zoom"
      ],
      1,
      1,
      5,
      2,
      10,
      3,
      15,
      5,
      20,
      10,
      24,
      20
    ]
  },
  metadata: {
    taskLayer: true,
  }
}

// Critical Tasks 
const critical_tasks_polygons_source = "critical_tasks_polygons_source"
const critical_tasks_polygons_layer = "critical_tasks_polygons_layer"
const critical_tasks_polygons_source_layer = "public.critical_tasks_polygons"

const critical_tasks_polygons_layer_config = {
  ...sampleDrawLayer,
  id: critical_tasks_polygons_layer,
  source: critical_tasks_polygons_source,
  'source-layer': critical_tasks_polygons_source_layer,
  generateId: true,
  paint: {
    ...sampleDrawLayer.paint,
    'fill-opacity': 0.3,
  },
  metadata: {
    taskLayer: true,
  },
};

const critical_tasks_points_source = "critical_tasks_points_source"
const critical_tasks_points_layer = "critical_tasks_points_layer"
const critical_tasks_points_source_layer = "public.critical_tasks_points"

const critical_tasks_points_layer_config = {
  ...samplePointLayer,
  id: critical_tasks_points_layer,
  source: critical_tasks_points_source,
  'source-layer': critical_tasks_points_source_layer,
  generateId: true,
  paint: {
    ...samplePointLayer.paint,
    'circle-opacity': 0.3,
  },
  metadata: {
    taskLayer: true,
  },
};


// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export default function TaskManagementMapEffect(
  map,
  mapboxgl,
  apiPrefix,
  tasks,
  showAreaOnMap,
  reduxCurrentlySelectedGroup,
  setLayerClicked,
  markers,
  setMarkers,
  showTasksVisibleToggle = true,
  selectedColorTab = TASK_COLOR_TAB_DATA[0].value,
) {

  const critical_tasks_polygons_tiles = `${apiPrefix}/critical_tasks_tiles/${critical_tasks_polygons_source_layer}/{z}/{x}/{y}.pbf`;
  const critical_tasks_points_tiles = `${apiPrefix}/critical_tasks_tiles/${critical_tasks_points_source_layer}/{z}/{x}/{y}.pbf`;

  // critical_tasks
  if (map.current.getLayer(critical_tasks_polygons_layer)) {
    map.current.removeLayer(critical_tasks_polygons_layer)
  }
  if (map.current.getSource(critical_tasks_polygons_source)) {
    map.current.removeSource(critical_tasks_polygons_source)
  }
  if (map.current.getLayer(critical_tasks_points_layer)) {
    map.current.removeLayer(critical_tasks_points_layer)
  }
  if (map.current.getSource(critical_tasks_points_source)) {
    map.current.removeSource(critical_tasks_points_source)
  }
  if (markers.length) {
    markers.forEach(marker => {
      marker.remove();
    });
  }
  
  // /critical_tasks


  // AORs
  if (!!showTasksVisibleToggle && !!showAreaOnMap && tasks?.length > 0) {
    map.current.addSource(critical_tasks_polygons_source, {
      'type': 'vector',
      'tiles': [
        critical_tasks_polygons_tiles + "?user_group_guid=" + reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(critical_tasks_polygons_layer_config);

    map.current.on('click', critical_tasks_polygons_layer_config.id, noop);

    map.current.on('touchend', critical_tasks_polygons_layer_config.id,noop);
    map.current.on('mouseenter', critical_tasks_polygons_layer_config.id, (e) => {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', critical_tasks_polygons_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'grab';
    });

    map.current.addSource(critical_tasks_points_source, {
      'type': 'vector',
      'tiles': [
        critical_tasks_points_tiles + "?user_group_guid=" + reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(critical_tasks_points_layer_config);

    const filtertedTaskIds = [];
    const _markes = [];
    const groupedMarkers = [];
    tasks.forEach(task => {
      filtertedTaskIds.push(task?.id);
      const geoLngLat = task?.total_geolocation?.centroid?.geometry?.coordinates;
      if (geoLngLat?.length > 0) {
        let markerColor = TASK_STATUS_LIST.find(item => item.label === task?.status)?.color;

        if (selectedColorTab === TASK_COLOR_TAB_DATA[1].value) {
          const rtoTimeFrameObj = RTO_TIME_FRAME_OPTIONS.find(frame => frame.value === task?.rto_time_frame);
          const stability = getProcessStability(
            task?.status, 
            task?.deadline_date, 
            task?.restoration_status, 
            parseInt(task?.rto),
            rtoTimeFrameObj,
            task?.is_critical
          );
          markerColor = stability?.color;
        }
        
        const sameLatlngMarkerIndex = groupedMarkers.findIndex(item => item.stringLatlng === geoLngLat.join(','));
        if (sameLatlngMarkerIndex === -1) {
          const sameLatlngMarker = {
            stringLatlng: geoLngLat.join(','),
            geoLngLat,
            markerColor,
            tasks: [task],
          }
          groupedMarkers.push(sameLatlngMarker);
        } else {
          let sameLatlngMarker = groupedMarkers[sameLatlngMarkerIndex];

          sameLatlngMarker = {
            ...sameLatlngMarker,
            tasks: [...sameLatlngMarker.tasks, task],
          }
          groupedMarkers[sameLatlngMarkerIndex] = sameLatlngMarker;
        }
      
      }
    });

    groupedMarkers.forEach(group => {
      const marker = new mapboxgl.Marker({ color: group.markerColor })
        .setLngLat(group.geoLngLat)
        .addTo(map.current);
      marker.getElement().addEventListener('click', function () {
        setLayerClicked({
          layer: {
            metadata: {
              taskLayer: true,
            },
          },
          e: { lngLat: group.geoLngLat },
          features: group.tasks,
        })
      });
      _markes.push(marker);
    });
    setMarkers(_markes);

    map.current.setFilter(critical_tasks_polygons_layer_config.id, ['in', 'id', ...filtertedTaskIds]);
    map.current.setFilter(critical_tasks_points_layer_config.id, ['in', 'id', ...filtertedTaskIds])
  }
  // AORs

}