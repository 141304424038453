import {Map3DBuildingsConfig} from '../constants/Map3DBuildingsConfig'

export default function threeDBuildingsEffect(mapHasLoaded, map, threeDBuildingsToggle)
{
	if(mapHasLoaded)
  {
    if(threeDBuildingsToggle)
    {
      if(!map.current.getStyle().layers.find(l=>l.id===Map3DBuildingsConfig.id))
      {
        map.current.addLayer(Map3DBuildingsConfig);
      }
    }
    else
    {
      if(map.current.getStyle().layers.find(l=>l.id===Map3DBuildingsConfig.id))
      {
        map.current.removeLayer(Map3DBuildingsConfig.id)
      }
    }
  }
}