import React, { useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { useDispatch } from 'react-redux';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useNavigate } from 'react-router-dom';
import {
  deleteResourceRequest,
  fetchResourceRequest,
} from 'actions/IAP213RRActions';
import { startLoading } from 'reducers/loading/loading.action';

export const ResourceDetailsDialog = ({ show, onClose, editData }) => {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const [key, setKey] = useState('requestor');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);

  const editResourceHandler = () => {
    reduxDispatch(fetchResourceRequest(editData?.id));
    navigate('/incident_management/ICS_213-RR');
    onClose();
  };

  const orderColumns = [
    {
      dataField: 'quantity',
      text: 'Qty',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Qty' },
    },
    {
      dataField: 'kind',
      text: 'Kind',
      sort: false,
      attrs: { title: 'Kind' },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: false,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'cost',
      text: 'Cost',
      sort: true,
      attrs: { title: 'Cost' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">Priority U or R</h6>
              {row?.priority?.trim()}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">Item Description</h6>
              {row?.item_description?.trim()}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">Requested Reporting Location</h6>
              {row?.requested_reporting_location?.trim()}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-1">Requested Reporting Date/Time</h6>
              {row?.requested_reporting_time &&
                moment(row.requested_reporting_time).format('MM/DD/YYYY HH:mm')}
            </div>
            <div className="col-md-6 mb-3 mb-md-0">
              <h6 className="mb-1">Order # (LSC)</h6>
              {row?.order_no?.trim()}
            </div>
            <div className="col-md-6">
              <h6 className="mb-1">ETA (LSC)</h6>
              {row?.eta && moment(row.eta).format('MM/DD/YYYY HH:mm')}
            </div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const onDeleteResource = () => {
    setDeleteConfirmationData(editData.id);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteResourceRequest(deleteConfirmationData));
    setShowConfirmDialog(false);
    setDeleteConfirmationData(null);
    onClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="xl"
        fullscreen="xl-down"
      >
        <Modal.Header closeButton closeVariant="white" className="pb-0">
          <Modal.Title>Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Form ID: <span className="weight-400">{editData?.id}</span>
          </h6>
          <div className="tab-wrapper content-gap-none pt-3">
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
              <Tab eventKey="requestor" title="Requestor">
                <h5>Order</h5>
                <StylishNewTable
                  keyField={'id'}
                  columns={orderColumns}
                  rows={editData ? editData?.orders : []}
                  expandRow={expandRow}
                />
                <hr className="dashed my-4" />
                <h5>POC Phone Number if Known and Suitable Substitutes</h5>
                {editData?.poc_phone_number
                  ? editData?.poc_phone_number?.trim()
                  : '-'}
                <hr className="dashed my-4" />
                <h5>Requestor Position and Signature</h5>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label d-block">Name:</label>
                    {editData?.requester
                      ? editData?.requester?.profile_settings?.userName
                      : '-'}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label d-block">Date:</label>
                    {editData?.requester_date
                      ? moment(editData.requester_date).format(
                          'MM/DD/YYYY HH:mm'
                        )
                      : '-'}
                  </div>
                </div>
                <hr className="dashed my-4" />
                <h5>Section Chief/Command Staff Approval</h5>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label d-block">Name:</label>
                    {editData?.approval_user
                      ? editData?.approval_user?.profile_settings?.userName
                      : '-'}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label d-block">Date:</label>
                    {editData?.approval_user_date
                      ? moment(editData.approval_user_date).format(
                          'MM/DD/YYYY HH:mm'
                        )
                      : '-'}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="plans" title="Plans">
                <div className="row">
                  <div className="col-md-6">
                    <h5>RESL</h5>
                    {editData?.resl === true ? 'Yes' : 'No'}
                  </div>
                  <div className="col-md-6">
                    <h5>Availability</h5>
                    {editData?.availability === true
                      ? 'Resources Available as Noted in Block 12'
                      : 'Resources Not Available'}
                  </div>
                </div>
                <hr className="dashed my-4" />
                <h5>RESL Review/Signature</h5>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label d-block">Name:</label>
                    {editData?.resl_review_user
                      ? editData?.resl_review_user?.profile_settings?.userName
                      : '-'}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label d-block">Date:</label>
                    {editData?.resl_review_user_date
                      ? moment(editData.resl_review_user_date).format(
                          'MM/DD/YYYY HH:mm'
                        )
                      : '-'}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="logistics" title="Logistics">
                <h5>Requisition/Purchase Order #</h5>
                {editData?.requisition_purchase_order}
                <hr className="dashed my-4" />
                <h5>Supplier Name/Phone/Fax/Email</h5>
                {editData?.supplier ? editData?.supplier : '-'}
                <hr className="dashed my-4" />
                <h5>Notes</h5>
                {editData?.notes ? editData?.notes : '-'}
                <hr className="dashed my-4" />
                <h5>Logistics Section Signature</h5>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label d-block">Name:</label>
                    {editData?.logistics_user
                      ? editData?.logistics_user?.profile_settings?.userName
                      : '-'}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label d-block">Date:</label>
                    {editData?.logistics_user_date
                      ? moment(editData.logistics_user_date).format(
                          'MM/DD/YYYY HH:mm'
                        )
                      : '-'}
                  </div>
                </div>
                <hr className="dashed my-4" />
                <h5>Order Placed By</h5>
                {editData?.order_placed_by_type
                  ? editData?.order_placed_by_type?.charAt(0).toUpperCase() +
                    editData?.order_placed_by_type?.slice(1)
                  : '-'}
                {editData?.order_placed_by ? (
                  <span className="ms-3">
                    ({editData?.order_placed_by?.trim()})
                  </span>
                ) : null}
              </Tab>
              <Tab eventKey="finance" title="Finance">
                <h5>Reply/Comments From Finance</h5>
                {editData?.finance_comments ? editData?.finance_comments : '-'}
                <hr className="dashed my-4" />
                <h5>Finance Section Signature</h5>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label d-block">Name:</label>
                    {editData?.finance_section
                      ? editData?.finance_section?.profile_settings?.userName
                      : '-'}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label d-block">Date:</label>
                    {editData?.finance_section_date
                      ? moment(editData.finance_section_date).format(
                          'MM/DD/YYYY HH:mm'
                        )
                      : '-'}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group ms-auto">
            <StylishNewButton
              type="button"
              className="button--primary"
              onClick={editResourceHandler}
            >
              Edit Resource
            </StylishNewButton>
            <StylishNewButton
              className="button--tertiary button--reverse"
              type="button"
              onClick={onDeleteResource}
            >
              Delete Resource
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this resource?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedDeleteActionData}
        />
      )}
    </>
  );
};
