export const chatDGPTSessionsSchema = [
  {
    // UUID
    dgpt_session_id: 'string',
    // 'event' | 'aor' | 'chat'
    functions: {},
    // Object: event | aor | {}
    initSessionInfo: {},
    locationInfo: {},
    // Array of GPT Message Completions
    messages: [],
    centroid: {},
    weatherCurrent: {},
    weatherForecast: {},
    bingNews: {},
    events: [],
    powerOutages: [],
    powerOutagePredictions: [],
    socialMediaPosts: {
      twitter: {
        posts: [],
        analysis: 'string',
      },
    },
  },
];

export const sitrepDataSources = [
  'EVENTS',
  'CURRENT WEATHER',
  'FORECAST WEATHER',
  'POWER OUTAGES',
  //'POWER OUTAGE PREDICTIONS', TODO restore when time permits https://disastertech.atlassian.net/browse/INO-90
  'NEWS',
  'SOCIAL MEDIA',
  'ICS-Active',
  'ICS-Historic',
];

export const tenantId = '1ed96d38-7385-4a96-8d28-d5bc7da9c92a';
export const sharepointDriveId =
  'b!oV3olmVTrU-SMkl5-fh8BjfvpRkdaxNNsK3ls1QHczHHiAxDcGvkQIAbwg1Mym8_';

export const fileExtensions = [
  '.c',
  '.cpp',
  '.cs',
  '.css',
  '.doc',
  '.docx',
  '.go',
  '.html',
  '.java',
  '.js',
  '.json',
  '.md',
  '.pdf',
  '.php',
  '.pptx',
  '.py',
  '.rb',
  '.sh',
  '.tex',
  '.ts',
  '.txt',
  '.msg', // Converted to txt
  '.xls', // Converted to txt
  '.xlsx', // Converted to txt
  '.url', // Converted to txt
  '.csv', // Converted to txt
];

export const section_template_types = [
  { label: 'Manual Entry', value: 'manual' },
  { label: 'AI Summarized', value: 'ai' },
];
