import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { selectUser, useAppSelector } from 'slices/commonSelectors';

export const rosterKeys = {
  base: 'rosters',
  list: (groupGuids: string[]) =>
    [rosterKeys.base, ...groupGuids.sort()] as const,
};

export const useRosters = (groupGuids: string[] = []) => {
  const user = useAppSelector(selectUser);

  return useQuery({
    queryKey: rosterKeys.list(groupGuids),
    queryFn: async () => {
      const { data } = await axios.post(
        config.apiGateway.fetchRostersForGroups,
        {
          user_guid: user.user_guid,
          groupGuids,
        }
      );
      return (data || []) as RosterItem[];
    },
    enabled: !!user.user_guid && groupGuids.length > 0,
  });
};
