import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { saveAs } from 'file-saver';

import { getUserGuid, getGroupGuid } from 'slices/sliceHelpers';

// Slice
const dchatSlice = createSlice({
  name: 'dchat',
  initialState: {
    current_sitrep_id: null,
    dchat: {},
    dchatId: null,
    status: 'idle',
    error: null,
    streamtext: '', // New state variable for websocket stream text
    dgptSitrepToInitialIncidentReport: undefined,
    isFetchGroupAIUsageLoaded: true,
    groupAIUsage: {},
    isFetchAllGroupsAIUsageLoaded: true,
    allGroupsAIUsage: [],
    isUploadSOPLoaded: true,
    isFetchSOPsRequestLoaded: true,
    SOPs: [],
    isDeleteSOPLoaded: true,
  },
  reducers: {
    setCurrentSitrepId: (state, action) => {
      state.current_sitrep_id = action.payload;
    },
    pollDChatSuccess: (state, action) => {
      state.dchat = action.payload;
    },
    postDChatSuccess: (state, action) => {
      state.dchatId = action.payload;
      // Nothing since polling is used to fetch data
    },
    postCancelRunSuccess: (state, action) => {
      state.dchat = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setDchatStream: (state, action) => {
      state.streamtext = action.payload; // Update stream text
    },
    setPostDchatInit: (state, action) => {
      state.streamtext = ''; // Update stream text
    },
    setSelectedDChat: (state, action) => {
      state.dchat = action.payload; // Set selected chat
    },
    setDgptSitrepToInitialIncidentReportSummary: (state, action) => {
      state.dgptSitrepToInitialIncidentReport = action.payload;
    },
    setFetchGroupAIUsageLoaded: (state, action) => {
      state.isFetchGroupAIUsageLoaded = action.payload;
    },
    fetchGroupAIUsageSuccess: (state, action) => {
      const newGroupAIUsage = { ...state.groupAIUsage };
      newGroupAIUsage[action.payload.group_guid] = action.payload.usage;
      state.groupAIUsage = newGroupAIUsage;
    },
    setFetchAllGroupsAIUsageLoaded: (state, action) => {
      state.isFetchAllGroupsAIUsageLoaded = action.payload;
    },
    fetchAllGroupsAIUsageSuccess: (state, action) => {
      state.allGroupsAIUsage = action.payload;
    },
    setUploadSOPLoaded: (state, action) => {
      state.isUploadSOPLoaded = action.payload;
    },
    setFetchSOPsLoaded: (state, action) => {
      state.isFetchSOPsRequestLoaded = action.payload;
    },
    fetchSOPsSuccess: (state, action) => {
      state.SOPs = action.payload.SOPs;
    },
    setDeleteSOPsLoaded: (state, action) => {
      state.isDeleteSOPLoaded = action.payload;
    },
  },
});

export const {
  setCurrentSitrepId,
  pollDChatSuccess,
  postDChatSuccess,
  postCancelRunSuccess,
  setError,
  setStatus,
  setDchatStream, // New action for setting stream text
  setPostDchatInit,
  setSelectedDChat,
  setDgptSitrepToInitialIncidentReportSummary,
  setFetchGroupAIUsageLoaded,
  fetchGroupAIUsageSuccess,
  fetchGroupAIUsageError,
  setFetchAllGroupsAIUsageLoaded,
  fetchAllGroupsAIUsageSuccess,
  setUploadSOPLoaded,
  setFetchSOPsLoaded,
  fetchSOPsSuccess,
  setDeleteSOPsLoaded,
} = dchatSlice.actions;

export default dchatSlice.reducer;

export const pollDChat = (dchatId) => {
  let interval;
  return (dispatch, getState) => {
    dispatch(setStatus('loading'));
    const user_guid = getUserGuid(getState);
    const state = getState();
    const current_sitrep_id = state.dchat.current_sitrep_id;
    const group_guid = getGroupGuid(getState);

    const data = {
      id: dchatId,
      dchat_id: dchatId,
      user_guid: user_guid,
      current_sitrep_id: current_sitrep_id,
      group_guid: group_guid,
    };

    // Initialize start time and a flag to track if the toast has been shown
    const startTime = Date.now();
    let toastShown = false;

    // Polling mechanism
    interval = setInterval(() => {
      // Check if 2 minutes have passed and the toast hasn't been shown yet
      if (!toastShown && Date.now() - startTime > 120000) {
        // 120000 ms = 2 minutes
        toast.info(
          'Large datasets take time to process. Try selecting fewer files for a faster response.',
          { ...toastConfig, autoClose: false }
        );
        toastShown = true; // Prevent the toast from showing multiple times
      }

      axios
        .get(`${config.apiGateway.disasterchat}/poll`, { params: data })
        .then((response) => {
          const dchat = response.data;
          if (!dchat) {
            dispatch(setStatus('succeeded'));
            clearInterval(interval);
          } else if (dchat.status === 'Error') {
            toast.error(dchat?.err_msg, { ...toastConfig, autoClose: false });
            dispatch(setError('DChat Error. Cancel run and try again.'));
            dispatch(cancelRun(dchat));
            clearInterval(interval);
          } else if (dchat.status === 'Complete') {
            dispatch(pollDChatSuccess(dchat));
            dispatch(setStatus('succeeded'));
            clearInterval(interval);
          } else {
            dispatch(setStatus('ongoing'));
            dispatch(pollDChatSuccess(dchat));
          }
        })
        .catch((error) => {
          console.error('Error checking DChat status:', error);
          dispatch(setError(error.message));
          dispatch(setStatus('failed'));
          toast.error('Pratus error: please contact support@disastertech.com', {
            ...toastConfig,
            autoClose: false,
          });
          clearInterval(interval);
        });
    }, 500); // Check every half second
  };
};

export const postDChat = (data) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  dispatch(setPostDchatInit());
  try {
    const user_guid = getUserGuid(getState);
    const state = getState();
    const current_sitrep_id = state.dchat.current_sitrep_id;
    const group_guid = getGroupGuid(getState);
    const payload = {
      ...data,
      user_guid,
      current_sitrep_id,
      group_guid: group_guid,
    };
    let response;
    if (data.dchat_id) {
      response = await axios.put(`${config.apiGateway.disasterchat}`, payload);
    } else {
      response = await axios.post(`${config.apiGateway.disasterchat}`, payload);
    }
    dispatch(postDChatSuccess(response.data));
    dispatch(setStatus('succeeded'));
    // Begin polling for dchat data
    dispatch(pollDChat(response.data));
  } catch (error) {
    toast.error(error.message, { ...toastConfig, autoClose: false });
    dispatch(setError(error.message));
    dispatch(setStatus('failed'));
  }
};

export const cancelRun = (data) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  try {
    const response = await axios.post(
      `${config.apiGateway.disasterchat}/cancel`,
      data
    );
    dispatch(postCancelRunSuccess(response.data));
    dispatch(setStatus('succeeded'));
    // Begin polling for dchat data
    dispatch(pollDChat(response.data.id));
  } catch (error) {
    toast.error(error.message, { ...toastConfig, autoClose: false });
    dispatch(setError(error.message));
    dispatch(setStatus('failed'));
  }
};

export const fetchGroupAIUsage = ({ group_guid }) => async (dispatch) => {
  dispatch(setFetchGroupAIUsageLoaded(false));

  try {
    const response = await axios(config.apiGateway.fetchGroupAIUsage, {
      method: 'POST',
      data: JSON.stringify({ group_guid }),
    });
    dispatch(fetchGroupAIUsageSuccess({ group_guid, usage: response.data }));
    dispatch(setFetchGroupAIUsageLoaded(true));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setFetchGroupAIUsageLoaded(true));
  }
};

export const fetchAllGroupsAIUsage = () => async (dispatch, getState) => {
  dispatch(setFetchAllGroupsAIUsageLoaded(false));

  try {
    const response = await axios(config.apiGateway.fetchAllGroupsAIUsage, {
      method: 'POST',
    });

    const data = response.data || [];
    dispatch(fetchAllGroupsAIUsageSuccess(data));
    dispatch(setFetchAllGroupsAIUsageLoaded(true));
  } catch (error) {
    console.error('Error withq DGPT task:', error);
    dispatch(setFetchAllGroupsAIUsageLoaded(true));
  }
};

export const uploadSOP = (file) => async (dispatch, getState) => {
  const state = getState();
  const { currentIncident } = state.app;

  dispatch(setUploadSOPLoaded(false));
  const { group_guid } = getState().app.currentlySelectedGroup;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('group_guid', group_guid);
  formData.append('tenantId', currentIncident?.tenant_id); // Append tenantId
  formData.append('sharepointDriveId', currentIncident?.sharepoint_location); // Append sharepointDriveId

  try {
    const fileUploadInstance = axios.create({
      ...axios.defaults,
      headers: {
        ...axios.headers,
        'Content-Type': undefined,
      },
    });

    fileUploadInstance.interceptors.request.use((config) => {
      const token = sessionStorage['accessToken'];
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    const response = await fileUploadInstance // Add return here to ensure the action returns a promise
      .post(config.apiGateway.uploadSOP, formData, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

    dispatch(setUploadSOPLoaded(true));
    dispatch(fetchSOPs());
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setUploadSOPLoaded(true));
  }
};

export const fetchSOPs = () => async (dispatch, getState) => {
  dispatch(setFetchSOPsLoaded(false));
  const { group_guid } = getState().app.currentlySelectedGroup;

  try {
    const response = await axios(config.apiGateway.fetchSOPs, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    });

    dispatch(fetchSOPsSuccess({ group_guid, SOPs: response.data }));
    dispatch(setFetchSOPsLoaded(true));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setFetchSOPsLoaded(true));
  }
};

export const deleteSOP = (id) => async (dispatch, getState) => {
  dispatch(setDeleteSOPsLoaded(false));
  const { group_guid } = getState().app.currentlySelectedGroup;

  try {
    const response = await axios(config.apiGateway.deleteSOP, {
      method: 'POST',
      data: JSON.stringify({
        id,
      }),
    });

    dispatch(setDeleteSOPsLoaded(true));
    dispatch(fetchSOPs());
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setDeleteSOPsLoaded(false));
  }
};

export const checkDeletedSOPFiles = (vectorStoreId) => async (
  dispatch,
  getState
) => {
  const { group_guid } = getState().app.currentlySelectedGroup;

  try {
    const response = await axios(config.apiGateway.deletedSOPFiles, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
        vector_store_id: vectorStoreId,
      }),
    });

    if (response?.data?.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error with DGPT task:', error);
    return false;
  }
};

export const downloadSOP = (fileId, filename) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.apiGateway.downloadSOP}/${fileId}`,
      {
        responseType: 'blob', // Set response type to 'blob' to handle binary data
      }
    );
    saveAs(response.data, filename); // Save file with the original filename
  } catch (error) {
    console.error('Error downloading SOP:', error);
    toast.error('Failed to download file. Please try again later.');
  }
};
