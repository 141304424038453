import React from 'react';
const StylishNewCheckbox = React.forwardRef(
  (
    {
      className,
      disabled,
      checked,
      defaultChecked,
      required,
      onClick,
      key,
      label,
      onLabelClick,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <div
          className={
            'form-checkbox align-items-center ' + (className ? className : '')
          }
        >
          {onLabelClick ? (
            <div className="form-checkbox__inner">
              <input
                type="checkbox"
                ref={ref}
                {...props}
                disabled={disabled}
                checked={checked}
                defaultChecked={defaultChecked}
                onClick={onClick}
                key={key}
                readOnly
              />
              <span className="icon"></span>
            </div>
          ) : (
            <>
              <input
                type="checkbox"
                ref={ref}
                {...props}
                disabled={disabled}
                defaultChecked={defaultChecked}
                checked={checked}
                onClick={onClick}
                key={key}
                readOnly
              />
              <span className="icon"></span>
            </>
          )}
          <label className="form-label" onClick={onLabelClick}>
            {label}
            {required ? (
              <span aria-label="Required field" className="required">
                *
              </span>
            ) : null}
          </label>
        </div>
      </>
    );
  }
);

StylishNewCheckbox.displayName = 'StylishNewCheckbox';

export { StylishNewCheckbox };
