import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import moment from 'moment';
import { ResourceTypes } from '../IncidentConfig/ResourceTypes';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';
import { Controller, useForm } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { Modal } from 'react-bootstrap';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import Accordion from 'react-bootstrap/Accordion';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { updateWorkAssignmentResource } from 'actions/IAPForm204Actions';

const formatedResourceTypes = ResourceTypes.map((resourceType) => ({
  label: resourceType.name,
  value: resourceType.name,
}));

export default function IAPForm204ResourceDialog({
  show,
  onClose,
  updateAssignmentResourceData,
  resourceDialogType,
  allMembers,
}) {
  const reduxDispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(true);
  const [selectedTab, setSelectedTab] = useState(true);
  const [incidentMembers, setIncidentMembers] = useState([]);
  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
  } = useForm({ mode: 'onChange' });
  useEffect(() => {
    if (
      !!resourceDialogType &&
      resourceDialogType === 'Edit' &&
      !!incidentMembers &&
      !!updateAssignmentResourceData
    ) {
      if (updateAssignmentResourceData.leader_guid) {
        const leader = incidentMembers.find(
          (member) =>
            member.value.user_guid === updateAssignmentResourceData.leader_guid
        );
        setValue('leader_guid', leader);
      }
      setValue('name', updateAssignmentResourceData.name);
      setValue('contact', updateAssignmentResourceData.contact);
      setValue('total', updateAssignmentResourceData.total);
      setValue('reporting_info', updateAssignmentResourceData.reporting_info);
      setValue(
        'special_instructions',
        updateAssignmentResourceData.special_instructions
      );
      setValue('create215a', updateAssignmentResourceData.create215a);
      setValue('type', {
        label: updateAssignmentResourceData.type,
        value: updateAssignmentResourceData.type,
      });
      setValue(
        'datetime_ordered',
        moment(updateAssignmentResourceData.datetime_ordered).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      );
      setValue(
        'eta',
        moment(updateAssignmentResourceData.eta).format('YYYY-MM-DD HH:mm:ss')
      );
      setValue('location', updateAssignmentResourceData.location);
      setValue('description', updateAssignmentResourceData.description);
      setSelectedTab(updateAssignmentResourceData.arrived);
    }
  }, [resourceDialogType, updateAssignmentResourceData, incidentMembers]);
  useEffect(() => {
    if (allMembers) {
      const members = allMembers.map((member) => ({
        label: member.profile_settings?.userName || member.email_address,
        value: member,
      }));
      setIncidentMembers(members);
    }
  }, [allMembers]);

  const toggleData = [
    { label: 'Arrived', value: true },
    { label: 'Not Arrived', value: false },
  ];

  const handleToggleButtonClick = (data) => {
    setSelectedTab(data);
  };

  const handleResourceSubmitClick = async (data) => {
    const resourceData = {
      name: data.name,
      leader_guid: data.leader_guid?.value?.user_guid,
      total: data.total,
      reporting_info: data.reporting_info,
      special_instructions: data.special_instructions,
      create215a: data.create215a,
      type: data.type?.value,
      arrived: selectedTab,
      location: data.location,
      datetime_ordered: data.datetime_ordered,
      eta: data.eta,
      description: data.description,
      id: updateAssignmentResourceData?.id,
      action_id: updateAssignmentResourceData?.action_id,
    };
    try {
      reduxDispatch(startLoading());
      await reduxDispatch(updateWorkAssignmentResource(resourceData));
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Resource</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(handleResourceSubmitClick)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Identifyer / Name</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput value={value} onChange={onChange} />
              )}
            />
            {errors.name && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Leader</label>
            <Controller
              control={control}
              name="leader_guid"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={incidentMembers}
                  value={value}
                  placeholder="Select a Type"
                  onChange={onChange}
                  isDisabled={false}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                />
              )}
            />
            {errors.leader_guid && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Contact Information #</label>
            <StylishNewInput
              disabled={true}
              value={
                watch('leader_guid')
                  ? getValues('leader_guid').value?.profile_settings
                      ?.mobile_phone_number || ''
                  : ''
              }
            />
          </div>
          <div className="mb-3">
            <label className="form-label">
              # of Persons / Total Resources on Hand
            </label>
            <Controller
              control={control}
              name="total"
              rules={{ required: true, min: 1 }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="number"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {errors.total?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors.total?.type === 'min' && (
              <span className="form-text form-error">
                Value should be greater than 0
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">
              Reporting info / Notes / Remarks
            </label>
            <Controller
              control={control}
              name="reporting_info"
              rules={{ required: true, maxLength: 1000 }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea value={value} onChange={onChange} />
              )}
            />
            {errors.reporting_info?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors.reporting_info?.type === 'maxLength' && (
              <span className="form-text form-error">
                Maximum 1000 characters allowed
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Special Instructions</label>
            <Controller
              control={control}
              name="special_instructions"
              rules={{ required: true, maxLength: 1000 }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea value={value} onChange={onChange} />
              )}
            />
            {errors.special_instructions?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors.special_instructions?.type === 'maxLength' && (
              <span className="form-text form-error">
                Maximum 1000 characters allowed
              </span>
            )}
          </div>
          <div className="my-4">
            <Controller
              control={control}
              name="create215a"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <StylishNewCheckbox
                  checked={value}
                  label="Create 215a to display special instructions"
                  onClick={() => onChange(!value)}
                />
              )}
            />
          </div>
          <Accordion className="dark">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Additional Resource Notes</Accordion.Header>
              <Accordion.Body>
                <div className="mb-3">
                  <label className="form-label">Type</label>
                  <Controller
                    control={control}
                    name="type"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        options={formatedResourceTypes}
                        value={value}
                        placeholder="Select a Type"
                        onChange={onChange}
                        isDisabled={false}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={false}
                      />
                    )}
                  />
                  {errors.type?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Date / Time Ordered</label>
                  <Controller
                    control={control}
                    name="datetime_ordered"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishDateTimePicker
                        value={value}
                        type="datetime-local"
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">ETA</label>
                  <Controller
                    control={control}
                    name="eta"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishDateTimePicker
                        value={value}
                        type="datetime-local"
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="mb-3 pt-1">
                  <StylishNewToggleButtonGroup
                    className="d-md-inline-flex"
                    data={toggleData}
                    selected={selectedTab}
                    onChange={handleToggleButtonClick}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Current Location</label>
                  <Controller
                    control={control}
                    name="location"
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput value={value} onChange={onChange} />
                    )}
                  />
                </div>
                <div className="m-0">
                  <label className="form-label">Resouce Notes</label>
                  <Controller
                    control={control}
                    name="description"
                    rules={{ maxLength: 1000 }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewTextArea value={value} onChange={onChange} />
                    )}
                  />
                  {errors.description?.type === 'maxLength' && (
                    <span className="form-text form-error">
                      Maximum 1000 characters allowed
                    </span>
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              type="button"
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
      {!isLoaded && <div>loading...</div>}
    </Modal>
  );
}
