import React from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import IconClose from '../../../assets/images/icon__times.svg';
import { mapStyles } from '../constants/mapStyles.js';
import { StylishNewRadio } from 'components/DesignSystems/New/StylishNewRadio';

const MapBase = (props) => {
  const { mapSettings, handleMapSettings, onClose } = props;

  // functions
  const handleClickClose = () => {
    onClose();
  };

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        <h4 className="m-0">Basemap</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {mapStyles.map((style, idx) => {
          return (
            <div key={'basemap-' + idx}>
              {idx !== 0 ? (
                <hr key={idx} className="dashed w-10 thin m-0" />
              ) : null}
              {/* <div
                key={style.key}
                className={`d-flex align-items-center py-3 ${
                  idx === 0 ? 'pt-0' : ''
                } ${idx === mapStyles.length - 1 ? 'pb-0' : ''}`}
              >
                <span>{style.key}</span>
                <StylishSwitcher
                  className={'ms-auto'}
                  onChange={() => handleMapSettings({
                    ...mapSettings,
                    basemap: style.value
                  })}
                  checked={mapSettings.basemap === style.value}
                />
              </div> */}
              <StylishNewRadio
                className={`my-3 ${idx === 0 ? 'mt-0' : ''} ${
                  idx === mapStyles.length - 1 ? 'mb-0' : ''
                }`}
                key={style.key}
                label={style.key}
                checked={mapSettings.basemap === style.value}
                onChange={() =>
                  handleMapSettings({
                    ...mapSettings,
                    basemap: style.value,
                  })
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MapBase;
