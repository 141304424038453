import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import {
  GEOLOCATION_OPTIONS,
  RESTORATION_STATUS_LIST,
  RTO_TIME_FRAME_OPTIONS,
  TASK_PRIORITY,
  TASK_STATUS_LIST,
  TASK_TYPE,
} from './TaskManagementConstant';
import moment from 'moment';
import generateUUID from 'utils/sharedUtils/generateUUID';
import {
  getAllCommentForTask,
  getAllLogsForTask,
  getAllTaskUnits,
  saveTask,
} from 'actions/TaskManagementActions';
import TaskManagementComment from './TaskManagementComment';
import TaskManagementLogs from './TaskManagementLogs';
import { getProcessStability } from './TaskManagementUtil';
import TaskManagementDetails from './TaskManagementDetails';
import IconRemove from 'assets/images/icon__delete.svg';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';

import { getAllAOR } from 'actions/locationAction';
import { toast } from 'react-toastify';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { SharedIcon } from '../SharedIcon/SharedIcon';

export default function TaskManagementCreateEditModal(props) {
  const { selectedTask, onClose } = props;

  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const rostersForGroup = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });
  const teamList = useSelector((state) => {
    return state.app.distributionListItems;
  });

  const allAor = useSelector((state) => {
    return state.app.allAor;
  });
  const currentGroupId = reduxCurrentlySelectedGroup?.group_guid;

  const [reporterMembers, setReporterMembers] = useState([]);
  const [assigneeMembers, setAssigneeMembers] = useState([]);
  const [geoLocation, setGeoLocation] = useState();
  const [tab, setTab] = useState('comments');
  const [markerColor, setMarkerColor] = useState('blue');
  const [geoLocationTypeOptions, setGeoLocationTypeOptions] = useState(
    GEOLOCATION_OPTIONS
  );
  const [aorsWithLocation, setAorsWithLocation] = useState([]);
  const [teamAor, setTeamAor] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(
    TASK_PRIORITY[0].value
  );

  const teamOptions = useMemo(() => {
    const teamOptions = [];
    if (teamList?.length > 0) {
      teamList.forEach((item) => {
        teamOptions.push({
          label: item?.name,
          value: item?.id,
        });
      });
    }

    return teamOptions;
  }, [teamList]);

  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    mode: 'onChange',
    values: {
      process_owner: [
        {
          process_owner_guid: '',
          process_owner_position: '',
          process_owner_business: '',
        },
      ],
    },
  });

  useEffect(() => {
    setValue('rto', '0');
    setValue('rto_time_frame', 'hours');
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'process_owner',
  });

  const onTaskData = (data) => {
    if (reduxCurrentlySelectedGroup?.group_guid) {
      const params = {
        id: data?.id || generateUUID(),
        task_id: data.task_id,
        title: data?.title,
        content: data?.description,
        type: data?.type?.value,
        reporter_team_guid: data?.reporter_team?.value,
        reporter_member_guid: data?.reporter_member?.value,
        assignee_team_guid: data?.assignee_team?.value,
        deadline_date: data?.date_time,
        geolocation_type: data?.geolocation_type?.value,
        group_guid: reduxCurrentlySelectedGroup?.group_guid,
        status: data?.status?.value,
        business_leadership_team_tag: data.business_leadership_team_tag,
        unit: data.unit,
        rto: data?.rto || '0',
        rto_time_frame: data?.rto_time_frame?.value || 'hours',
        documents_needed: data.documents_needed,
        system_apps: data.system_apps,
        process_owner: data.process_owner.map((e) => {
          return {
            ...e,
            process_owner_guid: e.process_owner_guid.value,
          };
        }),
        location_aor:
          data?.geolocation_type?.value ===
          'Geolocation of AOR of Reporter Team'
            ? teamAor?.id
            : data?.geolocation_type?.value === 'Geolocation of AOR'
            ? data?.location_aor?.value.id
            : null,
        geolocation:
          data?.geolocation_type?.value === 'Custom Geolocation'
            ? geoLocation
            : null,
        current_location_geom:
          data?.geolocation_type?.value === 'Custom Geolocation'
            ? geoLocation?.geojson?.data?.features?.[0]?.geometry
            : null,
        isCreated: data?.id ? false : true,
        priority: selectedPriority,
        restoration_status: data?.restoration_status?.value,
        is_critical: data?.is_critical,
      };
      reduxDispatch(saveTask(params));
    }
    onClose();
  };

  const onAddNewPosition = () => {
    append({
      process_owner_guid: '',
      process_owner_position: '',
      process_owner_business: getValues('assignee_team')?.label,
    });
  };

  const onRemovePosition = (index) => {
    remove(index);
    if (fields.length === 1) {
      onAddNewPosition();
    }
  };

  const handleReporterTeam = (selected) => {
    updateUnit(selected);
    if (rostersForGroup?.length > 0 && selected) {
      const childMemberIds =
        teamList.find((item) => item.id === selected?.value)?.members || [];

      const collection = rostersForGroup
        .filter(
          (member) =>
            childMemberIds.includes(member.user_guid) &&
            member.group_guid === currentGroupId
        )
        .map((member) => ({
          label: member.email_address,
          value: member.user_guid,
        }));

      setReporterMembers(collection);
    }
    if (allAor && allAor.length > 0 && selected) {
      const team = teamList.find((item) => item.id === selected?.value);
      if (team) {
        const aorWithLocation = allAor.find(
          (item) => item.id === team?.teamLocation?.location_id
        );
        if (aorWithLocation) {
          setGeoLocationTypeOptions([
            GEOLOCATION_OPTIONS[0],
            aorsWithLocation && GEOLOCATION_OPTIONS[1],
            GEOLOCATION_OPTIONS[2],
          ]);
          setGeoLocation(aorWithLocation.location);
          setValue('geolocation_type', GEOLOCATION_OPTIONS[0], {
            shouldValidate: true,
          });
          setTeamAor(aorWithLocation);
        } else {
          setGeoLocation(null);
          setValue('geolocation_type', null, { shouldValidate: true });
          setGeoLocationTypeOptions([
            aorsWithLocation && GEOLOCATION_OPTIONS[1],
            GEOLOCATION_OPTIONS[2],
          ]);
          setTeamAor(null);
        }
      } else {
        setGeoLocation(null);
        setValue('geolocation_type', null, { shouldValidate: true });
        setGeoLocationTypeOptions([
          aorsWithLocation && GEOLOCATION_OPTIONS[1],
          GEOLOCATION_OPTIONS[2],
        ]);
        setTeamAor(null);
      }
    }
  };

  const handleAssigneeTeam = (selected) => {
    updateProcessOwnerBusiness(selected);
    if (rostersForGroup?.length > 0 && selected) {
      const childMemberIds =
        teamList.find((item) => item.id === selected?.value)?.members || [];

      const collection = rostersForGroup
        .filter(
          (member) =>
            childMemberIds.includes(member.user_guid) &&
            member.group_guid === currentGroupId
        )
        .map((member) => ({
          label: member.email_address,
          value: member.user_guid,
        }));

      setAssigneeMembers(collection);
    }
  };

  const updateProcessOwnerBusiness = (selected) => {
    if (!selected) return;
    for (let i = 0; i < fields.length; i++) {
      setValue(`process_owner.${i}.process_owner_business`, selected.label);
    }
  };
  const updateUnit = (selectedBusinessLeadershipTeam) => {
    if (selectedBusinessLeadershipTeam) {
      setValue('unit', selectedBusinessLeadershipTeam.label);
    }
  };

  const getTaskStatusColor = () => {
    const taskStatus = watch('status') || selectedTask?.status;
    const selected = TASK_STATUS_LIST.find(
      (elem) => elem.value === taskStatus?.value
    );
    if (selected) {
      setMarkerColor(selected?.color);
    }
    return selected?.color;
  };

  const handleTogglePriority = (value) => {
    setSelectedPriority(value);
  };

  // useEffect
  useEffect(() => {
    setValue('rto', '0');
    setValue('is_critical', true);
    setValue('rto_time_frame', RTO_TIME_FRAME_OPTIONS[0]);
  }, []);

  useEffect(() => {
    if (reduxCurrentlySelectedGroup) {
      reduxDispatch(getAllAOR(false));
      reduxDispatch(getAllTaskUnits());
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (allAor && allAor.length > 0) {
      const allAorWithLocation = allAor.filter((aor) => !!aor.location);
      if (allAorWithLocation.length > 0) {
        setGeoLocationTypeOptions([
          !watch('reporter_team_guid')
            ? GEOLOCATION_OPTIONS[0]
            : teamAor && GEOLOCATION_OPTIONS[0],
          GEOLOCATION_OPTIONS[1],
          GEOLOCATION_OPTIONS[2],
        ]);
        setAorsWithLocation([
          ...allAorWithLocation.map((aor) => {
            return {
              label: aor.name,
              value: aor,
            };
          }),
        ]);
      } else {
        setAorsWithLocation([]);
        setGeoLocationTypeOptions([GEOLOCATION_OPTIONS[2]]);
      }
    }
  }, [allAor]);

  useEffect(() => {
    if (selectedTask) {
      reduxDispatch(getAllCommentForTask(selectedTask.id));
      reduxDispatch(getAllLogsForTask(selectedTask.id));
      const reporter_team = teamList.find(
        (val) => val.id === selectedTask?.reporter_team_guid
      );
      const reporter_member = rostersForGroup.find(
        (val) => val.user_guid === selectedTask?.reporter_member_guid
      );
      const assignee_team = teamList.find(
        (val) => val.id === selectedTask?.assignee_team_guid
      );

      let allAorWithLocation;

      if (allAor && allAor.length > 0) {
        allAorWithLocation = allAor.filter((aor) => !!aor.location);
      }

      setValue('unit', selectedTask?.unit);
      setValue('id', selectedTask?.id);
      setValue('task_id', selectedTask?.task_id);
      setValue('title', selectedTask?.title);
      setValue(
        'type',
        TASK_TYPE.find((elem) => elem.value === selectedTask?.type)
      );
      setValue(
        'reporter_team',
        reporter_team
          ? { label: reporter_team.name, value: reporter_team.id }
          : null
      );
      handleReporterTeam(
        reporter_team
          ? { label: reporter_team.name, value: reporter_team.id }
          : null
      );
      setValue(
        'reporter_member',
        reporter_member
          ? {
              label: reporter_member.email_address,
              value: reporter_member.user_guid,
            }
          : null
      );
      setValue(
        'assignee_team',
        assignee_team
          ? { label: assignee_team.name, value: assignee_team.id }
          : null
      );
      handleAssigneeTeam(
        assignee_team
          ? { label: assignee_team.name, value: assignee_team.id }
          : null
      );
      setValue(
        'geolocation_type',
        GEOLOCATION_OPTIONS.find(
          (op) => op.value === selectedTask?.geolocation_type,
          { shouldValidate: true }
        )
      );

      selectedTask?.deadline_date &&
        setValue('date_time', moment(selectedTask?.deadline_date));

      setValue('description', selectedTask?.content);
      setValue(
        'status',
        TASK_STATUS_LIST.find((elem) => elem.label === selectedTask?.status)
      );

      reporter_member?.user_name
        ? setValue('business_leadership_team_tag', reporter_member?.user_name)
        : setValue(
            'business_leadership_team_tag',
            reporter_member?.email_address
          );

      setValue('rto', selectedTask.rto || '0');
      setValue(
        'rto_time_frame',
        RTO_TIME_FRAME_OPTIONS.find(
          (elem) => elem.value === selectedTask?.rto_time_frame
        ) || 'hours'
      );
      setValue('documents_needed', selectedTask.documents_needed);
      setValue('system_apps', selectedTask.system_apps);
      setSelectedPriority(selectedTask?.priority);
      setValue(
        'restoration_status',
        RESTORATION_STATUS_LIST.find(
          (elem) => elem.value === selectedTask?.restoration_status
        )
      );
      setValue('is_critical', selectedTask?.is_critical);
      if (selectedTask.process_owner.length) {
        for (let i = 0; i < selectedTask.process_owner.length; i++) {
          const po = selectedTask.process_owner[i];
          setValue(`process_owner.${i}.process_owner_guid`, {
            label: po.email_address,
            value: po.process_owner_guid,
          });
          setValue(
            `process_owner.${i}.process_owner_position`,
            po.process_owner_position
          );
          setValue(
            `process_owner.${i}.process_owner_business`,
            po.process_owner_business
          );
          if (i !== selectedTask.process_owner.length - 1) onAddNewPosition();
        }
      }
      if (selectedTask.geolocation_type === GEOLOCATION_OPTIONS[0].value) {
        setTeamAor(selectedTask.location_aor_data);
        setGeoLocation(selectedTask?.location_aor_data?.location);
      } else if (
        selectedTask.geolocation_type === GEOLOCATION_OPTIONS[1].value
      ) {
        const selectedAOR = allAorWithLocation.filter(
          (item) => selectedTask.location_aor === item.id
        );

        if (selectedAOR.length) {
          setValue('location_aor', {
            label: selectedAOR[0]?.name,
            value: selectedAOR[0]?.id,
          });
        } else {
          setValue('location_aor', null);
        }

        setGeoLocation(selectedAOR[0]?.location);
      } else {
        setGeoLocation(selectedTask?.geolocation);
      }
    }

    getTaskStatusColor();
  }, [selectedTask, teamList, rostersForGroup]);

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /[0-9]|Backspace/.test(keyValue);
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  const onSetGeoLocation = (location) => {
    if (
      watch('geolocation_type') &&
      watch('geolocation_type')?.value !== 'Custom Geolocation'
    ) {
      toast.error(`${watch('geolocation_type')?.value} can not be updated`);
      return;
    }
    setGeoLocation(location);
  }; //
  const onChangeGeolocationType = (geolocation_type) => {
    if (geolocation_type.value === GEOLOCATION_OPTIONS[0].value) {
      setGeoLocation(teamAor?.location);
    } else if (geolocation_type.value === GEOLOCATION_OPTIONS[1].value) {
      setGeoLocation(
        selectedTask?.location_aor_data
          ? selectedTask?.location_aor_data.location
          : null
      );
    } else {
      setGeoLocation(selectedTask?.geolocation);
    }
  };

  const showStability = () => {
    const assessmentStatus = watch('status')?.value || selectedTask?.status;
    const assessmentDate = watch('date_time') || selectedTask?.deadline_date;
    const restorationStatus =
      watch('restoration_status')?.value || selectedTask?.restorationStatus;
    const rto = watch('rto') || selectedTask?.rto;
    const rtoTimeFrame =
      watch('rto_time_frame')?.value || selectedTask?.rto_time_frame || 'hours';
    const rtoTimeFrameObj = RTO_TIME_FRAME_OPTIONS.find(
      (frame) => frame.value === rtoTimeFrame
    );
    const isCritical = watch('is_critical') || selectedTask?.is_critical;
    const stability = getProcessStability(
      assessmentStatus,
      assessmentDate,
      restorationStatus,
      parseInt(rto),
      rtoTimeFrameObj,
      isCritical
    );

    return (
      <div
        className="task-status-badge"
        style={{
          color: stability.color,
          border: `1px solid ${stability.color}`,
        }}
      >
        {stability.label}
      </div>
    );
  };
  const autoFilled = (email) => {
    const fullDetails = rostersForGroup.filter((item) => {
      return item.user_guid === email.value;
    });
    fullDetails[0]?.user_name
      ? setValue('business_leadership_team_tag', fullDetails[0]?.user_name)
      : setValue('business_leadership_team_tag', fullDetails[0]?.email_address);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        enforceFocus={false}
        size="xl"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {selectedTask?.id ? 'Edit' : 'Create'} Critical Task
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onTaskData)}>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">
                    Critical Task Id
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>Task ID should be unique</Tooltip>
                      )}
                      placement="top"
                    >
                      <span className="material-symbols-outlined fs-6 ps-2">
                        info
                      </span>
                    </OverlayTrigger>
                  </label>
                  <Controller
                    control={control}
                    name="task_id"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        placeholder="Task Id"
                      />
                    )}
                  />
                  {errors?.task_id?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Critical Process</label>
                  <Controller
                    control={control}
                    name="title"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        placeholder="Title"
                      />
                    )}
                  />
                  {errors?.title?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">
                    Critical Process Description
                  </label>
                  <Controller
                    control={control}
                    name="description"
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewTextArea
                        onChange={onChange}
                        value={value}
                        placeholder="Description"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label d-flex">Prioritization</label>
                  <StylishNewToggleButtonGroup
                    data={TASK_PRIORITY}
                    selected={selectedPriority}
                    onChange={handleTogglePriority}
                    className="mt-4 mt-lg-0 w-100"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label d-flex">Criticalness</label>
                      <Controller
                        control={control}
                        name="is_critical"
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewCheckbox
                            onClick={onChange}
                            checked={value}
                            className="task-form-height"
                            label={'Is Critical Process?'}
                          />
                        )}
                      />
                    </div>

                    <div className="col-6">
                      <label className="form-label d-flex">Stability</label>
                      {showStability()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label d-flex">Assessment Status</label>
                  <Controller
                    control={control}
                    name="status"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        value={value}
                        defaultValue={value}
                        options={TASK_STATUS_LIST}
                        onChange={(val) => {
                          onChange(val);
                          getTaskStatusColor();
                        }}
                        placeholder="Task Filter"
                        isClearable={false}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors?.status?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label d-flex">
                    Restoration Status
                  </label>
                  <Controller
                    control={control}
                    name="restoration_status"
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        value={value}
                        defaultValue={RESTORATION_STATUS_LIST[0]}
                        options={RESTORATION_STATUS_LIST}
                        onChange={onChange}
                        placeholder="Restoration Status"
                        isClearable={false}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={
                          watch('status')?.value !== TASK_STATUS_LIST[2].value
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Assessment Date</label>
                  <Controller
                    control={control}
                    name="date_time"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishDateTimePicker
                        disableDateTimeLabel={true}
                        onChange={onChange}
                        value={value}
                        isClearable={true}
                        placeholderText={'MM/DD/YYYY'}
                      />
                    )}
                  />
                  {errors?.date_time?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Type</label>
                  <Controller
                    control={control}
                    name="type"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        value={value}
                        defaultValue={value}
                        options={TASK_TYPE}
                        onChange={onChange}
                        placeholder="Select Type"
                        isClearable={false}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors?.type?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">RTO</label>
                  <Controller
                    control={control}
                    name="rto"
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="number"
                        onKeyPress={handleKeyPress}
                        onChange={onChange}
                        value={value}
                        placeholder={0}
                      />
                    )}
                  />
                  {errors?.rto?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">RTO time frame</label>
                  <Controller
                    control={control}
                    name={`rto_time_frame`}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        value={value}
                        defaultValue={{ label: 'hours', value: 'hours' }}
                        options={RTO_TIME_FRAME_OPTIONS}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeholder="Select..."
                        isClearable={false}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Business Leadership Team</label>
                  <Controller
                    control={control}
                    name="reporter_team"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        value={value}
                        defaultValue={value}
                        options={teamOptions}
                        onChange={(selected) => {
                          onChange(selected);
                          handleReporterTeam(selected);
                        }}
                        placeholder="Select Business Leadership Team Member"
                        isClearable={false}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors?.reporter_team?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">
                    Business Leadership Team Member
                  </label>
                  <Controller
                    control={control}
                    name="reporter_member"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        value={value}
                        defaultValue={value}
                        options={reporterMembers}
                        onChange={(selected) => {
                          onChange(selected);
                          autoFilled(selected);
                        }}
                        placeholder="Select Business Leadership Team Member"
                        isClearable={false}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors?.reporter_member?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Process Owner Team</label>
                  <Controller
                    control={control}
                    name="assignee_team"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        value={value}
                        defaultValue={value}
                        options={teamOptions}
                        onChange={(selected) => {
                          onChange(selected);

                          handleAssigneeTeam(selected);
                        }}
                        placeholder="Select Process Owner Team"
                        isClearable={false}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors?.reporter_team?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              {fields && !!fields?.length ? (
                <div className="col-md-12">
                  <hr className=" my-4" />
                </div>
              ) : null}
              {fields &&
                fields.map((field, index) => {
                  return (
                    <div className="col-md-12" key={field.id}>
                      <div className="row">
                        {index !== 0 ? (
                          <div className="col-md-12">
                            <hr className="dashed my-4" />
                          </div>
                        ) : null}
                        <div className="col-4">
                          <div className="mb-3">
                            <label className="form-label">
                              {index > 0 ? 'Alternate' : ''} Process Owner
                            </label>
                            <Controller
                              control={control}
                              name={`process_owner.${index}.process_owner_guid`}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <StylishNewSelect
                                  value={value}
                                  defaultValue={value}
                                  options={assigneeMembers}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeholder="Select..."
                                  isClearable={false}
                                  isSearchable={false}
                                  isMulti={false}
                                  isDisabled={false}
                                />
                              )}
                            />
                            {errors?.process_owner?.at(index)
                              ?.process_owner_guid?.type === 'required' && (
                              <span className="form-text form-error">
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="mb-3">
                            <label className="form-label">
                              {index > 0 ? 'Alternate' : ''} Process Owner
                              Position
                            </label>
                            <Controller
                              control={control}
                              name={`process_owner.${index}.process_owner_position`}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <StylishNewInput
                                  type="text"
                                  onChange={onChange}
                                  value={value}
                                  placeholder="Unit"
                                />
                              )}
                            />
                            {errors?.process_owner?.at(index)
                              ?.process_owner_position?.type === 'required' && (
                              <span className="form-text form-error">
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="mb-3">
                            <label className="form-label">
                              {index > 0 ? 'Alternate' : ''} Process Owner
                              Business
                            </label>
                            <Controller
                              control={control}
                              name={`process_owner.${index}.process_owner_business`}
                              rules={{ required: false }}
                              render={({ field: { onChange, value } }) => (
                                <StylishNewInput
                                  type="text"
                                  onChange={onChange}
                                  value={value}
                                  placeholder="Process Owner Business"
                                  disabled={true}
                                />
                              )}
                            />
                            {errors?.process_owner?.at(index)
                              ?.process_owner_business?.type === 'required' && (
                              <span className="form-text form-error">
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="button-group">
                            {fields.length < 2 &&
                              fields.length !== assigneeMembers.length && (
                                <StylishNewButton
                                  type="button"
                                  customButton
                                  className={'button--icon primary'}
                                  onClick={onAddNewPosition}
                                >
                                  <SharedIcon iconName="add" bold />
                                </StylishNewButton>
                              )}
                            {index !== 0 ? (
                              <StylishNewButton
                                type="button"
                                customButton
                                className={'button--icon primary ms-2'}
                                onClick={() => onRemovePosition(index)}
                              >
                                <img src={IconRemove} alt="" />
                              </StylishNewButton>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {fields && !!fields?.length ? (
                <div className="col-md-12">
                  <hr className=" my-4" />
                </div>
              ) : null}
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Unit</label>
                  <Controller
                    control={control}
                    name="unit"
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        value={value}
                        onChange={onChange}
                        placeholder="Unit"
                        disabled={true}
                      />
                    )}
                  />
                  {errors?.unit?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">
                    Business Leadership Team Tag
                  </label>
                  <Controller
                    control={control}
                    name="business_leadership_team_tag"
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        placeholder="Business Leadership Team Tag"
                        disabled={true}
                      />
                    )}
                  />
                  {/* {errors?.business_leadership_team_tag?.type ===
                    'required' && (
                      <span className="form-text form-error">
                        This field is required
                      </span>
                    )} */}
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Documents Needed</label>
                  <Controller
                    control={control}
                    name="documents_needed"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        placeholder="Documents Needed"
                      />
                    )}
                  />
                  {errors?.documents_needed?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">System Apps</label>
                  <Controller
                    control={control}
                    name="system_apps"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        onChange={onChange}
                        value={value}
                        placeholder="System Apps"
                      />
                    )}
                  />
                  {errors?.system_apps?.type === 'required' && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <hr className="my-3" />
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label mb-3">Geolocation</label>
                  <Controller
                    control={control}
                    name="geolocation_type"
                    rules={{
                      required: false,
                      validate: (geoLocation) => {
                        if (
                          geoLocation &&
                          geoLocation.value ===
                            'Geolocation of AOR of Reporter Team' &&
                          !getValues('reporter_team')
                        ) {
                          return 'Reporter team is not selected. Or Reporter team has no AOR.';
                        }
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        value={value}
                        options={geoLocationTypeOptions}
                        onChange={(e) => {
                          onChangeGeolocationType(e);
                          onChange(e);
                        }}
                        placeholder="Select..."
                        isClearable={false}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors?.geolocation_type?.type === 'validate' && (
                    <span className="form-text form-error">
                      {errors.geolocation_type?.message}
                    </span>
                  )}
                </div>
              </div>
              {watch('geolocation_type') &&
                watch('geolocation_type')?.value === 'Geolocation of AOR' && (
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label mb-3">
                        Select Aor For Geolocation
                      </label>
                      <Controller
                        control={control}
                        name="location_aor"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewSelect
                            value={value}
                            options={aorsWithLocation}
                            onChange={(e) => {
                              setGeoLocation(e.value.location);
                              onChange(e);
                            }}
                            placeholder="Select..."
                            isClearable={false}
                            isSearchable={false}
                            isMulti={false}
                            isDisabled={false}
                          />
                        )}
                      />
                      {errors?.location_aor?.type === 'required' && (
                        <span className="form-text form-error">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                )}
              {watch('geolocation_type') &&
                watch('geolocation_type').value ===
                  'Geolocation of AOR of Reporter Team' &&
                location && (
                  <GeolocationWrapper
                    geoLocation={geoLocation}
                    geolocation_type={watch('geolocation_type')}
                    markerColor={markerColor}
                    onSetGeoLocation={onSetGeoLocation}
                  />
                )}
              {watch('geolocation_type') &&
                watch('geolocation_type').value === 'Geolocation of AOR' &&
                location && (
                  <GeolocationWrapper
                    geoLocation={geoLocation}
                    geolocation_type={watch('geolocation_type')}
                    markerColor={markerColor}
                    onSetGeoLocation={onSetGeoLocation}
                  />
                )}
              {watch('geolocation_type') &&
                watch('geolocation_type').value === 'Custom Geolocation' && (
                  <GeolocationWrapper
                    geoLocation={geoLocation}
                    geolocation_type={watch('geolocation_type')}
                    markerColor={markerColor}
                    onSetGeoLocation={onSetGeoLocation}
                  />
                )}
              <div className="col-12">
                <hr className="my-3" />
              </div>
              {selectedTask && (
                <div className="col-12">
                  <div className="tab-wrapper content-gap-none">
                    <Tabs activeKey={tab} onSelect={(k) => setTab(k)}>
                      <Tab eventKey="details" title="Details">
                        {tab === 'details' && (
                          <TaskManagementDetails selectedTask={selectedTask} />
                        )}
                      </Tab>
                      <Tab eventKey="comments" title="Comments">
                        {tab === 'comments' && (
                          <TaskManagementComment task_id={selectedTask?.id} />
                        )}
                      </Tab>
                      <Tab eventKey="logs" title="Logs">
                        {tab === 'logs' && (
                          <TaskManagementLogs task_id={selectedTask?.id} />
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={() => onClose()}
                type="submit"
              >
                Cancel
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button--primary"
                // disabled={isSubmitted && !isValid}
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

const GeolocationWrapper = ({
  geoLocation,
  onSetGeoLocation,
  geolocation_type,
  markerColor,
}) => {
  const mapboxToken = window.env.MAPBOX_ACCESS_TOKEN;

  return (
    <div className="col-12">
      <div className="mb-3">
        <label className="form-label mb-3">Geolocation</label>
        <GeoLocationComponent
          location={geoLocation}
          setLocation={(location) => onSetGeoLocation(location)}
          mapboxToken={mapboxToken}
          isShowMarker={true}
          markerColor={markerColor}
          hideSelectLocationButton={
            geolocation_type &&
            (geolocation_type.value === 'Geolocation of AOR' ||
              geolocation_type.value === 'Geolocation of AOR of Reporter Team')
              ? true
              : false
          }
        />
      </div>
    </div>
  );
};
