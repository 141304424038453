import React, { useState, useEffect } from 'react';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useSitrepsV2 } from './hooks/useSitrepsV2';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './CustomAccordion.css';
import { useCustomDataSources } from './hooks/useCustomDataSources';
import { useFeatureFlag } from '../../utils/featureFlags';
import { selectGroup, useAppSelector } from 'slices/commonSelectors';
import { useCapacityAnalysisList } from 'hooks/useCapacity';
import { generateInferredName } from '../MapComponent/utils/generateInferredName';

let isPushed = false;

const LocationRequiredDataSources = [
  {
    source: 'Current Weather',
    description: 'LOCATION REQUIRED (Event).  Current weather at location.',
    id: '',
  },
  {
    source: 'Forecast Weather',
    description: 'LOCATION REQUIRED (Event).  Forecast weather at location.',
    id: '',
  },
  {
    source: 'Power Outages',
    description: 'LOCATION REQUIRED (Event).  Power outages at location.',
    id: '',
  },
  {
    source: 'ERI (Energy Reliability Index)',
    description:
      'LOCATION REQUIRED (Event).  ERI (Energy Reliability Index) at location.',
    id: '',
  },
];

const DataSources = ({
  selections = [],
  setSelections,
  usingSitrep = false,
}) => {
  const { data: sitreps = [] } = useSitrepsV2();
  const currentSitrepId = useSelector((state) => state.dchat.current_sitrep_id);
  const group_guid = useAppSelector(selectGroup);
  const { data: capacityAnalysisList = [] } = useCapacityAnalysisList(group_guid.group_guid);
  const [dataSources, setDataSources] = useState([]);
  const neoFlag = useFeatureFlag('NEO');
  const isUsingTeams =
    sessionStorage['isUsingTeams'] === true ||
    sessionStorage['isUsingTeams'] === 'true';

  const DChatDataSources = [
    {
      source: 'ICS-Active',
      description: 'Current ICS data. Quick and concise.',
      id: '',
    },
    {
      source: 'ICS-Historic',
      description:
        'Full version history of ICS data for current incident. Expect longer run times. Precise prompts are advised.',
      id: '',
    },
  ];

  const { data: customDataSources = [] } = useCustomDataSources();

  useEffect(() => {
    let fetchedDataSources = [
      { source: 'SITREP', description: 'Situation Reports', id: '' },
      {
        source: 'Files',
        description: `Files uploaded to Pratus${
          isUsingTeams ? ' and SharePoint' : ''
        }`,
        id: '',
      },
      { source: 'Web', description: 'Web Scraping', id: '' },
      { source: 'News', description: 'News Articles', id: '' },
      { source: 'Social Media', description: 'Social Media Posts', id: '' },
    ];

    if (usingSitrep && neoFlag && !isPushed) {
      LocationRequiredDataSources.push({
        source: 'Seerist Events',
        description: 'LOCATION REQUIRED. Events and articles for your AOR',
        id: '',
      });
      isPushed = true;
    }

    if (usingSitrep) {
      fetchedDataSources = fetchedDataSources.filter(
        (source) => source.source !== 'SITREP'
      );
      fetchedDataSources = [
        ...fetchedDataSources,
        ...LocationRequiredDataSources,
        ...DChatDataSources,
      ];
    } else {
      fetchedDataSources = [...fetchedDataSources, ...DChatDataSources];
    }
    
    const customSources = customDataSources.map((cd) => ({
      source: cd.name,
      description: cd.description,
      id: cd.id,
      isCustom: true,
      customData: cd,
    }));
    
    if (usingSitrep) {
      fetchedDataSources = [...fetchedDataSources, ...customSources];
    } else {
      fetchedDataSources = [...fetchedDataSources, ...customSources];
    }
    
    if (neoFlag) {
      fetchedDataSources.push({
        source: 'NEO Analysis',
        description: 'Noncombatant Evacuation Order Analysis.',
        id: '',
      });
    }

    setDataSources(fetchedDataSources);
  }, [usingSitrep, isUsingTeams, customDataSources, neoFlag]);

  const sitrepOptions = sitreps
    .filter((s) => !s.archived)
    .map((sitrep) => ({
      value: sitrep.id,
      label: sitrep.name,
    }));

  const selectedNeo = selections.find(
    (selection) => selection.source === 'NEO Analysis'
  );
  const selectedNeoOption = selectedNeo
    ? (() => {
        const neo = capacityAnalysisList.find((neo) => neo.id === selectedNeo.id);
        return neo 
          ? { 
            value: neo.id, 
            label: generateInferredName(neo)
          } 
          : null;
      })()
    : null;

  const handleSitrepChange = (selectedOption) => {
    const newSelections = selections.filter(
      (selection) => selection.source !== 'SITREP'
    );
    if (selectedOption) {
      newSelections.push({ source: 'SITREP', id: selectedOption.value });
    }

    // Update the data sources for SITREP
    setDataSources((prevDataSources) =>
      prevDataSources.map((source) =>
        source.source === 'SITREP'
          ? { ...source, id: selectedOption ? selectedOption.value : '' }
          : source
      )
    );

    setSelections(newSelections);
  };

  const handleNeoChange = (selectedOption) => {
    // Process new selections outside of the state updater
    const newSelections = selections.filter(
      (selection) => selection.source !== 'NEO Analysis'
    );
    if (selectedOption) {
      newSelections.push({ source: 'NEO Analysis', id: selectedOption.value });
    }

    // Update the data sources for NEO Analysis
    setDataSources((prevDataSources) =>
      prevDataSources.map((source) =>
        source.source === 'NEO Analysis'
          ? { ...source, id: selectedOption ? selectedOption.value : '' }
          : source
      )
    );

    // Set the new selections
    setSelections(newSelections);
  };

  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      setSelections([...selections, row]);
    } else {
      setSelections(
        selections.filter((selection) => selection.source !== row.source)
      );
    }
  };

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelections(rows);
    } else {
      setSelections([]);
    }
  };

  const selectedSitrep = usingSitrep
    ? null
    : selections.find((selection) => selection.source === 'SITREP');
  const selectedSitrepOption = selectedSitrep
    ? sitrepOptions.find((option) => option.value === selectedSitrep.id)
    : null;

  const columns = [
    {
      dataField: 'source',
      text: 'Source Name',
      sort: true,
      attrs: { title: 'Source Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: false,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'data',
      text: 'Data',
      sort: false,
      formatter: (cellContent, row) => {
        if (row.source === 'SITREP' && !usingSitrep) {
          if (!sitrepOptions.length) {
            return <div>No SITREPS Available</div>;
          }
          return (
            <StylishNewSelect
              options={sitrepOptions}
              value={selectedSitrepOption || null}
              onChange={handleSitrepChange}
              isClearable={true}
              placeholder="Select a SITREP"
            />
          );
        }
        // For both usingSitrep true or false, render the select for NEO Analysis.
        else if (row.source === 'NEO Analysis') {
          if (!capacityAnalysisList.length) {
            return <div>No NEOs Available</div>;
          }
          return (
            <StylishNewSelect
              options={capacityAnalysisList.map((neo) => ({
                value: neo.id,
                label: generateInferredName(neo)
              }))}
              value={selectedNeoOption || null}
              onChange={handleNeoChange}
              isClearable={true}
              placeholder="Select a NEO"
            />
          );
        } else if (row.isCustom) {
          return (
            <div>
              <em>Custom Data Source</em>
            </div>
          );
        }
        return null;
      },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    hideSelectAll: false,
    selected: usingSitrep
      ? selections.map((selection) => selection.source)
      : selections.map((s) => s.source),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  };

  useEffect(() => {
    if (sitrepOptions.length > 0 && currentSitrepId && !usingSitrep) {
      const newSelections = selections.map((s) =>
        s.source === 'SITREP' ? { ...s, id: currentSitrepId } : s
      );
      if (!newSelections.find((s) => s.source === 'SITREP')) {
        const newSitrepSelection = { source: 'SITREP', id: currentSitrepId };
        setSelections([...newSelections, newSitrepSelection]);
      } else if (JSON.stringify(newSelections) !== JSON.stringify(selections)) {
        setSelections(newSelections);
      }
    }
  }, [currentSitrepId, sitrepOptions, selections, usingSitrep]);

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="border-b">Data Sources</Accordion.Header>
        <Accordion.Body>
          <div>
            {usingSitrep ? (
              <>
                <div>
                  <small>Use the sidebar to manage Custom Data Sources.</small>
                </div>
                <div>
                  <label>Select data for DisasterChat to consider.</label>
                </div>
                <div>
                  <small>
                    Multiple data sources can be more comprehensive, but takes
                    longer to process. Fewer or one data source can lead to more
                    focused results.
                  </small>
                </div>
              </>
            ) : (
              <>
                <div>
                  <label>Select data for DisasterChat to consider.</label>
                </div>
                <div>
                  <small>
                    Multiple data sources can be more comprehensive, but takes
                    longer to process. Fewer or one data source can lead to more
                    focused results.
                  </small>
                </div>
              </>
            )}

            <StylishNewTable
              key={`table-${dataSources.length}-${selections.length}`}
              keyField={'source'}
              rows={dataSources}
              columns={columns}
              selectRow={selectRow}
            />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default DataSources;
