import React, { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { FormBuilderDashboardAssignmentsResources } from './FormBuilderDashboardAssignmentsResources';
import { FormBuilderDashboardIncidentDetails } from './FormBuilderDashboardIncidentDetails';
import mapboxgl from '!mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useDispatch, useSelector } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { MeetingModal } from './MeetingModal';
import { fetchMeetings } from 'actions/MeetingsActions';

export const FormBuilderDashboardMain = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);
  const [selectedTab, setSelectedTab] = useState('Calendar');
  const [openMeetingModal, setOpenMeetingModal] = useState(false);
  const [allScheduleToIncident, setAllScheduleToIncident] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const reduxDispatch = useDispatch();

  const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });
  const user = useSelector((state) => state.prepare.user);

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxMeetings = useSelector((state) => state.app.meetings);

  const toggleData = [
    { label: 'Calendar', value: 'Calendar' },
    // { label: 'Map', value: 'Map' },
  ];

  const handleToggleButtonClick = (value) => {
    setSelectedTab(value);
  };

  const mapboxAccessToken = window.env.MAPBOX_ACCESS_TOKEN;

  mapboxgl.accessToken = mapboxAccessToken;

  useEffect(() => {
    if (reduxCurrentIncident?.id) {
      if (selectedTab) {
        reduxDispatch(fetchMeetings({ incident_id: reduxCurrentIncident?.id }));
      }
    }
  }, [selectedTab, reduxCurrentIncident]);

  useEffect(() => {
    if (selectedTab === 'Map') {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [lng, lat],
        zoom: zoom,
      });
    } else {
      map.current?.remove();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (reduxMeetings?.length) {
      setAllScheduleToIncident(
        reduxMeetings.map((meetingData) => ({
          title: meetingData.name,
          start: new Date(meetingData.meeting_date).toISOString(),
          ...meetingData,
        }))
      );
    }
  }, [reduxMeetings]);

  const onClickMeeting = (meeting) => {
    setOpenMeetingModal(true);
    setSelectedMeeting(meeting);
  };
  return (
    <div className="row h-md-100">
      <div className={'col-md-6'}>
        <FormBuilderDashboardIncidentDetails />
        <hr className="dashed" />
        <FormBuilderDashboardAssignmentsResources />
      </div>

      <div className="col-md-6 h-md-100">
        <div className="d-flex align-items-center mb-4">
          <StylishNewToggleButtonGroup
            data={toggleData}
            selected={selectedTab}
            onChange={handleToggleButtonClick}
            size={'sml'}
            className="d-md-inline-flex"
          />

          <StylishNewButton
            className="button--secondary button--reverse button--sml ms-auto"
            onClick={() => setOpenMeetingModal(true)}
          >
            Add Meeting
          </StylishNewButton>
        </div>
        {selectedTab === 'Calendar' ? (
          <>
            <div className="calendar calendar-dashboard">
              <FullCalendar
                plugins={[listPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'title',
                  center: '',
                  right: 'prev,next today',
                }}
                initialView="listMonth"
                events={allScheduleToIncident}
                eventClick={(e) => onClickMeeting(e.event)}
                eventTimeFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: user.timeFormat === 'hour12Mode',
                }}
                slotLabelFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  meridiem: 'short',
                  hour12: user.timeFormat === 'hour12Mode',
                }}
              />
            </div>
          </>
        ) : selectedTab === 'Map' ? (
          <>
            <div className="map" ref={mapContainer} />
          </>
        ) : null}
      </div>

      {openMeetingModal && (
        <MeetingModal
          show={openMeetingModal}
          onClose={() => {
            setSelectedMeeting(null);
            setOpenMeetingModal(false);
          }}
          updateMeetingData={selectedMeeting}
        />
      )}
    </div>
  );
};
