import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import {StylishNewCheckbox} from 'components/DesignSystems/New/StylishNewCheckbox';

import {
  updateIcsIncidentInfo,
} from 'slices/ics_incidentInfoSlice';

export default function ICSIncidentInfo({
  reduxCurrentIncident,
  ics_incident_info,
}) {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });



  const status = useSelector((state) => state.ics_incident_info.status); // Get status from
  const map_sketch = useSelector((state) => state.ics_incident_info.map_sketch);
  const safety_plan = useSelector((state) => state.ics_incident_info.safety_plan);

  const [situationSummary, setSituationSummary] = useState(ics_incident_info.situation_summary);
  const [operationalPeriodCommandEmphasis, setOperationalPeriodCommandEmphasis] = useState(ics_incident_info.operational_period_command_emphasis);
  const [specialMedicalEmergencyProcedures, setSpecialMedicalEmergencyProcedures] = useState(ics_incident_info.special_medical_emergency_procedures);
  const [medicalAirAssetsYn, setMedicalAirAssetsYn] = useState(ics_incident_info.medical_air_assets_yn||false);
  const [mapSketch, setMapSketch] = useState(ics_incident_info.map_sketch);
  const [safetyPlan, setSafetyPlan] = useState(ics_incident_info.safety_plan);

  const [situationSummaryChanged, setSituationSummaryChanged] = useState(false);
  const [operationalPeriodCommandEmphasisChanged, setOperationalPeriodCommandEmphasisChanged] = useState(false);
  const [specialMedicalEmergencyProceduresChanged, setSpecialMedicalEmergencyProceduresChanged] = useState(false);
  const [medicalAirAssetsYnChanged, setMedicalAirAssetsYnChanged] = useState(false);
  const [mapSketchChanged, setMapSketchChanged] = useState(false);
  const [safetyPlanChanged, setSafetyPlanChanged] = useState(false);

  useEffect(() => {
    setMapSketch(map_sketch);
    setSafetyPlan(safety_plan);
  }, [map_sketch, safety_plan]);

  useEffect(()=>{
    setSituationSummary(ics_incident_info?.situation_summary)
    setOperationalPeriodCommandEmphasis(ics_incident_info?.operational_period_command_emphasis)
    setSpecialMedicalEmergencyProcedures(ics_incident_info?.special_medical_emergency_procedures)
    setMedicalAirAssetsYn(ics_incident_info?.medical_air_assets_yn)
    setMapSketch(ics_incident_info?.map_sketch)
    setSafetyPlan(ics_incident_info?.safety_plan)
  },[ics_incident_info])

  function saveClicked() {
    const newIncidentInfo = {
      ...ics_incident_info,
      situation_summary: situationSummary,
      operational_period_command_emphasis: operationalPeriodCommandEmphasis,
      special_medical_emergency_procedures: specialMedicalEmergencyProcedures,
      medical_air_assets_yn: medicalAirAssetsYn,
      map_sketch: (!!mapSketchChanged && mapSketch) || 'KEEP', // Let back end know not to change this
      safety_plan: (!!safetyPlanChanged && safetyPlan) || 'KEEP', // Let back end know not to change this
    };
    reduxDispatch(
      updateIcsIncidentInfo(reduxCurrentIncident.id, newIncidentInfo)
    );
  }

  function handleFileChange(event) {
    if (event.target.name === 'map_sketch') {
      setMapSketch(event.target.files[0]);
      setMapSketchChanged(true);
    } else if (event.target.name === 'safety_plan') {
      setSafetyPlan(event.target.files[0]);
      setSafetyPlanChanged(true);
    }
  }

  function downloadMapSketch() {
    if (mapSketch) {
      const url = URL.createObjectURL(
        new Blob([mapSketch], { type: 'image/png' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.download = 'map_sketch.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  const DownloadPdfButton = ({ base64String }) => {
    const handleDownload = () => {
      // Create a blob from the base64 string
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a link element, set the download attribute with the desired file name, and click it
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Safety_Plan.pdf';
      link.click();

      // Clean up the URL object after the download
      window.URL.revokeObjectURL(link.href);
    };

    return (
      <button onClick={handleDownload}>
        Download Safety Plan
      </button>
    );
  };

  return (
    <div className="ICSIncidentInfo">
      <h4>Incident Info</h4>

      <div className="ICSIncidentInfo-SituationSummary">
        <h5>Situation Summary</h5>
        {status === 'loading' && (
          <div>
            Loading Incident Info...<i className="fa fa-spinner fa-pulse"></i>
          </div>
        )}
        <StylishNewTextArea
          onChange={(e) => setSituationSummary(e.target.value)}
          value={situationSummary}
        />
      </div>

      <div className="ICSIncidentInfo-OperationalPeriodCommandEmphasis">
        <h5>Operational Period Command Emphasis</h5>
        <StylishNewTextArea
          onChange={(e) => setOperationalPeriodCommandEmphasis(e.target.value)}
          value={operationalPeriodCommandEmphasis}
        />
      </div>

      <div className="ICSIncidentInfo-SpecialMedicalEmergencyProcedures">
        <h5>Special Medical Emergency Procedures</h5>
        <StylishNewTextArea
          onChange={(e) => setSpecialMedicalEmergencyProcedures(e.target.value)}
          value={specialMedicalEmergencyProcedures}
        />
      </div>

      <div className="ICSIncidentInfo-MedicalAirAssets">
        <h5>Medical Air Assets</h5>
        <StylishNewCheckbox
          checked={medicalAirAssetsYn}
          onChange={(e) => setMedicalAirAssetsYn(e.target.checked)}
        />
      </div>

      <div className="ICSIncidentInfo-MapSketch">
        <h5>Map Sketch</h5>
        {mapSketch && (
          <div>
            <img
              src={`data:image/png;base64,${mapSketch.toString('base64')}`}
              alt="Map Sketch"
              width={'200px'}
              height={'200px'}
            />
          </div>
        )}
        <input type="file" name="map_sketch" onChange={handleFileChange} />
      </div>

      <div className="ICSIncidentInfo-SafetyPlan">
        <h5>Safety Plan</h5>
        {safetyPlan && (
          <div>
            <DownloadPdfButton base64String={safetyPlan} />
          </div>
        )|| (
          <div>Upload a Safety Plan</div>
        )}
        <input type="file" name="safety_plan" onChange={handleFileChange} />
      </div>

      <div>
        <StylishNewButton onClick={() => saveClicked()}>
          Save Incident Info Updates
        </StylishNewButton>
      </div>
    </div>
  );
}
