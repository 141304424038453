import React, { useEffect, useState } from 'react';
import generateUUID from 'utils/sharedUtils/generateUUID';
import NotificationRecipients from './NotificationRecipients';
import validateEmailAddress from 'utils/validateEmailAddress';
import validatePagerAddress from 'utils/validatePagerAddress';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { Modal } from 'react-bootstrap';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import ManageTeamLocation from './ManageTeamLocation';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { changeGroupAdminNameToOrgAdmin } from 'components/Roles/RolesUtils/changeGroupAdminNameToOrgAdmin';

export default function NewDistributionListDialog({
  show,
  roster,
  onClose,
  recipientTypes,
  distributionLists,
  teamLocation,
}) {
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [emailRecipients, setEmailRecipients] = useState([]);
  const [SMSRecipients, setSMSRecipients] = useState([]);
  const [TTSHomeRecipients, setTTSHomeRecipients] = useState([]);
  const [TTSCellRecipients, setTTSCellRecipients] = useState([]);
  const [pagerRecipients, setPagerRecipients] = useState([]);
  const [selectedTeamLocation, setSelectedTeamLocation] = useState(null);
  const [templateTitle, setTemplateTitle] = useState();

  const column = [
    {
      dataField: 'email_address',
      text: 'Email Address',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Email Address' },
    },
    {
      dataField: 'user_name',
      text: 'Member Name',
      sort: true,
      attrs: { title: 'Member Name' },
    },
    {
      dataField: 'role_string',
      formatter: (cell) => changeGroupAdminNameToOrgAdmin(cell),
      text: 'Organization Role',
      sort: true,
      attrs: { title: 'Organization Role' },
    },
  ];

  const getUniqueValidations = (recipients, check) => {
    const recipientValidations = recipients
      .map((recipient) => check(recipient.content))
      .filter((invalid) => invalid.length)
      .reduce((acc, item) => {
        item.forEach((inner) => acc.push(inner));
        return acc;
      }, []);

    return [...new Set(recipientValidations)];
  };

  useEffect(() => {
    if (teamLocation) {
      setSelectedTeamLocation(teamLocation);
    }
  }, [teamLocation]);

  const onAddMembersClick = () => {
    const SMSValidations = getUniqueValidations(
      SMSRecipients,
      getSMSValidationsForItem
    );
    const emailValidations = getUniqueValidations(
      emailRecipients,
      getEmailValidationsForItem
    );
    const TTSCellValidations = getUniqueValidations(
      TTSCellRecipients,
      getTTSValidationsForItem
    );
    const TTSHomeValidations = getUniqueValidations(
      TTSHomeRecipients,
      getTTSValidationsForItem
    );
    const pagerValidations = getUniqueValidations(
      pagerRecipients,
      getPagerValidationsForItem
    );

    const aggregateValidations = [
      ...SMSValidations,
      ...emailValidations,
      ...TTSCellValidations,
      ...TTSHomeValidations,
      ...pagerValidations,
    ];

    if (aggregateValidations.length > 0) {
      toast.error(
        <div>
          {aggregateValidations.map((text) => (
            <>
              - {text}
              <br />
            </>
          ))}
        </div>,
        toastConfig
      );
    } else {
      const newList = {
        id: generateUUID(),
        name: templateTitle,
        members: selectedMembers.map((member) => member.user_guid),
        nonMembers: [
          ...emailRecipients,
          ...SMSRecipients,
          ...TTSHomeRecipients,
          ...TTSCellRecipients,
          ...pagerRecipients,
        ],
        location_entity_id: selectedTeamLocation?.location_entity_data?.value,
        location_sub_entity_id:
          selectedTeamLocation?.location_sub_entity_data?.value,
        location_enterprise_id:
          selectedTeamLocation?.location_enterprise_data?.value,
        location_asset_id: selectedTeamLocation?.location_asset_data?.value,
        location_id: selectedTeamLocation?.location_id,
      };
      const currentList = distributionLists.findIndex(
        (ele) => ele.name === newList.name
      );
      if (currentList !== -1) {
        const errorMessage =
          `Duplicate Relevant Team Name.\n\n` +
          'Failed Create New Relevant Team';
        console.warn(errorMessage);
        toast.error(errorMessage, toastConfig);
        return;
      } else {
        onClose(newList);
      }
    }
  };

  function getEmailValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [...validation_messages, `Email cannot be blank`];
    } else if (!validateEmailAddress(item)) {
      validation_messages = [...validation_messages, `Invalid email address`];
    }
    return validation_messages;
  }

  function getSMSValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `SMS number cannot be blank`,
      ];
    } else if (
      !item.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)
    ) {
      validation_messages = [...validation_messages, `Invalid SMS number`];
    }
    return validation_messages;
  }

  function getTTSValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `Text-To-Speech number cannot be blank`,
      ];
    } else if (
      !item.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)
    ) {
      validation_messages = [
        ...validation_messages,
        `Invalid Text-To-Speech number`,
      ];
    }
    return validation_messages;
  }

  function getPagerValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `Pager address cannot be blank`,
      ];
    } else if (!validatePagerAddress(item)) {
      validation_messages = [...validation_messages, `Invalid pager address`];
    }
    return validation_messages;
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: selectedMembers.map((e) => e.user_guid),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      let selection = selectedMembers.map((i) => i.user_guid);
      if (selection.includes(row.user_guid)) {
        selection = selection.filter((i) => i !== row.user_guid);
      } else {
        selection.push(row.user_guid);
      }
      setSelectedMembers(
        roster.filter((item) => selection.includes(item.user_guid))
      );
    },
    onSelectAll: (isSelect, rows, e) => {
      let selection = selectedMembers.map((i) => i.user_guid);
      rows.forEach((row) => {
        if (selection.includes(row.user_guid)) {
          selection = selection.filter((i) => i !== row.user_guid);
        } else {
          selection.push(row.user_guid);
        }
      });
      setSelectedMembers(
        roster.filter((item) => selection.includes(item.user_guid))
      );
    },
  };
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <form>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>New Relevant Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select Organization Members</h5>
          <StylishNewTable
            keyField={'user_guid'}
            rows={roster}
            columns={column}
            selectRow={selectRow}
            hasPagination={true}
            paginationPerPageItem="10"
          />

          {!!recipientTypes.length && (
            <>
              <hr className="dashed" />
              <h4 className="mt-4">Additional Recipients</h4>
              <div className="bg-gray-900 rounded p-3 p-md-4">
                {!!recipientTypes.find((t) => t === 'Email') && (
                  <>
                    <h5>Email Recipients</h5>
                    <NotificationRecipients
                      recipients={emailRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setEmailRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getEmailValidationsForItem}
                      type="emailRecipients"
                    />
                    {recipientTypes.findIndex((i) => i === 'Email') !==
                    recipientTypes.length - 1 ? (
                      <hr className="dashed my-4" />
                    ) : null}
                  </>
                )}

                {!!recipientTypes.find((t) => t === 'SMS') && (
                  <>
                    <h5>SMS Recipients</h5>
                    <NotificationRecipients
                      recipients={SMSRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setSMSRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getSMSValidationsForItem}
                      type="SMSRecipients"
                    />
                    {recipientTypes.findIndex((i) => i === 'SMS') !==
                    recipientTypes.length - 1 ? (
                      <hr className="dashed my-4" />
                    ) : null}
                  </>
                )}

                {!!recipientTypes.find((t) => t === 'Pager') && (
                  <>
                    <h5> Pager Recipients</h5>

                    <NotificationRecipients
                      recipients={pagerRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setPagerRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getPagerValidationsForItem}
                      type="pagerRecipients"
                    />
                    {recipientTypes.findIndex((i) => i === 'Pager') !==
                    recipientTypes.length - 1 ? (
                      <hr className="dashed my-4" />
                    ) : null}
                  </>
                )}

                {!!recipientTypes.find(
                  (t) => t === 'Text-To-Speech (cell)'
                ) && (
                  <>
                    <h5>Text-To-Speech (cell) Recipients</h5>
                    <NotificationRecipients
                      recipients={TTSCellRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setTTSCellRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getTTSValidationsForItem}
                      type="TTSCellRecipients"
                    />
                    {recipientTypes.findIndex(
                      (i) => i === 'Text-To-Speech (cell)'
                    ) !==
                    recipientTypes.length - 1 ? (
                      <hr className="dashed my-4" />
                    ) : null}
                  </>
                )}

                {!!recipientTypes.find(
                  (t) => t === 'Text-To-Speech (home)'
                ) && (
                  <>
                    <h5>Text-To-Speech (home) Recipients</h5>

                    <NotificationRecipients
                      recipients={TTSHomeRecipients}
                      onRecipientsChange={(newRecipients) =>
                        setTTSHomeRecipients(newRecipients)
                      }
                      getValidationMessagesForItem={getTTSValidationsForItem}
                      type="TTSHomeRecipients"
                    />
                    {recipientTypes.findIndex(
                      (i) => i === 'Text-To-Speech (home)'
                    ) !==
                    recipientTypes.length - 1 ? (
                      <hr className="dashed my-4" />
                    ) : null}
                  </>
                )}
              </div>
              <hr className="dashed" />
            </>
          )}

          <label className="form-label">Team Title</label>
          <StylishNewInput
            className="NewDistributionListDialog-input mb-4"
            value={templateTitle}
            onChange={(e) => setTemplateTitle(e.target.value)}
          />
          <hr className="dashed" />
          <ManageTeamLocation
            selectedTeamLocation={selectedTeamLocation}
            onChangeTeamLocation={setSelectedTeamLocation}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group flex-column align-items-md-end">
            <StylishNewButton
              type="button"
              onClick={onAddMembersClick}
              className="button--primary"
              disabled={
                !(
                  templateTitle &&
                  (selectedMembers.length ||
                    emailRecipients.length ||
                    SMSRecipients.length ||
                    TTSHomeRecipients.length ||
                    TTSCellRecipients.length ||
                    pagerRecipients.length)
                )
              }
            >
              Save Team
            </StylishNewButton>
            {!(
              templateTitle &&
              (selectedMembers.length ||
                emailRecipients.length ||
                SMSRecipients.length ||
                TTSHomeRecipients.length ||
                TTSCellRecipients.length ||
                pagerRecipients.length)
            ) && (
              <span className="form-text form-error">
                Form Validation Error
              </span>
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
