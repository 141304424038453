import React from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import { MdEdit, MdLocationOn } from 'react-icons/md';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import EntityList from 'components/IAP/EntityList';
import GeolocationDetails from './GeolocationDetails';

const CollapsibleGeolocationsList = ({
  onMap = false,
  AORSelected,
  setAORSelected,
  flyTo,
  onEdit,
  onDelete,
  onAdd,
}) => {
  const [expanded, toggleExpanded] = useToggle(onMap);
  const reduxCoreComponents = useSelector((state) => state.app.coreComponents);
  // Add archived=false property to each AOR since EntityList expects it
  const aors = (
    reduxCoreComponents?.CCs?.['Areas of Responsibility'] || []
  ).map((aor) => ({
    ...aor,
    archived: false, // Default all AORs to not archived
  }));

  if (AORSelected) {
    return (
      <GeolocationDetails
        aor={AORSelected}
        onBack={() => setAORSelected(null)}
      />
    );
  }

  return (
    <EntityList
      entities={aors}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      header={
        <div className="d-flex align-items-center justify-content-between flex-grow-1">
          <span>AORs</span>
          <StylishNewButton
            className={classNames('button', {
              'button--primary': expanded,
              'button--secondary': !expanded,
            })}
            onClick={onAdd}
          >
            <SharedIcon iconName="add" classes="me-2" />
            Create
          </StylishNewButton>
        </div>
      }
      renderEntity={(aor) => (
        <Card
          key={aor.id}
          className="bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between cursor-pointer"
          style={{
            padding: '0.625rem 1rem 0.625rem 1rem',
          }}
          onClick={() => setAORSelected(aor)}
        >
          <span className="text-truncate">{aor.name}</span>
          <span style={{ float: 'right' }}>
            {!!onMap && (
              <StylishNewButton
                className="button button--link"
                onClick={(e) => {
                  e.stopPropagation();
                  flyTo(aor);
                }}
              >
                <MdLocationOn />
              </StylishNewButton>
            )}
            <StylishNewButton
              className="button button--link"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(aor);
              }}
            >
              <MdEdit />
            </StylishNewButton>
            <StylishNewButton
              className="button button--link"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(aor);
              }}
            >
              <SharedIcon iconName="delete" />
            </StylishNewButton>
          </span>
        </Card>
      )}
    />
  );
};

export default CollapsibleGeolocationsList;
