import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import TaskManagementGraph from './TaskManagementGraph';
import TaskManagementList from './TaskManagementList';
import {
  TASK_COLOR_TAB_DATA,
  TASK_STATUS_LIST,
} from './TaskManagementConstant';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import TaskManagementCreateEditModal from './TaskManagementCreateEditModal';
import { fetchTaskListDataAction } from 'actions/TaskManagementActions';
import ReactPaginate from 'react-paginate';
import TaskManagementFilter from './TaskManagementFilter';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import PageTitle from 'components/headers/PageTitle';

export default function TaskManagementDashboard() {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const [selectedFilters, setSelectedFilters] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedColorTab, setSelectedColorTab] = useState(
    TASK_COLOR_TAB_DATA[0].value
  );

  const reduxTaskList = useSelector((state) => {
    return state.app.taskList;
  });

  const totalTaskList = useSelector((state) => {
    return state.app.totalTaskList;
  });
  const reduxSaveTaskSuccess = useSelector((state) => {
    return state.app.saveTaskSuccess;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const filterTaskList = useMemo(() => {
    let filterTaskList = [];
    if (reduxTaskList?.length > 0) {
      const sortedTaskList = [...reduxTaskList].sort((a, b) => {
        // Assuming 'created_at' is a date string in a format that Moment.js can parse
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateB - dateA;
      });

      filterTaskList = sortedTaskList.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          status: TASK_STATUS_LIST.find((elem) => elem.value === item.status)
            ?.label,
        };
      });
    }

    return filterTaskList;
  }, [reduxTaskList]);

  const handleSearchTerm = (term) => {
    setSearchTerm(term);
  };

  const handleCreateNewTask = () => {
    setOpenModal(true);
    setSelectedTask(null);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedTask(null);
  };

  const handleClickTask = (task) => {
    setOpenModal(true);
    setSelectedTask(task);
  };

  const handleChangeFilters = (val) => {
    setSelectedFilters(val);
  };

  useEffect(() => {
    if (reduxCurrentlySelectedGroup.group_guid) {
      const filter = {
        assessment: selectedFilters?.assessment?.value,
        restoration: selectedFilters?.restoration?.value,
        stability: selectedFilters?.stability?.value,
        priority: selectedFilters?.priority?.value,
        team: selectedFilters?.team?.value,
      };
      reduxDispatch(
        fetchTaskListDataAction(
          reduxCurrentlySelectedGroup.group_guid,
          filter,
          currentPage,
          searchTerm
        )
      );
    }
  }, [
    searchTerm,
    currentPage,
    selectedFilters,
    reduxSaveTaskSuccess,
    reduxCurrentlySelectedGroup,
  ]);

  return (
    <>
      <div className="site-main">
        <div className="container-fluid">
          <PageTitle
            title="Critical Task Management"
            parents={['Task Management']}
          />

          <div className="row mb-4">
            <div className="col-12 col-md-6">
              <StylishNewButton
                type="button"
                className="button--primary w-100 w-md-auto"
                onClick={() => {
                  handleCreateNewTask();
                }}
              >
                Create Critical Task
              </StylishNewButton>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-md-flex mt-2 mt-lg-0">
                <StylishNewToggleButtonGroup
                  data={TASK_COLOR_TAB_DATA}
                  selected={selectedColorTab}
                  onChange={(val) => {
                    setSelectedColorTab(val);
                  }}
                  className="ms-auto"
                />
              </div>
            </div>
          </div>

          <div className="form-block mb-4">
            <TaskManagementFilter
              onChange={(val) => handleChangeFilters(val)}
              filters={selectedFilters}
            />
          </div>

          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <TaskManagementGraph
                tasks={filterTaskList}
                title={'Critical Task Status'}
                selectedColorTab={selectedColorTab}
              />
            </div>

            <div className="col-xl-6 col-lg-6 mb-4 mb-xl-0">
              <div className="mb-4 mb-xl-0">
                <TaskManagementList
                  onSearchTerm={(val) => handleSearchTerm(val)}
                  tasks={filterTaskList}
                  selectedColorTab={selectedColorTab}
                  onSelectTask={(task) => handleClickTask(task)}
                />
              </div>
              <div className="d-flex justify-content-center justify-content-md-end">
                <ReactPaginate
                  className="pagination"
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={({ selected }) => setCurrentPage(selected + 1)}
                  pageRangeDisplayed={3}
                  pageCount={Math.ceil(totalTaskList / 50)}
                  previousLabel="Prev"
                  forcePage={currentPage - 1}
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!!openModal && (
        <TaskManagementCreateEditModal
          selectedTask={selectedTask}
          onClose={() => handleModalClose()}
        />
      )}
    </>
  );
}
