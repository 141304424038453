import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import moment from 'moment';
import { createRoot } from 'react-dom/client';

import mapboxgl from '!mapbox-gl';
import config from 'constants/apiConfig';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconArrowLeft from '../../assets/images/icon__arrow--left.svg';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { MdLocationOn } from 'react-icons/md';
import {
  getAbbreviationName,
  getProcessStability,
  getTaskStatusColor,
} from './TaskManagementUtil';
import {
  RTO_TIME_FRAME_OPTIONS,
  TASK_COLOR_TAB_DATA,
  TASK_STATUS_LIST,
} from './TaskManagementConstant';
import TaskManagementMapDetail from './TaskManagementMapDetail';
import TaskManagementMapEffect from './TaskManagementMapEffect';
import TaskManagementMapLayerPopup from './TaskManagementMapLayerPopup';
import TaskManagementGraph from './TaskManagementGraph';
import ReactPaginate from 'react-paginate';
import { fetchTaskListDataAction } from 'actions/TaskManagementActions';
import IconSort from 'assets/images/icon__sort--blue.svg';
import TaskManagementFilter from './TaskManagementFilter';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';

export default function TaskManagementMap() {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const rostersForGroup = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });
  const reduxTaskList = useSelector((state) => {
    return state.app.taskList;
  });
  const totalTaskList = useSelector((state) => {
    return state.app.totalTaskList;
  });
  const popupPlaceholder = document.createElement('div');
  const popupRoot = createRoot(popupPlaceholder);

  const [lng, setLng] = useState(-93.33); //-70.9);
  const [lat, setLat] = useState(45);
  const [zoom, setZoom] = useState(3);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [layerClicked, setLayerClicked] = useState();
  const [showSidebarSecondary, setShowSidebarSecondary] = useState(false);
  const [sidebarSecondaryActiveItem, setSidebarSecondaryActiveItem] = useState(
    null
  );
  const [selectedTask, setSelectedTask] = useState();
  const [taskSearchInput, setTaskSearchInput] = useState('');
  const [markers, setMarkers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedColorTab, setSelectedColorTab] = useState(
    TASK_COLOR_TAB_DATA[0].value
  );

  const mapContainer = useRef(null);
  const map = useRef(null);
  const basemap = 'mapbox://styles/mapbox/satellite-streets-v11';

  const flyToGeolocation = (item) => {
    if (
      !!item.geolocation &&
      item.geolocation.geojson &&
      item.geolocation.geojson.data &&
      item.geolocation.geojson.data.features &&
      item.geolocation.geojson.data.features.length
    ) {
      var box = turf.bbox(item.geolocation.geojson.data.features[0].geometry);
      map.current.fitBounds(box, { padding: 50, maxZoom: 15 });
    }
  };

  const getAssigneeName = (task) => {
    let res = '';
    if (task?.process_owner?.length > 0 && rostersForGroup?.length > 0) {
      const person = task?.process_owner[0];
      if (person?.user_name) {
        res = getAbbreviationName(person?.user_name);
      }
    }

    return res;
  };

  const handleClickTask = (task) => {
    setShowSidebarSecondary(true);
    setSidebarSecondaryActiveItem('Selected Task');
    setSelectedTask(task);
  };

  const handleClickFilter = () => {
    setShowSidebarSecondary(true);
    setSidebarSecondaryActiveItem('Filter Task');
  };

  const handleChangeFilters = (val) => {
    setSelectedFilters(val);
  };

  const tasks = useMemo(() => {
    let filterTaskList = [];
    if (reduxTaskList?.length > 0) {
      const sortedTaskList = [...reduxTaskList].sort((a, b) => {
        // Assuming 'created_at' is a date string in a format that Moment.js can parse
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateB - dateA;
      });

      filterTaskList = sortedTaskList.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          status: TASK_STATUS_LIST.find((elem) => elem.value === item.status)
            ?.label,
        };
      });
    }

    return filterTaskList;
  }, [taskSearchInput, reduxTaskList]);

  // Initialize Map
  useEffect(() => {
    mapboxgl.accessToken = window.env.MAPBOX_ACCESS_TOKEN;

    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: basemap,
      center: [lng, lat],
      zoom: zoom,
      projection: 'mercator',
      preserveDrawingBuffer: false,
      transformRequest: (url) => {
        if (url.startsWith(config.apiPrefix + '/tiles/')) {
          return {
            url: url,
            headers: {
              Authorization:
                'Bearer ' + process.env.REACT_APP_MAP_EVENT_TILES_TOKEN,
            },
          };
        }
      },
    });
    map.current.getCanvas().id = 'mapcanvas';
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
    map.current.addControl(geocoder);

    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');
    //map.current.addControl(new mapboxgl.ScaleControl());

    map.current.addControl(
      new mapboxgl.AttributionControl({
        compact: true,
      })
    );

    map.current.on('style.load', () => {
      setMapHasLoaded(true);
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const noop = () => {};
    map.current.on('idle', noop);
  });

  // map effect
  useEffect(() => {
    if (selectedTask) {
      setShowSidebarSecondary(true);
      setSidebarSecondaryActiveItem('Selected Task');
    }
  }, selectedTask);

  useEffect(() => {
    if (map?.current && mapHasLoaded && !!reduxCurrentlySelectedGroup) {
      TaskManagementMapEffect(
        map,
        mapboxgl,
        config.apiPrefix,
        tasks,
        true,
        reduxCurrentlySelectedGroup,
        setLayerClicked,
        markers,
        setMarkers,
        true,
        selectedColorTab
      );
    }
  }, [
    map,
    mapHasLoaded,
    tasks,
    reduxCurrentlySelectedGroup,
    mapboxgl,
    taskSearchInput,
    selectedColorTab,
  ]);

  useEffect(() => {
    if (!!layerClicked && !!layerClicked.layer.metadata) {
      const popup = new mapboxgl.Popup({ closeOnClick: false });
      const popups = document.getElementsByClassName('mapboxgl-popup');
      if (popups.length) {
        popups[0].remove();
      }

      if (layerClicked.layer.metadata.taskLayer) {
        popupRoot.render(
          <TaskManagementMapLayerPopup
            features={layerClicked.features}
            handleReportSelected={(task) => {
              document.webkitExitFullscreen();
              setSelectedTask(task);
            }}
          />
        );
      }

      popup
        .setLngLat(layerClicked.e.lngLat)
        .setDOMContent(popupPlaceholder)
        .addTo(map.current);
      setLayerClicked();
    }
  }, [layerClicked]);

  useEffect(() => {
    map.current.resize();
  }, []);

  useEffect(() => {
    if (reduxCurrentlySelectedGroup.group_guid) {
      const filter = {
        assessment: selectedFilters?.assessment?.value,
        restoration: selectedFilters?.restoration?.value,
        stability: selectedFilters?.stability?.value,
        priority: selectedFilters?.priority?.value,
        team: selectedFilters?.team?.value,
      };
      reduxDispatch(
        fetchTaskListDataAction(
          reduxCurrentlySelectedGroup.group_guid,
          filter,
          currentPage,
          taskSearchInput
        )
      );
    }
  }, [
    taskSearchInput,
    currentPage,
    selectedFilters,
    reduxCurrentlySelectedGroup,
  ]);

  const innerContent = (
    <div className="site-main__map">
      <div className="map-sidebar">
        <div className="sidebar-content-wide">
          <div className="sidebar-title">
            {showSidebarSecondary && (
              <StylishNewButton
                customButton
                className={'button--icon'}
                onClick={() => {
                  setSelectedTask(null);
                  setShowSidebarSecondary(false);
                  setSidebarSecondaryActiveItem(null);
                }}
              >
                <img src={IconArrowLeft} alt="" />
              </StylishNewButton>
            )}
            <h4 className="m-0">Task Map</h4>
          </div>
          <div className="sidebar-inner">
            {!showSidebarSecondary ? (
              <>
                <div className="d-flex">
                  <StylishNewToggleButtonGroup
                    data={TASK_COLOR_TAB_DATA}
                    selected={selectedColorTab}
                    onChange={(val) => {
                      setSelectedColorTab(val);
                    }}
                    className="w-100"
                  />
                </div>
                <hr className="thin w-10 mt-3 mb-2" />

                <div className="d-flex">
                  <StylishNewInput
                    placeholder="Search Task..."
                    value={taskSearchInput}
                    onChange={(e) => setTaskSearchInput(e.target.value)}
                    className={'mb-3'}
                  />
                  <StylishNewButton
                    type="button"
                    className={`button--primary filter-btn ms-2`}
                    onClick={() => handleClickFilter()}
                  >
                    <img src={IconSort} alt="" />
                  </StylishNewButton>
                </div>
                <TaskManagementGraph
                  tasks={tasks}
                  height={
                    selectedColorTab === TASK_COLOR_TAB_DATA[0].value
                      ? 200
                      : 160
                  }
                  selectedColorTab={selectedColorTab}
                  className="p-0"
                />
                <hr className="dashed w-10 mt-3 mb-2" />

                <div className="scroll scroll-tasks">
                  {!!tasks?.length > 0 ? (
                    <>
                      {tasks.map((task, index) => {
                        let stability = null;
                        let bgColor = getTaskStatusColor(task.status);
                        let statusLabel = task.status;
                        if (selectedColorTab === TASK_COLOR_TAB_DATA[1].value) {
                          const rtoTimeFrameObj = RTO_TIME_FRAME_OPTIONS.find(
                            (frame) => frame.value === task?.rto_time_frame
                          );
                          stability = getProcessStability(
                            task?.status,
                            task?.deadline_date,
                            task?.restoration_status,
                            parseInt(task?.rto),
                            rtoTimeFrameObj,
                            task?.is_critical
                          );
                          bgColor = stability?.color;
                          statusLabel = stability?.label;
                        }

                        return (
                          <div
                            className="d-flex-column task-map-card cursor-pointer"
                            key={'tasklist-card' + index}
                            onClick={() => handleClickTask(task)}
                          >
                            <h5 className="mb-1">{task.title}</h5>
                            <div className="d-flex justify-content-between">
                              <div
                                className="task-status-badge"
                                style={{ background: bgColor }}
                              >
                                {statusLabel}
                              </div>
                              <div className="">
                                {moment(task.deadline_date).format(
                                  'MMM D YYYY'
                                )}
                              </div>
                              <span className="task-person-name">
                                {getAssigneeName(task)}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <p className="m-0">No Task Found</p>
                  )}
                </div>
                <div className="d-flex justify-content-center justify-content-md-end mt-3">
                  <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={({ selected }) =>
                      setCurrentPage(selected + 1)
                    }
                    pageRangeDisplayed={3}
                    pageCount={Math.ceil(totalTaskList / 50)}
                    previousLabel="Prev"
                    forcePage={currentPage - 1}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            ) : (
              <>
                {/* Show Filter Task options */}
                {sidebarSecondaryActiveItem === 'Filter Task' ? (
                  <>
                    <div className="task-detail-container">
                      <h4 className="mb-1">Filter Task</h4>

                      <TaskManagementFilter
                        isVertical={true}
                        onChange={(val) => handleChangeFilters(val)}
                        filters={selectedFilters}
                      />
                    </div>
                  </>
                ) : null}

                {/* Show Selected Task */}
                {sidebarSecondaryActiveItem === 'Selected Task' ? (
                  <div className="tab-wrapper map-tab-container">
                    <div
                      className="anchor anchor--white cursor-pointer d-flex align-items-center bg-gray-900 p-3 rounded mb-3"
                      onClick={() => flyToGeolocation(selectedTask)}
                    >
                      <MdLocationOn className="img-h-20 me-3" />
                      {selectedTask?.geolocation ? (
                        <>
                          {selectedTask?.geolocation?.name ||
                            selectedTask?.geolocation?.address ||
                            JSON.stringify(
                              selectedTask?.geolocation?.centroid?.geometry
                                ?.coordinates
                            )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <TaskManagementMapDetail task={selectedTask} />
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="map-wrapper">
        <div ref={mapContainer} className="map-container"></div>
      </div>
    </div>
  );

  return innerContent;
}
