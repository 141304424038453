import React from 'react';

const WeatherAnalytics: React.FC = () => {
  return (
    <div>
      <h1>Weather Analytics</h1>
    </div>
  );
};

export default WeatherAnalytics;
