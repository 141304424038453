import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import {
  fetchAllPermissions,
  editRole,
  fetchAllPermissionsInRole,
  fetchAllRoleAssignmentsInGroup,
} from 'actions/roleActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';

export default function EditRoleDialog({
  show,
  group_guid,
  onClose,
  existingRole,
}) {
  const reduxDispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const [isLoaded, setIsLoaded] = useState(true);

  const { user_guid } = useSelector((state) => {
    return state.app.user;
  });

  useEffect(() => {
    if (typeof user_guid !== 'undefined') {
      reduxDispatch(fetchAllPermissions());
      reduxDispatch(fetchAllPermissionsInRole(existingRole));
      reduxDispatch(fetchAllRoleAssignmentsInGroup(existingRole.group_guid));
    }
  }, [user_guid]);

  const isEditRoleLoaded = useSelector((state) => {
    return state.app.isEditRoleLoaded;
  });

  const isFetchAllRolesInGroupLoaded = useSelector((state) => {
    return state.app.isFetchAllRolesInGroupLoaded;
  });

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  useEffect(() => {
    if (!isLoaded && isEditRoleLoaded && isFetchAllRolesInGroupLoaded) {
      onClose();
    }
  }, [isEditRoleLoaded, isFetchAllRolesInGroupLoaded, isLoaded, onClose]);

  const reduxAllPermissions = useSelector((state) => {
    const permissions =
      Array.isArray(state.app.allPermissions) &&
      state.app.allPermissions
        .filter(({ action }) => {
          switch (action) {
            case 'Open or Close Group':
            case 'Transfer Pod Onwership':
            case 'Accept Pod Ownership Transfer Request':
            case 'Create Report':
            case 'Edit Report Name':
            case 'Edit Report Data':
            case 'Delete Report':
            case 'Create Pod':
            case 'Edit Pod':
            case 'Delete Pod':
            case 'Add user to Pod Need-To-Know List':
            case 'Remove user from Pod Need-To-Know List':
            case 'Read Pod Data':
            case 'Write Pod Data':
            case 'Delete Pod Data':
            case 'Create Layout':
            case 'Delete Layout':
            case 'Edit Layout':
            case 'Edit Layout Data':
              return false;
          }

          if (
            !reduxFeatureFlags.includes('MISSION_MANAGER') &&
            (action === 'Manage Activities' ||
              action === 'Accept Task Assignments')
          ) {
            return false;
          }

          return true;
        })
        .map((permission) => {
          switch (permission.action) {
            case 'Read Pod Data':
              return {
                ...permission,
                action: 'Read From Private Database',
                description:
                  'Allows the user to read data and data products within the private database.',
              };
            case 'Write Pod Data':
              return {
                ...permission,
                action: 'Write To Private Database',
                description:
                  'Allows the user to add or modify data or data products in the private database.',
              };
            case 'Delete Pod Data':
              return {
                ...permission,
                action: 'Delete From Private Database',
                description:
                  'Allows the user to delete data or data products from the private database.',
              };
            case 'Send Alerts':
              return {
                ...permission,
                action: 'Send Notifications',
                description:
                  'Allows the user to send notifications such as via SMS, email, Teams, and so on.',
              };
            case 'Create Dashboard':
              return {
                ...permission,
                action: 'Create Layout',
                description: 'Allows the user to create a Layout.',
              };
            case 'Edit Dashboard Name':
              return {
                ...permission,
                action: 'Edit Layout',
                description: 'Allows the user to edit the name of a Layout.',
              };
            case 'Edit Dashboard Data':
              return {
                ...permission,
                action: 'Edit Layout Data',
                description:
                  'Allows the user to add and remove Wigets to and from a Layout.',
              };
            case 'Delete Dashboard':
              return {
                ...permission,
                action: 'Delete Layout',
                description: 'Allows the user to delete a Layout.',
              };
            case 'Open or Close Incident':
              return {
                ...permission,
                action: 'Archive Incident',
                description:
                  'Allows the user to end the activity of a Incident. This locks out all Incident members from performing any action within the Incident or accessing Incident data.',
              };
            default:
              return permission;
          }
        });

    return permissions || [];
  });

  const reduxAllPermissionsInRole = useSelector((state) => {
    return state.app.allPermissionsInRole;
  });

  useEffect(() => {
    if (typeof reduxAllPermissionsInRole !== 'undefined') {
      setRolePermissions(
        reduxAllPermissionsInRole.map((permission) => {
          return permission.id;
        })
      );
    }
  }, [reduxAllPermissionsInRole]);

  const [rolePermissions, setRolePermissions] = useState([]);

  useEffect(() => {
    if (existingRole) {
      setValue('roleName', existingRole.name);
      setValue('roleDescription', existingRole.description);
    }
  }, [existingRole]);

  const getIsPermissionChecked = (permission_id) => {
    return rolePermissions.includes(permission_id);
  };

  const handlePermissionsCheckboxChange = (permission_id) => {
    let newPermissions = rolePermissions;
    if (rolePermissions.includes(permission_id)) {
      newPermissions = rolePermissions.filter(
        (permission) => permission !== permission_id
      );
    } else {
      const readPermission = reduxAllPermissions.find(
        (permission) => permission.action === 'Read From Private Database'
      );
      const writePermission =
        reduxAllPermissions.find(
          (permission) => permission.action === 'Write To Private Database'
        ) || {};
      const deletePermission =
        reduxAllPermissions.find(
          (permission) => permission.action === 'Delete From Private Database'
        ) || {};
      const hasReadPermission =
        !!readPermission &&
        !!rolePermissions.find(
          (existingId) => existingId === readPermission.id
        );

      if (
        !hasReadPermission &&
        (writePermission.id === permission_id ||
          deletePermission.id === permission_id)
      ) {
        toast.error(
          'You must first enable the permission to read from the database.'
        );
      } else {
        newPermissions.push(permission_id);
      }
    }
    setRolePermissions([...newPermissions]);
  };

  if (
    typeof reduxAllPermissions === 'undefined' ||
    typeof reduxAllPermissionsInRole === 'undefined'
  ) {
    return <div>Loading Edit Role Dialog....</div>;
  }

  const onEditRoleCreateSubmit = ({ roleName, roleDescription }) => {
    setIsLoaded(false);

    const role = {};
    role['role_id'] = existingRole.id;
    role['group_guid'] = group_guid;
    role['role_name'] = roleName;
    role['description'] = roleDescription;
    role['access_level'] = existingRole.access_level;
    role['permissions'] = rolePermissions;
    role['tags'] = [];
    reduxDispatch(startLoading());
    reduxDispatch(editRole(role));
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Edit Role</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onEditRoleCreateSubmit)}>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Role Name:</label>
              <Controller
                control={control}
                name="roleName"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={(e) => onChange(e.target.value)}
                    value={value || ''}
                  />
                )}
              />
              {errors.roleName && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {['Org Admin', 'Group Admin'].includes(watch('roleName')) ? (
                <span className="form-text form-error">
                  You cannot create a role with that name
                </span>
              ) : null}
            </div>
            <div className="mb-3">
              <label className="form-label">Role Description:</label>
              <Controller
                control={control}
                name="roleDescription"
                rules={{ required: true, maxLength: 500 }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    value={value || ''}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
              {errors.roleDescription?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors.roleDescription?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Maximum 500 character allowed
                </span>
              )}
            </div>
            <hr className="dashed" />
            {!['Org Admin', 'Group Admin'].includes(watch('roleName')) && (
              <>
                <h5>Permissions</h5>
                {reduxAllPermissions.map((permission) => {
                  return (
                    <>
                      <div className="mt-3" key={permission.id}>
                        <Controller
                          control={control}
                          name={permission.id}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewCheckbox
                              checked={getIsPermissionChecked(permission.id)}
                              onClick={() =>
                                handlePermissionsCheckboxChange(permission.id)
                              }
                              label={permission.action}
                            />
                          )}
                        />
                        <span className="form-info mt-2">
                          {permission.description}
                        </span>
                      </div>
                      <hr className="my-3" />
                    </>
                  );
                })}
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <div className="button-group">
              <StylishNewButton
                type="submit"
                className="button--primary"
                disabled={
                  !isLoaded ||
                  ['Org Admin', 'Group Admin'].includes(watch('roleName')) ||
                  (isSubmitted && !isValid)
                }
              >
                Edit Role
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
