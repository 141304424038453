import { getLoaclTimeZoneAndOffset } from '../../utils/dashboardApplication';
import {
  CHANGE_SELECTED_WORKSPACE,
  CHANGE_WORKSPACE_TIMEZONE,
  UPDATE_ALL_WORKSPACE_LIST,
  ADD_WORKSPACE_TO_WORKSPACE_LIST,
  CHANGE_SELECTED_WORKSPACE_STATUS,
} from './workspaces.constants';

// Define the shape of the state
interface WorkspaceState {
  selectedWorkspace: string | null;
  timezone: string;
  timezoneOffset: number;
  workspaceName: string | null;
  status: boolean;
  type: string | null;
  allWorkspace: Array<any>; // Replace `any` with the specific type if known
  allWorkspaceLoaded: boolean;
}

// Define the shape of the action
interface WorkspaceAction {
  type: string;
  payload?: any; // Replace `any` with the specific type if known
}

// Initial state with proper typing
const initialState: WorkspaceState = {
  selectedWorkspace: null,
  timezone: getLoaclTimeZoneAndOffset().timezone,
  timezoneOffset: getLoaclTimeZoneAndOffset().offset,
  workspaceName: null,
  status: false,
  type: null,
  allWorkspace: [],
  allWorkspaceLoaded: false,
};

// Reducer with proper typing
const workspaceReducer = (
  state: WorkspaceState = initialState,
  action: WorkspaceAction
): WorkspaceState => {
  switch (action.type) {
    case CHANGE_SELECTED_WORKSPACE:
      return {
        ...state,
        selectedWorkspace: action.payload,
      };

    case CHANGE_SELECTED_WORKSPACE_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case UPDATE_ALL_WORKSPACE_LIST:
      return {
        ...state,
        allWorkspace: action.payload,
        allWorkspaceLoaded: true,
      };

    case ADD_WORKSPACE_TO_WORKSPACE_LIST:
      return {
        ...state,
        allWorkspace: [...state.allWorkspace, action.payload],
      };

    case CHANGE_WORKSPACE_TIMEZONE:
      return {
        ...state,
        timezone: action.payload.timezone,
        timezoneOffset: action.payload.timezoneOffset,
      };

    default:
      return state;
  }
};

export default workspaceReducer;
