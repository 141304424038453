import React, { useEffect, useRef, useState } from 'react';
import PratusCornerLogo from 'images/branding/pratus-corner-logo.png';
import Dropdown from 'react-bootstrap/Dropdown';
import StylishNewSelect, {
  OptionType,
} from '../DesignSystems/New/StylishNewSelect';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentIncident,
  updateCurrentlySelectedGroup,
} from '../../actions/diceActions';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchExerciseWorkspaces } from '../../actions/workspaceActions';
import { getInitials } from '../../helpers/intials';
import SidebarNav from '../sidebarNav/SidebarNav';
import { useSidebarNavContext } from '../sidebarNav/SidebarNavProvider';
import { useClickAway } from 'react-use';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { useStreamlinePlatformFlag } from 'utils/featureFlags';

import * as Msal from '@azure/msal-browser';
import { msalConfig } from 'components/Auth/MSALAuthObjects';
import { useIsDev } from '../../hooks/useIsDev';
import { useSelectedExerciseWorkspace } from '../headers/useSelectedExerciseWorkspace';

const PratusHeader: React.FC = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const iapMatch = useMatch({ path: '/iap/:incidentId', end: false });

  const streamlinePlatform = useStreamlinePlatformFlag();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleRef = React.createRef<HTMLButtonElement>();

  const { toggle, isOpen } = useSidebarNavContext();

  const [showDropdown, setShowDropdown] = useState(false);
  const isDev = useIsDev();
  const [groupOptions, setGroupOptions] = useState<OptionType[]>([]);
  const [incidentOptions, setIncidentOptions] = useState<OptionType[]>([]);

  const { teamsInitialized } = useSelector((state: any) => {
    return state.auth;
  });

  const user = useSelector((state: any) => {
    // TODO: obviously this should just be done in the reducer.
    // BEWARE using this user in dependency arrays. depend on a specific property.
    return {
      ...(state.app.user as User),
      ...(state.prepare.user as PrepareUser),
    };
  });

  const featureFlags = useSelector((state: any) => {
    return state.app.featureFlags as string[];
  });

  const currentGroup = useSelector((state: any) => {
    return state.app.currentlySelectedGroup as Group;
  });

  const groups = useSelector((state: any) => {
    return state.app.groups as Group[];
  });

  const currentIncident = useSelector((state: any) => {
    return state.app.currentIncident as IncidentAugmented;
  });

  const incidents = useSelector((state: any) => {
    return state.app.incidents as IncidentAugmented[];
  });

  useClickAway(dropdownRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setGroupOptions(
      groups
        .filter((group) => !group.is_closed)
        .map((group) => ({
          label: group.group_name,
          value: group.group_guid,
        }))
    );
  }, [groups]);
  useEffect(() => {
    setIncidentOptions(
      incidents
        .filter((incident) => incident.group_guid === currentGroup.group_guid)
        .map((incident) => ({
          label: incident.name,
          value: incident.id,
        }))
    );
    const urlIncidentId = iapMatch?.params.incidentId;
    if (urlIncidentId && !currentIncident) {
      const foundIncident = incidents.find(
        (incident) => incident.id === urlIncidentId
      );
      dispatch(updateCurrentIncident(foundIncident));
    }
  }, [
    currentIncident,
    dispatch,
    incidents,
    currentGroup,
    iapMatch?.params.incidentId,
  ]);

  const {
    exercises,
    selectedExercise: currentExercise,
    handleSelectExerciseId: exerciseChangeHandler,
  } = useSelectedExerciseWorkspace();

  const exerciseOptions = exercises.map((exercise) => ({
    label: exercise?.displayName,
    value: exercise?.id,
  }));

  useEffect(() => {
    if (currentGroup.group_guid) {
      dispatch(
        fetchExerciseWorkspaces(user.user_guid, currentGroup.group_guid)
      );
    }
  }, [dispatch, user.user_guid, currentGroup.group_guid]);

  const organizationChangeHandler = (orgGuid: string) => {
    if (orgGuid !== currentGroup?.group_guid) {
      dispatch(
        updateCurrentlySelectedGroup(
          groups.find((group) => group.group_guid === orgGuid)
        )
      );
      const firstIncident = incidents.find(
        (incident) => incident.group_guid === orgGuid
      );
      dispatch(updateCurrentIncident(firstIncident));
      navigate(`/map`);
    }
  };

  const incidentChangeHandler = (incidentId: string) => {
    if (incidentId !== currentIncident?.id) {
      dispatch(
        updateCurrentIncident(
          incidents.find((incident) => incident.id === incidentId)
        )
      );
    }
  };

  const handleLogout = async () => {
    if (!teamsInitialized) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    } else {
      const msalInstance = new Msal.PublicClientApplication(msalConfig);
      await msalInstance.initialize(); // Initialize the MSAL instance before any other operations
      await msalInstance.logoutRedirect({
        onRedirectNavigate: () => false,
      });
      navigate('/logout');
    }
  };

  return (
    <>
      <header
        id="app-header"
        className="bg-bglight d-flex justify-content-between position-sticky top-0 zIndex--3"
      >
        <div className="d-flex logo-container d-flex align-items-center">
          <img src={PratusCornerLogo} alt="Pratus Logo" />
          <button
            className="button--icon sml border-0 ms-2 p-3"
            onClick={() => toggle()}
            ref={toggleRef}
          >
            {isOpen ? (
              <i className="material-symbols-outlined">menu_open</i>
            ) : (
              <i className="material-symbols-outlined">menu</i>
            )}
          </button>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex ps-2 help-icon">
            {/*TODO: tab focus state not working on this and the dropdown toggle*/}
            {featureFlags.includes('SELF_HOST_KNOWLEDGE_BASE') ? (
              <NavLink to="/knowledge_base">
                <i className="material-symbols-outlined color-primary-hover">
                  help
                </i>
              </NavLink>
            ) : (
              <a
                className="text-white"
                href={'https://disastertech-6694186.hs-sites.com/knowledge'}
                target="_blank"
                rel="noreferrer"
              >
                <i className="material-symbols-outlined color-primary-hover">
                  help
                </i>
              </a>
            )}
          </div>

          <Dropdown
            className="dropdown__pratus"
            ref={dropdownRef}
            show={showDropdown}
          >
            <Dropdown.Toggle
              className="button button--secondary bg-0 border-0"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <div className="avatar-initials sml">
                {getInitials(
                  user.profile_settings?.userName ?? user.email_address
                )}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <div className="section-profile">
                <div className="d-flex align-items-center">
                  <div className="avatar-initials sml bg-bglight">
                    {getInitials(
                      user.profile_settings?.userName ?? user.email_address
                    )}
                  </div>
                  <div className="d-flex flex-column justify-content-start text-white ps-2">
                    <span>{user.profile_settings?.userName}</span>
                    <span className="tw-text-xs">{user.email_address}</span>
                  </div>
                </div>
              </div>
              <Dropdown.Divider />
              <div className="section-select">
                <label className="form-label">Sponsor Organization</label>
                <StylishNewSelect
                  classes={'secondary sml'}
                  options={groupOptions}
                  value={
                    currentGroup?.group_guid
                      ? {
                          value: currentGroup.group_guid,
                          label: currentGroup.group_name,
                        }
                      : ''
                  }
                  placeholder="Select a Organization"
                  onChange={(e) => {
                    organizationChangeHandler(e.value);
                  }}
                />
                <label className="form-label">Incident Workspace</label>
                <StylishNewSelect
                  classes={'secondary sml'}
                  options={incidentOptions}
                  value={
                    currentIncident?.id
                      ? {
                          value: currentIncident.id,
                          label: currentIncident.name,
                        }
                      : ''
                  }
                  placeholder="Select an Incident Workspace"
                  onChange={(e) => incidentChangeHandler(e.value)}
                />
                <label className="form-label">Exercise Workspace</label>
                <StylishNewSelect
                  classes={'secondary sml'}
                  options={exerciseOptions}
                  value={
                    currentExercise?.id
                      ? {
                          value: currentExercise.id,
                          label: currentExercise.displayName,
                        }
                      : ''
                  }
                  placeholder="Select an Exercise Workspace"
                  onChange={(e) => exerciseChangeHandler(e.value)}
                />
              </div>
              <div className="section-pages">
                <Dropdown.Item
                  as={NavLink}
                  to="/profile"
                  onClick={() => setShowDropdown(false)}
                >
                  Profile
                </Dropdown.Item>
                {streamlinePlatform ? null : (
                  <Dropdown.Item
                    as={NavLink}
                    to="/activity"
                    onClick={() => setShowDropdown(false)}
                  >
                    Activity Log
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  as="a"
                  href={window.location.origin + '/privacy.html'}
                  target="_blank"
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                >
                  Privacy Policy
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  href={window.location.origin + '/tos.html'}
                  target="_blank"
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                >
                  Terms of Service
                </Dropdown.Item>
                {isDev ? (
                  <>
                    <Dropdown.Item
                      as={NavLink}
                      to="/subscription_management"
                      onClick={() => setShowDropdown(false)}
                    >
                      Subscription Management
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/feature_flags"
                      onClick={() => setShowDropdown(false)}
                    >
                      Feature Flags
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/ai_usages"
                      onClick={() => setShowDropdown(false)}
                    >
                      AI Usages
                    </Dropdown.Item>
                    <div className="version mt-2">
                      {process.env.REACT_APP_PLATFORM_VERSION}
                    </div>
                  </>
                ) : null}
                <Dropdown.Divider className="mb-0" />
                <Dropdown.Item
                  onClick={() => handleLogout()}
                  className="pointer-cursor d-flex justify-content-center align-items-center"
                >
                  <SharedIcon iconName="logout" />
                  <span className="ps-2">Log Out</span>
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      <SidebarNav toggleRef={toggleRef} />
    </>
  );
};

export default PratusHeader;
