import React, { useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import SectionTemplateManager from './SectionTemplateManager';
import SitrepTemplateManager from './SitrepTemplateManager';
import CustomDataSourceManager from './CustomDataSourceManager';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import SitrepManager from './SitrepManager';
import { SitrepLoader } from './SitrepLoader';

type SitrepSidebarProps = {
  onClose: () => void;
  selectedDatetime: string;
  map: any;
  mapHasLoaded: boolean;
  toggleDGPTOpen: () => void;
};

const SitrepSidebar: React.FC<SitrepSidebarProps> = ({
  onClose,
  selectedDatetime,
  map,
  mapHasLoaded,
  toggleDGPTOpen,
}) => {
  const [selectedSitrepId, setSelectedSitrepId] = useState<UUID | undefined>();

  const [currentView, setCurrentView] = useState('list');

  const views = [
    { label: 'SITREPS', value: 'list' },
    { label: 'SITREP Templates', value: 'sitrepTemplates' },
    { label: 'Section Templates', value: 'sectionTemplates' },
    { label: 'Custom Data', value: 'customDataSources' },
  ];

  /**
   * NOTE: We're using flex-column to achieve scrolling without worrying about calculating
   * the height of the sidebar. Sidebar-title needs some flex love (0 0 4rem) stay exactly 4rem in height.
   */
  return (
    <div className="sidebar-content-wide d-flex flex-column">
      <div
        className="sidebar-title"
        style={{
          flex: '0 0 4rem',
        }}
      >
        {selectedSitrepId ? (
          <StylishNewButton
            className={'button--icon'}
            onClick={() => setSelectedSitrepId(undefined)}
          >
            <SharedIcon iconName={'arrow_left_alt'} size={'sm'} />
          </StylishNewButton>
        ) : null}

        <h4 className="m-0">SITREPS</h4>

        <StylishNewButton className={'button--icon'} onClick={onClose}>
          <SharedIcon iconName={'close'} />
        </StylishNewButton>
      </div>

      {selectedSitrepId ? (
        <div
          style={{
            overflowY: 'auto',
          }}
        >
          <SitrepLoader
            sitrepId={selectedSitrepId}
            map={map}
            mapHasLoaded={mapHasLoaded}
            toggleDGPTOpen={toggleDGPTOpen}
            selectedDatetime={selectedDatetime}
          />
        </div>
      ) : (
        <>
          <StylishNewToggleButtonGroup
            className={'p-2'}
            data={views}
            selected={currentView}
            onChange={(val) => setCurrentView(val)}
          />

          {currentView === 'list' ? (
            <SitrepManager handleSitrepSelect={setSelectedSitrepId} />
          ) : currentView === 'sitrepTemplates' ? (
            <SitrepTemplateManager />
          ) : currentView === 'sectionTemplates' ? (
            <SectionTemplateManager />
          ) : currentView === 'customDataSources' ? (
            <CustomDataSourceManager />
          ) : null}
        </>
      )}
    </div>
  );
};

export default SitrepSidebar;
